import React, { useState, useEffect } from "react";
import { Layout } from "../../containers";
import { VALIDATE_TOKEN, RESET_PASSWORD_EMAIL } from "../../services/auth.service";
import { WrapAuthForm } from "./style";
import {
	// TextField,
	OutlinedInput,
	useMediaQuery,
	InputAdornment,
	IconButton,
} from "@material-ui/core";

import { useAuthContext } from "../../contexts/Auth.context";
import { ButtonCustom } from "../../components";
import swal from "sweetalert";

const ValidateToken = (props) => {
	const [form, setForm] = useState({
		token: "",
	});
	const { isAuthenticated } = useAuthContext();

	const isMobile = useMediaQuery("(max-width:500px)");

	const onChange = async (name, value) => {
		setForm({
			...form,
			[name]: value,
		});
	};

	const sendResetPassword = async () => {
		let payload = {
			email: props.match.params.email,
		};
		await RESET_PASSWORD_EMAIL(payload)
			.then(async (response) => {
				if (response.message === "Ok") {
					swal("Berhasil", "Password reset email sent !", "success");
				}
			})
			.catch((e) => {
				swal(
					"Error",
					`${
						e.response.data?.errors?.email?.toString()
							? `${e.response.data?.errors?.email?.toString()},`
							: ""
					}
		            `,
					"error",
				);
			});
	};

	const onSubmit = async () => {
		let payload = {
			...form,
			email: props.match.params.email,
		};
		await VALIDATE_TOKEN(payload)
			.then(async (response) => {
				if (response.message === "Ok") {
					swal("Berhasil", "Token Terverifikasi", "success");
					localStorage.setItem("TOKEN_EMAIL", form.token);
					window.location = `/reset-password/${props.match.params.email}`;
				}
			})
			.catch((e) => {
				swal("Error", `Token Expired !`, "error");
			});
	};

	useEffect(() => {
		isAuthenticated && props.history.push("/");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout withNavbar={false} {...props}>
			<WrapAuthForm>
			<div className="header">
				<div className="title">Email Dikirim</div>
					<div className="subtitle">
					<p>
						Kami telah mengirim tautan verifikasi ke email kamu.kamu bisa konfirmasi
						email kamu dengan membuka tautan tersebut, atau memasukkan 4 digit kode yang
						ada dalam email tersebut.
					</p>
				</div>
				</div>
				<div style={isMobile ? { padding: 16 } : {}}>
					<div className="formGroup">
						<div style={{ marginBottom: "16px" }}>
							<OutlinedInput
								id="standard-adornment-token"
								placeholder="kode"
								fullWidth
								type={"text"}
								value={form.token}
								size="small"
								onChange={(e) => onChange("token", e.target.value)}
							/>
						</div>
					</div>
					<div style={{ textAlign: "center", marginTop: 16, marginBottom: 16 }}>
						<p>
							Tidak Menerima kode ?{" "}
							<div
								onClick={sendResetPassword}
								style={{ cursor: "pointer", color: "#602993" }}
							>
								Kirim Ulang !
							</div>
						</p>
					</div>
					<ButtonCustom color="primary" onClick={onSubmit}>
						OK
					</ButtonCustom>
				</div>
			</WrapAuthForm>
		</Layout>
	);
};

export default ValidateToken;
