import { useMediaQuery } from "@material-ui/core";
import React from "react";
import PaymentPageDesktop from "./PaymentPage.desktop";
import PaymentPageMobile from "./PaymentPage.mobile";

const PaymentPage = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return isMobile ? <PaymentPageMobile {...props} /> : <PaymentPageDesktop {...props} />;
};
export default PaymentPage;
