import styled from "styled-components";

export const WrapAuthForm = styled.div`
	position: relative;
	padding: 64px 78px;
	width: 654px;
	margin: 0 auto;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	margin-top: 32px;
	.title {
		color: #333;
		font-size: 36px;
		font-family: "DMSans-Bold";
		text-align: center;
		margin-bottom: 28px;
	}
	.withSocial {
		cursor: pointer;
		background: rgba(239, 248, 252, 1);
		border-radius: 5px;
		margin-bottom: 8px;
		.center {
			display: flex;
			margin: 0 auto;
			width: fit-content;
			padding: 15px 0px;
		}
		.logo {
			width: 14px;
			img {
				width: 100%;
			}
		}
		.text {
			margin-left: 14px;
			color: #999;
			font-size: "inter-Regular";
			font-size: 14px;
		}
	}
	.separator {
		color: #c5c9d1;
		font-size: 12px;
		margin: 16px 0px;
		display: flex;
		:before {
			content: "";
			width: 50%;
			height: 1px;
			display: block;
			background: #c5c9d1;
			position: relative;
			top: 8px;
			margin-right: 8px;
		}
		:after {
			content: "";
			width: 50%;
			height: 1px;
			display: block;
			background: #c5c9d1;
			position: relative;
			top: 8px;
			margin-left: 8px;
		}
	}

	.formGroup {
		margin-bottom: 24px;
		border-radius: 5px;
		.MuiInput-underline:before {
			border-bottom: none;
		}
		.MuiInput-underline:after {
			border-bottom: none;
		}
		.MuiInput-underline:hover:not(.Mui-disabled):before {
			border-bottom: none;
		}
		.MuiInputBase-input {
			border-bottom: none;
			:focus {
				border-bottom: none;
			}
		}
		.MuiInput-formControl {
			border: 1px solid #c4c4c4;
			border-radius: 5px;
			margin-bottom: 16px;
			input {
				padding: 12px 16px;
				background: #fff;
			}
		}
		.MuiOutlinedInput-notchedOutline {
			border: none;
			box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
		}
		label {
			margin-bottom: 12px;
			display: block;
		}
	}
	.others {
		text-align: center;
		font-size: 12px;
		color: #rgba(66, 66, 66, 1);
		margin-top: 34px;
		a {
			color: rgba(255, 117, 151, 1);
			text-decoration: none;
			font-weight: 700;
		}
		p {
			margin-bottom: 8px;
		}
	}

	.content {
		.formInput {
			margin-bottom: 18px;
			span {
				color: #999;
			}
		}
		padding: 16px;
		.MuiInput-underline:before {
			border-bottom: none;
		}
		.MuiInput-underline:after {
			border-bottom: none;
		}
		.MuiInput-underline:hover:not(.Mui-disabled):before {
			border-bottom: none;
		}
		.MuiInputBase-input {
			border-bottom: none;
			:focus {
				border-bottom: none;
			}
		}
		.MuiInput-formControl {
			border: 1px solid #c4c4c4;
			border-radius: 5px;
			input {
				padding: 12px 16px;
				background: #fff;
			}
		}
		.MuiOutlinedInput-notchedOutline {
			border: none;
			box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
		}
		label {
			margin-bottom: 12px;
			display: block;
		}
		.MuiFormControlLabel-root {
			margin-left: 4px;
			.MuiTypography-root {
				font-size: 14px;
				line-height: 16px;
				color: #333;
			}
		}
	}

	@media screen and (max-width: 500px) {
		width: 100%;
		padding: 0px;
		box-shadow: unset;

		.content {
			padding: 16px;
			padding-top: 220px;
		}
	}
`;
