import { Radio, withStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ButtonCustom, Skeleton } from "../../components";
import constanta from "../../configs/constanta";
import { Layout, Navbar } from "../../containers";
import { handleSubmitSelect } from "./handler";
import { WrapCustomerServiceDesktop } from "./style";

const CustomerServiceDesktop = (props) => {
	const {
		isLoading,
		isDisabled,
		selected,
		customerService,
		handleSelectCustomerService,
	} = props;

	const PinkRadio = withStyles({
		root: {
			color: `${constanta.COLOR.primary}`,
			"&$checked": {
				color: `${constanta.COLOR.primary}`,
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	return (
		<Layout withNavbar={true} {...props}>
			<WrapCustomerServiceDesktop>
				<div className="container">
					<p style={{ marginBottom: "24px" }}>Pilih CS Support</p>
					{isLoading ? (
						<Skeleton type="bulletList" />
					) : (
						customerService.map((item, index) => (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "16px",
									justifyContent: "space-between",
								}}
								key={index}
								onClick={() => handleSelectCustomerService(index)}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<div
										style={{
											width: "100px",
											height: "100px",
										}}
									>
										<img
											loading="lazy"
											style={{
												width: "100%",
												height: "100%",
												objectFit: "cover",
												borderRadius: "50%",
											}}
											src={
												constanta.APP_HOST_NAME + "/" + item.photo
											}
											alt={item.nama}
										/>
									</div>
									<div style={{ marginLeft: "24px" }}>
										<p style={{ fontWeight: "bold" }}>
											{item.nama}{" "}
											<span
												style={{
													color: constanta.COLOR.primary,
												}}
											>{`(${item.category.name})`}</span>
										</p>
										<p>{item.nomor}</p>
									</div>
								</div>
								<div className="radioBtn">
									<PinkRadio
										checked={selected === index}
										value={selected}
									/>
								</div>
							</div>
						))
					)}
				</div>
				<ButtonCustom
					style={{ margin: "0px 8px" }}
					rounded
					isDisabled={isDisabled}
					onClick={() => handleSubmitSelect(customerService[selected].nomor)}
				>
					Pilih CS
				</ButtonCustom>
			</WrapCustomerServiceDesktop>
		</Layout>
	);
};
export default CustomerServiceDesktop;
