import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ProfilePageDesktop from "./ProfilePage.desktop";
import ProfilePageMobile from "./ProfilePage.mobile";

const Profile = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <ProfilePageMobile {...props} /> : <ProfilePageDesktop {...props} />;
};

export default Profile;
