import React, { useState, useEffect } from "react";
import {
	GET_PROFILE,
	PUT_PROFILE,
	PUT_PHOTO_PROFILE,
} from "../../../services/user.service";
import { Spacer } from "../../../components";
import { Layout, Navbar } from "../../../containers";
import {
	Button,
	Avatar,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormLabel,
	CircularProgress,
} from "@material-ui/core";
import { WrapProfileMobile, WrapMenu } from "./style";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";
import constanta from "../../../configs/constanta";

const EditProfileMobile = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState({});
	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			...user,
		},
	});

	const onSubmit = async (value) => {
		setIsLoading(true);
		let payload = {
			phone_code_id: 62,
			...value,
		};
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		await PUT_PROFILE(user.id, payload)
			.then((res) => {
				swal("Success", "Berhasil merubah profile saya", "success");
				retrieveDataUser();
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const retrieveDataUser = () => {
		setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		GET_PROFILE(user.id)
			.then((res) => {
				let initialUser = {
					name: res.data.name,
					phone: res.data.phone,
					email: res.data.email,
					gender: res.data.gender,
					bio: res.data.bio,
					birthday: res.data.birthday,
					profile_photos: res.data.profile_photos,
					referral_code: res.data.referral_code,
					customer_type_id: res.data.customer_type_id,
				};
				setUser(initialUser);
				reset(initialUser);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleSubmitUpload = (e) => {
		e.preventDefault();
		let dataImage = e.target.files[0];
		var formData = new FormData();
		formData.append("avatar", dataImage);

		let token = JSON.parse(localStorage.getItem("TOKEN"));
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		if (dataImage) {
			setIsLoading(true);
			PUT_PHOTO_PROFILE(user.id, formData, token.accessToken)
				.then((response) => {
					swal("Success", "Berhasil merubah photo profile saya", "success");
					retrieveDataUser();
				})
				.catch((err) => {
					swal("Error", "Gagal merubah photo profile saya", "success");
				});
		} else {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		retrieveDataUser();
	}, []);

	const profilePhoto = user.profile_photos
		? user.profile_photos.medium.url
		: "https://via.placeholder.com/150";

	return (
		<Layout withNavbar={false} {...props}>
			<Navbar type="navback" title="Edit Saya" {...props} />
			<WrapProfileMobile>
				{isLoading ? (
					<div
						style={{ display: "flex", flexDirection: "column" }}
						className="content">
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								height: "100%",
								justifyContent: "center",
							}}>
							<CircularProgress disableShrink />
							<p style={{ marginTop: "14px" }}>Loading</p>
						</div>
					</div>
				) : (
					<>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								marginTop: "70px",
							}}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "20px",
									justifyContent: "center",
								}}>
								<div>
									<Avatar
										alt="photo saya"
										src={profilePhoto}
										style={{ height: "100px", width: "100px" }}
									/>
									<div style={{ marginTop: "14px" }}>
										<input
											accept="image/*"
											style={{ display: "none" }}
											id="contained-button-file"
											multiple
											type="file"
											onChange={handleSubmitUpload}
										/>
										<label htmlFor="contained-button-file">
											<Button
												variant="outlined"
												color="primary"
												component="span">
												Pilih Photo
											</Button>
										</label>
									</div>
								</div>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "20px",
								}}>
								<Controller
									name="name"
									control={control}
									render={({ field }) => {
										return (
											<TextField
												{...field}
												autoFocus={true}
												label={"Nama"}
												variant="outlined"
												fullWidth
												rounded={true}
												disabled={isLoading}
											/>
										);
									}}
								/>
							</div>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "20px",
								}}>
								<Controller
									name="gender"
									control={control}
									render={({ field }) => {
										return (
											<>
												<FormLabel
													component="legend"
													style={{ paddingRight: "14px" }}>
													Gender:
												</FormLabel>
												<RadioGroup
													{...field}
													name="gender"
													style={{ flexDirection: "row" }}
													aria-label="gender"
													value={`${field.value}`}>
													<FormControlLabel
														value="0"
														control={<Radio />}
														label="Laki-Laki"
														color="primary"
													/>
													<FormControlLabel
														value="1"
														control={<Radio />}
														label="Perempuan"
														color="primary"
													/>
												</RadioGroup>
											</>
										);
									}}
								/>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "20px",
								}}>
								<Controller
									name="birthday"
									control={control}
									render={({ field }) => {
										return (
											<TextField
												{...field}
												type="date"
												autoFocus={true}
												label={"Tanggal Lahir"}
												variant="outlined"
												fullWidth
												rounded={true}
												disabled={isLoading}
											/>
										);
									}}
								/>
							</div>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "20px",
								}}>
								<Controller
									name="email"
									control={control}
									render={({ field }) => {
										return (
											<TextField
												{...field}
												autoFocus={true}
												label={"Email"}
												variant="outlined"
												fullWidth
												rounded={true}
												disabled={isLoading}
											/>
										);
									}}
								/>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "20px",
								}}>
								<Controller
									name="phone"
									control={control}
									render={({ field }) => {
										return (
											<TextField
												defaultValue={field.value}
												type="number"
												autoFocus={true}
												{...field}
												label={"No Telepon"}
												variant="outlined"
												fullWidth
												rounded={true}
												disabled={isLoading}
											/>
										);
									}}
								/>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "20px",
								}}>
								<Controller
									name="bio"
									control={control}
									render={({ field }) => {
										return (
											<TextField
												{...field}
												label={"Bio"}
												autoFocus={true}
												variant="outlined"
												fullWidth
												rounded={true}
												disabled={isLoading}
											/>
										);
									}}
								/>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "20px",
								}}>
								{user && user?.customer_type_id == 11 ? (
									<Controller
										name="referral_code"
										control={control}
										render={({ field }) => {
											return (
												<TextField
													{...field}
													label={"Kode Referral"}
													autoFocus={true}
													variant="outlined"
													fullWidth
													rounded={true}
													disabled={isLoading}
												/>
											);
										}}
									/>
								) : null}
							</div>
						</div>

						<div style={{ width: "100%" }}>
							<Button
								size="large"
								style={{ width: "100%" }}
								onClick={handleSubmit((value) => {
									onSubmit(value);
								})}
								color="primary"
								variant="contained">
								Simpan Perubahan
							</Button>
						</div>
					</>
				)}
			</WrapProfileMobile>
		</Layout>
	);
};

export default EditProfileMobile;
