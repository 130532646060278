import styled from "styled-components";

export const WrapProductCard = styled.div`
	position: relative;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0px 2px 15px rgba(0, 18, 30, 0.06);
	margin-right: 11px;
	cursor: pointer;

	.image {
		.discount {
			position: absolute;
			right: 16px;
			text-align: center;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 38px;
			}
			.text {
				font-size: 8px;
				color: #fff;
				z-index: 1;
				position: relative;
				left: 4px;
				top: 2px;
			}
			.total {
				font-size: 14px;
				color: #fff;
				z-index: 1;
				position: relative;
				left: 4px;
				top: 2px;
			}
		}
		img {
			width: 100%;
			border-radius: 4px;
		}
	}
	.desc {
		padding: 8px;
		font-family: "Inter-Medium";
		.title {
			width: 100%;
			min-height: 28px;
			overflow: hidden;
			text-overflow: ellipsis;
			h4 {
				margin: 0;
				padding: 0;
				color: #626262;
				font-size: 11px;
				font-weight: 500;
				font-family: "Inter-Medium";
			}
		}
		.price {
			margin-top: 8px;
			font-size: 12px;
			color: #1E3354;
			font-weight: 700;
			font-family: "Inter-Medium";
		}
		.discount {
			display: flex;
			margin-top: 8px;
			.price-strike {
				color: #B9B9B9;
				font-size: 8px;
				font-family: "Inter-Regular";
				text-decoration: line-through;
				line-height: 12px;
			}
			.percentage {
				margin-right: 4px;
				color: #fff;
				font-size: 8px;
				font-family: "Inter-Regular";
				background: #DB6F5E;
				border-radius: 2px;
				padding: 1px 3px;
				text-align: center;
			}
		}
	}

	.rating {
		margin-top: 6px;
		display: flex;
		font-size: 10px;
		color: #7F8E9D;
		span {
			padding-right: 6px;
			margin: 0 6px;
			border-right: 1px solid #e5e5e5;
		}

	@media (max-width: 500px) {
		.desc {
			.title {
				height: 30px;
				h4 {
					font-size: 12px;
				}
			}
			.price {
				font-size: 12px;
				margin-top: 4px;
			}
			.discount {
				.price-strike,
				.percentage {
					font-size: 12px;
				}
			}
		}
	}
`;

export const WrapBundlingCard = styled.div`
	position: relative;
	border-radius: 8px;
	background-color: #ffffff;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
	margin-right: 14px;
	cursor: pointer;
	min-width: 300px;
	min-height: 339px;

	.price {
		margin-top: 8px;
		font-size: 16px;
		color: #d64d6f;
		font-family: "DMSans-Regular";
	}

	.discount {
		display: flex;
		margin-top: 8px;
		.price-strike {
			color: #959595;
			font-size: 13px;
			font-family: "DMSans-Regular";
			text-decoration: line-through;
		}
		.percentage {
			margin-left: 8px;
			color: #4db6ac;
			font-size: 12px;
			font-family: "DMSans-Regular";
			background: rgba(116, 214, 214, 0.2);
			border-radius: 4px;
			padding: 0px 8px;
			text-align: center;
		}
	}

	@media (max-width: 500px) {
		.desc {
			.title {
				height: 30px;
				h4 {
					font-size: 12px;
				}
			}
			.price {
				font-size: 12px;
				margin-top: 4px;
			}
			.discount {
				.price-strike,
				.percentage {
					font-size: 12px;
				}
			}
		}
	}
`;

export const WrapDetailBundling = styled.div`
	position: relative;
	border-radius: 8px;
	background-color: #ffffff;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
	margin-right: 14px;
	cursor: pointer;
	min-width: 260px;
	min-height: 339px;

	.price {
		margin-top: 8px;
		font-size: 16px;
		color: #d64d6f;
		font-family: "DMSans-Regular";
	}

	.discount {
		display: flex;
		margin-top: 8px;
		.price-strike {
			color: #959595;
			font-size: 13px;
			font-family: "DMSans-Regular";
			text-decoration: line-through;
		}
		.percentage {
			margin-left: 8px;
			color: #4db6ac;
			font-size: 12px;
			font-family: "DMSans-Regular";
			background: rgba(116, 214, 214, 0.2);
			border-radius: 4px;
			padding: 0px 8px;
			text-align: center;
		}
	}

	@media (max-width: 500px) {
		.desc {
			.title {
				height: 30px;
				h4 {
					font-size: 12px;
				}
			}
			.price {
				font-size: 12px;
				margin-top: 4px;
			}
			.discount {
				.price-strike,
				.percentage {
					font-size: 12px;
				}
			}
		}
	}
`;
