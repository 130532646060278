import { Checkbox, Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { LayoutWithAuth, Navbar } from "../../containers";
import { WrapCartPage, HStack, VStack } from "./style";
import { isEmpty } from "lodash";
import {
	EmptyState,
	MenuBottom,
	ProductCardFull,
	Spacer,
	ProductCardBundling,
} from "../../components";
import { useAuthContext } from "../../contexts/Auth.context";
import {
	EST_SHIPPING_COST,
	GET_COURIER_RECOMENDATION,
} from "../../services/supportData.service";
import {
	handleChecked,
	handleCheckedAll,
	handleDelete,
	handleDeleteAll,
	handlePlusMinus,
	handlePlusMinusBundling,
	handleDeleteBundling,
	subTotalPrice,
	subTotalPriceBundling,
} from "./handler";
import { ReactComponent as IconTrashGray } from "../../assets/icons/iconTrashGray.svg";

const CartPageMobile = (props) => {
	const { user } = useAuthContext();
	const [cart, setCart] = useState([]);
	const [isRefetch, setIsRefetch] = useState(false);
	let localstorage = JSON.parse(localStorage.getItem("CART"));

	const _mappingCart = () => {
		let render = [];
		return cart.map((item, index) => {
			console.log(item);
			if (item.type === "bundling") {
				render = (
					<ProductCardBundling
						productBundling={item}
						withCheckbox={true}
						type="default"
						isChecked={item.isChecked}
						handleChecked={(e) =>
							handleChecked(index, e.target.checked, setCart)
						}
						onDelete={() => handleDeleteBundling(item.id, cart, setIsRefetch)}
						handleMinus={() =>
							handlePlusMinusBundling("minus", item.id, cart, setIsRefetch)
						}
						handlePlus={() =>
							handlePlusMinusBundling("plus", item.id, cart, setIsRefetch)
						}
						quantity={item.total}
					/>
				);
			} else {
				render = (
					<ProductCardFull
						key={index}
						type="cart"
						name={item.name}
						image_url={item.object.image_url}
						price={
							!isEmpty(user) && user.customer_type?.name === "Customer"
								? item.object.price.consumer
								: item.object.price.user
						}
						// discount = '',
						slug={item.slug}
						color={item.color}
						size={item.size}
						is_preorder={item.is_preorder}
						day_preorder={item.day_preorder}
						onDelete={() => handleDelete(item.code, cart, setIsRefetch)}
						handleMinus={() =>
							handlePlusMinus("minus", item.code, cart, setIsRefetch)
						}
						handlePlus={() =>
							handlePlusMinus("plus", item.code, cart, setIsRefetch)
						}
						quantity={item.total}
						isChecked={item.isChecked}
						handleChecked={(e) =>
							handleChecked(index, e.target.checked, setCart)
						}
					/>
				);
			}
			return <div>{render}</div>;
		});
	};

	const countTotalPrice = (cart, user) => {
		let pcs = subTotalPrice(cart, user);
		let bundling = subTotalPriceBundling(cart);
		let total = pcs + bundling;
		return total;
	};

	useEffect(() => {
		const fetch = () => {
			let temp = [];
			if (localstorage !== null) {
				localstorage.map((item) => {
					temp.push({
						...item,
						isChecked: false,
					});
					return "";
				});
				setCart(temp);
				localStorage.setItem("CART", JSON.stringify(temp));
			}
		};

		const getCourierRecomendatiion = async () => {
			const recommendationCourier = await GET_COURIER_RECOMENDATION();
			if (recommendationCourier) {
				localStorage.setItem(
					"RECOMMENDATION_COURIER",
					JSON.stringify(recommendationCourier.data)
				);
			}
		};

		fetch();
		getCourierRecomendatiion();
		localStorage.removeItem("SHIPPING_SERVICES");
	}, []);

	useEffect(() => {
		const fetch = () => {
			let temp = [];
			localstorage.map((item) => {
				temp.push(item);
				return "";
			});
			setCart(temp);
			localStorage.setItem("CART", JSON.stringify(temp));
			setIsRefetch(false);
		};
		isRefetch && fetch();
	}, [isRefetch]);

	return (
		<LayoutWithAuth withNavbar={false} {...props}>
			<Navbar type="navback" title="Keranjang" {...props} />
			<WrapCartPage>
				{isEmpty(cart) ? (
					<EmptyState title="Tidak ada produk di keranjang" />
				) : (
					<>
						<div
							style={{
								display: "flex",
							}}>
							<HStack
								style={{
									alignItems: "center",
									justifyContent: "space-between",
									minHeight: "58px",
									paddingLeft: "4px",
									width: "100%",
								}}>
								<HStack style={{ alignItems: "center" }}>
									<Checkbox
										color="primary"
										onChange={(e) =>
											handleCheckedAll(e.target.checked, setCart)
										}
									/>
									Select all
								</HStack>
								{cart.filter((x) => x.isChecked).length >= 1 && (
									<div
										style={{
											marginRight: "14px",
											cursor: "pointer",
											color: "#FA634D",
										}}
										onClick={() =>
											handleDeleteAll(cart, setCart, setIsRefetch)
										}>
										Delete all
									</div>
								)}
							</HStack>
						</div>

						<div className="items">{_mappingCart()}</div>
					</>
				)}
			</WrapCartPage>
			<MenuBottom
				type="checkout"
				submitText="Checkout"
				isDisabled={!(cart.filter((x) => x.isChecked).length >= 1)}
				price={countTotalPrice(cart, user)}
				count={cart.filter((x) => x.isChecked).length}
				onSubmit={() => props.history.push("/checkout")}
			/>
		</LayoutWithAuth>
	);
};

export default CartPageMobile;
