import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapHome = styled.div`
	position: relative;
	min-height: 100vh;

	.title {
		margin: 16px 20px;
		color: #acaebe;
	}
	h5 {
		font-size: 20px;
		font-weight: 500;
	}
	p {
		color: ${constanta.COLOR.strk};
		font-size: 12px;
		line-height: 150%;
	}

	@media (max-width: 500px) {
		background: ${constanta.COLOR.lightBlue};
		padding-bottom: 30px;
		h3 {
			margin: 0;
		}
		.heading {
			font-size: 14px;
			color: ${constanta.COLOR.text};
			text-transform: lowercase;
		}
	}
`;

export const WrapMenu = styled.div`
	position: relative;
	background: ${constanta.COLOR.lightBlue};
	margin: 0 20px;
	padding: 0 12px;
	background: #fff;
	border-radius: 12px;
	button,
	a {
		width: 100%;
		height: 40px;
		justify-content: flex-start;
		height: 56px;
		// border-bottom: 1px solid #e5e5e5;
	}
	button.logout {
		justify-content: space-between;
		color: ${constanta.COLOR.red};
	}
`;
