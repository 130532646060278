import axios from "axios";
import swal from "sweetalert";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
	let getToken = JSON.parse(localStorage.getItem("TOKEN"));

	config.headers = Object.assign(
		{
			Authorization: "Bearer " + getToken?.accessToken ?? "",
		},
		config.headers,
	);
	return config;
});

axiosInstance.interceptors.response.use(
	function (response) {
		return response.data;
	},
	function (error) {
		if (error.response?.status === 401 && window.location.pathname !== "/login") {
			swal({ title: "Sesi berakhir silahkan login ulang", icon: "info" }).then(() => {
				localStorage.removeItem("TOKEN");
				localStorage.removeItem("USER");
				window.location = "/login";
			});
		} else if (error.response?.status === 401 && window.location.pathname === "/login") {
			swal({ title: error.response.data.error, icon: "info" }).then(() =>
				window.location.reload(),
			);
		} else if (error.response?.status === 500) {
			swal("Oops!", "Ups, data tidak ditemukan", "warning").then(() =>
				window.location.reload(),
			);
		} else {
			if (window.location.pathname === "/login") {
				swal("Oops!", "Email dan Password tidak cocok", "warning").then(() =>
					window.location.reload(),
				);
			} else if (window.location.pathname === "/register" && error.response.status === 422) {
				swal(
					"Oops!",
					"Ups, terjadi kesalahan " + error.response.data.errors.email,
					"warning",
				).then(() => window.location.reload());
			} else {
				swal("Oops", "Ups, terjadi kesalahan " + error.response?.status, "warning").then(
					() => window.location.reload(),
				);
			}
		}
		return Promise.reject(error);
	},
);
