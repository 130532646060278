import { useMediaQuery } from "@material-ui/core";
import React, { useEffect } from "react";
import CartPageDesktop from "./CartPage.dekstop";
import CartPageMobile from "./CartPage.mobile";

const CartPage = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <CartPageMobile {...props} /> : <CartPageDesktop {...props} />;
};

export default CartPage;
