import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ProductCard, ProductBundlingCard, Skeleton, EmptyState } from "../../components";
import { Layout, Navbar } from "../../containers";
import { WrapHome } from "./style";
import { useAuthContext } from "../../contexts/Auth.context";
import { useOrderContext } from "../../contexts/Order.context";
import { FAQ_API } from "../../services/faq.service";
import { ChipContained } from "../../components/chips";
import constanta from "../../configs/constanta";
import { Spacer } from "../../components";
import ListAccordion from "./listAccordion";
import LogoPink from "../../assets/img/logoNaishaPink.svg";


const FaqPageMobile = (props) => {
	const { refreshOrderContext } = useOrderContext();
	const { user } = useAuthContext();

	const isMobile = useMediaQuery("(max-width:500px)");
	const { isAuthenticated } = useAuthContext();

	const [faq, setDataFaq] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getFAQ = async () => {
		setIsLoading(true);

		FAQ_API()
			.then((res) => {
				setDataFaq(res);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getFAQ();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<WrapHome>
			<Navbar type="navback" {...props} />
			<Layout {...props} withNavbar={false}>
				<div style={{ marginTop: "60px", marginLeft: "14px", marginRight: "14px",  }}>
					<Grid container spacing={3} style={{ marginTop: 20 }}>
						<Grid
							item
							xs={12}
							style={{
								textAlign: "center",
							}}
						>
							<div>
								<img src={LogoPink} 
									style={{ width: "50%", height: "auto" }}
								alt="naisha" />
							</div>
							<div 
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									position: "relative",
									left: "70px",
								}}
							>
								<h6 
									style={{ 
										fontWeight: "bold", 
										fontSize: "18px",
								}}>FAQ</h6>
							</div>
						</Grid>
						<Grid item xs={12}>
							{faq && faq.data
								? faq.data.map((item, index) => {
										return (
											<div key={index}>
												<ListAccordion item={item} />
											</div>
										);
								  })
								: null}
						</Grid>
					</Grid>
				</div>
			</Layout>
		</WrapHome>
	);
};

export default FaqPageMobile;
