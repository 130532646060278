const style = (theme) => ({
    button: {
        color: 'white', 
        fontSize: '.6rem',
    },
    buttonClicked: {
        fontSize: '.6rem',
        color: 'white'
    },
    tokokuClicked: {
        border: '1px solid #fbbb14'
    }
});

export default style;
