export const UserRoute = [
	{
		title: "Akun Saya",
		children: [
			{
				name: "Profile",
				link: "/akun/profile",
			},
			{
				name: "Alamat",
				link: "/akun/alamat",
			},
		],
	},
	{
		title: "Pesanan Saya",
		children: [
			{
				name: "Belum Bayar",
				link: "/orders/0",
			},
			{
				name: "Konfirmasi Bayar",
				link: "/orders/1",
			},
			{
				name: "Diproses",
				link: "/orders/2",
			},
			{
				name: "Dikirim",
				link: "/orders/3",
			},
			{
				name: "Diterima",
				link: "/orders/4",
			},
			{
				name: "Dibatalkan",
				link: "/orders/5",
			},
		],
	},
	{
		title: "Hadiah Level",
		children: [
			{
				name: "Progress",
				link: "/hadiah/progress",
			},
			{
				name: "Berhasil",
				link: "/hadiah/complete",
			},
		],
	},
	{
		title: "Kupon Promo",
		children: [
			{
				name: "Kupon Saya",
				link: "/kupon-saya",
			},
			{
				name: "Ambil Kupon",
				link: "/ambil-kupon",
			},
		],
	},
];

export const ResellerRoute = [
	{
		title: "Reseller",
		children: [
			{
				name: "Kartu Reseller",
				link: "/reseller-card",
			},
		]
	},
];