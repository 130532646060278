import React, { useState, useEffect } from "react";
import { Layout } from "../../containers";
import { WrapAuthForm } from "./style";
import {
	TextField,
	OutlinedInput,
	useMediaQuery,
	InputAdornment,
	IconButton,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Typography,
	CircularProgress,
	Switch,
	Box,
} from "@material-ui/core";
import { useAuthContext } from "../../contexts/Auth.context";
import swal from "sweetalert";
import { ButtonCustom } from "../../components";
import { REGISTER_AFFILIATE, LOGIN_GOOGLE } from "../../services/auth.service";
import { mappingForms } from "../../utils/formsUtils";
import { submitRegisterAffiliate } from "./handler";
import bannerImg from "../../assets/img/register.png";

const RegisterAffiliatePage = (props) => {
	const { isAuthenticated } = useAuthContext();

	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		name: "",
		phone: "",
		email: "",
		// password: "",
		// password_confirmation: "",
		address: "",
		instagram_username: "",
		instagram_follower: "",
		tiktok_username: "",
		tiktok_follower: "",
		youtube_username: "",
		youtube_subscribe: "",
		ratecard: null,
		is_recomend: false,
		improvement: "",
		interest: [],
		otherInterest: "",
		recomend_speaker: "",
	});
	const isMobile = useMediaQuery("(max-width:500px)");

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const onChange = async (name, value) => {
		console.log(name, value);
		setForm({
			...form,
			[name]: value,
		});
	};

	const onSubmit = async () => {
		setLoading(true);

		form.otherInterest !== "" && form.interest.push(form.otherInterest);
		const res = await submitRegisterAffiliate(form, props.history);
		if (res) {
			setLoading(false);
			form.otherInterest !== "" && form.interest.pop();
		}
	};

	useEffect(() => {
		isAuthenticated && props.history.push("/");
	}, []);

	const InputText = ({ name, label, value, placeholder }) => {
		return (
			<div style={{ marginBottom: "16px" }}>
				<label>{label}</label>
				<OutlinedInput
					id={name}
					placeholder={placeholder}
					fullWidth
					type={"text"}
					value={value}
					size="small"
					onChange={(e) => onChange({ name }, e.target.value)}
				/>
			</div>
		);
	};

	const forms = [
		{
			id: "name",
			label: "Nama Lengkap",
			type: "text",
			required: true,
			value: form.name,
		},
		{
			id: "phone",
			label: "Nomor HP (WA)",
			type: "text",
			required: true,
			value: form.phone,
			desc: "Contoh: +6281234567890",
		},
		{
			id: "email",
			label: "Email",
			type: "text",
			required: true,
			value: form.email,
		},
	];

	const interests = [
		"Digital Marketing",
		"Parenting",
		"Customer Service",
		"Iklan & Ads",
	];

	const handleCheckboxChange = (e, interest) => {
		const newInterests = e.target.checked
			? [...form.interest, interest]
			: form.interest.filter((value) => value !== interest);
		setForm({ ...form, interest: newInterests });
	};

	return (
		<Layout withNavbar={false} {...props}>
			{loading && (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						zIndex: 9999,
						backgroundColor: "rgba(0,0,0,0.5)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<CircularProgress color="primary" />
				</div>
			)}
			<WrapAuthForm>
				<div style={isMobile ? { padding: 16 } : {}}>
					<div className="title">{"Form VIP Member"}</div>
					<div>
						<img
							src={bannerImg}
							alt="banner"
							style={{
								width: "100%",
							}}
						/>
					</div>
					<div className="content">{mappingForms(forms, setForm, form)}</div>
					<ButtonCustom color="primary" onClick={onSubmit}>
						Daftar VIP Member
					</ButtonCustom>
					<div style={{ textAlign: "center", marginTop: 16 }}>
						<p>
							Sudah punya akun ? Klik ke halaman <a href={"/login"}>Login</a>
						</p>
					</div>
				</div>
			</WrapAuthForm>
		</Layout>
	);
};

export default RegisterAffiliatePage;
