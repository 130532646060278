import React, { useState, useEffect } from "react";
import { Layout, Navbar } from "../../containers";
import { Grid, useMediaQuery, Avatar, Button } from "@material-ui/core";
import {
	GET_PROFILE,
	PUT_PROFILE,
	PUT_PHOTO_PROFILE,
} from "../../services/user.service";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FAQ_API } from "../../services/faq.service";
import { WrapHome, WrapMenu } from "./style";
import LogoPink from "../../assets/img/Clouds.png";
import ListAccordion from "./listAccordion";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as IconOrder } from "../../assets/icons_1/Setting.svg";
import { ReactComponent as Info } from "../../assets/icons_1/InfoSquare.svg";
import { ReactComponent as IconLogout } from "../../assets/icons_1/Logout.svg";
import { ReactComponent as Bookmark } from "../../assets/icons_1/bookmark.svg";
import { ReactComponent as IconClock } from "../../assets/icons/iconClock.svg";
import { ReactComponent as BoxTick } from "../../assets/icons_1/box-tick.svg";
import { ReactComponent as Payment } from "../../assets/icons_1/payment.svg";
import constanta from "../../configs/constanta";
import ToolbarMobile from "../../components/Mobile/ToolbarMobile";

const AccountPageMobile = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	const [faq, setDataFaq] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState({});
	const [linkWa, setLinkWa] = useState("");
	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			...user,
		},
	});

	const retrieveDataUser = () => {
		setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		if (user === null) {
			props.history.push("/login");
		}
		setUser(user);

		const withdrawRequest = `Halo Admin Naisha, saya ${user?.name} ingin melakukan penarikan saldo sebesar Rp. ${user?.saldo}`;
		setLinkWa(`https://wa.me/6283804879906?text=${withdrawRequest}`);
	};

	useEffect(() => {
		retrieveDataUser();
	}, []);

	const getFAQ = async () => {
		setIsLoading(true);

		FAQ_API()
			.then((res) => {
				setDataFaq(res);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getFAQ();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const profilePhoto = user.profile_photos
		? user.profile_photos.medium.url
		: "https://via.placeholder.com/150";

	return (
		<WrapHome>
			<Navbar type="navback" title="Profil" {...props} />
			<Layout {...props} withNavbar={false}>
				<div style={{ marginTop: "60px", marginBottom: "60px" }}>
					<Grid container spacing={3} style={{ marginTop: 20 }}>
						<Grid
							item
							xs={12}
							style={{
								textAlign: "center",
								backgroundImage: `url(${LogoPink})`,
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								display: "flex",
								alignItems: "center",
								marginBottom: "20px",
								justifyContent: "center",
							}}>
							<div
								style={{
									background: `linear-gradient(to top, ${constanta.COLOR.lightBlue} 50%, rgba(255, 255, 255, 0))`,
									width: "100%",
									position: "relative",
									bottom: "-30px",
									paddingBottom: "50px",
								}}>
								<div>
									<img
										src={profilePhoto}
										style={{
											width: "99px",
											height: "auto",
											borderRadius: "50%",
											border: "2px solid #F2F2F2",
										}}
										alt="naisha"
									/>
								</div>
								{console.log(user, "user")}
								{user && user.name && (
									<>
										<h5>{user.name}</h5>
										<p>
											{user?.village?.district?.regency?.name},{" "}
											{user?.village?.district?.regency?.province?.name}
										</p>
									</>
								)}
							</div>
						</Grid>
						<Grid item xs={12}>
							<div className="title">
								<h6>SETTINGS</h6>
							</div>
							<WrapMenu>
								{(user?.customer_type?.name === "Reseller" ||
									user?.customer_type?.name === "Member") && (
									<Button
										startIcon={<Bookmark />}
										onClick={() => {
											props.history.push("/reseller-card");
										}}>
										Kartu Reseller
									</Button>
								)}
								<Button
									startIcon={<BoxTick />}
									onClick={() => {
										props.history.push("/order-history-mobile");
									}}>
									Riwayat Pesanan
								</Button>
								<Button
									startIcon={<IconOrder />}
									onClick={() => {
										props.history.push("/akun/profile");
									}}>
									Pengaturan Profil
								</Button>
							</WrapMenu>
						</Grid>
						<Grid item xs={12}>
							<div className="title">
								<h6>Keuangan</h6>
							</div>
							<WrapMenu>
								<Button
									startIcon={<Payment />}
									href={linkWa}
									disabled={user?.saldo == 0 ? true : false}>
									Tarik Saldo (Rp. {user?.saldo})
								</Button>
							</WrapMenu>
						</Grid>
						<Grid item xs={12}>
							<div className="title">
								<h6>GENERAL INFO</h6>
							</div>
							<WrapMenu>
								<Button
									startIcon={<Info />}
									onClick={() => {
										props.history.push("/company-profile");
									}}>
									Company Profile
								</Button>
							</WrapMenu>
						</Grid>
						<Grid item xs={12}>
							<WrapMenu>
								<Button
									className="logout"
									endIcon={<IconLogout />}
									onClick={() => {
										localStorage.removeItem("USER_NAISHA");
										localStorage.removeItem("TOKEN");
										props.history.push("/login");
									}}>
									Keluar
								</Button>
							</WrapMenu>
						</Grid>
					</Grid>
				</div>
			</Layout>
			{/* {isMobile && (
				<div style={{ marginTop: 20 }}>
					<ToolbarMobile />
				</div>
			)} */}
		</WrapHome>
	);
};

export default AccountPageMobile;
