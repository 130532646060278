import styled from "styled-components";

export const WrapHome = styled.div`
	position: relative;
	background: #EFF8FC;

	.logoGroup {
		width: 100%;
		padding: 0px 24px;
		display: flex;
		margin-top: 16px;
		.item {
			cursor: pointer;
			text-align: center;
			width: 100%;
			p {
				font-size: 12px;
				color: #333;
			}
		}
	}

	.logoInfo {
		width: 100%;
		padding: 0px 24px;
		display: flex;
		margin-top: 16px;
		.item {
			cursor: pointer;
			text-align: center;
			width: 100%;
			p {
				font-size: 12px;
				color: #333;
			}
		}
	}

	@media (max-width: 500px) {
	padding-bottom: 100px;
		h3 {
			font-size: 16px;
		}
	}
`;

export const WrapContentIcon = styled.div`
	display: flex;
	justify-content: space-between;
	.item {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		p {
			margin-top: 12px;
			font-size: 12px;
			color: #333;
		}
	}
`;

export const WrapCountDown = styled.div`
	display: flex !important;
	width: 134px !important;
	justify-content: space-between !important;

	.container-count {
		background-color: #EF5DA8 !important;
		color: #fff !important;
		height: 26px !important;
		width: 26px !important;
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		border-radius: 3px !important;
	}
`;
