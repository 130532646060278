import React, { useState, useEffect } from "react";

import LayoutAccount from "../../../containers/LayoutAccount";
import { Typography, Card, Avatar, Button, TextField, CircularProgress } from "@material-ui/core";
import { GET_PROFILE } from "../../../services/user.service";
import TemplateIMG from "../../../assets/img/card_reseller_template.jpeg";

const ResellerCardMobile = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
    const [user, setUser] = useState({});
    const [profilePhoto, setProfilePhoto] = useState("");

    const retrieveDataUser = () => {
		setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
        setProfilePhoto(
            user && user.profile_photos
                ? user.profile_photos.medium.url
                : "https://via.placeholder.com/150",
        );
        setUser(user);
        setIsLoading(false);
	};

	useEffect(() => {
		retrieveDataUser();
	}, []);



	return (
			<Card>
                <div style={{ padding: "14px",
                    position: "relative",
                    marginTop: "100px",
                    }}>
                    <img src={TemplateIMG} alt="template" style={{ width: "100%" }} />
                    <div
                        style={{
                            position: "absolute",
                            zIndex: "99",
                            top: "33%",
                            left: "50%",
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "14px",
                            }}
                        >
                            {user.name}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginTop: "0px",
                            }}
                        >Reseller {user.city}</Typography>
                        <Typography
                            style={{
                                marginTop: "14px",
                                fontSize: "14px",
                                fontWeight: "bold",
                            }}
                        >ID {user.id}</Typography>
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            zIndex: "99",
                            top: "28%",
                            left: "10%",
                        }}
                    >
                        <img src={profilePhoto} alt="profile" style={{ width: "29vw", borderRadius: "50%" }} />
                    </div>
                </div>
			</Card>
	);
};

export default ResellerCardMobile;
