import { isEmpty } from "lodash";
import swal from "sweetalert";
import { CHECKOUT, GET_ORDER_BY_ID } from "../../services/orders.service";
import { formatRupiah } from "../../utils/currencyFormat";
import Helpers from "../../utils/ErrorHandler";
import axios from "axios";

let getToken = JSON.parse(localStorage.getItem("TOKEN"));
let getReferral = JSON.parse(localStorage.getItem("REFERRAL"));

function buildFormData(formData, data, parentKey) {
	if (
		data &&
		typeof data === "object" &&
		!(data instanceof Date) &&
		!(data instanceof File)
	) {
		Object.keys(data).forEach((key) => {
			buildFormData(
				formData,
				data[key],
				parentKey ? `${parentKey}[${key}]` : key
			);
		});
	} else {
		const value = data == null ? "" : data;

		formData.append(parentKey, value);
	}
}
function jsonToFormData(data) {
	const formData = new FormData();

	buildFormData(formData, data);

	return formData;
}

export const subTotalPrice = (state, user) => {
	let subtotal = 0;
	if (state.filter((x) => x.isChecked).length >= 1) {
		state
			.filter((x) => x.isChecked)
			.map((item) => {
				if (item.type !== "bundling") {
					let perkalian =
						!isEmpty(user) && user.customer_type?.name === "Customer"
							? item.object.price.consumer * item.total
							: item.object.price.user * item.total;
					subtotal = subtotal + perkalian;
					return "";
				}
			});
	}

	return subtotal;
};

export const subTotalPriceBundling = (state, user) => {
	let subtotal = 0;
	if (state.filter((x) => x.isChecked).length >= 1) {
		state
			.filter((x) => x.isChecked)
			.map((item) => {
				if (item.type === "bundling") {
					let perkalian = item.price * item.total;
					subtotal = subtotal + perkalian;
					return "";
				}
			});
	}
	return subtotal;
};

export const getShippingService = (state, type = "default") => {
	let service = "";
	if (!isEmpty(state)) {
		if (type === "default") {
			state.map((item) => {
				if (!isEmpty(item.services.filter((x) => x.isChecked))) {
					if (formatRupiah(getShippingCost(state)) == 0) {
						service = `${item.code.toUpperCase()} (${
							item.services.filter((x) => x.isChecked)[0].service
						}) `;
					} else {
						service = `${item.code.toUpperCase()} (${
							item.services.filter((x) => x.isChecked)[0].service
						}) Rp ${formatRupiah(getShippingCost(state))}`;
					}
				}
				return "";
			});
		} else if (type === "full") {
			state.map((item) => {
				if (!isEmpty(item.services.filter((x) => x.isChecked))) {
					service = {
						courier: item.code,
						courier_type: item.services.filter((x) => x.isChecked)[0].service,
						courier_estimated: item.services.filter((x) => x.isChecked)[0]
							.cost[0].etd,
						shipping_cost: getShippingCost(state),
					};
				}
				return "";
			});
		}
	}
	return service;
};

export const getShippingCost = (state) => {
	let price = 0;
	if (!isEmpty(state)) {
		state.map((item) => {
			if (!isEmpty(item.services.filter((x) => x.isChecked))) {
				price = item.services.filter((x) => x.isChecked)[0].cost[0].value;
			}
			return "";
		});
	}
	return price;
};

export const onChangeForm = async (value, state, setState, name) => {
	setState({
		...state,
		form: {
			...state.form,
			[name]: value,
		},
	});
};

export const handleBuyNow = async (
	state,
	shippingServices,
	listProducts,
	newPayment,
	history,
	isDropshipper,
	isFileResi
) => {
	let customer = JSON.parse(localStorage.getItem("ALAMAT_CUSTOMER")).filter(
		(x) => x.isPrimary
	)[0];
	let payment = JSON.parse(localStorage.getItem("PAYMENTS_METHOD")).filter(
		(x) => x.isChecked
	)[0];

	let selectkurir = JSON.parse(
		localStorage.getItem("SHIPPING_SERVICES")
	).filter((x) => x.isChecked)[0];

	let marketplace;
	let dropshipper;
	let isResi = JSON.parse(localStorage.getItem("IS_FILE_RESI"));

	if (isDropshipper) {
		marketplace = JSON.parse(localStorage.getItem("MARKETPLACE")).filter(
			(x) => x.isChecked
		)[0].code;
		dropshipper = JSON.parse(localStorage.getItem("DROPSHIPPER")).filter(
			(x) => x.isChecked
		)[0];
	}

	const voucher = JSON.parse(localStorage.getItem("USE_VOUCHER"));

	const getProducts = () => {
		let products = [];
		listProducts.map((item) => {
			if (item.type === "bundling") {
				products.push({
					bundling_id: item.bundling_id,
					qty: item.total,
					bundling_items: item.bundling_items,
					referral: getReferral,
				});
			} else {
				products.push({
					sku_code: item.object.code,
					qty: item.total,
					referral: getReferral,
				});
			}
			return "";
		});
		return products;
	};

	const body = {
		customer: {
			name: customer.namaPenerima,
			phone: customer.noTelpPenerima,
			address: customer.alamatLengkapPenerima,
			postal_code: customer.kodePos,
			subdistrict_id: customer.selectDistrict,
			province: customer.selectProvinceName,
			city: customer.selectCityName,
			name_distric: customer.selectSubdistrictName,
		},
		order: {
			voucher_id: voucher ? voucher.id : "",
			customer_note: state.form.customer_note,
			products: getProducts(),
		},
		payment_method: payment.id,
	};
	if (isDropshipper) {
		body.order.marketplace = marketplace;
		body.order.is_dropship = isDropshipper ? "1" : "0";
		body.order.courier = dropshipper.code;
		if (isFileResi) {
			body.order.resi_file = isResi ? "1" : "0";
			body.order.resi = state.form.resi_file;
		} else {
			body.order.resi = state.form.resi;
		}
	} else {
		body.order.courier = selectkurir.logistic_name;
		body.order.courier_type = selectkurir.rate_name;
		body.order.shipping_cost = selectkurir.rate ?? 0;
	}
	let payload = {
		data: body,
	};

	let formData = jsonToFormData(payload);

	swal({
		title: "Membuat Pesanan",
		text: "Anda yakin ingin membuat pesanan ?",
		buttons: {
			cancel: "Batal",
			ok: "Oke",
		},
	}).then(async (value) => {
		if (value === "ok") {
			const url = `${process.env.REACT_APP_BASE_URL}/api/orders/checkout`;
			await axios({
				method: "post",
				url: url,
				data: formData,
				headers: {
					Authorization: "Bearer " + getToken?.accessToken ?? "",
					"Content-Type": "multipart/form-data",
				},
			})
				.then(async (res) => {
					if (res.data.success) {
						const order = await GET_ORDER_BY_ID(res.data.order_id);
						newPayment(order.data[0]);
						localStorage.setItem("DATA_ORDER", JSON.stringify(order.data[0]));
						swal({
							title: "Pemesanan Berhasil",
							text: "Pemesanan produk telah berhasil dilakukan",
							buttons: {
								ok: "Lanjut",
							},
						}).then((btn) => {
							if (btn === "ok") {
								localStorage.removeItem("USE_VOUCHER");
								history.push("/payment");
							}
						});
					}
				})
				.catch((e) => {
					console.log(e, "error");
					let error = Helpers.ErrorHandler(e);
					swal("Failed", error.message, "error");
					// 			swal(
					// 				"Error",
					// 				`
					//   ${
					// 		e.response.data.errors["data.customer.name"] !== undefined
					// 			? e.response.data.errors["data.customer.name"][0]
					// 			: ""
					// 	}
					//   ${
					// 		e.response.data.errors["data.customer.address"] !== undefined
					// 			? e.response.data.errors["data.customer.address"][0]
					// 			: ""
					// 	}
					//   ${
					// 		e.response.data.errors["data.customer.postal_code"] !== undefined
					// 			? e.response.data.errors["data.customer.postal_code"][0]
					// 			: ""
					// 	}
					//   ${
					// 		e.response.data.errors["data.customer.subdistrict_id"] !== undefined
					// 			? e.response.data.errors["data.customer.subdistrict_id"]
					// 			: ""
					// 	}
					//   ${
					// 		e.response.data.errors["data.order.courier"] !== undefined
					// 			? e.response.data.errors["data.order.courier"][0]
					// 			: ""
					// 	}
					//   ${
					// 		e.response.data.errors["data.order.shipping_cost"] !== undefined
					// 			? e.response.data.errors["data.order.shipping_cost"][0]
					// 			: ""
					// 	}
					//   ${
					// 		e.response.data.errors["data.order.products"] !== undefined
					// 			? e.response.data.errors["data.order.products"][0]
					// 			: ""
					// 	}
					//   ${
					// 		e.response.data.errors["data.payment_method"] !== undefined
					// 			? e.response.data.errors["data.payment_method"][0]
					// 			: ""
					// 	}
					//   `,
					// 				"error",
					// 			);
				});
		}
	});
};
