import React from "react";
import NoImageProduct from "../../assets/img/noImage.svg";
import { formatRupiah } from "../../utils/currencyFormat";
import { useHistory } from "react-router";
import { WrapProductCardFull, WrapProduct } from "./style";
import { QuantityCounter } from "..";
import { Checkbox, Card } from "@material-ui/core";
import { ReactComponent as IconTrashGray } from "../../assets/icons/iconTrashGray.svg";
import Spacer from "../Spacer";
import { useMediaQuery } from "@material-ui/core";
import constanta from "../../configs/constanta";

const ProductCardBundling = (props) => {
	const {
		productBundling,
		type,
		withCheckbox,
		isChecked,
		handleChecked,
		onDelete = null,
		handleMinus = null,
		handlePlus = null,
		withQuantityButton = true,
		withDeleteButton = true,
		withBorderBottom = false,
	} = props;

	let history = useHistory();

	const isMobile = useMediaQuery("(max-width:500px)");

	const renderMappingBundling = (data, type) => {
		let price;
		let discount;
		price = data.product.skus[0].price.bundling_price;
		discount = data.product.skus[0].price.user_without_disc;

		return (
			<>
				<div
					style={{
						display: "flex",
						paddingBottom: "16px",
						paddingRight: "16px",
						paddingTop: "16px",
					}}
				>
					<div className="image">
						<img
							src={data.product.image_url ?? NoImageProduct}
							alt={`product-${data.product.slug}`}
						/>
					</div>
					<div className="wrapper-content">
						<h4 className="title">{data.product.name}</h4>
						<div className="tags">
							<div className="tag">{data.color}</div>
							<div className="tag">{data.size}</div>
						</div>

						{price ? (
							<div className="price">
								<p>Rp {formatRupiah(price)}</p>{" "}
							</div>
						) : null}

						{data.product.stock && (
							<div className="stock">
								<p>Sisa {data.product.stock}</p>
							</div>
						)}
						{discount && (
							<div className="discount">
								<p>Rp {formatRupiah(discount)}</p>{" "}
							</div>
						)}
					</div>
				</div>
				{/* {isMobile && (
						<>
							<div className="wrapper-action">
								{withQuantityButton && (
									<div className="quantity">
										<QuantityCounter
											onClickMinus={handleMinus}
											onClickPlus={handlePlus}
											total={data.total}
										/>
									</div>
								)}
								{withDeleteButton && (
									<div className="delete" onClick={onDelete}>
										<IconTrashGray />
									</div>
								)}
							</div>
						</>
					)} */}
			</>
		);
	};

	let render;
	if (productBundling) {
		render = productBundling.items.map((item) => {
			return (
				<>
					<WrapProductCardFull>{renderMappingBundling(item, type)}</WrapProductCardFull>
				</>
			);
		});
	}

	return (
		<>
			<div style={{ borderBottom: withBorderBottom ? "1px solid #DDD" : "none" }}>
				{type === "default" ? (
					<Card className="card-custom">
						<WrapProduct>
							<div style={{ display: "flex", paddingLeft: "16px" }}>
								<div
									style={{
										display: "flex",
										paddingTop: "16px",
									}}
								>
									{withCheckbox && (
										<div className="checkbox">
											<Checkbox
												color="primary"
												checked={isChecked}
												onChange={handleChecked}
											/>
										</div>
									)}
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									{render}
								</div>
								<div
									style={{
										display: "flex",
									}}
								>
									{!isMobile && (
										<>
											<div className="wrapper-action">
												{withQuantityButton && (
													<div className="quantity">
														<QuantityCounter
															onClickMinus={handleMinus}
															onClickPlus={handlePlus}
															total={productBundling.total}
														/>
													</div>
												)}
												{withDeleteButton && (
													<div className="delete" onClick={onDelete}>
														<IconTrashGray />
													</div>
												)}
											</div>
										</>
									)}
								</div>
							</div>
						</WrapProduct>
						<div style={{ paddingLeft: "24px", paddingBottom: "16px" }}>
							<p style={{ fontWeight: "bold", color: constanta.COLOR.primary }}>
								Subtotal : Rp {formatRupiah(productBundling.price)}
							</p>
						</div>
					</Card>
				) : (
					<>
						<WrapProduct>
							<div
								style={{
									display: "flex",
									paddingLeft: type === "default" ? "16px" : "0",
								}}
							>
								<div
									style={{
										display: "flex",
										paddingTop: "16px",
									}}
								>
									{withCheckbox && (
										<div className="checkbox">
											<Checkbox
												color="primary"
												checked={isChecked}
												onChange={handleChecked}
											/>
										</div>
									)}
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									{render}
								</div>
								<div
									style={{
										display: "flex",
									}}
								>
									{!isMobile && (
										<>
											<div className="wrapper-action">
												{withQuantityButton && (
													<div className="quantity">
														<QuantityCounter
															onClickMinus={handleMinus}
															onClickPlus={handlePlus}
															total={productBundling.total}
														/>
													</div>
												)}
												{withDeleteButton && (
													<div className="delete" onClick={onDelete}>
														<IconTrashGray />
													</div>
												)}
											</div>
										</>
									)}
								</div>
							</div>
						</WrapProduct>
						<div
							style={{
								paddingLeft: type === "default" ? "24px" : "14px",
								paddingBottom: "16px",
							}}
						>
							<p style={{ fontWeight: "bold", color: constanta.COLOR.primary }}>
								Subtotal : Rp {formatRupiah(productBundling.price)}
							</p>
						</div>
					</>
				)}
			</div>
			<Spacer />
		</>
	);
};

export default ProductCardBundling;
