import { Checkbox, Grid, Card, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EmptyState, QuantityCounter, Spacer } from "../../../components";
import { ReactComponent as IconTrashGray } from "../../../assets/icons/iconTrashGray.svg";
import { Layout } from "../../../containers";
import { formatRupiah } from "../../../utils/currencyFormat";
import { WrapBundlingPage, HStack, VStack } from "../style";
import _, { isEmpty } from "lodash";
import { ButtonCustom } from "../../../components";

const BundlingPage = (props) => {
	const [bundling, setBundling] = useState(null);
	const { productBundling } = props;

	useEffect(() => {
		if (productBundling) {
			let priceBundling;
			let weightBundling;

			if (productBundling.items !== undefined) {
				priceBundling =
					productBundling.items
						.map((e) => {
							return e.product != null
								? e.product.skus[0].price.bundling_price
								: 0;
						})
						.reduce((a, b) => a + b) ?? 0;
			} else {
				priceBundling = "";
			}

			if (productBundling.items !== undefined) {
				weightBundling =
					productBundling.items
						.map((e) => {
							return e.product != null ? e.product.skus[0].weight_gram : 0;
						})
						.reduce((a, b) => a + b) ?? 0;
			} else {
				weightBundling = "";
			}

			let data = {
				...productBundling,
				price: priceBundling,
				items:
					productBundling.items &&
					productBundling.items.map((data) => {
						return {
							color: data.product.skus[0].properties.color,
							size: "",
							...data,
							isSelected: false,
						};
					}),
				total: 1,
				type: "bundling",
				isChecked: false,
				weight_gram: weightBundling,
			};
			setBundling(data);
		}
	}, [productBundling]);

	const _mappingProductBundling = (productBundling) => {
		if (productBundling) {
			return productBundling.map((data, index) => {
				let price;
				let discount;
				let nameProduct =
					data.product.name.length > 80
						? data.product.name.substring(0, 80) + "..."
						: data.product.name;

				price = data.product.skus[0].price.bundling_price;
				discount = data.product.skus[0].price.user_without_disc;

				return (
					<React.Fragment key={index}>
						<Grid item xs={2}>
							<div>
								<img
									style={{ width: "100%", height: "100%" }}
									src={data.product.image_url}
									alt={data.product.slug}
								/>
							</div>
						</Grid>
						<Grid item xs={8}>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<span>{nameProduct}</span>
								<div style={{ marginTop: 16 }}>
									<h6>Variasi Warna:</h6>
									<div
										style={{
											display: "flex",
											marginTop: "8px",
										}}
									>
										{_mappingColor(
											data.product.skus,
											data,
											data.color,
										)}
									</div>
								</div>
								<div style={{ marginTop: 16 }}>
									<h6>Variasi Ukuran:</h6>
									<div
										style={{
											display: "flex",
											marginTop: "8px",
										}}
									>
										{_mappingSize(
											data.product.skus,
											data,
											data.size,
											data.color,
										)}
									</div>
								</div>
							</div>
						</Grid>
						<Grid item xs={2}>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div className="price-bundling">
									<p>Rp {formatRupiah(price)}</p>
								</div>
								{discount === 0 ? null : (
									<>
										<div className="price-strike-bundling">
											Rp {formatRupiah(discount)}
										</div>
									</>
								)}
							</div>
						</Grid>
					</React.Fragment>
				);
			});
		}
	};

	const onSelectColorDesktop = async (color, product, code) => {
		let newProduct = {
			...product,
			color,
			code,
		};
		let x = bundling.items.map((item) => {
			if (item.id === newProduct.id) {
				return newProduct;
			} else {
				return item;
			}
		});

		let newBundling = {
			...bundling,
			items: x,
		};
		setBundling(newBundling);
	};

	const onSelectSizeDesktop = async (size, product, code) => {
		let newProduct = {
			...product,
			size,
			code,
			isSelected: true,
		};
		let x = bundling.items.map((item) => {
			if (item.id === newProduct.id) {
				return newProduct;
			} else {
				return item;
			}
		});

		let newBundling = {
			...bundling,
			items: x,
		};
		setBundling(newBundling);
	};

	const _mappingColor = (skus, product, initialColor) => {
		let button = [];
		let render = [];
		skus.map((item) => {
			button.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
			});
			return "";
		});
		_.uniqBy(button, "color").map((item, index) => {
			render.push(
				<div style={{ marginRight: "8px" }}>
					<ButtonCustom
						type="select"
						key={index}
						active={item.color === initialColor}
						onClick={() =>
							onSelectColorDesktop(item.color, product, item.code)
						}
					>
						{item.color}
					</ButtonCustom>
				</div>,
			);
			return "";
		});
		return render;
	};

	const _mappingSize = (skus, product, initialSize, initialColor) => {
		let button = [];
		let render = [];
		skus.map((item) => {
			button.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
			});
			return "";
		});
		_.uniqBy(button, "size").map((item, index) => {
			render.push(
				<div style={{ marginRight: "8px" }}>
					<ButtonCustom
						type="select"
						key={index}
						isDisabled={initialColor === ""}
						active={item.size === initialSize}
						onClick={() => onSelectSizeDesktop(item.size, product, item.code)}
					>
						{item.size}
					</ButtonCustom>
				</div>,
			);
			return "";
		});
		return render;
	};

	const handleMinus = () => {
		let dataBundling = {
			...bundling,
			total: bundling.total - 1,
		};
		if (dataBundling.total === 0) {
			props.history.push(`/bundling/${bundling.id}`);
		} else {
			setBundling(dataBundling);
		}
	};
	const handlePlus = () => {
		let dataBundling = {
			...bundling,
			total: bundling.total + 1,
		};
		setBundling(dataBundling);
	};

	const checkOutBundling = () => {
		let codeBundling = bundling.items.map((data) => {
			return data.code;
		});
		let productBundling = {
			...bundling,
			bundling_items: codeBundling,
			bundling_id: bundling.id,
			qty: bundling.total,
		};
		//konsep untuk masuk dalam cart
		let cart = JSON.parse(localStorage.getItem("CART"));
		let newData = [...cart, productBundling];
		localStorage.setItem("CART", JSON.stringify(newData));
		// localStorage.setItem("CHECKOUT_BUNDLING", JSON.stringify(productBundling));
		props.history.push("/cart");
	};

	const onDeleteBundling = () => {
		props.history.push(`/bundling/${bundling.id}`);
	};

	let isSelected =
		bundling !== null && bundling.items !== undefined
			? bundling.items.every((v) => v.isSelected === true)
			: false;

	return (
		<Layout {...props}>
			<div style={{ marginTop: 32, minHeight: "calc(100vh - 227px)" }}>
				<WrapBundlingPage>
					<>
						<p>Varian Bundling</p>
						<Spacer />
						<Grid container spacing={4}>
							<Grid item xs={9}>
								<Card
									className="card-custom"
									style={{
										padding: "16px",
									}}
								>
									<VStack>
										<Grid container spacing={2}>
											{_mappingProductBundling(bundling?.items)}
										</Grid>

										<div
											style={{
												display: "flex",
												marginTop: "14px",
												flexDirection: "row-reverse",
											}}
										>
											<div
												onClick={onDeleteBundling}
												style={{
													border: "1px solid #f1f1f1",
													borderRadius: "8px",
													padding: "8px",
													marginLeft: "12px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<IconTrashGray />
												<span style={{ paddingLeft: "12px" }}>
													Hapus
												</span>
											</div>
											<div
												style={{
													border: "1px solid #f1f1f1",
													borderRadius: "8px",
													padding: "8px",
												}}
											>
												<QuantityCounter
													onClickMinus={handleMinus}
													onClickPlus={handlePlus}
													total={bundling?.total}
												/>
											</div>
										</div>
									</VStack>
								</Card>
							</Grid>
							<Grid item xs={3}>
								<Card className="card-custom">
									<VStack
										style={{ maxHeight: "121px", padding: "14px" }}
									>
										<HStack
											style={{ justifyContent: "space-between" }}
										>
											<span>Total Belanja</span>
											{bundling && bundling.price ? (
												<span>
													Rp{" "}
													{formatRupiah(
														bundling.price * bundling.total,
													)}
												</span>
											) : null}
										</HStack>
										<Spacer variant="vertical" size="20" />
										<Button
											variant="contained"
											color="primary"
											style={{
												color: "#fff",
											}}
											disabled={!isSelected}
											onClick={() => checkOutBundling()}
										>
											{/* Checkout ({cart.filter((x) => x.isChecked).length}) */}
											Beli Sekarang
										</Button>
									</VStack>
								</Card>
							</Grid>
						</Grid>
					</>
				</WrapBundlingPage>
			</div>
		</Layout>
	);
};

export default BundlingPage;
