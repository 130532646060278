import { CUSTOMER_SERVICE_CONSTANT } from '../../configs/constanta';
import { GET_CUSTOMERS_SERVICE } from '../../services/customerService.service';

export const getCustomerServices = async (
  type = CUSTOMER_SERVICE_CONSTANT.customerService
) => {
  return await GET_CUSTOMERS_SERVICE(type);
};

export const handleSubmitSelect = (phoneNumber) => {
  // eslint-disable-next-line no-unused-expressions
  window.open(`https://wa.me/${phoneNumber}`, '_blank').focus;
};
