import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ProductCard, Skeleton } from "../../components";
import { Layout } from "../../containers";
import { WrapHome } from "./style";
import { useAuthContext } from "../../contexts/Auth.context";
import { useOrderContext } from "../../contexts/Order.context";
import { GET_PRODUCTS } from "../../services/products.service";
import { Spacer, EmptyState } from "../../components";
import _, { isEmpty } from "lodash";
import Pagination from "@material-ui/lab/Pagination";
import swal from "sweetalert";
import queryString, { parse } from "query-string";
import constanta from "../../configs/constanta";
import { withRouter } from "react-router";

const ResultSearchProductPage = (props) => {
	const { refreshOrderContext } = useOrderContext();
	const isMobile = useMediaQuery("(max-width:500px)");
	const { user } = useAuthContext();

	const { isAuthenticated } = useAuthContext();
	const [products, setProducts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [filters, setFilters] = useState({
		paginate: 10,
		page: 1,
		name: "",
	});

	const parsed = queryString.parse(props.location.search);

	const getFilter = (filters, nameProduct) => {
		let filter = `?paginate=${filters.paginate}&page=${filters.page}`;
		filters.name !== "" && (filter = filter + `&filter[name]=${nameProduct}`);
		nameProduct !== undefined &&
			(filter = filter + `&filter[name]=${nameProduct}`);
		return filter;
	};

	const getProducts = async () => {
		setIsLoading(true);

		GET_PRODUCTS(getFilter(filters, parsed.nama))
			.then((res) => {
				setProducts(res);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};
	useEffect(() => {
		getProducts();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	// const getDetail = async (item) => {
	// 	props.history.push(`/bundling/${item.id}`);
	// };

	const containerStyle = {
		maxWidth: 1220,
		position: "relative",
		margin: "0 auto",
		left: 0,
		right: 0,
	};

	return (
		<WrapHome>
			<Layout {...props} withContainer={false}>
				<div style={!isMobile ? containerStyle : {}}>
					<Grid container spacing={3} style={{ marginTop: 20 }}>
						{!isEmpty(parsed) ? (
							<Grid
								item
								xs={12}
								style={{
									marginTop: isMobile ? "92px" : "0",
									marginLeft: isMobile ? "20px" : "0",
									marginRight: isMobile ? "20px" : "0",
								}}>
								<span style={{ fontSize: "20px", fontWeight: "600" }}>
									Hasil pencarian untuk{" "}
								</span>
								<span
									style={{
										color: constanta.COLOR.primary,
										fontWeight: "bold",
										fontSize: "20px",
									}}>
									`{parsed.nama}`
								</span>
							</Grid>
						) : null}

						<Grid
							item
							xs={12}
							style={{
								marginLeft: isMobile ? "20px" : "0",
								marginRight: isMobile ? "20px" : "0",
							}}>
							{!isLoading ? (
								<div
									style={{
										width: "100%",
										display: "flex",
										flexWrap: "wrap",
										marginTop: isMobile ? "30px" : "0",
									}}>
									{!isEmpty(products.data) ? (
										products.data.map((item) => {
											if (isEmpty(user)) {
												return (
													<div
														style={{
															flexGrow: 1,
														}}>
														<ProductCard
															role={
																!isEmpty(user) ? user.customer_type?.name : ""
															}
															name={item.name}
															price={
																!isEmpty(user) &&
																user.customer_type?.name === "Customer"
																	? item.skus[0].price.consumer
																	: item.skus[0].price.user
															}
															image_url={item.image_url}
															key={item.slug}
															slug={item.slug}
														/>
													</div>
												);
											} else {
												return (
													<div
														style={{
															flexGrow: 1,
														}}>
														<ProductCard
															name={item.name}
															price={item.skus[0].price.consumer}
															image_url={item.image_url}
															key={item.slug}
															slug={item.slug}
														/>
													</div>
												);
											}
										})
									) : (
										<div
											style={{
												width: "100%",
												display: "flex",
												flexWrap: "wrap",
												justifyContent: "space-between",
											}}>
											<EmptyState
												desc="Produk Yang Anda Cari Tidak Ditemukan"
												// title=""
											/>
										</div>
									)}
								</div>
							) : (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										// justifyContent: "center",
										// alignItems: "center",
										// paddingRight: isMobile ? "74px" : "0",
									}}>
									<div style={{ paddingBottom: "24px" }}>
										<Skeleton type="product" />
									</div>
									<div style={{ paddingBottom: "24px" }}>
										<Skeleton type="product" />
									</div>
									<div style={{ paddingBottom: "24px" }}>
										<Skeleton type="product" />
									</div>
								</div>
							)}
						</Grid>
					</Grid>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							paddingTop: "24px",
							paddingBottom: "24px",
							marginLeft: isMobile ? "20px" : "0",
							marginRight: isMobile ? "20px" : "0",
						}}>
						<Pagination
							page={filters.page}
							onChange={(event, page) => {
								setFilters((prevState) => {
									return {
										...prevState,
										page: page,
									};
								});
							}}
							count={products.meta?.total}
							variant="outlined"
							shape="rounded"
						/>
					</div>
				</div>
			</Layout>
		</WrapHome>
	);
};

export default withRouter(ResultSearchProductPage);
