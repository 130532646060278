import { useMediaQuery } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { getProductBundling, getProducts } from "../bundling-detail/handler";
import BundlingPage from "./BundlingPage.desktop";

const BundlingCheckout = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	const [product, setProduct] = useState({});
	const [productBundlings, setProductBundlings] = useState({});
	const [imageProducts, setImageProducts] = useState([]);
	const [uniqueImagesProduct, setUniqueImagesProduct] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [otherProducts, setOtherProducts] = useState([]);
	const [dataAlamat, setDataAlamat] = useState([]);
	const [filters, setFilters] = useState({
		paginate: 10,
		categoryId: 2,
		name: "",
		tags: [],
		slug: "",
		sort: "ref_price",
		page: 1,
	});

	const getFilter = (categoryId) => {
		let filter = `?paginate=${filters.paginate}&page=${filters.page}`;
		filters.name !== "" && (filter = filter + `&filter[name]=${filters.name}`);
		filters.slug !== "" && (filter = filter + `&filter[slug]=${filters.slug}`);
		filters.sort !== "" && (filter = filter + `&sort=${filters.sort}`);
		!isEmpty(filters.tags) && (filter = filter + `&filter[tags]=${filters.tags.toString()}`);
		categoryId !== undefined && (filter = filter + `&filter[category.id]=${categoryId}`);

		return filter;
	};

	useEffect(() => {
		const fetch = async () => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			await getProductBundling(
				props.match.params.id,
				setProduct,
				setImageProducts,
				setUniqueImagesProduct,
				setProductBundlings
			);
			await getProducts(getFilter(2), setOtherProducts);
			setIsLoading(false);
		};
		fetch();
	}, [props.match.params.id]);

	useEffect(() => {
		const fetch = async () => {
			let local = JSON.parse(localStorage.getItem("ALAMAT_CUSTOMER"));
			if (local !== null) {
				setDataAlamat(local);
			} else {
				localStorage.setItem("ALAMAT_CUSTOMER", JSON.stringify([]));
				setDataAlamat([]);
			}
		};
		fetch();
	}, []);

	return (
		<>
			<BundlingPage productBundling={productBundlings} {...props} />
		</>
	);
};

export default BundlingCheckout;
