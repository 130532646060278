import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapCustomerService = styled.div`
	position: relative;
	padding-top: 65px;
	.container {
		padding: 16px;
	}
	.item {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;
		.logo {
			width: 20%;
			img {
				width: 100%;
				border-radius: 50%;
			}
		}
		.info {
			position: relative;
			margin-left: 8px;
			width: 60%;
			p {
				width: 100%;
				display: inline-block;
				color: #333;
				margin: 0;
				padding: 0;
				line-height: 12px;
				:nth-child(1) {
					font-size: 14px;
					font-family: "DMSans-Bold";
				}
				:nth-child(2) {
					font-size: 10px;
				}
				.role {
					color: ${constanta.COLOR.primary};
					font-family: "DMSANS-REGULAR";
					font-size: 12px;
				}
			}
		}
		.radioBtn {
			width: 10%;
			position: relative;
			top: 10px;
		}
	}
	.floating {
		position: fixed;
		bottom: 16px;
		width: 100%;
	}
`;

export const WrapCustomerServiceDesktop = styled.div`
	position: relative;
	margin-top: 32px;
	.container {
		padding: 16px;
	}
`;
