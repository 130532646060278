import React, { useState, useEffect } from "react";

import LayoutAccount from "../../../containers/LayoutAccount";
import { Typography, Card, Avatar, Button, TextField, CircularProgress } from "@material-ui/core";
import { GET_PROFILE } from "../../../services/user.service";
import TemplateIMG from "../../../assets/img/card_reseller_template.jpeg";

const ResellerCardDesktop = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
    const [user, setUser] = useState({});
    const [profilePhoto, setProfilePhoto] = useState("");
	const [filter, setFilter] = useState({
		type: "ongkir",
		is_purchasable: "0",
		name: "",
		claimed: true,
	});

    const retrieveDataUser = () => {
		setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
        setProfilePhoto(
            user && user.profile_photos
                ? user.profile_photos.medium.url
                : "https://via.placeholder.com/150",
        );
        setUser(user);
        setIsLoading(false);
	};

	useEffect(() => {
		retrieveDataUser();
	}, []);


	return (
		<LayoutAccount {...props}>
			<Card>
                <div style={{ padding: "14px",
                    position: "relative",
                    }}>
                    <img src={TemplateIMG} alt="template" style={{ width: "100%" }} />
                    <div
                        style={{
                            position: "absolute",
                            zIndex: "99",
                            // top: "210px",
                            // left: "450px",
                            top: "35%",
                            left: "50%",
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: "34px",
                                fontWeight: "bold",
                                marginTop: "14px",
                            }}
                        >
                            {user.name}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                marginTop: "14px",
                            }}
                        >Reseller {user?.village?.name}</Typography>
                        <Typography
                            style={{
                                marginTop: "54px",
                                fontSize: "24px",
                                fontWeight: "bold",
                            }}
                        >ID {user.id}</Typography>
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            zIndex: "99",
                            top: "165px",
                            left: "85px",
                        }}
                    >
                        <Avatar
                            alt="photo saya"
                            src={profilePhoto}
                            style={{  width: "250px", height:"250px", borderRadius: "50%" }}
                        />
                    </div>
                </div>
			</Card>
		</LayoutAccount>
	);
};

export default ResellerCardDesktop;
