import { useMediaQuery } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import ResultSearchProductDesktop from "./result-product.desktop";
import ResultSearchProductMobile from "./result-product.mobile";

const ResultSearchProduct = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <ResultSearchProductMobile {...props}/> : <ResultSearchProductDesktop {...props}/>;
};

export default ResultSearchProduct;
