import styled from "styled-components";

export const WrapperQuantityCounter = styled.div`
	position: relative;
	width: auto;

	button {
		color: #333;
		border: none;
		background: transparent;
		outline: none;
		font-size: 16px;
		height: 34px;
		width: 34px;
		border-radius: 8px;
		border-left: 1px solid #e0e0e0;
		border-right: 1px solid #e0e0e0;
		border-radius: 2px;
	}
	span {
		// border: 1px solid transparent;
		padding: 0px 8px;
		margin: 0;
		font-size: 20px;
		position: relative;
		top: 1px;
	}
`;
