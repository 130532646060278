import React, {useState, useEffect} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CoinIMG from "../../assets/icon-img/coin.svg";
import CheckIMG from "../../assets/icon-img/check-small-rounded.svg";
import { Typography } from "@material-ui/core";
import { GET_REWARD_DAILY, REWARD_DAILY_CLAIM } from "../../services/loginDaily.service";
import swal from "sweetalert";

export default function ClaimModal({ open, handleClose }) {
	const [data, setData] = useState([]);
	
	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		if (!user) {
			return;
		}
		GET_REWARD_DAILY(user.id)
			.then((res) => {
				setData(res);
			})
			.catch((err) => {})
			.finally(() => {});
	}, []);

	const onSubmit = async () => {
		const user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		const payload = {
			user_id: user.id,
			// claim_id: 2,
		};
		await REWARD_DAILY_CLAIM(payload)
			.then((res) => {
				handleClose();
				if(res.message === "Sudah klaim Koin") {
					swal({
						title: "Success",
						text: "Koin sudah didapatkan",
						icon: "warning",
						button: "OK",
					});
					return;
				}
				swal({
					title: "Success",
					text: "Koin berhasil didapatkan",
					icon: "success",
					button: "OK",
				});
			})
			.catch((err) => {
				handleClose();
				swal({
					title: "Failed",
					text: "Koin gagal didapatkan",
					icon: "error",
					button: "OK",
				});
			})
			.finally(() => {});
	};
	
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={"xs"}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{data.length > 0 ? (
				<>
				<DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold", textAlign: "center" }}>
					Reward Koin
				</DialogTitle>
				<DialogContent>
					<div style={{ display: "flex", justifyContent: "space-around"}}>
						{data && data.map((item, index) => (
							<div key={index}>
								<div>
									<div 
										style={{
											borderRadius: "8px",
											padding: "4px",
											border: "1px solid #ffa500",
											textAlign: "center",
											marginRight: "4px",
										}}
									>
									<Typography
										variant="body2"
										style={{
											fontSize: 10,
											fontWeight: "bold",
											textAlign: "center",
										}}
									>
										+{item.reward}
									</Typography>
									<img
										src={item.claimed ? CheckIMG :  CoinIMG}
										alt="gambar-tangga"
										height={10}
										style={{
											padding: "3px",
											background: "#ffa500",
											borderRadius: "50%",
										}}
									/>
									</div>
									<Typography
										variant="body2"
										style={{
											marginTop: "4px",
											fontSize: 10,
											fontWeight: "bold",
											textAlign: "center",
										}}
									>
										Hari {item.day}
									</Typography>
								</div>
						</div>
						))}
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={onSubmit}
						variant="contained"
						style={{ color: "white", width: "100%" }}
						color="primary"
						autoFocus
					>
						Klaim
					</Button>
				</DialogActions>
				</>
				) : (
					<DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold", textAlign: "center" }}>
						Login untuk klaim koin
					</DialogTitle>
				)}
			</Dialog>
		</div>
	);
}
