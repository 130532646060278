import _ from "lodash";

export const handlePlusMinus = (type, code, state, setIsRefetch) => {
	let cloneArrays = state;
	const index = _.findIndex(cloneArrays, { code: code });
	if (type === "plus") {
		state[index].total !== state[index].stock &&
			(cloneArrays[index] = {
				...cloneArrays[index],
				total: state[index].total + 1,
			});
		localStorage.setItem("CART", JSON.stringify(cloneArrays));
		setIsRefetch(true);
	} else {
		if (state[index].total !== 1) {
			cloneArrays[index] = {
				...cloneArrays[index],
				total: state[index].total - 1,
			};
		} else {
			cloneArrays.splice(index, 1);
		}
		localStorage.setItem("CART", JSON.stringify(cloneArrays));
		setIsRefetch(true);
	}
};

export const handlePlusMinusBundling = (type, id, state, setIsRefetch) => {
	let cloneArrays = state;
	const index = _.findIndex(cloneArrays, { id: id });
	if (type === "plus") {
		state[index].total !== state[index].stock &&
			(cloneArrays[index] = {
				...cloneArrays[index],
				total: state[index].total + 1,
			});
		localStorage.setItem("CART", JSON.stringify(cloneArrays));
		setIsRefetch(true);
	} else {
		if (state[index].total !== 1) {
			cloneArrays[index] = {
				...cloneArrays[index],
				total: state[index].total - 1,
			};
		} else {
			cloneArrays.splice(index, 1);
		}
		localStorage.setItem("CART", JSON.stringify(cloneArrays));
		setIsRefetch(true);
	}
};

export const handleDeleteBundling = (id, state, setIsRefetch) => {
	let cloneArrays = state;
	const index = _.findIndex(cloneArrays, { id: id });
	cloneArrays.splice(index, 1);
	localStorage.setItem("CART", JSON.stringify(cloneArrays));
	setIsRefetch(true);
};

export const handleDelete = (code, state, setIsRefetch) => {
	let cloneArrays = state;
	const index = _.findIndex(cloneArrays, { code: code });
	cloneArrays.splice(index, 1);
	localStorage.setItem("CART", JSON.stringify(cloneArrays));
	setIsRefetch(true);
};

export const subTotalPrice = (state, user) => {
	let subtotal = 0;
	if (state.filter((x) => x.isChecked).length >= 1) {
		state
			.filter((x) => x.isChecked)
			.map((item) => {
				if (item.type !== "bundling") {
					let perkalian =
						user.customer_type?.name === "Customer"
							? item.object.price.consumer * item.total
							: item.object.price.user * item.total;
					subtotal = subtotal + perkalian;
					return "";
				}
			});
	}

	return subtotal;
};

export const subTotalPriceBundling = (state, user) => {
	let subtotal = 0;
	if (state.filter((x) => x.isChecked).length >= 1) {
		state
			.filter((x) => x.isChecked)
			.map((item) => {
				if (item.type === "bundling") {
					let perkalian = item.price * item.total;
					subtotal = subtotal + perkalian;
					return "";
				}
			});
	}
	return subtotal;
};

export const handleDeleteAll = (state, setState, setIsRefetch) => {
	setState(state.filter((x) => !x.isChecked));
	localStorage.setItem(
		"CART",
		JSON.stringify(state.filter((x) => !x.isChecked))
	);
	setIsRefetch(true);
};

export const handleChecked = (index, checked, setState) => {
	let localCart = JSON.parse(localStorage.getItem("CART"));
	localCart[index] = {
		...localCart[index],
		isChecked: checked,
	};
	localStorage.setItem("CART", JSON.stringify(localCart));
	setState(localCart);
};

export const handleCheckedAll = (checked, setState) => {
	let localCart = JSON.parse(localStorage.getItem("CART"));
	let temp = [];
	localCart.map((item) => {
		temp.push({
			...item,
			isChecked: checked,
		});
		return "";
	});

	localStorage.setItem("CART", JSON.stringify(temp));
	setState(temp);
};
