import React, { useRef, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { WrapButton } from './style';
import { ReactComponent as FileUploadIcon } from '../../assets/icons/fileUpload.svg';
import { isEmpty } from 'lodash';

const ButtonFileUpload = ({
  id = '',
  children,
  color = 'primary',
  style,
  fullWidth = true,
  rounded = false,
  isDisabled = false,
  hasIcon = true,
  onChange,
}) => {
  const isMobile = useMediaQuery('(max-width:500px)');
  const [fileName, setFileName] = useState(null);
  const fileInput = useRef(null);

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleChange = (event) => {
    if (event.target.files.length > 0) {
      onChange(event);
      setFileName(event.target.files[0].name);
    }
  };

  const _content = () => {
    if (isEmpty(fileName)) {
      return children;
    }
    return fileName;
  };

  return (
    <WrapButton style={style} isMobile={isMobile}>
      <div className={color} style={{ width: fullWidth ? '100%' : 'auto' }}>
        <input
          id={id}
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          ref={fileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
        <button
          onClick={handleClick}
          style={{ borderRadius: rounded ? 100 : 8 }}
          disabled={isDisabled}
        >
          {hasIcon && isEmpty(fileName) && <FileUploadIcon />} {_content()}
        </button>
      </div>
    </WrapButton>
  );
};

export default ButtonFileUpload;
