import styled from 'styled-components';
import constanta from '../../configs/constanta';

export const WrapNavbar = styled.div`
  position: relative;
  .topNav {
    position: relative;
    width: 100%;
    height: 33px;
    background-color: ${constanta.COLOR.darkPink};
    .content {
      display: flex;
      color: ${constanta.COLOR.white};
      font-size: 10px;

      .left {
        p {
          margin: 0;
          padding: 0;
          margin-top: 9px;
          margin-left: 4px;
        }
        svg {
          top: 5px;
          position: relative;
        }
      }
      .right {
        position: absolute;
        right: 0;
        ul {
          margin: 0;
          padding: 0;
          display: flex;
          li {
            display: block;
            margin-left: 24px;
            cursor: pointer;
            padding: 9px 0px;
          }
        }
      }
    }
  }

  .nav {
    height: 64px;
    background-color: ${constanta.COLOR.primary};
    .content {
      display: flex;
      .logo {
        cursor: pointer;
        margin: 14px 0px;
        width: 117px;
        height: 36px;
        img {
          width: 100%;
        }
      }
      .category {
        cursor: pointer;
        margin: 21px 0px;
        margin-left: 30px;
        display: flex;
        p {
          margin: 1px 0px;
          padding: 0;
          font-size: 16px;
          color: ${constanta.COLOR.white};
          margin-left: 4px;
          font-family: 'DMSans-Bold';
        }
      }
      .searchWrap {
        margin: 12px 0px;
        width: 572px;
        margin-left: 32px;
      }
      .cart {
        cursor: pointer;
        display: flex;
        width: 27px;
        position: relative;
        margin-left: 32px;
        margin-top: 15px;
        svg {
          margin-top: 5px;
        }
        .count {
          font-size: 10px;
          color: ${constanta.COLOR.white};
          background-color: ${constanta.COLOR.darkRed};
          padding: 3px;
          border-radius: 30px;
          height: 12px;
          width: 12px;
          text-align: center;
          position: absolute;
          right: 0;
        }
      }
      .orders {
        width: 100px;
        position: relative;
        cursor: pointer;
        margin-top: 20px;
        margin-left: 32px;
        display: flex;
        p {
          margin: 0;
          padding: 0;
          padding-right: 12px;
          color: ${constanta.COLOR.white};
          font-size: 16px;
          font-family: 'DMSans-Bold';
        }
        .count {
          font-size: 10px;
          color: ${constanta.COLOR.white};
          background-color: ${constanta.COLOR.darkRed};
          padding: 3px;
          border-radius: 30px;
          height: 12px;
          width: 12px;
          text-align: center;
          position: absolute;
          right: -4px;
          top: -4px;
        }
      }

      .profile {
        position: relative;
        cursor: pointer;
        margin-top: 16px;
        margin-left: 32px;
        display: flex;
        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 64px;
          margin-right: 8px;
          img {
            width: 100%;
          }
        }
        p {
          margin: 0;
          padding: 0;
          padding-right: 12px;
          color: ${constanta.COLOR.white};
          font-size: 16px;
          font-family: 'DMSans-Bold';
          padding-top: 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 92px;
          height: 25px;
        }
        svg {
          position: relative;
          top: 10px;
        }
      }

      .profile-menu {
        width: 100%;
        transform: translate3d(0px, 20px, 0px) !important;
        z-index: 2;
        .menu {
          margin-top: 20px;
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
          border-radius: 10px;
          :before {
            content: '';
            border-color: transparent transparent #fff transparent;
            border-style: solid;
            border-width: 10px;
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 2px;
            margin-right: 16%;
          }
        }
        ul {
          padding: 0;
          li {
            font-size: 16px;
            font-family: 'DMSans-Bold';
            font-weight: 700;
            line-height: 20px;
            padding-left: 20px;
            :first-child {
              padding-top: 20px;
              padding-bottom: 12px;
            }
            :last-child {
              padding-top: 12px;
              padding-bottom: 20px;
            }
          }
        }
      }
    }
  }

  .notLogin {
    display: flex;
    color: #fff;
    font-size: 16px;
    font-family: 'DMSans-Bold';
    margin-top: 19px;
    margin-left: 32px;
    .separator {
      content: '';
      display: block;
      width: 2px;
      height: 24px;
      background: #fff;
      margin: 0px 15px;
    }
  }
`;

export const WrapNavbarMobile = styled.div`
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 99;
  .hamburger {
    margin-right: 16px;
    cursor: pointer;
  }
  .logo {
    width: 75px;
    img {
      width: 100%;
    }
  }
  .right {
    position: absolute;
    right: 0;
    display: flex;
    div {
      margin-right: 16px;
      position: relative;
    }
    .count {
      font-size: 10px;
      color: ${constanta.COLOR.white};
      background-color: ${constanta.COLOR.darkRed};
      padding: 3px;
      border-radius: 30px;
      height: 12px;
      width: 12px;
      text-align: center;
      position: absolute;
      right: -4px;
      top: -4px;
    }
  }
  
  .cartCount {
    font-size: 10px;
    color: ${constanta.COLOR.white};
    background-color: ${constanta.COLOR.darkRed};
    padding: 3px;
    border-radius: 30px;
    height: 12px;
    width: 12px;
    text-align: center;
    position: relative;
    right: 16px;
    top: 4px;
  }

  .navbarOnPage {
    background: #EFF8FC;
    padding: 16px;
    display: flex;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    .left {
      display: flex;
      width: 100%;
      h3{
        font-size: 18px;
        font-family: 'Inter-Regular';
        color: #2B2B2B;
      }
    }
    .right {
      div {
        margin-right: 8px;
        width: 24px;
        cursor: pointer;
      }
    }
    .back {
      cursor: pointer;
      margin-top: 4px;
    }
  }
`;

export const WrapDrawer = styled.div`
  position: relative;
  width: 200px;
  font-family: 'DMSans-Regular';
  .logo {
    padding: 16px;
    text-align: center;
  }
  .name {
    text-align: center;
    font-size: 18px;
    color: #000;
    font-family: 'DMSans-Bold';
  }
  .MuiTypography-body1 {
    font-size: 14px;
  }
`;
