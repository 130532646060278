import React, { useState, useEffect } from "react";
import { Spacer } from "../../components";
import LayoutAccount from "../../containers/LayoutAccount";
import { Grid, Card, Button, Typography } from "@material-ui/core";
import swal from "sweetalert";
import { GET_REWARDS, REWARD_CLAIM, GET_OMZET } from "../../services/rewards.service";
import { WrapTags } from "./style.js";
import NoImageProduct from "../../assets/img/noImage.svg";
import Progressbar from "../../components/Progress";
import { formatRupiah } from "../../utils/currencyFormat";
import DetailHadiahModal from "./detail-hadiah-modal";
import constanta from "../../configs/constanta";

const HadiahProgress = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [isOpenHadiah, setIsOpenHadiah] = useState(false);
	const [detailHadiah, setDetailhadiah] = useState({});
	const [level, setLevel] = useState({});

	const toggleModalHadiah = () => {
		setIsOpenHadiah(!isOpenHadiah);
	};

	const retrieveProgressHadiah = () => {
		setIsLoading(true);
		GET_REWARDS()
			.then((res) => {
				setData(res.data);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const retrieveOmzet = () => {
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		setIsLoading(true);
		GET_OMZET(user.id)
			.then((res) => {
				setLevel(res.data);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		retrieveProgressHadiah();
		retrieveOmzet();
	}, []);

	const handleClaimReward = (detail) => {
		setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		let payload = {
			user_id: user.id,
			reward_omzet_id: detail.id,
		};
		REWARD_CLAIM(payload)
			.then((res) => {
				setData(res.data);
				swal("Selamat !", "Sukses klaim hadiah", "success");
				retrieveProgressHadiah();
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const takeReward = async (detail) => {
		swal({
			title: "Ambil sekarang ?",
			text: "apakah kamu yakin ingin klaim ini sekarang ?",
			buttons: {
				cancel: "Batal",
				ok: "Ok",
			},
		}).then((value) => {
			if (value === "ok") {
				handleClaimReward(detail);
			}
		});
	};

	const RenderHadiahProgressCard = (dataSource) => {
		return (
			<>
				{dataSource.map((item, index) => {
					const BASE_URL = process.env.REACT_APP_BASE_URL;
					let percentage;
					let remaining;
					if (level && level.omzet_total !== 0 && item.maximum_omzet !== 0) {
						percentage = (level.omzet_total / item.maximum_omzet) * 100;
						remaining = 100 - percentage;
					} else {
						percentage = 0;
						remaining = 100 - percentage;
					}
					return (
						<>
							<div style={{ display: "flex", flexDirection: "column" }} key={index}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "14px",
									}}
								>
									<div
										style={{
											height: "24px",
											width: "24px",
											backgroundColor: constanta.COLOR.primary,
											position: "absolute",
											borderTopRightRadius: "4px",
											borderBottomRightRadius: "4px",
										}}
									>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												color: "#fff",
											}}
										>
											{item.level}
										</div>
									</div>
									<Card
										style={{ minHeight: "180px", width: "459px" }}
										className="card-outlined"
									>
										<div
											style={{
												display: "flex",
												padding: "14px",
											}}
										>
											<img
												src={BASE_URL + "/" + item.images ?? NoImageProduct}
												alt="product"
												onClick={() => {
													setDetailhadiah(item);
													toggleModalHadiah();
												}}
												style={{
													width: "130px",
													height: "130px",
													borderRadius: "10px",
													objectFit: "cover",
												}}
											/>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													marginLeft: "14px",
												}}
											>
												<Typography style={{ fontSize: "14px" }}>
													{item.reward_desc}
												</Typography>
												<WrapTags>
													<div className="tag">
														{Math.round(remaining)}% lagi
													</div>
												</WrapTags>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														paddingTop: "8px",
													}}
												>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															paddingBottom: "6px",
														}}
													>
														<Typography
															variant="body2"
															component="span"
															style={{
																fontSize: "14px",
																fontWeight: "bold",
																color: "black",
																paddingRight: "6px",
															}}
														>
															Omset
														</Typography>
														<div>
															<Typography
																variant="body2"
																component="span"
																style={{
																	fontSize: "14px",
																	fontWeight: "bold",
																	paddingRight: "6px",
																	color: constanta.COLOR.blue,
																}}
															>
																Rp{" "}
																{level && level.omzet_total ? formatRupiah(level.omzet_total) : "0"} /
															</Typography>
															<Typography
																variant="body2"
																component="span"
																color="primary"
																style={{
																	fontSize: "14px",
																	fontWeight: "bold",
																	color: "#333",
																	paddingRight: "6px",
																}}
															>
																{formatRupiah(item.maximum_omzet)}
															</Typography>
														</div>
													</div>
													<Progressbar value={percentage} />
													{/* periodic disable claim */}
													{/* {item.minimum_omzet === item.maximum_omzet ? (
														<Button
															onClick={() => {
																takeReward(item);
															}}
															style={{
																marginTop: "12px",
																color: "white",
															}}
															size="small"
															variant="contained"
															color="primary"
														>
															Klaim Hadiah
														</Button>
													) : null} */}
												</div>
											</div>
										</div>
									</Card>
								</div>
							</div>
						</>
					);
				})}
			</>
		);
	};

	return (
		<LayoutAccount {...props}>
			<Card className="card-custom">
				<div
					style={{
						padding: "14px",
						minHeight: "calc(100vh - 227px)",
						marginLeft: "8px",
						marginRight: "8px",
					}}
				>
					<div
						style={{
							borderBottom: "1px solid #EEEEEE",
							paddingBottom: "12px",
						}}
					>
						<span style={{ fontSize: "20px", color: "#333333" }}>Progress Hadiah</span>
					</div>
					<Spacer />
					{RenderHadiahProgressCard(data)}
					<DetailHadiahModal
						open={isOpenHadiah}
						handleClose={toggleModalHadiah}
						detail={detailHadiah}
					/>
				</div>
			</Card>
		</LayoutAccount>
	);
};

export default HadiahProgress;
