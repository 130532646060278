import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import constanta from "../../configs/constanta";

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 11,
		borderRadius: 3,
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
	},
	bar: {
		borderRadius: 3,
		backgroundColor: constanta.colorPrimary,
	},
}))(LinearProgress);

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
});

export default function Progressbar(props) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<BorderLinearProgress variant="determinate" value={props.value} />
		</div>
	);
}
