import { isEmpty } from "lodash";
import React from "react";
import { Layout } from "../../containers";
import { useOrderContext } from "../../contexts/Order.context";
import { formatRupiah } from "../../utils/currencyFormat";
import {
	AmountWrapperDesktop,
	InfoWrapperDesktop,
	MethodPaymentWrapperDesktop,
	WrapPaymentPageDesktop,
} from "./style";
import iconCopy from "../../assets/icons/iconCopy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Grid, Typography, Button } from "@material-ui/core";
import { ButtonCustom } from "../../components";
import moment from "moment";

const getTotalPayment = (payment) => {
	return payment.total - payment.payment.code_unique;
};

const PaymentPageDesktop = (props) => {
	const { payment } = useOrderContext();

	let expired =
		payment && payment.payment !== undefined
			? moment(payment.payment.expired_at).format("DD MMM YYYY HH:mm")
			: "";
	return (
		<Layout {...props}>
			<WrapPaymentPageDesktop style={{ background: "none" }}>
				<Typography variant="h1">Pembayaran</Typography>
				<Grid container spacing={3}>
					<Grid item xs={9}>
						<AmountWrapperDesktop>
							<div className="title">Nominal Bayar</div>
							{expired && (
								<div style={{ display: "flex" }}>
									<p
										className="description1"
										style={{ marginTop: "4px", marginRight: "4px" }}
									>{`Harap melakukan pembayaran sebelum`}</p>
									<p
										className="description-primary"
										style={{ marginTop: "4px", marginRight: "4px" }}
									>{`${expired},`}</p>
									<p
										className="description1"
										style={{ marginTop: "4px", marginRight: "4px" }}
									>
										{` jika melebihi waktu yang ditentukan, maka pembayaran otomatis dibatalkan`}
									</p>
								</div>
							)}
							<p className="description" style={{ marginTop: "8px" }}>
								Transfer sesuai dengan nominal berikut sampai digit terakhir.
							</p>

							<div className="total">
								Rp {!isEmpty(payment) && formatRupiah(getTotalPayment(payment))}
							</div>
						</AmountWrapperDesktop>
						<MethodPaymentWrapperDesktop>
							<div className="title">Metode Pembayaran</div>
							<p className="description">
								Transfer ke salah satu nomor rekening yang tertera sesuai nominal
								yang telah ditentukan dan lakukan konfirmasi setelahnya.
							</p>
							{!isEmpty(payment) && (
								<div className="list">
									<div className="logo" style={{ width: "110px" }}>
										<img
											src={payment.payment.payment_method.bank.icon}
											alt={payment.payment.payment_method.bank.name}
										/>
									</div>
									<div className="info">
										<span>{payment.payment.payment_method.bank.name}</span>
										<span>{payment.payment.payment_method.account_number}</span>
										<span>{payment.payment.payment_method.account_name}</span>
									</div>
									<CopyToClipboard
										text={payment.payment.payment_method.account_number}
									>
										<div className="copyBtn">
											<img src={iconCopy} alt="copy" />
										</div>
									</CopyToClipboard>
								</div>
							)}
						</MethodPaymentWrapperDesktop>
					</Grid>

					<Grid item xs>
						<InfoWrapperDesktop>
							<div className="details">
								{/* <div className="detail">
									<div className="text">Total Belanja</div>
									<div className="price">
										Rp{" "}
										{!isEmpty(payment) && formatRupiah(payment.payment.amount)}
									</div>
								</div> */}
								{/* <div className="detail">
									<div className="text">Promo Diskon</div>
									<div className="price">Rp 0</div>
								</div> */}
								{/* <div className="detail">
									<div className="text">Biaya Pengiriman</div>
									<div className="price">
										Rp{" "}
										{!isEmpty(payment) && formatRupiah(payment.shipping.cost)}
									</div>
								</div> */}
								{/* <div className="detail">
									<div className="text">Kode Unik</div>
									<div className="price">
										{!isEmpty(payment) &&
											formatRupiah(payment.payment.code_unique)}
									</div>
								</div> */}
								<div className="total">
									<p>Total Pembayaran</p>
									<p>
										Rp{" "}
										{!isEmpty(payment) &&
											formatRupiah(getTotalPayment(payment))}
									</p>
								</div>
							</div>
							<div className="bottomButton">
								<Button
									color="primary"
									variant="contained"
									style={{ width: "100%", color: "white" }}
									onClick={() => props.history.push("/confirm-payment")}
								>
									Konfirmasi Pembayaran
								</Button>
							</div>
						</InfoWrapperDesktop>
					</Grid>
				</Grid>
			</WrapPaymentPageDesktop>
		</Layout>
	);
};
export default PaymentPageDesktop;
