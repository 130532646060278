import React, { useContext, useEffect, useState } from "react";

export const AuthContext = React.createContext();
export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = (props) => {
	const { children } = props;
	const [isAuthenticated, setIsAuthenticated] = useState(null);
	const [user, setUser] = useState({});

	const logout = () => {
		localStorage.removeItem("USER_NAISHA");
		localStorage.removeItem("TOKEN");
		window.location = "/login";
	};

	const getToken = () => {
		const token = JSON.parse(localStorage.getItem("TOKEN"));
		return token;
	};

	useEffect(() => {
		const getUser = JSON.parse(localStorage.getItem("USER_NAISHA"));
		const token = JSON.parse(localStorage.getItem("TOKEN"));
		getUser === null ? setUser({}) : setUser(getUser);

		token && token.accessToken ? setIsAuthenticated(true) : setIsAuthenticated(false);
	}, []);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				user,
				getToken,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
