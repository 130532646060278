import { useMediaQuery } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useOrderContext } from '../../contexts/Order.context';
import ConfirmPaymentPageDesktop from './confirmPayment.desktop';
import ConfirmPaymentPageMobile from './confirmPayment.mobile';

const forms = [
  {
    id: 'date',
    label: 'Tanggal Pembayaran',
    type: 'date',
    required: true,
    value: '',
  },
  {
    id: 'from_bank',
    label: 'Bank Pengirim',
    type: 'text',
    required: true,
    value: '',
  },
  {
    id: 'from_account_name',
    label: 'Nama Pemilik Rekening',
    type: 'text',
    required: true,
    value: '',
  },
  {
    id: 'file',
    label: 'Upload Bukti Transfer',
    type: 'file',
    required: true,
    value: '',
  },
];

const defaultFormValues = {
  date: '',
  from_bank: '',
  from_account_name: '',
  file: {
    name: '',
  },
};

const ConfirmPaymentPage = (props) => {
  const { payment } = useOrderContext();
  const [isDisabled, setDisabled] = useState(true);
  const [formValues, setFormValues] = useState(defaultFormValues);

  useEffect(() => {
    if (!isEmpty(formValues)) {
      if (
        !isEmpty(formValues.date) &&
        !isEmpty(formValues.from_bank) &&
        !isEmpty(formValues.from_account_name) &&
        !isEmpty(formValues.file.name)
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [formValues]);

  const isMobile = useMediaQuery('(max-width:500px)');
  return isMobile ? (
    <ConfirmPaymentPageMobile
      {...{ ...props, isDisabled, forms, payment, formValues, setFormValues }}
    />
  ) : (
    <ConfirmPaymentPageDesktop
      {...{ ...props, isDisabled, forms, payment, formValues, setFormValues }}
    />
  );
};

export default ConfirmPaymentPage;
