import React, { useEffect, useState } from "react";
import {
	ButtonCustom,
	MenuBottom,
	ProductCard,
	ProductCardFull,
	ProductSlider,
	QuantityCounter,
	Skeleton,
	Stars,
	PreOrder,
} from "../../components";
import {
	Card,
	Button,
	Avatar,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormLabel,
	CircularProgress,
} from "@material-ui/core";
import { Layout } from "../../containers";
import { useAuthContext } from "../../contexts/Auth.context";
import { formatRupiah } from "../../utils/currencyFormat";
import { useOrderContext } from "../../contexts/Order.context";
import { WrapProductPageMobile } from "./style";
import _, { isEmpty } from "lodash";
import {
	handleAddToCart,
	handleBuyNow,
	onClickTotalProduct,
	onSelectColor,
	onSelectSize,
	copyProductLink,
} from "./handler";
import { Divider, Grid } from "@material-ui/core";
import { ReactComponent as IconArrowDown } from "../../assets/icons/iconArrowDownLine.svg";
import { ReactComponent as IconArrowUp } from "../../assets/icons/iconArrowUpLine.svg";
import { ReactComponent as IconHeart } from "../../assets/icons_1/love.svg";
import { ReactComponent as IconDiscount } from "../../assets/icons_1/ticket-discount.svg";
import constanta from "../../configs/constanta";
import CouponBg from "../../assets/icons_1/coupon_bg.svg";
import swal from "sweetalert";

const ProductPageMobile = (props) => {
	const {
		isLoading,
		product,
		imageProducts,
		uniqueImagesProduct,
		otherProducts,
		dataAlamat,
	} = props;
	const { user, isAuthenticated } = useAuthContext();
	const [selectedProduct, setSelectedProduct] = useState({
		index: 0,
		name: "",
		slug: "",
		color: "",
		size: "",
		code: "",
		is_preorder: 0,
		day_preorder: 0,
		total: 1,
		stock: 10,
		object: {},
	});
	const [isShowFullDesc, setIsShowFullDesc] = useState(false);
	const [refSlider, setRefSlider] = useState({});
	const { refreshOrderContext } = useOrderContext();
	const [state, setState] = useState({
		isDisabledButton: true,
		isRefetch: false,
		isShowAddToCart: false,
	});

	const sliderSettings = {
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: selectedProduct.index,
		afterChange: (current) =>
			setSelectedProduct({
				...selectedProduct,
				index: current,
			}),
	};

	const _mappingImage = () =>
		uniqueImagesProduct.map((image, key) => (
			<div className="imgProduct" style={{ width: 500 }} key={key}>
				<img src={image.image_url} alt={`product-${image.code}`} />
			</div>
		));

	const _mappingColor = () => {
		let button = [];
		let render = [];
		let render1 = [];
		imageProducts.map((item) => {
			button.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
				stock: item.stock,
			});
			return "";
		});
		_.uniqBy(button, "color").map((item, index) => {
			render.push(
				<ButtonCustom
					type="select"
					key={index}
					isDisabled={item.stock === 0}
					active={item.color === selectedProduct.color}
					onClick={() =>
						onSelectColor(
							item.color,
							index,
							selectedProduct,
							setSelectedProduct,
							refSlider
						)
					}>
					{`${item.color} ${item.stock === 0 ? `( Habis )` : ""}`}
				</ButtonCustom>
				// <FormControlLabel
				// 	key={index}
				// 	isDisabled={item.stock === 0}
				// 	active={item.color === selectedProduct.color}
				// 	control={<Radio />}
				// 	label={item.color}
				// 	color="primary"
				// 	onClick={() =>
				// 		onSelectColor(
				// 			item.color,
				// 			index,
				// 			selectedProduct,
				// 			setSelectedProduct,
				// 			refSlider,
				// 		)
				// 	}
				// />
			);
			return "";
		});
		render1.push(
			<RadioGroup
				row
				aria-labelledby="demo-row-radio-buttons-group-label"
				name="row-radio-buttons-group">
				{render}
			</RadioGroup>
		);
		return render;
	};

	const _mappingSize = () => {
		let button = [];
		let render = [];
		imageProducts.map((item) => {
			button.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
			});
			return "";
		});
		button
			.filter((item) => item.color === selectedProduct.color)
			.map((item, index) => {
				render.push(
					<ButtonCustom
						type="select"
						key={index}
						mdSize
						active={item.size === selectedProduct.size}
						onClick={() =>
							onSelectSize(item.size, selectedProduct, setSelectedProduct)
						}>
						{item.size}
					</ButtonCustom>
				);
				return "";
			});
		return render;
	};

	const getPriceAfterSelectSize = () => {
		if (!isEmpty(selectedProduct.object)) {
			return user?.customer_type?.name === "Customer"
				? formatRupiah(selectedProduct.object.price.consumer)
				: formatRupiah(selectedProduct.object.price.user);
		} else {
			return user?.customer_type?.name === "Customer"
				? formatRupiah(
						uniqueImagesProduct[selectedProduct.index].price.consumer
				  )
				: formatRupiah(uniqueImagesProduct[selectedProduct.index].price.user);
		}
	};

	useEffect(() => {
		const updateSelectedProduct = async () => {
			setSelectedProduct({
				...selectedProduct,
				name: product.name,
				slug: product.slug,
				color: imageProducts[0]?.properties?.color ?? "",
				is_preorder: imageProducts[0]?.properties?.is_preorder ?? 0,
				day_preorder: imageProducts[0]?.properties?.day_preorder ?? 0,
			});
		};
		updateSelectedProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageProducts]);

	useEffect(() => {
		const identifySelected = async () => {
			!isEmpty(product.skus) &&
				product.skus.map((sku) => {
					if (sku.properties.color === selectedProduct.color) {
						if (selectedProduct.size !== "") {
							sku.properties.size === selectedProduct.size &&
								setSelectedProduct({
									...selectedProduct,
									code: sku.code,
									stock: sku.stock,
									object: sku,
								});
						}
					}
					return "";
				});
		};
		if (selectedProduct.size !== "" && selectedProduct.color !== "") {
			setState({
				...state,
				isDisabledButton: false,
			});
		}

		identifySelected();
	}, [selectedProduct.color, selectedProduct.size]);

	const getPriceCustomer = () => {
		let render;
		const min_price = formatRupiah(product?.min_price?.consumer_price_idr ?? 0);
		const max_price = formatRupiah(
			product?.max_price?.consumer_price_idr ?? min_price
		);
		if (isAuthenticated) {
			if (!isEmpty(selectedProduct.object)) {
				render = formatRupiah(selectedProduct.object.price.consumer);
			} else {
				render = min_price + " - " + max_price;
			}
		} else {
			if (!isEmpty(selectedProduct.object)) {
				render = formatRupiah(selectedProduct.object.price.consumer);
			} else {
				render = min_price + " - " + max_price;
			}
		}
		return (
			<div>
				<p style={{ margin: 0, fontSize: "16px" }}>Harga Customer</p>
				<p className="customer">Rp {render}</p>
			</div>
		);
	};

	const getPriceReseller = () => {
		let render;
		const getMinPrice = () => {
			return product.skus.reduce((min, sku) => {
				return sku.price.reseller < min ? sku.price.reseller : min;
			}, Infinity);
		};

		const getMaxPrice = () => {
			return product.skus.reduce((max, sku) => {
				return sku.price.reseller > max ? sku.price.reseller : max;
			}, 0);
		};

		if (isAuthenticated) {
			if (!isEmpty(selectedProduct.object)) {
				render = formatRupiah(selectedProduct.object.price.user);
			} else {
				render =
					formatRupiah(getMinPrice()) + " - " + formatRupiah(getMaxPrice());
			}
		} else {
			if (!isEmpty(selectedProduct.object)) {
				render = formatRupiah(selectedProduct.object.price.user);
			} else {
				render =
					formatRupiah(getMinPrice()) + " - " + formatRupiah(getMaxPrice());
			}
		}
		return (
			<div>
				{isEmpty(user) ||
				(!isEmpty(user) && user.customer_type?.name) === "Customer" ? null : (
					<>
						<p style={{ margin: 0, fontSize: "16px" }}>Harga Reseller</p>
						<p className="customer">Rp {render}</p>
					</>
				)}
			</div>
		);
	};

	const getCoupon = () => {
		return (
			<div className="back">
				<div className="left">
					<IconDiscount />
				</div>
				<div className="right">
					<h6>Ada Kupon cashback nih Rp 10.000</h6>
					<p>Masih ada 2 kupon di produk yang serupa</p>
				</div>
			</div>
		);
	};

	const getRating = () => {
		return (
			<div style={{ paddingTop: "4px", display: "flex" }}>
				<Stars count={4.5} /> <span style={{ marginLeft: "8px" }}>4.5</span>{" "}
				<span style={{ marginLeft: "8px" }}>| Terjual 234</span>
			</div>
		);
	};

	const getRangePriceComissionOnlyNumber = () => {
		if (product.skus) {
			let skus = product.skus;
			const getComission = skus.reduce(function (prev, current) {
				return prev.price.commission >= current.price.commission
					? prev
					: current;
			});

			const maxReseller = skus.reduce(function (prev, current) {
				return prev.price.user > current.price.user ? prev : current;
			});
			function getComissionPercentage(price) {
				let calc =
					(maxReseller.price.consumer - price) / maxReseller.price.consumer;
				return Math.ceil(calc * 100);
			}

			return (
				<div style={{ display: "flex" }}>
					{!isEmpty(user) && user.customer_type?.name === "Customer" ? null : (
						<>
							<span style={{ marginRight: "8px" }}>Komisi Anda:</span>
							<span
								style={{ color: constanta.COLOR.primary, fontWeight: "bold" }}>
								{`(${getComissionPercentage(getComission.price.user)}%)`}
							</span>
							<span
								style={{
									marginLeft: "12px",
									color: constanta.COLOR.primary,
									fontWeight: "bold",
								}}>
								Rp.{" "}
								{formatRupiah(
									getComission.price.consumer - getComission.price.user
								)}
							</span>
						</>
					)}
				</div>
			);
		}
		return null;
	};

	return (
		<WrapProductPageMobile>
			{!isLoading && (
				<Layout {...props}>
					<div
						className="image"
						style={{ marginTop: 80, background: "#EFF8FC" }}>
						<ProductSlider
							refSlider={setRefSlider}
							customSettings={sliderSettings}
							style={{ padding: 0 }}>
							{_mappingImage()}
						</ProductSlider>
						<div className="imgTypeProduct">
							{uniqueImagesProduct[selectedProduct.index]?.color ?? ""}
						</div>
						<div className="imgCounter">
							{selectedProduct.index + 1} / {uniqueImagesProduct.length}
						</div>
					</div>

					<div className="information">
						<div style={{}}>
							<div
								className="price"
								style={{ display: "flex", paddingTop: "4px" }}>
								{getPriceReseller()}
							</div>
							<div className="price" style={{ display: "flex" }}>
								{getPriceCustomer()}
							</div>
							{/* <div className="like">
								<IconHeart  style={{ padding: 7 }}/>
							</div> */}
						</div>
						<div className="stok" style={{ paddingTop: "4px" }}>
							{getRangePriceComissionOnlyNumber()}
						</div>
						<h4 className="name">{product.name}</h4>
						{selectedProduct.is_preorder !== 0 ? (
							<PreOrder days={selectedProduct.day_preorder} />
						) : null}
						{/* <div className="rating">
							{getRating()}
						</div> */}
						{/* <div className="coupon" style={{ paddingTop: "4px" }}>
							{getCoupon()}
						</div> */}

						{/* <div className="price">
							{`Rp `}
							{getPriceAfterSelectSize()}
						</div> */}
						<hr />
						<div style={{ marginTop: 16 }}>
							<h3>Warna:</h3>
							<div className="type">{_mappingColor()}</div>
						</div>
						<div style={{ marginTop: 16 }}>
							<h3>Ukuran:</h3>
							<div className="type">{_mappingSize()}</div>
						</div>
						{/* <div style={{ marginTop: 16 }}>
							<h3>Biaya Pengiriman:</h3>
							<div style={{ marginTop: 8 }}>
								{isEmpty(dataAlamat) ? (
									<div onClick={() => props.history.push("/akun/alamat")}>
										<p className="link">Tambah Alamat</p>
										<p>untuk mengetahui Ongkos Kirim</p>
									</div>
								) : (
									<div>
										<div
											className="seeAllAlamat"
											onClick={() => props.history.push("/akun/alamat")}
										>
											lihat semua alamat
										</div>
										<p>
											Ongkos kirim mulai dari{" "}
											<span className="primary"> Rp 12.000</span>
										</p>
										<p>
											ke{" "}
											<span className="bold">
												{`${dataAlamat[0].selectCityType} ` +
													dataAlamat[0].selectCityName}
											</span>
										</p>
									</div>
								)}
							</div>
						</div> */}
						<div className="rincian">
							<h3>Informasi Produk</h3>
							<div className="status">New</div>
							<div>
								<table>
									<tr>
										<td>Berat</td>
										<td style={{ textAlign: "right" }}>
											{!isEmpty(selectedProduct.object)
												? selectedProduct.object.weight_gram
												: product.skus[0].weight_gram}{" "}
											<span>gram</span>
										</td>
									</tr>
									<tr>
										<td>Stok</td>
										<td style={{ textAlign: "right" }}>
											{!isEmpty(selectedProduct.object)
												? selectedProduct.object.stock
												: product.skus[0].stock}{" "}
											<span>Tersedia</span>
										</td>
									</tr>
									<tr>
										<td>Bahan</td>
										<td style={{ textAlign: "right" }}>
											{!isEmpty(selectedProduct.object)
												? selectedProduct.object.properties.material
												: product.skus[0].properties.material}
										</td>
									</tr>
									{/* <tr>
										<td>Dikirim Dari</td>
										<td>D.I Yogyakarta</td>
									</tr> */}
								</table>
								<div
									className="desc"
									style={{ height: isShowFullDesc ? "max-content" : 84 }}>
									{product.description.split("\n").map((desc, index) => (
										<p key={index}>{desc}</p>
									))}
								</div>
								{!isShowFullDesc ? (
									<button onClick={() => setIsShowFullDesc(!isShowFullDesc)}>
										Deskripsi Lengkap <IconArrowDown fill="#fff" />
									</button>
								) : (
									<button onClick={() => setIsShowFullDesc(!isShowFullDesc)}>
										Sembunyikan <IconArrowUp />
									</button>
								)}
								{isEmpty(user) ||
								(!isEmpty(user) &&
									user.customer_type?.name === "Customer") ? null : (
									<>
										<Divider />
										<div className="affiliate">
											<h3>
												Jadi Affiliator Naisha, Dapatkan Komisi{" "}
												{user.commision_affliliate
													? `${user.commision_affliliate}%`
													: "Menarik"}
											</h3>
											<p>Copy link di bawah ini dan bagikan ke teman kamu</p>
											<div className="btnGroup" style={{ marginTop: 4 }}>
												<ButtonCustom
													type="naked"
													fullWidth={false}
													isDisabled={user == null}
													onClick={() => {
														copyProductLink(product.slug);
													}}>
													Copy Link
												</ButtonCustom>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
					<Grid
						container
						spacing={3}
						style={{ marginTop: 16, marginBottom: 68 }}>
						<Grid item xs={12} style={{ padding: "0px 16px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									margin: "0 20px",
								}}>
								<h3
									style={{
										fontSize: 14,
										fontWeight: "semi-bold",
										color: "#1E3354",
										padding: 0,
									}}>
									Produk Serupa
								</h3>
								<div
									onClick={() => {
										window.location.href = `/category?type=${2}`;
									}}>
									<a
										style={{
											fontSize: 11,
											fontWeight: "semi-bold",
											color: "#3AD4E0",
											padding: 0,
											cursor: "pointer",
										}}>
										Lihat Semua
									</a>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} style={{ marginLeft: 20 }}>
							{!isLoading ? (
								<ProductSlider>
									{!isEmpty(otherProducts) &&
										otherProducts.map((item) => {
											if (isEmpty(user)) {
												return (
													<ProductCard
														role={
															!isEmpty(user) ? user?.customer_type?.name : ""
														}
														name={item.name}
														price={
															!isEmpty(user) &&
															user?.customer_type?.name === "Customer"
																? item.skus[0].price.consumer
																: item.skus[0].price.user
														}
														image_url={item.image_url}
														key={item.slug}
														slug={item.slug}
													/>
												);
											} else {
												return (
													<ProductCard
														name={item.name}
														price={item.skus[0].price.consumer}
														image_url={item.image_url}
														key={item.slug}
														slug={item.slug}
													/>
												);
											}
										})}
								</ProductSlider>
							) : (
								<Skeleton type="product" />
							)}
						</Grid>
					</Grid>
				</Layout>
			)}
			<MenuBottom
				isDisabled={state.isDisabledButton}
				isFullWrapper={state.isShowAddToCart}
				onClickWrapper={() => setState({ ...state, isShowAddToCart: false })}
				onClickAddToCart={() =>
					setState({ ...state, isShowAddToCart: !state.isShowAddToCart })
				}
				onSubmit={() => handleBuyNow(selectedProduct, props.history)}
				history={props.history}>
				<div className="addToCartPopup">
					<div style={{ marginTop: 16 }}>
						{!isEmpty(selectedProduct.object) && (
							<ProductCardFull
								name={selectedProduct.name}
								// price={
								// 	user && user.customer_type?.name === "Customer"
								// 		? selectedProduct.object.price.consumer
								// 		: selectedProduct.object.price.user
								// }
								price={
									!isEmpty(user) && user.customer_type?.name === "Customer"
										? selectedProduct.object.price.consumer
										: selectedProduct.object.price.user
								}
								image_url={selectedProduct.object.image_url}
								stock={selectedProduct.object.stock}
								withCheckbox={false}
								withDeleteButton={false}
								withQuantityButton={false}
								color={selectedProduct.object.properties.color}
								size={selectedProduct.object.properties.size}
							/>
						)}
					</div>

					<div style={{ marginTop: 16 }}>
						<h3>Warna:</h3>
						<div className="type">{_mappingColor()}</div>
					</div>
					<div style={{ marginTop: 16 }}>
						<h3>Ukuran:</h3>
						<div className="type">{_mappingSize()}</div>
					</div>
					<div
						style={{
							marginTop: 16,
							display: "flex",
							position: "relative",
							marginBottom: 20,
						}}>
						<h3>Jumlah:</h3>
						<QuantityCounter
							total={selectedProduct.total}
							style={{
								position: "absolute",
								right: 16,
								top: 10,
								borderTop: "1px solid #e0e0e0",
								borderBottom: "1px solid #e0e0e0",
							}}
							onClickMinus={() =>
								onClickTotalProduct(
									"minus",
									selectedProduct,
									setSelectedProduct
								)
							}
							onClickPlus={() =>
								onClickTotalProduct("plus", selectedProduct, setSelectedProduct)
							}
						/>
					</div>
					<ButtonCustom
						mdSize
						isDisabled={state.isDisabledButton}
						onClick={() => {
							handleAddToCart(selectedProduct);
							refreshOrderContext();
							swal(
								"Success",
								"Berhasil menambahkan produk kedalam keranjang",
								"success"
							);
						}}>
						Tambah ke Keranjang
					</ButtonCustom>
				</div>
			</MenuBottom>
		</WrapProductPageMobile>
	);
};

export default ProductPageMobile;
