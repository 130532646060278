import React, { useState, useEffect } from "react";
import { Layout } from "../../containers";
import { WrapAuthForm } from "./style";
import {
	// TextField,
	OutlinedInput,
	useMediaQuery,
	InputAdornment,
	IconButton,
} from "@material-ui/core";
import GoogleLogin from "react-google-login";
import constanta from "../../configs/constanta";
import brandGoogle from "../../assets/brand/google.svg";
import { useAuthContext } from "../../contexts/Auth.context";
import swal from "sweetalert";
import { ButtonCustom } from "../../components";
import { REGISTER, LOGIN_GOOGLE } from "../../services/auth.service";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import bannerImg from "../../assets/img/register_banner.png";

const RegisterPage = (props) => {
	const { isAuthenticated } = useAuthContext();

	const [showPassword, setShowPassword] = useState(false);
	const [form, setForm] = useState({
		name: "",
		email: "",
		password: "",
		password_confirmation: "",
		referral_code: "",
	});
	const isMobile = useMediaQuery("(max-width:500px)");

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const responseGoogleSignIn = async (e) => {
		let value = e.profileObj;
		const body = {
			name: value.name,
			email: value.email,
			google_id: value.googleId,
			avatar: value.imageUrl,
		};
		await LOGIN_GOOGLE(body)
			.then((response) => {
				if (response.message === "Ok") {
					localStorage.setItem("USER_NAISHA", JSON.stringify(response.user));
					localStorage.setItem("TOKEN", JSON.stringify(response.token));

					swal("Berhasil", "Login berhasil !", "success");
					window.location = "/";
				}
			})
			.catch(() => {
				swal("Error", "Email / password tidak cocok", "error");
			});
	};

	const onChange = async (name, value) => {
		setForm({
			...form,
			[name]: value,
		});
	};

	const onSubmit = async () => {
		await REGISTER(form)
			.then((response) => {
				swal("Berhasil", "Selamat anda berhasil register", "success");
				props.history.push("/login");
			})
			.catch((e) => {
				console.log(e.response.data);
				swal(
					"Error",
					`${
						e.response.data?.errors?.name?.toString()
							? `${e.response.data?.errors?.name?.toString()},`
							: ""
					}
					${
						e.response.data?.errors?.email?.toString()
							? `${e.response.data?.errors?.email?.toString()},`
							: ""
					} 
					${
						e.response.data?.errors?.referral?.toString()
							? `${e.response.data?.errors?.referral?.toString()},`
							: ""
					} 
					${
						e.response.data?.errors?.password?.toString()
							? `${e.response.data?.errors?.password?.toString()}`
							: ""
					}`,
						"error",
					);
			});
	};

	useEffect(() => {
		isAuthenticated && props.history.push("/");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout withNavbar={false} {...props}>
			<WrapAuthForm>
                <div>
					<img src={bannerImg} alt="banner" style={{ width: "100%",
					position: "absolute",
					top: -32,
					left: 0,
					maxHeight: 200
				}} />
                </div>
				<div style={{ padding: 16, paddingTop: 220}}>
                <div className="formGroup">
						<div style={{ marginBottom: "16px" }}>
							<OutlinedInput
								id="standard-adornment-email"
								placeholder="Nama Lengkap"
								fullWidth
								type={"text"}
								value={form.name}
								size="small"
								onChange={(e) => onChange("name", e.target.value)}
							/>
						</div>
						<div style={{ marginBottom: "16px" }}>
							<OutlinedInput
								id="standard-adornment-email"
								placeholder="Email"
								fullWidth
								type={"text"}
								value={form.email}
								size="small"
								onChange={(e) => onChange("email", e.target.value)}
							/>
						</div>
						<div style={{ marginBottom: "16px" }}>
							<OutlinedInput
								id="standard-adornment-password"
								placeholder="Password"
								fullWidth
								size="small"
								type={showPassword ? "text" : "password"}
								value={form.password}
								onChange={(e) => onChange("password", e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</div>
						<div style={{ marginBottom: "16px" }}>
							<OutlinedInput
								id="standard-adornment-password"
								placeholder="Ulangi Password"
								fullWidth
								size="small"
								type={showPassword ? "text" : "password"}
								value={form.password_confirmation}
								onChange={(e) => onChange("password_confirmation", e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</div>
						<div >
							<OutlinedInput
								id="standard-adornment-email"
								placeholder="Kode referral (optional)"
								fullWidth
								type={"text"}
								value={form.referral_code}
								size="small"
								onChange={(e) => onChange("referral_code", e.target.value)}
							/>
						</div>
					</div>
                    <ButtonCustom color="primary" onClick={onSubmit}>
                        Daftar
                    </ButtonCustom>
                    <div className="separator">Atau</div>
					<GoogleLogin
						clientId={constanta.GOOGLE_CLIENT_ID}
						render={(renderProps) => (
							<div className="withSocial" onClick={renderProps.onClick}>
								<div className="center">
									<div className="logo">
										<img src={brandGoogle} alt="google" />
									</div>
									<div className="text">Daftar dengan Google</div>
								</div>
							</div>
						)}
						buttonText="Login"
						onSuccess={responseGoogleSignIn}
						onFailure={responseGoogleSignIn}
						cookiePolicy={"single_host_origin"}
					/>
					{/* <div className="withSocial">
					<div className="center">
						<div className="logo">
							<img src={brandApple} alt="apple" />
						</div>
						<div className="text">{text} dengan Apple</div>
					</div>
				</div> */}
					
                    
                    <div className="others">
                        <div style={{ textAlign: "center", marginTop: 16 }}>
                            <p>
                                Sudah punya akun ? <a href={"/login"}>Masuk sini</a>
                            </p>
                        </div>
                    </div>
				</div>
			</WrapAuthForm>
		</Layout>
	);
};

export default RegisterPage;
