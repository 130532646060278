import { axiosInstance } from "./axiosInstance";

export const GET_PROVINCES = () => {
	// const url = `/api/support/location/provinces`;
	const url = `/api/support/kirimpaket/location/provinces`;
	const data = axiosInstance.get(url);
	return data;
};

export const GET_CITIES = (body) => {
	// let filter = "";
	// if (filters.name === undefined) {
	// 	filter = `?filter[province.id]=${filters.provinceId}`;
	// } else {
	// 	filters.name && (filter = `?filter[name]=${filters.name}`);
	// 	filters.provinceId && (filter = filter + `&filter[province.id]=${filters.provinceId}`);
	// }
	// const url = `/api/support/location/cities${filter}`;
	const url = `/api/support/kirimpaket/location/cities`;
	const data = axiosInstance.get(url, {
		params: {
			id: body.id,
		},
	});
	return data;
};

export const GET_DISTRICT = (body) => {
	const url = `/api/support/kirimpaket/location/districts`;
	const data = axiosInstance.get(url, {
		params: {
			id: body.id,
		},
	});
	return data;
};

export const GET_SUBDSITRICTS = (body) => {
	// const url = `api/support/location/subdistricts?city_id=${city_id}`;
	// const data = axiosInstance.get(url);
	const url = `/api/support/kirimpaket/location/subdistricts`;
	const data = axiosInstance.get(url, {
		params: {
			id: body.id,
		},
	});
	return data;
};


export const CHECK_COST = (body) => {
	const url = `/api/support/kirimpaket/cost`;
	const data = axiosInstance.post(url, body);
	return data;
}
