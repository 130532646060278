import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import listRoutes from "../src/configs/listRoutes";
import HomePage from "./pages/home-page";
import { AuthContextProvider } from "./contexts/Auth.context";
import { OrderContextProvider } from "./contexts/Order.context";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import constanta from "./configs/constanta";

import LoginPage from "./pages/login-page";
import RegisterPage from "./pages/register-page";
import ProductPage from "./pages/product-page";
import Inter from "./assets/fonts/Inter/Inter-Regular.ttf";

const theme = createTheme({
	palette: {
		primary: {
			main: constanta.COLOR.primary,
		},
		secondary: {
			main: constanta.COLOR.darkRed,
		},
	},
	typography: {
		fontFamily: "Inter, Arial, sans-serif",
		button: {
			textTransform: "capitalize",
			color: "white",
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: `{
						@font-face: {
							font-family: "Inter";
							src: url(${Inter});
						},
					}`,
				},
			},
		}
	},
	// props: {
	// 	MuiButton: {
	// 		size: "small",
	// 	},
	// },
});

function App() {
	return (
		<div className="nasihaApp">
			<MuiThemeProvider theme={theme}>
				<Router>
					<Switch>
						<OrderContextProvider>
							<AuthContextProvider>
								<Route exact path="/" component={HomePage} />
								<Route exact path="/product/:slug" component={ProductPage} />
								{listRoutes.map((route, index) => (
									<Route
										exact
										path={route.path}
										component={route.component}
										key={index}
									/>
								))}
							</AuthContextProvider>
						</OrderContextProvider>
					</Switch>
				</Router>
			</MuiThemeProvider>
		</div>
	);
}

export default App;
