import React from "react";
import { ReactComponent as StarYellow } from "../../assets/icons/iconStarYellow.svg";
import { ReactComponent as Star } from "../../assets/icons/iconStar.svg";
import { WrapStars } from "./style";

const StarsComponent = ({ count = 5 }) => {
  const _mappingStars = () => {
    let stars = [];

    if (count > 5) {
      return "error, more than 5 !";
    }

    for (let index = 0; index < count; index++) {
      stars.push(<StarYellow key={index} />);
    }

    while (stars.length !== 5) {
      stars.push(<Star key={stars.length} />);
    }

    return stars;
  };

  return (
    <WrapStars>
      <div className="stars">{_mappingStars()}</div>
    </WrapStars>
  );
};

export default StarsComponent;
