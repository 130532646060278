import { useMediaQuery } from "@material-ui/core";
import React from "react";
import SkeletonProduct from "../../assets/img/skeletonProduct.svg";
import SkeletonProductList from "../../assets/img/skeletonProductList.svg";
import SkeletonProductDesktop from "../../assets/img/skeletonProductDesktop.svg";
import SkeletonDescProduct from "../../assets/img/skeletonDescProduct.svg";
import SkeletonImageView from "../../assets/img/skeletonImageView.svg";
import SkeletonRincianProduct from "../../assets/img/skeletonRincianProduct.svg";
import SkeletonBulletList from "../../assets/img/skeletonBulletList.svg";
import { WrapSkeleton } from "./style";

const SkeletonComponent = ({ type = "product" }) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	return (
		<WrapSkeleton>
			{type === "product" &&
				(isMobile ? (
					<img src={SkeletonProduct} alt="loading-product" style={{ width: "100%" }} />
				) : (
					<img src={SkeletonProductDesktop} alt="loading-product" />
				))}
			{type === "productList" &&
				(isMobile ? (
					<img src={SkeletonProductList} alt="loading-product" />
				) : (
					<img src={SkeletonProductList} alt="loading-product" />
				))}
			{type === "imageView" &&
				(isMobile ? (
					<img src={SkeletonImageView} alt="loading-product" />
				) : (
					<img src={SkeletonImageView} alt="loading-product" />
				))}
			{type === "productDetail.desc" &&
				(isMobile ? (
					<img src={SkeletonDescProduct} alt="loading-product" />
				) : (
					<img src={SkeletonDescProduct} alt="loading-product" />
				))}
			{type === "productDetail.rincian" &&
				(isMobile ? (
					<img src={SkeletonRincianProduct} alt="loading-product" />
				) : (
					<img src={SkeletonRincianProduct} alt="loading-product" />
				))}
			{type === "bulletList" &&
				(isMobile ? (
					<img src={SkeletonBulletList} alt="loading-product" />
				) : (
					<img src={SkeletonBulletList} alt="loading-product" />
				))}
		</WrapSkeleton>
	);
};

export default SkeletonComponent;
