import styled from "styled-components";
import constanta from "../../configs/constanta";


export const WrapPaymentMethodPage = styled.div`
	position: relative;
	padding-top: 82px;
	min-height: calc(100vh - 150px);
	background-color: #EFF8FC;
	.list {
		background-color: #fff;
		display: flex;
		margin: 10px 20px;
		padding: 16px 20px;
		.logo {
			width: 30%;
			padding: 4px 16px;
			img {
				width: 100%;
			}
		}
		.info {
			padding: 8px 0;
			position: relative;
			margin-left: 8px;
			width: 60%;
			span {
				width: 100%;
				display: inline-block;
				color: #333;
				margin: 0;
				padding: 0;
				line-height: 12px;
				:nth-child(1) {
					color: ${constanta.COLOR.text};
					font-size: 10px;
					font-family: "Inter-Medium";
				}
				:nth-child(2) {
					color: ${constanta.COLOR.text};
					font-size: 14px;
					font-family: "Inter-Medium";
				}

			}
		}
		.radioBtn {
			width: 10%;
			position: relative;
			top: 10px;
		}
	}
	.floating {
		background-color: #fff;
		position: fixed;
		bottom: 16px;
		width: 100%;
		padding: 24px 20px;
		button{
			width: 92%;
		}
	}
`;
