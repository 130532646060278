import styled from "styled-components";

export const WrapShippingService = styled.div`
	position: relative;
	display: inline-block;
	// padding: 8px 8px;
	cursor: pointer;
	width: 100%;
	color: #1e3354;

	.title {
		font-size: 14px;
		color: #333;
		font-family: "DMSans-Bold";
	}
	.desc {
		font-size: 12px;
		color: #333;
		border-bottom: 1px solid #ddd;
		padding: 8px 0px;
	}
	.services {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 4px 16px;
		background: #fff;
		border-radius: 4px;
		width: 100%;
		.title {
			font-size: 16px;
			font-weight: 600;
		}
		.checkbox {
			float: left;
		}
		.desc {
			border-bottom: none;
			padding: 0;
		}
		.right {
			display: flex;
			align-items: center;
		}
		.price {
			font-size: 14px;
			font-weight: 600;
		}
	}
	.hidden {
		display: none;
	}
`;

export const WrapShippingPage = styled.div`
	position: relative;
	padding: 0 100px;
	background: #eff8fc;
	min-height: 100vh;
	.floating {
		position: fixed;
		bottom: 16px;
		width: 88%;
		background: #fff;
		z-index: 999;
	}

	@media (max-width: 768px) {
		padding: 0 26px;
	}

	@media (max-width: 768px) {
		padding: 0 26px;
	}
`;
