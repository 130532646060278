import { useMediaQuery } from "@material-ui/core";
import React from "react";
import MarketPlaceDesktop from "./marketPlace.desktop";
import MarketPlaceMobile from "./marketPlace.mobile";

const PaymentMethodPage = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	return isMobile ? <MarketPlaceMobile {...props} /> : <MarketPlaceDesktop {...props} />;
};
export default PaymentMethodPage;
