import { useMediaQuery } from '@material-ui/core';
import React from 'react'
import CheckoutPageDesktop from './CheckoutPage.desktop';
import CheckoutPageMobile from './CheckoutPage.mobile'

const CheckoutPage = props => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return isMobile ? <CheckoutPageMobile {...props} /> : <CheckoutPageDesktop {...props} />
}

export default CheckoutPage