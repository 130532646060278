import styled from "styled-components";

export const WrapCartPage = styled.div`
	position: relative;
	width: 100%;
	background: #eff8fc;
	min-height: 100vh;
	padding: 24px;
	.checkAll {
		color: #999;
		font-size: 14px;
		background: #fff;
		padding: 16px;
	}
	.items {
		margin: 0px 12px;
		margin-bottom: 80px;
	}
	.deleteAll {
		position: absolute;
		right: 12px;
		margin-top: 12px;
	}

	@media (max-width: 768px) {
		position: relative;
		width: 100%;
		padding: 0px;
		padding-top: 70px;

		.items {
			margin: 14px;
			margin-bottom: 80px;
		}
	}
`;

export const HStack = styled.div`
	display: flex;
	flex-direction: row;
`;

export const VStack = styled.div`
	display: flex;
	flex-direction: column;
`;
