import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ProductCard, ProductBundlingCard, Skeleton, EmptyState } from "../../components";
import { Layout } from "../../containers";
import { WrapHome } from "./style";
import { useAuthContext } from "../../contexts/Auth.context";
import { useOrderContext } from "../../contexts/Order.context";
import { GET_PRODUCTS, GET_BUNDLING } from "../../services/products.service";
import { GET_CATEGORY } from "../../services/popup.service";
import { ChipContained } from "../../components/chips";
import { Spacer } from "../../components";
import Constanta from "../../configs/constanta";
import _, { isEmpty } from "lodash";
import Pagination from "@material-ui/lab/Pagination";
// import {
// 	onClickTotalProduct,
// 	onSelectColorDesktop,
// 	onSelectedImage,
// 	onSelectSize,
// 	handleAddToCart,
// 	handleBuyNow,
// } from "../product-page/handler";
import swal from "sweetalert";

const KategoriPage = (props) => {
	const { user } = useAuthContext();
	const isMobile = useMediaQuery("(max-width:500px)");
	const { isAuthenticated } = useAuthContext();
	const { refreshOrderContext } = useOrderContext();

	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [products, setProducts] = useState([]);
	const [bundlings, setBundlings] = useState([]);
	const [filters, setFilters] = useState({
		paginate: 10,
		page: 1,
		category: "",
		subCategory: "",
	});
	const [isLoading, setIsLoading] = useState(false);

	const getFilter = (filters, categoryId) => {
		let filter = `?paginate=${filters.paginate}&page=${filters.page}`;
		categoryId !== undefined && (filter = filter + `&filter[category.id]=${categoryId}`);

		return filter;
	};

	const getProducts = async () => {
		setIsLoading(true);
		let category = `${filters.category},${filters.subCategory}`;

		GET_PRODUCTS(getFilter(filters, category))
			.then((res) => {
				setProducts(res);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};
	useEffect(() => {
		getProducts();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	useEffect(() => {
		GET_CATEGORY()
			.then((res) => {
				setCategories(res.data);
			})
			.catch((err) => {});
	}, []);

	const setFilterByCategory = (data) => {
		setFilters((prevState) => {
			return {
				...prevState,
				category: data,
				subCategory: "",
			};
		});
	};

	const setFilterBySubCategory = (data) => {
		setFilters((prevState) => {
			return {
				...prevState,
				subCategory: data,
			};
		});
	};

	const getDetail = async (item) => {
		props.history.push(`/bundling/${item.id}`);
	};

	const containerStyle = {
		maxWidth: 1220,
		position: "relative",
		margin: "0 auto",
		left: 0,
		right: 0,
	};

	return (
		<WrapHome>
			<Layout {...props} withContainer={false}>
				<div style={!isMobile ? containerStyle : {}}>
					<Grid container spacing={3} style={{ marginTop: 20 }}>
						<Grid item xs={12}>
							<div
								style={{
									minHeight: "130px",
									backgroundColor: "#F969AE",
									borderRadius: "10px",
								}}
							>
								<div style={{ padding: "20px" }}>
									<div style={{ display: "flex", marginBottom: "12px" }}>
										<span
											style={{
												fontSize: "32px",
												color: "#fff",
												fontWeight: "bold",
											}}
										>
											Kategori
										</span>
									</div>
									<div style={{ display: "flex", flexWrap: "wrap" }}>
										{categories.map((item, index) => {
											return (
												<React.Fragment key={index}>
													<ChipContained
														text={item.name}
														isActive={filters.category === item.id}
														onClickChip={() => {
															if (item.children.length > 0) {
																setSubCategories(item.children);
																setFilterByCategory(item.id);
															}
														}}
													/>
													<Spacer />
												</React.Fragment>
											);
										})}
									</div>
								</div>
							</div>
						</Grid>
						{subCategories.length > 0 && (
							<Grid item xs={12}>
								<div
									style={{
										minHeight: "130px",
										backgroundColor: "#ffffff",
										borderRadius: "8px",
										boxShadow: "0px 2px 15px rgb(0 0 0 / 7%)",
									}}
								>
									<div style={{ padding: "20px" }}>
										<Grid container spacing={2}>
											{subCategories.map((item, index) => {
												let isActive = filters.subCategory === item.id;
												return (
													<React.Fragment key={index}>
														<Grid item xs={3}>
															<div
																style={{
																	display: "flex",
																	flexDirection: "row",
																	alignItems: "center",
																	cursor: "pointer",
																	color: isActive
																		? Constanta.COLOR.primary
																		: "#000",
																	fontWeight: isActive
																		? "bold"
																		: "normal",
																}}
																onClick={() => {
																	setFilterBySubCategory(item.id);
																}}
															>
																<div
																	style={{
																		height: "30px",
																		width: "30px",
																		borderRadius: "10px",
																		border: "1px solid #E6E6E6",
																		padding: "4px",
																	}}
																>
																	<img
																		style={{
																			width: "100%",
																			height: "100%",
																		}}
																		src={item.icon_url}
																		alt="icon"
																	/>
																</div>
																<div
																	style={{
																		marginLeft: "6px",
																	}}
																>
																	{item.name}
																</div>
															</div>
														</Grid>
													</React.Fragment>
												);
											})}
										</Grid>
									</div>
								</div>
							</Grid>
						)}
						<Grid item xs={12}>
							{!isLoading ? (
								<div
									style={{
										display: "flex",
										flexWrap: "wrap",
										width: "100%",
									}}
								>
									<>
										{!isEmpty(products.data) ? (
											products.data.map((item, index) => {
												if (item && item.show_product) {
													if (!isEmpty(user)) {
														return (
															<React.Fragment key={index}>
																<div style={{ margin: "8px" }}>
																	<ProductCard
																		role={
																			!isEmpty(user)
																				? user.customer_type
																						.name
																				: ""
																		}
																		name={item.name}
																		price={
																			!isEmpty(user) &&
																			user.customer_type
																				.name === "Customer"
																				? item.skus[0].price
																						.consumer
																				: item.skus[0].price
																						.user
																		}
																		discount={
																			// item.skus[0].price.consumer
																			item.skus[0].price
																				.consumer
																		}
																		image_url={item.image_url}
																		key={item.slug}
																		slug={item.slug}
																	/>
																</div>
															</React.Fragment>
														);
													} else {
														return (
															<React.Fragment key={index}>
																<div style={{ margin: "8px" }}>
																	<ProductCard
																		role={
																			!isEmpty(user)
																				? user.customer_type
																						.name
																				: ""
																		}
																		name={item.name}
																		price={
																			item.skus[0].price
																				.consumer
																		}
																		discount={
																			item.skus[0].price
																				.consumer
																		}
																		image_url={item.image_url}
																		key={item.slug}
																		slug={item.slug}
																	/>
																</div>
															</React.Fragment>
														);
													}
												}
											})
										) : (
											<div
												style={{
													width: "100%",
													display: "flex",
													flexWrap: "wrap",
													justifyContent: "space-between",
												}}
											>
												<EmptyState
													desc="Produk Yang Anda Cari Tidak Ditemukan"
													// title=""
												/>
											</div>
										)}
									</>
								</div>
							) : (
								<div style={{ display: "flex", flexDirection: "column" }}>
									<div style={{ paddingBottom: "24px" }}>
										<Skeleton type="product" />
									</div>
									<div style={{ paddingBottom: "24px" }}>
										<Skeleton type="product" />
									</div>
									<div style={{ paddingBottom: "24px" }}>
										<Skeleton type="product" />
									</div>
								</div>
							)}
						</Grid>
					</Grid>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							paddingTop: "24px",
						}}
					>
						<Pagination
							page={filters.page}
							onChange={(event, page) => {
								setFilters((prevState) => {
									return {
										...prevState,
										page: page,
									};
								});
							}}
							count={products.meta?.total}
							variant="outlined"
							shape="rounded"
						/>
					</div>
				</div>
			</Layout>
		</WrapHome>
	);
};

export default KategoriPage;
