const STATUS_ORDERS = {
  waiting_payment: {
    code: 10,
    message: 'Menunggu Pembayaran.',
  },
  forwarded: {
    code: 20,
    message: 'Pesanan Terbayar.',
  },
  processed: {
    code: 30,
    message: 'Pesanan Sedang Diproses.',
  },
  delivery: {
    code: 40,
    message: 'Pesanan Sedang Dikirim.',
  },
  done: {
    code: 50,
    message: 'Pesanan Diterima Customer.',
  },
  canceled: {
    code: 92,
    message: 'Pesanan Dibatalkan Oleh Reseller.',
  },
  canceledAdmin: {
    code: 91,
    message: 'Pesanan Dibatalkan Oleh Admin.',
  },
};

export default STATUS_ORDERS;
