import React from 'react';
import ImgEmpty from '../../assets/img/empty.svg';
import { WrapEmptyState } from './style';

const EmptyStateComponent = ({ title = 'Data Kosong', desc = '', image }) => {
  return (
    <WrapEmptyState>
      <div className="image">
        <img src={image ?? ImgEmpty} alt="kosong" />
      </div>
      <div className="desc">
        <div className="title">{title}</div>
        <p>{desc}</p>
      </div>
    </WrapEmptyState>
  );
};

export default EmptyStateComponent;
