import { useMediaQuery } from "@material-ui/core";
import React from "react";
import LoginPageDesktop from "./LoginPage.desktop";
import LoginPageMobile from "./LoginPage.mobile";

const PaymentPage = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return isMobile ? <LoginPageMobile {...props} /> : <LoginPageDesktop {...props} />;
};
export default PaymentPage;
