import React, { useState, useEffect } from "react";
import { WrapBundlingCard } from "./style";
import NoImageProduct from "../../assets/img/noImage.svg";
import IconDiscount from "../../assets/icons/icDiscount.svg";
import { useMediaQuery, Button } from "@material-ui/core";
import { formatRupiah } from "../../utils/currencyFormat";
import { useHistory } from "react-router";

const ProductBundlingCard = ({ product, userType, buyBundle, getDetail }) => {
	let history = useHistory();
	const isMobile = useMediaQuery("(max-width:500px)");

	let priceBundling;

	if (product.items !== undefined) {
		priceBundling =
			product.items
				.map((e) => (e.product != null ? e.product.skus[0].price.bundling_price : 0))
				.reduce((a, b) => a + b) ?? 0;
	} else {
		priceBundling = "";
	}

	return (
		<div >
			<WrapBundlingCard>
				<div style={{ padding: "12px" }}>
					<h4 style={{ marginBottom: "24px" }}>{product?.name}</h4>
					<div
						style={{ }}
						onClick={() => getDetail()}
					>
						{product.items !== undefined
							? product.items.map((data, index) => {
									let price;
									let discount;
									let nameProduct =
										data.product.name.length > 80
											? data.product.name.substring(0, 80) + "..."
											: data.product.name;

									price = data.product.skus[0].price.bundling_price;

									discount = data.product.skus[0].price.user_without_disc;

									return (
										<div
											style={{ display: "flex", paddingBottom: "20px" }}
											key={index}
										>
											<div
												style={{
													height: "127px",
													width: "100px",
													flexShrink: "0",
												}}
											>
												<img
													src={data.product?.image_url ?? NoImageProduct}
													alt="product"
													style={{
														height: "100%",
														width: "100%",
														borderTopLeftRadius: "8px",
														borderBottomLeftRadius: "8px",
													}}
												/>
											</div>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													marginLeft: "14px",
												}}
											>
												<div className="title">
													<span
														style={{
															fontSize: "12px",
															fontWeight: "bold",
														}}
													>
														{nameProduct}
													</span>
												</div>
												{discount === 0 ? null : (
													<>
														<div className="discount">
															<div className="price-strike">
																Rp {formatRupiah(discount)}
															</div>
														</div>
													</>
												)}
												<div className="price">
													<p>Rp {formatRupiah(price)}</p>
												</div>
											</div>
										</div>
									);
							  })
							: null}
					</div>
					<div
						style={{
							display: "flex",
							marginBottom: "12px",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span style={{ fontSize: "14px" }}>Harga Bundling</span>
							<div className="price total">
								<p>Rp {formatRupiah(priceBundling)}</p>
							</div>
						</div>
						<div style={{ zIndex: "3" }}>
							<Button
								style={{ color: "white", borderRadius: "4px" }}
								variant="contained"
								color="primary"
								onClick={() => {
									if (buyBundle) {
										buyBundle();
									}
								}}
							>
								Beli Bundling
							</Button>
						</div>
					</div>
				</div>
				{/* <div className="image">
					{discount === 0 ? null : (
						<>
							{isHaveDiscount && (
								<div className="discount">
									<div className="text">DISKON</div>
									<div className="total">{resultDiscount}%</div>
									<img src={IconDiscount} alt="discount" />
								</div>
							)}
						</>
					)}
					<img src={image_url ?? NoImageProduct} alt="product" />
				</div>
				<div className="desc">
					<div className="title">
						<h4>{name}</h4>
					</div>
					<div className="price">
						<p>Rp {formatRupiah(price)}</p>
					</div>
					{discount === 0 ? null : (
						<>
							{isHaveDiscount && (
								<div className="discount">
									<div className="price-strike">Rp {formatRupiah(discount)}</div>
									<div className="percentage">
										<p>{resultDiscount}%</p>
									</div>
								</div>
							)}
						</>
					)}
				</div> */}
			</WrapBundlingCard>
		</div>
	);
};

export default ProductBundlingCard;
