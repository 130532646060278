import { axiosInstance } from "./axiosInstance";

export const GET_POPUP = () => {
	const url = `/api/popup`;
	const response = axiosInstance.get(url);
	return response;
};

export const GET_CATEGORY = () => {
	const url = `/api/categories`;
	const response = axiosInstance.get(url);
	return response;
};
