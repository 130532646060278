import React, { useState, useEffect } from "react";
import LogoPink from "../../assets/img/logoNaishaPink.svg";
import { WrapDrawer, WrapNavbarMobile } from "./style";
import { ReactComponent as IconCart } from "../../assets/icons/iconCartLine.svg";
import { ReactComponent as IconNotification } from "../../assets/icons/iconNotifLine.svg";
import { ReactComponent as IconHamburger } from "../../assets/icons/iconHamburger.svg";
import { ReactComponent as IconArrowLeft } from "../../assets/icons/iconArrowLeft.svg";
import IconLove from "../../assets/icons/iconLove.png";
import { ReactComponent as IconShare } from "../../assets/icons/iconShare.svg";
import { ReactComponent as IconCartLineGray } from "../../assets/icons/iconCartLineGray.svg";
import TanggaIMG from "../../assets/icon-img/mdi_stairs-up.svg";
import ChatIMG from "../../assets/icons_1/Chat.svg";
import TicketIMG from "../../assets/icons_1/TicketStar.svg";
import CartIMG from "../../assets/icons_1/Buy.svg";
import Cart_bkIMG from "../../assets/icons_1/Buy_bk.svg";
import SendIMG from "../../assets/icons_1/Send.svg";
import LeftIMG from "../../assets/icons_1/arrow_left.svg";
import constanta from "../../configs/constanta";
import { SearchCustom } from "../../components";
import {
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemText,
	Grid,
	Card,
	Avatar,
	Collapse,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useAuthContext } from "../../contexts/Auth.context";
import { isEmpty } from "lodash";
import queryString, { parse } from "query-string";
import { NavLink, withRouter } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { UserRoute } from "../LayoutAccount/constant";
import { GET_OMZET } from "../../services/rewards.service";
import { formatRupiah } from "../../utils/currencyFormat";
import { NavbarIconMobile, NavbarIconProduct } from "./navbar-icon";
import { CUSTOMER_SERVICE_CONSTANT } from "../../configs/constanta";

const useStyle = makeStyles((theme) => ({
	navLink: { textDecoration: "none", color: "#000000", paddingLeft: "12px" },
	navLinkActive: {
		backgroundColor: "#FFE3F0",
		display: "flex",
		alignItems: "center",
		height: "37px",
		borderRadius: "10px",
		paddingLeft: "12px",
		color: "#F969AE",
	},
}));

const NavigationOptionsList = (props) => {
	const { componentList, pathName } = props;
	const { children, title } = componentList;
	const [collapsed, setCollapsed] = React.useState(true);
	const classes = useStyle();
	const { isAuthenticated } = useAuthContext();

	const toggleCollapse = () => {
		setCollapsed((prevValue) => !prevValue);
	};

	const onClickSubMenu = () => {
		if (Array.isArray(children)) {
			toggleCollapse();
		}
	};

	useEffect(() => {
		let checkPath = children.find((item) => item.link === pathName);
		if (Array.isArray(children) && checkPath) {
			toggleCollapse();
		}
	}, []);

	// useEffect(() => {
	// 	if (isAuthenticated !== null) {
	// 		if (!isAuthenticated) {
	// 			window.location = "/login";
	// 		}
	// 	}
	// }, [isAuthenticated]);

	return (
		<div
			style={{
				margin: "14px",
				marginBottom: "12px",
			}}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					cursor: "pointer",
					color: !collapsed ? "#F969AE" : "rgba(0, 0, 0, 0.87)",
				}}
				onClick={() => {
					onClickSubMenu();
				}}>
				<span style={{ fontSize: "14px" }}>{title}</span>
				<i
					className="arrow-up  material-icons"
					style={{ fontWeight: "normal", opacity: 1 }}>
					{collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
				</i>
			</div>
			<Collapse in={!collapsed} timeout="auto" unmountOnExit>
				{Array.isArray(children) ? (
					<div>
						{children.map((child, childIndex) => {
							return (
								<div
									key={childIndex}
									onClick={() => {
										if (props.onClick) {
											props.onClick();
										}
									}}
									style={{
										marginTop: "12px",
										marginBottom: "12px",
									}}>
									<NavLink
										to={child.link}
										activeClassName={classes.navLinkActive}
										className={classes.navLink}>
										<span style={{ fontSize: "14px" }}>{child.name}</span>
									</NavLink>
								</div>
							);
						})}
					</div>
				) : null}
			</Collapse>
		</div>
	);
};

const NavbarMobileComponent = (props) => {
	const { logout, isAuthenticated } = useAuthContext();
	const {
		toggleDrawer,
		isOpenDrawer,
		type = "normal",
		title = "",
		cart,
	} = props;
	const isProductDetailPage =
		props.match && props.match.path === "/product/:slug";

	let parsed;
	if (props.location) {
		parsed = queryString.parse(props.location.search);
	}

	const [level, setLevel] = useState({});
	const [user, setUser] = useState({});
	const [profilePhoto, setProfilePhoto] = useState("");
	const [search, setSearch] = useState("");
	let getFromCart = JSON.parse(localStorage.getItem("CART"));

	useEffect(() => {
		// setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		// let profilePhoto;
		if (user) {
			GET_OMZET(user.id)
				.then((res) => {
					setLevel(res.data);
				})
				.catch((err) => {})
				.finally(() => {
					// setIsLoading(false);
				});
			setUser(user);
			setProfilePhoto(
				user && user.profile_photos
					? user.profile_photos.medium.url
					: "https://via.placeholder.com/150"
			);
		} else {
			// window.location = "/login";
		}
	}, []);

	const handleSearchProduct = (event) => {
		setSearch(event);
		if (event !== "") {
			window.location.href = `/products?nama=${event}`;
		} else {
			window.location.href = `/products`;
		}
	};

	useEffect(() => {
		if (!isEmpty(parsed)) {
			setSearch(parsed.name);
		}
	}, []);

	const _drawer = () => (
		<Drawer open={isOpenDrawer} onClose={() => toggleDrawer()}>
			<WrapDrawer>
				<div className="logo">
					<img src={LogoPink} alt="naisha" />
				</div>
				{/* <div className="name">Hai, {user.name?.split(" ")[0] ?? user.name}</div> */}
				<div
					style={{ display: "flex", marginLeft: "14px", marginRight: "14px" }}>
					<div>
						<Avatar
							alt="photo saya"
							src={profilePhoto}
							style={{ height: "34px", width: "34px" }}
						/>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							marginLeft: "12px",
						}}>
						<span style={{ fontSize: "14px", fontWeight: "bold" }}>
							{user.name}
						</span>
						<span style={{ fontSize: "14px", fontWeight: "normal" }}>
							{`Omset Rp ${
								level && level.omzet_total
									? formatRupiah(level.omzet_total)
									: "0"
							}`}
						</span>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						marginLeft: "14px",
						marginRight: "14px",
						marginTop: "14px",
					}}>
					<img
						src={TanggaIMG}
						alt="gambar-tangga"
						style={{ marginRight: "6px" }}
					/>
					<span style={{ fontWeight: "600" }}>{`Level ${
						level && level.omzet_level ? level.omzet_level.level : "-"
					}`}</span>
					<span style={{ marginLeft: "6px", color: "#F969AE" }}>{`Ke ${
						level && level.omzet_level ? level.omzet_level.level + 1 : "-"
					}`}</span>
				</div>
				<List>
					{UserRoute.map((route, index) => {
						return (
							<React.Fragment key={index}>
								{route.children && route.children.length > 0 ? (
									<>
										<NavigationOptionsList
											componentList={route}
											key={index}
											pathName={props.match?.path ?? ""}
										/>
									</>
								) : (
									"-"
								)}
							</React.Fragment>
						);
					})}
				</List>

				<Divider />
				{isAuthenticated ? (
					<List>
						<ListItem button onClick={logout}>
							<ListItemText primary={"Logout"} />
						</ListItem>
					</List>
				) : null}
			</WrapDrawer>
		</Drawer>
	);

	const _identifyNavbar = () => {
		if (type === "normal") {
			return (
				!isProductDetailPage && (
					<>
						{/* <div style={{ display: "flex", padding: 16}}>
							<div className="hamburger" onClick={() => toggleDrawer()}>
								<IconHamburger />
							</div>
							<div className="logo">
								<img src={LogoPink} alt="naisha" />
							</div>
							<div className="right"> */}
						{/* <div>
									<IconNotification />
									<span className="count">99</span>
								</div> */}
						{/* <div onClick={() => props.history.push("/cart")}>
									<IconCart />
									{!isEmpty(cart) && <span className="count">{cart.length}</span>}
								</div>
							</div>
						</div> */}
						<div
							style={{
								padding: "16px 21px",
								paddingBottom: 16,
								display: "flex",
								background: constanta.COLOR.primary,
							}}>
							<SearchCustom
								placeholder="Cari apa saja"
								handleSearch={(event) =>
									handleSearchProduct(event.target.value)
								}
							/>
							<NavbarIconMobile
								onClickIcon={() => {
									props.history.push(
										`/customer-services/${CUSTOMER_SERVICE_CONSTANT.customerService}`
									);
								}}
								img={ChatIMG}
								text="Chat Naisha"
							/>
							<NavbarIconMobile
								onClickIcon={() => {
									props.history.push(
										`/customer-services/${CUSTOMER_SERVICE_CONSTANT.customerService}`
									);
								}}
								img={TicketIMG}
								text="Ticket Star"
							/>
							<NavbarIconMobile
								onClickIcon={() => {
									props.history.push(`/cart`);
								}}
								img={CartIMG}
								text="Cart"
							/>
							{!isEmpty(getFromCart) && (
								<span className="cartCount">{getFromCart.length}</span>
							)}
						</div>
					</>
				)
			);
		} else if (type === "navback") {
			return (
				<div className="navbarOnPage">
					<div className="left">
						<div className="back" onClick={() => props.history.goBack()}>
							<IconArrowLeft />
						</div>
						<div style={{ textAlign: "center", width: "100%" }}>
							<h3>{title}</h3>
						</div>
					</div>
				</div>
			);
		}
	};

	return (
		<WrapNavbarMobile>
			{_identifyNavbar()}
			{isProductDetailPage && (
				<div className="navbarOnPage">
					<div className="left">
						<div className="back" onClick={() => props.history.goBack()}>
							<NavbarIconProduct img={LeftIMG} text="Cart" />
						</div>
						<SearchCustom
							placeholder="Cari apa saja"
							handleSearch={(event) => handleSearchProduct(event.target.value)}
						/>
						<div>
							<NavbarIconProduct
								onClickIcon={() => {
									props.history.push(`/cart`);
								}}
								img={SendIMG}
								text="Cart"
							/>
						</div>
						<div
							style={{
								display: "flex",
							}}>
							<NavbarIconProduct
								onClickIcon={() => {
									props.history.push(`/cart`);
								}}
								img={Cart_bkIMG}
								text="Cart"
							/>
							{!isEmpty(getFromCart) && (
								<span className="cartCount">{getFromCart.length}</span>
							)}
						</div>
					</div>
				</div>
			)}
			{_drawer()}
		</WrapNavbarMobile>
	);
};

export default NavbarMobileComponent;
