import styled from 'styled-components';
import constanta from '../../configs/constanta';

export const WrapConfirmPayment = styled.div`
  position: relative;
  padding-top: 60px;
  background: #EFF8FC;
  min-height: 90vh;
  h1 {
    font-family: 'DMSans-Bold';
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .confirmation {
    padding: 20px;

    border-radius: 10px;
    p {
      margin-top: 16px;
      text-align: center;
      font-family: Poppins;
      font-size: 13px;
      font-weight: 300;
      line-height: 20px;
    }
    .form {
      margin-top: 16px;
      fieldset {
        border-radius: 4px;
      }
    }
  }
  .properties {
    padding: 20px;

    border-radius: 10px;
    .title {
      font-family: 'DMSans-Bold';
      font-size: 16px;
    }
    .list {
      margin-top: 16px;
      .image {
        width: 30%;
        top: 18px;
        img {
          width: 100%;
        }
      }
      .item {
        display: flex;
        margin-bottom: 14px;
        padding-bottom: 4px;
        border-bottom: 1px solid #f1f1f1;
        .title {
          width: 100%;
        }
        .info {
          display: flex;
          flex-direction: column;
          margin-left: 14px;
          .product {
            margin-top: 14px;
            span {
              font-size: 14px;
              padding: 8px 12px;
              margin-right: 12px;
              background: #f2f2f2;
              border-radius: 8px;
              color: ${constanta.COLOR.darkPink};
            }
            .pricing {
              display: flex;
              margin-top: 12px;
              justify-content: space-between;
              p {
                font-family: 'DMSans-Bold';
                width: 50%;
              }
            }
          }
        }
      }
    }
    .total {
      font-family: 'DMSans-Bold';
      margin-top: 14px;
      font-size: 16px;
    }
  }
  .price {
    color: ${constanta.COLOR.darkPink};
  }
  .formInput {
    margin-bottom: 16px;
    .MuiOutlinedInput-root {
      border-radius: 20px;
    }
    span {
      font-size: 12px;
      margin-top: 8px;
      position: relative;
      display: block;
      color: #666;
    }
  }
  .MuiFormLabel-asterisk,
  .MuiInputLabel-asterisk {
    display: none !important;
  }

  @media (max-width: 500px) {
    .info {
      padding: 16px;
      .total {
        display: flex;
        padding: 8px 0px;
        border-bottom: 1px solid #ddd;
        p {
          font-family: 'DMSans-Bold';
          font-size: 14px;
          width: 50%;
          :last-child {
            color: ${constanta.COLOR.darkPink};
            position: relative;
            text-align: right;
            width: 50%;
          }
        }
      }
    }
    .confirmation {
      border-radius: 0;
    }
    .floating {
      position: fixed;
      height: 70px;
      bottom: 0;
      width: 100%;
      z-index: 999;
      background: #fff;
      padding: 16 0px;
      button {
        margin: 10px auto;
    }
  }
`;
