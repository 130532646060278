import swal from "sweetalert";
import { CONFIRM_PAYMENT } from "../../services/payment.service";

export const handleConfirmationPayment = async ({ history, payload }) => {
	swal({
		title: "Konfirmasi Pembayaran",
		text: "Anda yakin format sudah sesuai ?",
		buttons: {
			cancel: "Batal",
			ok: "Oke",
		},
	}).then(async (value) => {
		if (value === "ok") {
			await CONFIRM_PAYMENT(payload)
				.then((res) => {
					if (res) {
						swal({
							title: "Pemesanan Berhasil Terbayar",
							text: "Pemesanan produk telah berhasil dibayarkan",
							buttons: {
								ok: "Lanjut",
							},
						}).then((btn) => {
							if (btn === "ok") {
								history.push("/");
							}
						});
					}
				})
				.catch((e) => {
					//   console.log(e.response.data.errors);
					//   swal('Error', `${e.response.data.errors}`);
				});
		}
	});
};
