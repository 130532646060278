import { Grid, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Footer } from "../../components";
import { Navbar } from "../index";
import { WrapLayout } from "./style";

import FloatingActionButton from "../../components/FAB";

const LayoutComponent = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	const { withNavbar = true, withContainer = true } = props;

	const isContainer = withContainer
		? {
				maxWidth: 1220,
				position: "relative",
				margin: "0 auto",
				left: 0,
				right: 0,
		  }
		: {};

	let user = JSON.parse(localStorage.getItem("USER_NAISHA"));

	return (
		<WrapLayout>
			{withNavbar && (
				<Grid container>
					<Grid item xs={12}>
						<Navbar {...props} />
					</Grid>
				</Grid>
			)}
			<Grid container>
				<Grid item xs={12}>
					<div style={!isMobile ? isContainer : {}}>{props.children}</div>
				</Grid>
			</Grid>

			{!isMobile && (
				<Grid container>
					<Grid item xs={12}>
						<Footer />
					</Grid>
				</Grid>
			)}
			{/* {user ? (
				<FloatingActionButton redirect={() => props.history.push("/kupon-saya")} />
			) : null} */}
		</WrapLayout>
	);
};

export default LayoutComponent;
