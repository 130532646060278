import React from "react";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import constanta from "../../configs/constanta";

const PreOrder = ({ days }) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
			}}>
			<EventOutlinedIcon
				style={{
					color: constanta.COLOR.primary,
					marginRight: 4,
				}}
			/>
			<p
				style={{
					color: constanta.COLOR.primary,
				}}>
				Pre Order ({days} hari)
			</p>
		</div>
	);
};

export default PreOrder;
