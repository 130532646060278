import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import KuponSayaDesktop from "./KuponSaya.desktop";
import KuponSayaMobile from "./KuponSaya.mobile";
const KuponSaya = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <KuponSayaMobile {...props} /> : <KuponSayaDesktop {...props} />;
};

export default KuponSaya;
