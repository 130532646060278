import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { REGENERATE_TOKEN } from "../../services/user.service";

const Redirect = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Mengambil query parameters dari URL
    const queryParams = new URLSearchParams(location.search);

    const token = queryParams.get("token");

    const fetchUserData = async () => {
      try {
        await REGENERATE_TOKEN(token).then((data) => {
          localStorage.setItem("USER_NAISHA", JSON.stringify(data.user));
          localStorage.setItem("TOKEN", JSON.stringify(data.token));
        });

        localStorage.removeItem("TOKEN_EMAIL");
        swal("Berhasil", "Login berhasil !", "success");
        window.location = "/";
      } catch (error) {
        console.error("Login failed: ", error);
        swal("Error", "Login gagal!", "error");
      }
    };

    fetchUserData();
  }, [history, location]); // history dan location sebagai dependencies

  return null;
};

export default Redirect;
