import React, { useState, useEffect } from "react";
import { Layout } from "../index";
import { Grid, Card, Avatar, Collapse, Link, Button } from "@material-ui/core";
import { UserRoute, ResellerRoute } from "./constant";
import { WrapAccount } from "./style";
import { NavLink, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import TanggaIMG from "../../assets/icon-img/mdi_stairs-up.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { GET_OMZET } from "../../services/rewards.service";
import { formatRupiah } from "../../utils/currencyFormat";
import { useAuthContext } from "../../contexts/Auth.context";

const useStyle = makeStyles((theme) => ({
	navLink: { textDecoration: "none", color: "#000000", paddingLeft: "12px" },
	navLinkActive: {
		backgroundColor: "#FFE3F0",
		display: "flex",
		alignItems: "center",
		height: "37px",
		borderRadius: "10px",
		paddingLeft: "12px",
		color: "#F969AE",
	},
}));

const NavigationOptionsList = (props) => {
	const { componentList, pathName } = props;
	const { children, title } = componentList;
	const [collapsed, setCollapsed] = React.useState(true);
	const classes = useStyle();
	const { isAuthenticated } = useAuthContext();

	const toggleCollapse = () => {
		setCollapsed((prevValue) => !prevValue);
	};

	const onClickSubMenu = () => {
		if (Array.isArray(children)) {
			toggleCollapse();
		}
	};

	useEffect(() => {
		let checkPath = children.find((item) => item.link === pathName);
		if (Array.isArray(children) && checkPath) {
			toggleCollapse();
		}
	}, []);

	useEffect(() => {
		if (isAuthenticated !== null) {
			if (!isAuthenticated) {
				window.location = "/login";
			}
		}
	}, [isAuthenticated]);

	return (
		<div style={{ paddingBottom: "12px" }}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					cursor: "pointer",
					color: !collapsed ? "#F969AE" : "black",
				}}
				onClick={() => {
					onClickSubMenu();
				}}>
				<span style={{ fontWeight: "600" }}>{title}</span>
				<i className="arrow-up  material-icons">
					{collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
				</i>
			</div>
			<Collapse in={!collapsed} timeout="auto" unmountOnExit>
				{Array.isArray(children) ? (
					<div>
						{children.map((child, childIndex) => {
							return (
								<div
									key={childIndex}
									onClick={() => {
										if (props.onClick) {
											props.onClick();
										}
									}}
									style={{
										marginTop: "18px",
										marginBottom: "18px",
									}}>
									<NavLink
										to={child.link}
										activeClassName={classes.navLinkActive}
										className={classes.navLink}>
										<span>{child.name}</span>
									</NavLink>
								</div>
							);
						})}
					</div>
				) : null}
			</Collapse>
		</div>
	);
};

const LayoutAccount = (props) => {
	const [level, setLevel] = useState({});
	const [user, setUser] = useState({});
	const [profilePhoto, setProfilePhoto] = useState("");
	const [linkWa, setLinkWa] = useState("");

	useEffect(() => {
		// setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		// let profilePhoto;
		if (user) {
			GET_OMZET(user.id)
				.then((res) => {
					setLevel(res.data);
				})
				.catch((err) => {})
				.finally(() => {
					// setIsLoading(false);
				});
			setUser(user);
			setProfilePhoto(
				user && user.profile_photos
					? user.profile_photos.medium.url
					: "https://via.placeholder.com/150"
			);

			const withdrawRequest = `Halo Admin Naisha, saya ${user?.name} ingin melakukan penarikan saldo sebesar Rp. ${user?.saldo}`;
			setLinkWa(`https://wa.me/6283804879906?text=${withdrawRequest}`);
		} else {
			window.location = "/login";
		}
	}, []);

	return (
		<Layout {...props}>
			<div style={{ marginTop: 32, minHeight: "calc(100vh - 227px)" }}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<Card className="card-custom" style={{ marginBottom: "14px" }}>
							<WrapAccount>
								<div className="account">
									<div>
										<Avatar
											alt="photo saya"
											src={profilePhoto}
											style={{ height: "56px", width: "56px" }}
										/>
									</div>
									<div className="account-text">
										<span className="account-name">{user.name}</span>
										<span className="account-omzet">
											{`Omset Rp ${
												level && level.omzet_total
													? formatRupiah(level.omzet_total)
													: "0"
											}`}
										</span>
										<span className="account-omzet">
											{`Saldo Rp ${
												user && user.saldo ? formatRupiah(user.saldo) : "0"
											}`}
										</span>
									</div>
								</div>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img
										src={TanggaIMG}
										alt="gambar-tangga"
										className="account-icon"
									/>
									<span className="account-current-lvl">{`Level ${
										level && level.omzet_level ? level.omzet_level.level : "-"
									}`}</span>
									<span className="account-next-lvl">{`Ke ${
										level && level.omzet_level
											? level.omzet_level.level + 1
											: "-"
									}`}</span>
								</div>
								<Button
									href={linkWa}
									variant="outlined"
									target="_blank"
									fullWidth
									style={{ marginTop: "14px" }}
									disabled={user.saldo === 0 ? true : false}>
									Tarik Saldo
								</Button>
							</WrapAccount>
						</Card>
						<Card className="card-custom" style={{ minHeight: "247px" }}>
							<div style={{ padding: "16px" }}>
								{(user?.customer_type?.name === "Reseller" ||
									user?.customer_type?.name === "Member") &&
									ResellerRoute.map((route, index) => {
										return (
											<React.Fragment key={index}>
												{route.children && route.children.length > 0 ? (
													<>
														<NavigationOptionsList
															componentList={route}
															key={index}
															pathName={props.match.path}
														/>
													</>
												) : (
													"-"
												)}
											</React.Fragment>
										);
									})}

								{UserRoute.map((route, index) => {
									return (
										<React.Fragment key={index}>
											{route.children && route.children.length > 0 ? (
												<>
													<NavigationOptionsList
														componentList={route}
														key={index}
														pathName={props.match.path}
													/>
												</>
											) : (
												"-"
											)}
										</React.Fragment>
									);
								})}
							</div>
						</Card>
					</Grid>
					<Grid item xs={9}>
						{props.children}
					</Grid>
				</Grid>
			</div>
		</Layout>
	);
};

export default withRouter(LayoutAccount);
