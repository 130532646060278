import React, { useState, useEffect } from "react";
import { Spacer } from "../../components";
// import LayoutAccount from "../../containers/LayoutAccount";
import { Layout, Navbar } from "../../containers";
import { Grid, Card, Button, Typography } from "@material-ui/core";
import swal from "sweetalert";
import { GET_REWARDS, REWARD_CLAIM, GET_OMZET } from "../../services/rewards.service";
import { WrapTags, WrapMenu } from "./style.js";
import NoImageProduct from "../../assets/img/noImage.svg";
import Progressbar from "../../components/Progress";
import { formatRupiah } from "../../utils/currencyFormat";
import DetailHadiahModal from "./detail-hadiah-modal";
import MenuHadiah from "./menu-hadiah";
import constanta from "../../configs/constanta";

const HadiahProgress = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [isOpenHadiah, setIsOpenHadiah] = useState(false);
	const [detailHadiah, setDetailhadiah] = useState({});
	const [level, setLevel] = useState({});

	const toggleModalHadiah = () => {
		setIsOpenHadiah(!isOpenHadiah);
	};

	const retrieveProgressHadiah = () => {
		setIsLoading(true);
		GET_REWARDS()
			.then((res) => {
				setData(res.data);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const retrieveOmzet = () => {
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		setIsLoading(true);
		GET_OMZET(user.id)
			.then((res) => {
				setLevel(res.data);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		retrieveProgressHadiah();
		retrieveOmzet();
	}, []);

	const handleClaimReward = (detail) => {
		setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		let payload = {
			user_id: user.id,
			reward_omzet_id: detail.id,
		};
		REWARD_CLAIM(payload)
			.then((res) => {
				setData(res.data);
				swal("Selamat !", "Sukses klaim hadiah", "success");
				retrieveProgressHadiah();
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const takeReward = async (detail) => {
		swal({
			title: "Ambil sekarang ?",
			text: "apakah kamu yakin ingin klaim ini sekarang ?",
			buttons: {
				cancel: "Batal",
				ok: "Ok",
			},
		}).then((value) => {
			if (value === "ok") {
				handleClaimReward(detail);
			}
		});
	};

	const RenderHadiahProgressCard = (dataSource) => {
		return (
			<div style={{
				padding: "20px 20px 0px 40px",
				backgroundColor: "#fff",
				borderRadius: "8px",
			}}>
				<div style={{
					marginBottom: "20px",
					marginLeft: "-20px"
				}}>
					<h6 style={{
						color: "#0F172A",
						fontSize: "14px",
						lineHeight: "165%",
					}}>Tentang Level Akun</h6>
					<p style={{
						color: constanta.COLOR.blue,
						fontSize: "10px",
						lineHeight: "133.5%"
					}}>Level omset adalah bonus yang bisa di dapatkan dari experience (XP) pada setiap penambahan omset penjualanmu. Bonus berupa hadiah-hadiah menarik yang sesuai dengan level</p>
				</div>
				{dataSource.map((item, index) => {
					const BASE_URL = process.env.REACT_APP_BASE_URL;
					let percentage;
					let remaining;
					if (level && level.omzet_total !== 0 && item.maximum_omzet !== 0) {
						percentage = (level.omzet_total / item.maximum_omzet) * 100;
						remaining = 100 - percentage;
					} else {
						percentage = 0;
						remaining = 100 - percentage;
					}
					return (
						<>
							<div style={{ display: "flex", flexDirection: "column",
								}} key={index}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "14px",
										border: "1px solid #F4F4F4",
										borderRadius: "4px",
									}}
								>
									
									<div
										style={{
											height: "24px",
											width: "24px",
											backgroundColor: constanta.COLOR.primary,
											position: "absolute",
											borderTopRightRadius: "4px",
											borderBottomRightRadius: "4px",
											left: "14px",
										}}
									>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												color: "#fff",
											}}
										>
											{item.level}
										</div>
									</div>
									<Card
										style={{ minHeight: "180px", width: "459px" }}
										className="card-outlined"
									>
										<div
											style={{
												display: "",
												padding: "14px",
											}}
										>
											<img
												src={BASE_URL + "/" + item.images ?? NoImageProduct}
												alt="product"
												onClick={() => {
													setDetailhadiah(item);
													toggleModalHadiah();
												}}
												style={{
													width: "100%",
													borderRadius: "8px",
													objectFit: "cover",
													marginBottom: "14px",
												}}
											/>
											
											<div
												style={{
													display: "flex",
													flexDirection: "column",
												}}
											>
												<Typography style={{ fontSize: "16px", fontWeight: "bold", marginBottom: 9 }}>
													{item.reward_desc}
												</Typography>
												<Progressbar value={percentage} />
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														paddingTop: "8px",
													}}
												>
													<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
														<div
															style={{
																display: "",
																alignItems: "center",
																justifyContent: "space-between",
															}}
														>
															<Typography
																variant="body2"
																component="span"
																style={{
																	fontSize: "12px",
																	color: "#C7C7C7",
																	paddingRight: "6px",
																}}
															>
																Omset
															</Typography>
															<div>
																<Typography
																	variant="body2"
																	component="span"
																	style={{
																		fontSize: "14px",
																		fontWeight: "bold",
																		paddingRight: "6px",
																		color: constanta.COLOR.blue,
																	}}
																>
																	Rp{" "}
																	{level && level.omzet_total ? formatRupiah(level.omzet_total) : "0"} /
																</Typography>
																<Typography
																	variant="body2"
																	component="span"
																	color="primary"
																	style={{
																		fontSize: "14px",
																		fontWeight: "bold",
																		color: "#333",
																		paddingRight: "6px",
																	}}
																>
																	{formatRupiah(item.maximum_omzet)}
																</Typography>
															</div>
														</div>
														<WrapTags>
														<span>Sisa</span>
														<div className="tag">
															{Math.round(remaining)}%
														</div>
														</WrapTags>
													</div>
													
													{/* periodic disable claim */}
													{/* {item.minimum_omzet === item.maximum_omzet ? (
														<Button
															onClick={() => {
																takeReward(item);
															}}
															style={{
																marginTop: "12px",
																color: "white",
															}}
															size="small"
															variant="contained"
															color="primary"
														>
															Klaim Hadiah
														</Button>
													) : null} */}
												</div>
											</div>
										</div>
									</Card>
								</div>
							</div>
						</>
					);
				})}
			</div>
		);
	};

	const RenderMenu = () => {
		return (
			<WrapMenu>
				<div style={{
					padding: "70px 0 10px 0",
					margin: "0px 14px",
					borderRadius: "8px",
				}}>
					<Card className="card-custom">
						<div
							style={{
								display: "flex",
								padding: "4px",
								justifyContent: "space-around",
							}}
						>
							<Button 
								variant="contained"
							className="btn-menu active" onClick={() => props.history.push("/hadiah/progress")}>
								<span>Progress</span>
							</Button>
							<Button className="btn-menu" onClick={() => props.history.push("/hadiah/complete")}>
								<span>Berhasil</span>
							</Button>
						</div>
					</Card>
				</div>
			</WrapMenu>
		)
	}
	return (
		<Layout withNavbar={false} {...props}>
			<Navbar type="navback" title="Progress Hadiah" {...props} />
			{/* {RenderMenu()} */}
			<MenuHadiah 
				active="progress"
				{...props} 
			/>
			<Card style={{
				boxShadow: "none",
			}}>
				<div
					style={{
						padding: "14px",
						minHeight: "calc(100vh - 227px)",
						background: "#EFF8FC",
					}}
				>
					{RenderHadiahProgressCard(data)}
					<DetailHadiahModal
						open={isOpenHadiah}
						handleClose={toggleModalHadiah}
						detail={detailHadiah}
					/>
				</div>
			</Card>
		</Layout>
	);
};

export default HadiahProgress;
