import { Grid, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import React from "react";
import { useHistory } from "react-router";
import { ButtonCustom } from "../../components";
import { CUSTOMER_SERVICE_CONSTANT } from "../../configs/constanta";
import { Layout } from "../../containers";
import { formatRupiah } from "../../utils/currencyFormat";
import { mappingForms } from "../../utils/formsUtils";
import { handleConfirmationPayment } from "./handler";
import { WrapConfirmPayment } from "./style";

const getTotalPayment = (payment) => {
	return payment.total - payment.payment.code_unique;
};

//NOTE BUTUH ENHANCEMENT API DIBAGIAN LOOPING PRODUCT

// const _product = (value, key) => {
// 	return (
// 		<div className="item" key={key}>
// 			<div className="image" style={{ width: "110px" }}>
// 				{/* <img src={sku.image_url} alt={sku.product.name} /> */}
// 			</div>
// 			<div className="info">
// 				<div className="title">Gamis Set + Khimar Syar'i / Al Hijab</div>
// 				<div className="product">
// 					<span>Soft Pink</span>
// 					<span>L</span>
// 					<div className="pricing">
// 						{/* <p className="price">Rp {formatRupiah(sku.price.consumer)}</p> */}
// 						<p className="quantity">X{value.qty}</p>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

const ConfirmPaymentPageDesktop = (props) => {
	const { forms, payment, formValues, setFormValues, isDisabled } = props;
	const history = useHistory();

	return (
		<Layout {...props}>
			<WrapConfirmPayment style={{ background: "none" }}>
				<Typography variant="h1">Konfirmasi Pembayaran</Typography>
				<Grid container spacing={3}>
					<Grid item xs={7}>
						<div className="confirmation">
							<ButtonCustom
								color="secondary"
								onClick={() => {
									props.history.push(
										`/customer-services/${CUSTOMER_SERVICE_CONSTANT.paymentService}`,
									);
								}}
							>
								Konfirmasi Via WhatsApp
							</ButtonCustom>
							<p>atau Konfirmasi Langsung</p>
							<div className="form">
								{mappingForms(forms, setFormValues, formValues)}
								<ButtonCustom
									isDisabled={isDisabled}
									onClick={() => {
										let payload = {
											date: formValues.date,
											from_account_name: formValues.from_account_name,
											from_bank: formValues.from_bank,
											image: formValues.file,
											order_id: payment.id,
											payment_method: payment.payment.payment_method.id,
											amount: getTotalPayment(payment),
										};
										handleConfirmationPayment({
											history: history,
											payload,
										});
									}}
								>
									Konfirmasi
								</ButtonCustom>
							</div>
						</div>
					</Grid>
					<Grid item xs>
						<div className="properties">
							<div className="title">ID Pesanan : #{payment.id}</div>
							{/* <div className="title">ID Pesanan : #{payment.id}</div> */}
							{/* <div className="list">
								{!isEmpty(payment) &&
									payment.items.map((value, key) => {
									
										return _product(value, key);
									})}
							</div> */}
							<div className="total">
								Total Pemesanan :{" "}
								<span className="price">
									Rp {!isEmpty(payment) && formatRupiah(getTotalPayment(payment))}
								</span>
							</div>
						</div>
					</Grid>
				</Grid>
			</WrapConfirmPayment>
		</Layout>
	);
};

export default ConfirmPaymentPageDesktop;
