import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AccountPageDesktop from "./AccountPage.desktop";
import AccountPageMobile from "./AccountPage.mobile";

const Account = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <AccountPageMobile {...props} /> : <AccountPageDesktop {...props} />;
};

export default Account;
