import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import HadiahProgressDesktop from "./HadiahProgress.desktop";
import HadiahProgressMobile from "./HadiahProgress.mobile";

const HadiahProgress = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <HadiahProgressMobile {...props} /> : <HadiahProgressDesktop {...props} />;
};

export default HadiahProgress;
