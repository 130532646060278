import React from "react";
import { WrapAlamatMobile } from "./style";
import { isEmpty } from "lodash";
import { ButtonCustom, EmptyState } from "../../components";
import { Layout, Navbar } from "../../containers";
import { Grid, Switch } from "@material-ui/core";
import { handleDeleteAlamat, setToPrimary } from "./handler";
import MapIMG from "../../assets/img/Map.png";
import MapIMG_Inactive from "../../assets/img/Map_inactive.png";

const AlamatPageMobile = (props) => {
	const { dataAlamat, setDataAlamat, setIsRefetch } = props;

	const _extractNewLineAlamatLengkap = (address) =>
		address.split("\n").map((text, key) => <p key={key}>{text}</p>);

	const _mappingAlamat = () =>
		dataAlamat.map((alamat, index) => (
			<div className="cardAlamat" key={index}>
				<Grid container>
					<Grid item xs={10}>
						<div className="label">
							<span className="bold">{alamat.label}</span>{" "}
							{alamat.isPrimary && `(Utama)`}
						</div>
					</Grid>
					<Grid item xs={2}>
						<div className="switchPrimary">
							<Switch
								checked={alamat.isPrimary}
								color="primary"
								onChange={(e) =>
									setToPrimary(
										index,
										e.target.checked,
										dataAlamat,
										setDataAlamat,
										setIsRefetch
									)
								}
							/>
						</div>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "space-between" }}>
							<div >
								<h6 style={{ fontSize: 14, fontWeight: 700, marginBottom: 8 }}>
									{alamat.namaPenerima}
								</h6>
								{_extractNewLineAlamatLengkap(alamat.alamatLengkapPenerima)}
								<p>
									{alamat.selectSubdistrictName} - {alamat.selectCityType}{" "}
									{alamat.selectCityName}, {alamat.selectProvinceName}{" "}
									{alamat.kodePos}
								</p>
								<p style={{ marginTop: 8 }}>{alamat.noTelpPenerima}</p>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<img src={ alamat.isPrimary ? MapIMG : MapIMG_Inactive} alt="map" style={{ width: 64, height: 64 }} />
							</div>

						</div>
					</Grid>
				</Grid>
				<div className="action">
					<div onClick={() => props.history.push(`/akun/alamat/edit/${index}`)}>Ubah</div>
					{!alamat.isPrimary && (
						<div onClick={() => handleDeleteAlamat(index, dataAlamat, setIsRefetch)}>
							Hapus
						</div>
					)}
				</div>
			</div>
		));
	return (
		<Layout withNavbar={false} {...props}>
			<Navbar type="navback" title="Alamat Pengiriman" {...props} />
			<WrapAlamatMobile>
				<div className="content">
					{isEmpty(dataAlamat) ? (
						<EmptyState desc="Belum ada data penerima" />
					) : (
						<div>{_mappingAlamat()}</div>
					)}
				</div>
				<div className="bottomButton">
					<ButtonCustom mdSize onClick={() => props.history.push("/akun/alamat/add")}>
						Tambah Alamat
					</ButtonCustom>
				</div>
			</WrapAlamatMobile>
		</Layout>
	);
};

export default AlamatPageMobile;
