import styled from 'styled-components';
import constanta from '../../configs/constanta';

export const WrapButton = styled.div`
  button {
    cursor: pointer;
    position: relative;
    border: none;
    padding: 12px 16px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    font-size: ${(props) => (props.isMobile ? `14px` : `20px`)};
    font-family: 'DMSans-Regular';
    outline: none;
    :focus {
      outline: none;
    }
    svg {
      padding-right: 10px;
    }
  }
  .primary {
    button {
      background: ${constanta.COLOR.darkPink};
      :disabled {
        background: #666;
      }
    }
  }
  .secondary {
    button {
      background: ${constanta.COLOR.lightPink};
      color: ${constanta.COLOR.textPrimary};
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      :disabled {
        background: #666;
      }
    }
  }
`;
