import React, { useEffect, useState } from "react";
import { ButtonCustom } from "../../../components";
import { Layout, Navbar } from "../../../containers";
import {
	GET_CITIES,
	GET_PROVINCES,
	GET_DISTRICT,
	GET_SUBDSITRICTS,
} from "../../../services/location.service";
import { mappingForms } from "../../../utils/formsUtils";
import { handleAddAdress, handleEditAdress } from "../handler";
import { WrapAlamatMobile } from "../style";

const AlamatTambahPageMobile = (props) => {
	const [provinces, setProvinces] = useState([]);
	const [cities, setCities] = useState([]);
	const [disctricts, setDisctricts] = useState([]);
	const [subDistricts, setSubdistrics] = useState([]);
	const [formValues, setFormValues] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	let isEdit = props.match.path === "/akun/alamat/edit/:index";
	let index = props.match.params.index;

	useEffect(() => {
		let local = JSON.parse(localStorage.getItem("ALAMAT_CUSTOMER"));
		const fetch = async () => {
			setFormValues({
				alamatLengkapPenerima: local[index].alamatLengkapPenerima,
				isPrimary: local[index].isPrimary,
				kodePos: local[index].kodePos,
				label: local[index].label,
				namaPenerima: local[index].namaPenerima,
				noTelpPenerima: local[index].noTelpPenerima,
				selectCity: local[index].selectCity,
				selectCityName: local[index].selectCityName,
				selectCityType: local[index].selectCityType,
				selectProvince: local[index].selectProvince,
				selectProvinceName: local[index].selectProvinceName,
				selectDistrict: local[index].selectDistrict,
				selectDistrictName: local[index].selectDistrictName,
				selectSubdistrict: local[index].selectSubdistrict,
				selectSubdistrictName: local[index].selectSubdistrictName,
			});
		};
		setIsLoading(false);
		isEdit && fetch();
	}, [isEdit, props.match.params.index]);

	const forms = [
		{
			id: "label",
			label: "Label Alamat",
			type: "text",
			desc: "Contoh : Kantor, Rumah, Kos",
			required: true,
			value: isEdit ? formValues.label : "",
		},
		{
			id: "namaPenerima",
			label: "Nama Penerima",
			type: "text",
			required: true,
			value: isEdit ? formValues.namaPenerima : "",
		},
		{
			id: "noTelpPenerima",
			label: "No. Telepon Penerima",
			type: "text",
			required: true,
			value: isEdit ? formValues.noTelpPenerima : "",
		},
		{
			id: "alamatLengkapPenerima",
			label: "Alamat Lengkap",
			type: "multiline",
			desc: "Contoh : Jl. Naisha no.25 Yogyakarta",
			required: true,
			value: isEdit ? formValues.alamatLengkapPenerima : "",
		},
		{
			id: "selectProvince",
			label: "Pilih Provinsi",
			type: "select",
			options: provinces,
			required: true,
			value: isEdit ? formValues.selectProvince : "",
		},
		{
			id: "selectCity",
			label: "Kota",
			type: "select",
			options: cities,
			required: true,
			value: isEdit ? formValues.selectCity : "",
		},
		{
			id: "selectDistrict",
			label: "Pilih Kecamatan",
			type: "select",
			options: disctricts,
			required: true,
			value: isEdit ? formValues.selectDistrict : "",
		},
		{
			id: "selectSubdistrict",
			label: "Pilih Desa/Kelurahan",
			type: "select",
			options: subDistricts,
			required: true,
			value: isEdit ? formValues.selectSubdistrict : "",
		},
		{
			id: "kodePos",
			label: "Kode Pos",
			type: "number",
			required: true,
			value: isEdit ? formValues.kodePos : formValues.kodePos || "",
		},
	];

	useEffect(() => {
		const fetch = async () => {
			await GET_PROVINCES().then((res) => {
				setProvinces(res);
			});
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			await GET_CITIES({
				id: formValues.selectProvince,
			}).then((res) => {
				setCities(res);
			});
		};
		formValues.selectProvince && fetch();
	}, [formValues.selectProvince]);

	useEffect(() => {
		const fetch = async () => {
			await GET_DISTRICT({
				id: formValues.selectCity,
			}).then((res) => {
				setDisctricts(res);
			});
		};
		formValues.selectCity && fetch();
	}, [formValues.selectCity]);

	useEffect(() => {
		const fetch = async () => {
			await GET_SUBDSITRICTS({
				id: formValues.selectDistrict,
			}).then((res) => {
				setSubdistrics(res);
			});
		};
		formValues.selectDistrict && fetch();
	}, [formValues.selectDistrict]);

	useEffect(() => {
		if(formValues.selectSubdistrict) {
			setFormValues({
				...formValues,
				kodePos: subDistricts.find(
					(subdistrict) => subdistrict.id === formValues.selectSubdistrict
					)?.postal_code,
				});
		}
	}, [formValues.selectSubdistrict]);

	return (
		<Layout withNavbar={false} {...props}>
			<Navbar type="navback" title="Tambah Alamat" {...props} />
			{!isLoading && (
				<WrapAlamatMobile>
					<form
						onSubmit={() =>
							isEdit
								? handleEditAdress(index, formValues, props.history)
								: handleAddAdress(formValues, props.history)
						}
					>
						<div className="content">
							{mappingForms(forms, setFormValues, formValues)}
						</div>
						<div className="bottomButton">
							<ButtonCustom mdSize type="submit">
								{isEdit ? `Edit` : `Tambah`} Alamat
							</ButtonCustom>
						</div>
					</form>
				</WrapAlamatMobile>
			)}
		</Layout>
	);
};

export default AlamatTambahPageMobile;
