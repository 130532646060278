import React, { useEffect, useRef, useState } from "react";
import { WrapNavbar } from "./style";
import { ReactComponent as IconIphone } from "../../assets/icons/iconIphone.svg";
import Logo from "../../assets/img/logoNaisha.png";
import { ReactComponent as IconCategory } from "../../assets/icons/iconSort.svg";
import { ReactComponent as IconCart } from "../../assets/icons/iconCart.svg";
import { ReactComponent as IconOrder } from "../../assets/icons/iconOrder.svg";
import { ReactComponent as IconArrowDown } from "../../assets/icons/iconArrowDown.svg";
import queryString, { parse } from "query-string";
import { withRouter } from "react-router";
import imgPeople from "../../assets/img/people.png";
// import ImgGamis from "../../assets/img/gamis.png";
// import ImgKhimar from "../../assets/img/khimar.png";
// import ImgAksesoris from "../../assets/img/aksesoris.png";
import { SearchCustom } from "../../components";
import { useAuthContext } from "../../contexts/Auth.context";
import { isEmpty } from "lodash";
import {
	MenuList,
	ClickAwayListener,
	MenuItem,
	Paper,
	Popper,
} from "@material-ui/core";
import InfoDownloadAppModal from "./infoDownloadAppModal";

const NavbarDesktopComponent = (props) => {
	const { isAuthenticated, cart } = props;
	const { user, logout } = useAuthContext();
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [isOpenInfo, setIsOpenInfo] = useState(false);
	let getFromCart = JSON.parse(localStorage.getItem("CART"));

	const anchorRef = useRef(null);
	let parsed;
	if (props.location) {
		parsed = queryString.parse(props.location.search);
	}

	const toggleInfoModal = () => {
		setIsOpenInfo(!isOpenInfo);
	};

	const handleDropdown = () => {
		open ? setOpen(false) : setOpen(true);
	};

	const getProfile = () => {
		props.history.push("/akun/profile");
	};

	const handleSearchProduct = (event) => {
		setSearch(event);
		if (event !== "") {
			window.location.href = `/products?nama=${event}`;
		} else {
			window.location.href = `/products`;
		}
	};

	useEffect(() => {
		if (!isEmpty(parsed)) {
			setSearch(parsed.name);
		}
	}, []);

	const profilePhoto =
		!isEmpty(user) && user.profile_photos
			? user.profile_photos.medium.url
			: "https://via.placeholder.com/150";

	const _profile = () => {
		if (isAuthenticated) {
			return (
				<div
					className="profile"
					aria-controls="menu-dropdown"
					aria-haspopup="true"
					ref={anchorRef}
					onClick={handleDropdown}>
					<p>Hai, {user.name?.split(" ")[0] ?? user.name}</p>
					<div className="avatar">
						<img
							src={profilePhoto ? profilePhoto : imgPeople}
							alt="profile"
							style={{ borderRadius: "50%" }}
						/>
					</div>
					<IconArrowDown />
					<Popper
						open={open}
						anchorEl={anchorRef.current}
						transition
						disablePortal
						className="profile-menu">
						<Paper className="menu">
							<ClickAwayListener onClickAway={handleDropdown}>
								<MenuList>
									<MenuItem onClick={getProfile}>Profile</MenuItem>
									<MenuItem onClick={logout}>Logout</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Popper>
				</div>
			);
		} else {
			return (
				<div className="notLogin">
					<div
						onClick={() => props.history.push("/login")}
						style={{ cursor: "pointer" }}>
						Login
					</div>
					<div className="separator"></div>
					<div
						onClick={() => props.history.push("/register")}
						style={{ cursor: "pointer" }}>
						Register
					</div>
				</div>
			);
		}
	};

	return (
		<WrapNavbar>
			<div className="topNav">
				<div
					style={{
						maxWidth: 1220,
						position: "relative",
						margin: "0 auto",
						left: 0,
						right: 0,
					}}>
					<div className="content">
						<div className="left">
							<div
								style={{ cursor: "pointer", display: "flex" }}
								onClick={() => toggleInfoModal()}>
								<IconIphone />
								<p>Download Aplikasi Naisha</p>
							</div>
						</div>
						<div className="right">
							<ul>
								<li onClick={() => props.history.push("/orders/3")}>
									Cek Resi
								</li>
								<li onClick={() => props.history.push("/orders/0")}>
									Konfirmasi Pembayaran
								</li>
								<a
									href="/faq"
									// target="_blank"
									// rel="noreferrer"
									style={{ textDecoration: "none", color: "white" }}>
									<li>FAQ (Tanya Jawab)</li>
								</a>
								<li onClick={() => props.history.push("/company-profile")}>
									Profil Perusahaan
								</li>
								<li onClick={() => props.history.push("/customer-services/2")}>
									Hubungi Kami
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="nav">
				<div
					style={{
						maxWidth: 1220,
						position: "relative",
						margin: "0 auto",
						left: 0,
						right: 0,
					}}>
					<div className="content">
						<div className="logo" onClick={() => props.history.push("/")}>
							<img src={Logo} alt="naisha" />
						</div>

						<div
							className="category"
							onClick={() => props.history.push("/kategori")}>
							<IconCategory />
							<p>Kategori</p>
						</div>
						<div className="searchWrap">
							<SearchCustom
								placeholder="Cari nama produk"
								handleSearch={(event) =>
									handleSearchProduct(event.target.value)
								}
							/>
						</div>
						<div className="cart" onClick={() => props.history.push("/cart")}>
							<>
								{!isEmpty(getFromCart) && (
									<span className="count">{getFromCart.length}</span>
								)}
							</>

							<IconCart />
						</div>
						<div
							className="orders"
							onClick={() => props.history.push("/orders/0")}>
							<p>Pesanan</p>
							<IconOrder />
						</div>
						{_profile()}
					</div>
				</div>
				<InfoDownloadAppModal open={isOpenInfo} handleClose={toggleInfoModal} />
			</div>
		</WrapNavbar>
	);
};

export default withRouter(NavbarDesktopComponent);
