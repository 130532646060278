import React from "react";
import constanta from "../../configs/constanta";
import { Typography } from "@material-ui/core";


export const NavbarIconMobile = ({ img, text, onClickIcon }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				cursor: "pointer",
			}}
			onClick={() => {
				if (onClickIcon) {
					onClickIcon();
				}
			}
			}
		>
			<div
				style={{
					height: "44px",
					width: "38px",
					backgroundColor: constanta.COLOR.primary,
					borderRadius: "6px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img
					style={{
						height: "24px",
						width: "24px",
					}}
					src={img}
					alt={text}
				/>
			</div>
		</div>
	);
};

export const NavbarIconProduct = ({ img, text, onClickIcon }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				cursor: "pointer",
			}}
			onClick={() => {
				if (onClickIcon) {
					onClickIcon();
				}
			}
			}
		>
			<div
				style={{
					height: "44px",
					width: "38px",
					color: '#FFF',
					borderRadius: "6px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img
					style={{
						height: "24px",
						width: "24px",
					}}
					src={img}
					alt={text}
				/>
			</div>
		</div>
	);
};