import AlamatPage from "../pages/alamat-page";
import AlamatTambahPage from "../pages/alamat-page/tambah";
import CartPage from "../pages/cart-page";
import CheckoutPage from "../pages/checkout-page";
import ConfirmPaymentPage from "../pages/confirmPayment-page";
import CustomerServicePage from "../pages/customer-service-page";

import LoginPage from "../pages/login-page";
import Redirect from "../pages/redirect/index";

import OrderPage from "../pages/order-page";
import OrderHistoryMobile from "../pages/order-page/OrderHistoryMobile";
import PaymentPage from "../pages/payment-page";
import PaymentMethodPage from "../pages/paymentMethod-page";
import MarketPlacePage from "../pages/marketPlace-page";
import DropshipperPage from "../pages/dropship-page";
import ProductBundling from "../pages/productBundling-page/bundling-detail";
import ProductBundlingCheckout from "../pages/productBundling-page/bundling-checkout";

import RegisterPage from "../pages/register-page";
import RegisterAffiliatePage from "../pages/register-affiliate-page";
import ResetPasswordPage from "../pages/reset-password/resetPassword";
import ValidateToken from "../pages/reset-password/validateToken";
import SendResetPassword from "../pages/reset-password";
import ShippingPage from "../pages/shipping-page";
import AlamatCheckoutPage from "../pages/alamat-checkout-page";
import AlamatCheckoutTambahPage from "../pages/alamat-checkout-page/tambah";
import ProfilePage from "../pages/profile-page";
import ProfilePageEdit from "../pages/profile-page/edit";
import AccountPage from "../pages/account-page";
import PromoPage from "../pages/promo-page";
import ResultSearchProductPage from "../pages/resultSearchProduct-page";
import ResultCategoryProduct from "../pages/resultCategoryProduct-page";
import KategoriPage from "../pages/kategori-page";
import HadiahProgress from "../pages/hadiah-page/hadiah-progress";
import HadiahComplete from "../pages/hadiah-page/hadiah-complete";
import KuponSaya from "../pages/kupon-page/kupon-saya";
import AmbilKupon from "../pages/kupon-page/ambil-kupon";
import FaqPage from "../pages/faq-page";
import ResellerCard from "../pages/reseller-page/resellerCards";
import CompanyProfile from "../pages/company-page";

const listRoutes = [
  {
    path: "/login",
    name: "LOGIN",
    component: LoginPage,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterPage,
  },
  {
    path: "/register-vip-member",
    name: "register-vip-member",
    component: RegisterAffiliatePage,
  },
  {
    path: "/send-reset-password",
    name: "send-reset-password",
    component: SendResetPassword,
  },
  {
    path: "/validate-token/:email",
    name: "validate-token",
    component: ValidateToken,
  },
  {
    path: "/reset-password/:email",
    name: "reset-password",
    component: ResetPasswordPage,
  },
  {
    path: "/promo",
    name: "PROMO",
    component: PromoPage,
  },
  {
    path: "/products",
    name: "Products",
    component: ResultSearchProductPage,
  },
  {
    path: "/category",
    name: "Categories",
    component: ResultCategoryProduct,
  },

  {
    path: "/kategori",
    name: "Kategori",
    component: KategoriPage,
  },
  {
    path: "/bundling/:id",
    name: "bundling-detail",
    component: ProductBundling,
  },
  {
    path: "/bundling-checkout/:id",
    name: "bundlingCheckout",
    component: ProductBundlingCheckout,
  },
  {
    path: "/cart",
    name: "cart",
    component: CartPage,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: CheckoutPage,
  },

  {
    path: "/payment-method",
    name: "paymentMethod",
    component: PaymentMethodPage,
  },
  {
    path: "/marketplace",
    name: "marketplace",
    component: MarketPlacePage,
  },
  {
    path: "/dropshipper",
    name: "dropshipper",
    component: DropshipperPage,
  },
  {
    path: "/payment",
    name: "payment",
    component: PaymentPage,
  },
  {
    path: "/confirm-payment",
    name: "confirmPayment",
    component: ConfirmPaymentPage,
  },
  {
    path: "/alamat",
    name: "alamat",
    component: AlamatCheckoutPage,
  },
  {
    path: "/alamat/add",
    name: "alamat-checkout-tambah",
    component: AlamatCheckoutTambahPage,
  },
  {
    path: "/alamat/edit/:index",
    name: "alamat-checkout-tambah",
    component: AlamatCheckoutTambahPage,
  },
  {
    path: "/shipping-service",
    name: "shippingservice",
    component: ShippingPage,
  },
  {
    path: "/orders/:type",
    name: "order",
    component: OrderPage,
  },
  {
    path: "/order-history-mobile",
    name: "order history mobile",
    component: OrderHistoryMobile,
  },
  {
    path: "/customer-services/:type",
    name: "customerServices",
    component: CustomerServicePage,
  },
  {
    path: "/akun/alamat",
    name: "alamat",
    component: AlamatPage,
  },
  {
    path: "/akun/alamat/add",
    name: "alamat-tambah",
    component: AlamatTambahPage,
  },
  {
    path: "/akun/alamat/edit/:index",
    name: "alamat-tambah",
    component: AlamatTambahPage,
  },
  {
    path: "/akun/profile",
    name: "profile",
    component: ProfilePage,
  },
  {
    path: "/akun/profile/edit",
    name: "profile",
    component: ProfilePageEdit,
  },
  {
    path: "/akun",
    name: "akun",
    component: AccountPage,
  },
  {
    path: "/hadiah/progress",
    name: "hadiah",
    component: HadiahProgress,
  },
  {
    path: "/hadiah/complete",
    name: "hadiah",
    component: HadiahComplete,
  },
  {
    path: "/kupon-saya",
    name: "kupon",
    component: KuponSaya,
  },
  {
    path: "/ambil-kupon",
    name: "kupon",
    component: AmbilKupon,
  },
  {
    path: "/faq",
    name: "Faq",
    component: FaqPage,
  },
  {
    path: "/reseller-card",
    name: "resellerCard",
    component: ResellerCard,
  },
  {
    path: "/company-profile",
    name: "company",
    component: CompanyProfile,
  },
  {
    path: "/redirect",
    name: "redirect",
    component: Redirect,
  },
];

export default listRoutes;
