import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";

export const OrderContext = React.createContext();
export const useOrderContext = () => useContext(OrderContext);

export const OrderContextProvider = (props) => {
	const [payment, setPayment] = useState({});
	const [referralCode, setReferralCode] = useState("");
	const [cart, setCart] = useState([]);
	const [isRefetch, setIsRefetch] = useState(false);
	let localCart = localStorage.getItem("CART");

	const refreshOrderContext = async () => {
		setIsRefetch(true);
	};

	const newPayment = async (object) => {
		setPayment(object);
		localStorage.setItem("CURRENTLY_PAYMENT", JSON.stringify(object));
		localStorage.setItem("CART", JSON.stringify([]));
		refreshOrderContext();
	};

	const updateCart = async (setState) => {
		let getLocal = JSON.parse(localStorage.getItem("CART"));
		if (getLocal === null) {
			getLocal = [];
			localStorage.setItem("CART", JSON.stringify([]));
		}
		setState(getLocal);
	};

	useEffect(() => {
		const fetch = () => {
			updateCart(setCart);
			setIsRefetch(false);
		};
		isRefetch && fetch();
	}, [localCart, isRefetch]);

	useEffect(() => {
		const fetch = () => {
			if (JSON.parse(localStorage.getItem("CURRENTLY_PAYMENT"))) {
				const localstorage = JSON.parse(
					localStorage.getItem("CURRENTLY_PAYMENT")
				);
				if (localstorage !== null) {
					setPayment(localstorage);
				} else if (!isEmpty(payment)) {
					setPayment(payment);
				}
				let getLocalCart = JSON.parse(localStorage.getItem("CART"));
				getLocalCart !== null && setCart(getLocalCart);
			}
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetchReferral = () => {
			const url = new URL(window.location.href);
			const param = url.searchParams.get("refferal");
			const expired = JSON.parse(localStorage.getItem("REFERRAL_EXPIRED"));
			const referral = JSON.parse(localStorage.getItem("REFERRAL"));
			const currentDateTime = new Date().getTime();
			const lifeTime = 30 * 24 * 60 * 60 * 1000; // referral lifetime is 30 days

			if (currentDateTime > expired) {
				localStorage.removeItem("REFERRAL");
				localStorage.removeItem("REFERRAL_EXPIRED");
			}

			if (param !== null) {
				if (referral === null) {
					localStorage.setItem("REFERRAL", JSON.stringify(param));
					setReferralCode(param);
					localStorage.setItem(
						"REFERRAL_EXPIRED",
						JSON.stringify(currentDateTime + lifeTime)
					);
				} else if (referral !== null && expired !== null) {
					if (currentDateTime > expired) {
						localStorage.setItem("REFERRAL", JSON.stringify(param));
						setReferralCode(param);
						localStorage.setItem(
							"REFERRAL_EXPIRED",
							JSON.stringify(currentDateTime + lifeTime)
						);
					} else {
						setReferralCode(referral);
					}
				} else {
					setReferralCode(referral);
				}
			}
		};
		fetchReferral();
	}, []);

	return (
		<OrderContext.Provider
			value={{
				payment,
				newPayment,
				cart,
				refreshOrderContext,
				referralCode,
			}}>
			{props.children}
		</OrderContext.Provider>
	);
};
