import { useMediaQuery } from '@material-ui/core';
import React from 'react'
import PaymentMethodDesktopPage from './paymentMethod.desktop';
import PaymentMethodMobilePage from './paymentMethod.mobile'

const PaymentMethodPage = props => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return (
    isMobile ? <PaymentMethodMobilePage {...props} /> : <PaymentMethodDesktopPage {...props} />
  )
}
export default PaymentMethodPage