import { axiosInstance } from "./axiosInstance";

export const CHECKOUT = async (body) => {
	const url = `/api/orders/checkout`;
	const res = axiosInstance.post(url, {
		data: body,
		headers: { "Content-Type": "multipart/form-data" },
	});
	return res;
};

export const GET_ORDER_BY_ID = async (id) => {
	const url = `/api/orders?filter[order_id]=${id}`;
	const res = axiosInstance.get(url);
	return res;
};

export const GET_ORDER_BY_FILTER = async (filters) => {
	const url = `/api/orders${filters ?? ""}`;
	const res = axiosInstance.get(url);
	return res;
};

export const CANCEL_ORDER = async (id) => {
	const url = `/api/orders/cancel`;
	const res = axiosInstance.put(url, { order_id: id });
	return res;
};

export const DONE_ORDER = async (id) => {
	const url = `/api/orders/done`;
	const res = axiosInstance.put(url, { order_id: id });
	return res;
};

export const GET_DISTRIBUTOR_SHIPING_COST = async (body) => {
	const url = `/api/order/get-distributor`;
	const res = axiosInstance.post(url, body);
	return res;
};
