import {
	GET_PRODUCTS,
	GET_ALL_PRODUCTS,
} from "../../services/products.service";
import _, { isEmpty } from "lodash";
import swal from "sweetalert";

export const getProduct = async (
	slug,
	setProduct,
	setImageProducts,
	setUniqueImagesProduct
) => {
	let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
	let images = [];
	let items = [];
	let uniqueItems = [];
	if (user !== null) {
		await GET_PRODUCTS(`?filter[slug]=${slug}`).then((res) => {
			setProduct(res.data[0]);
			res.data[0].skus.map((sku) => {
				images.push({
					...sku,
					isSelected: false,
				});
				return "";
			});
			images[0] = {
				...res.data[0].skus[0],
				isSelected: true,
			};
			setImageProducts(images);
		});
		images.map((item) => {
			items.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
				price: item.price,
				isSelected: false,
			});
			return "";
		});
	} else {
		await GET_ALL_PRODUCTS(`?filter[slug]=${slug}`).then((res) => {
			setProduct(res.data[0]);
			res.data[0].skus.map((sku) => {
				images.push({
					...sku,
					isSelected: false,
				});
				return "";
			});
			images[0] = {
				...res.data[0].skus[0],
				isSelected: true,
			};
			setImageProducts(images);
		});
		images.map((item) => {
			items.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
				price: item.price,
				isSelected: false,
			});
			return "";
		});
	}

	_.uniqBy(items, "color").map((item, index) => {
		uniqueItems.push(item);
		return "";
	});
	uniqueItems[0] = {
		...uniqueItems[0],
		isSelected: true,
	};

	setUniqueImagesProduct(uniqueItems);
};

export const getProducts = async (filters, setOtherProducts) => {
	let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
	if (user !== null) {
		await GET_PRODUCTS(filters).then((res) => {
			setOtherProducts(res.data);
		});
	} else {
		await GET_ALL_PRODUCTS(filters).then((res) => {
			setOtherProducts(res.data);
		});
	}
};

export const onSelectedImage = async (
	setImageProducts,
	imageProducts,
	code,
	setSelectedProduct,
	selectedProduct
) => {
	setSelectedProduct({
		...selectedProduct,
		object: {},
	});
	let index = _.findIndex(imageProducts, { code: code });
	let clone = [];
	await imageProducts.map((image) => {
		clone.push({
			...image,
			isSelected: false,
		});
		return "";
	});
	clone[index] = {
		...clone[index],
		isSelected: true,
	};
	setImageProducts(clone);
};

export const onSelectColor = async (
	color,
	index,
	selectedProduct,
	setSelectedProduct,
	refSlider
) => {
	refSlider.slickGoTo(index);
	setSelectedProduct({
		...selectedProduct,
		color,
		index,
	});
};

export const onSelectColorDesktop = async (
	color,
	index,
	selectedProduct,
	setSelectedProduct
) => {
	setSelectedProduct({
		...selectedProduct,
		color,
		index,
	});
};

export const onSelectSize = async (
	size,
	selectedProduct,
	setSelectedProduct
) => {
	setSelectedProduct({
		...selectedProduct,
		size,
	});
};

export const onClickTotalProduct = (type, state, setState) => {
	if (type === "plus") {
		state.total !== state.stock &&
			setState({
				...state,
				total: state.total + 1,
			});
	} else {
		state.total !== 1 &&
			setState({
				...state,
				total: state.total - 1,
			});
	}
};

export const handleAddToCart = (selectedProduct) => {
	let localCart = JSON.parse(localStorage.getItem("CART") || "[]");
	if (isEmpty(localCart)) {
		localCart.push(selectedProduct);
		localStorage.setItem("CART", JSON.stringify(localCart));
	} else {
		let index = _.findIndex(localCart, { code: selectedProduct.code });
		if (index >= 0) {
			localCart[index] = {
				...localCart[index],
				total: localCart[index].total + selectedProduct.total,
			};
		} else {
			localCart.push(selectedProduct);
		}
		localStorage.setItem("CART", JSON.stringify(localCart));
	}
	return "";
};

export const handleBuyNow = async (selectedProduct, history) => {
	await handleAddToCart(selectedProduct);
	history.push("/cart");
};

export const copyProductLink = (slug) => {
	let url = `${window.location.origin}/product/${slug}`;
	let userId =
		JSON.parse(localStorage.getItem("USER_NAISHA"))?.referral_code ?? "";
	if (userId !== "") url = url + `?refferal=${userId}`;
	navigator.clipboard.writeText(url);
	swal("Berhasil", "Link produk berhasil disalin", "success");
};
