import React, { useEffect, useState } from "react";
import {
	Radio,
	withStyles,
	Button,
	CircularProgress,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@material-ui/core";
import constanta from "../../configs/constanta";
import { Layout, Navbar } from "../../containers";
import { EST_SHIPPING_COST } from "../../services/supportData.service";
import { formatRupiah } from "../../utils/currencyFormat";
import {
	getTotalWeight,
	getTotalBundlingWeight,
	handleClickSubmit,
	handleSelectService,
	showServices,
} from "./handler";
import { WrapShippingPage, WrapShippingService } from "./style";

import { isEmpty } from "lodash";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ShippingPageDesktop = (props) => {
	const [couriers, setCouriers] = useState([]);
	const [isRefetch, setIsRefetch] = useState(false);

	const PinkRadio = withStyles({
		root: {
			color: `${constanta.COLOR.primary}`,
			"&$checked": {
				color: `${constanta.COLOR.primary}`,
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const _mappingShipping = () => {
		let render = [];
		couriers.map((item, index) => {
			render.push(
				<div key={index} className="services">
					<div
						onClick={() =>
							showServices(index, setCouriers, couriers, setIsRefetch)
						}>
						<div className="title" style={{ textTransform: "uppercase" }}>
							{item.logistic_name}
						</div>
						{item.rate === 0 ? null : ( // <div className="desc">Cash On Delivery</div>
							<div className="desc">
								Mulai dari Rp {formatRupiah(item.rate ?? 0)}
							</div>
						)}
						{item.min_day && item.max_day && (
							<span>
								Estimasi {item.min_day}
								{" - "}
								{item.max_day} hari
							</span>
						)}
					</div>
					<div className={`${couriers[index].isHidden && "hidden"}`}>
						{/* {_mappingService(item.services, index)} */}
					</div>
					<div className="checkbox">
						<PinkRadio
							checked={item.isChecked}
							onChange={(e) =>
								handleSelectService(
									e,
									index,

									setCouriers,
									couriers,
									setIsRefetch
								)
							}
							value={item.service}
						/>
					</div>
				</div>
			);
			return "";
		});
		return <>{render}</>;
	};

	// const countTotalWeight = (cart) => {
	// 	let pcs = getTotalWeight(cart);
	// 	let bundling = getTotalBundlingWeight(cart);
	// 	let total = pcs + bundling;

	// 	return total;
	// };

	// const _mappingService = (array, index) => {
	// 	let render = [];
	// 	array.map((item, indexService) => {
	// 		render.push(
	// 			<div className={`services`} key={indexService}>
	// 				<div className="checkbox">
	// 					<PinkRadio
	// 						checked={item.isChecked}
	// 						onChange={(e) =>
	// 							handleSelectService(
	// 								e,
	// 								index,
	// 								indexService,
	// 								setCouriers,
	// 								couriers,
	// 								setIsRefetch,
	// 							)
	// 						}
	// 						value={item.service}
	// 					/>
	// 				</div>

	// 				{item.cost[0].note === "COD" ? (
	// 					<>
	// 						<div className="title">{item.service} </div>
	// 						<div className="desc">Ambil Pesanan di toko</div>
	// 					</>
	// 				) : (
	// 					<>
	// 						<div className="title">
	// 							{item.service}{" "}
	// 							<span className="primary">
	// 								Rp {(item.cost[0].value)}
	// 							</span>
	// 						</div>
	// 						<div className="desc">Estimasi tiba {item.cost[0].etd} hari</div>
	// 					</>
	// 				)}
	// 			</div>,
	// 		);
	// 		return "";
	// 	});
	// 	return render;
	// };

	// const isDisabledButton = () => {
	// 	let isDisabled = true;

	// 	// couriers.map((courier) => {
	// 	// 	if (courier.services.filter((x) => x.isChecked).length !== 0) {
	// 	// 		isDisabled = false;
	// 	// 	}
	// 	// 	return "";
	// 	// });
	// 	return isDisabled;
	// };

	useEffect(() => {
		const fetch = async () => {
			let getSelectedAlamat = JSON.parse(
				localStorage.getItem("ALAMAT_CUSTOMER")
			).filter((x) => x.isPrimary)[0];
			let getCart = JSON.parse(localStorage.getItem("CART")).filter(
				(x) => x.isChecked
			);
			let getCouriers = JSON.parse(localStorage.getItem("SHIPPING_SERVICES"));

			let getEstCost = await EST_SHIPPING_COST({
				province: getSelectedAlamat.selectProvinceName,
				city: getSelectedAlamat.selectCityName,
				district: getSelectedAlamat.selectDistrictName,
				subdistrict: getSelectedAlamat.selectSubdistrictName,
				postal_code: getSelectedAlamat.kodePos,

				weight: 1,
				// weight_gram: countTotalWeight(getCart),
			});

			if (getCouriers) {
				setCouriers(getCouriers);
			}
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setIsRefetch(false);
		};
		fetch();
	}, [isRefetch]);

	return (
		<>
			<Layout {...props}>
				<WrapShippingPage>
					<div style={{ paddingTop: "32px", minHeight: "calc(100vh - 227px)" }}>
						<div
							style={{
								paddingBottom: "24px",
								display: "flex",
								alignItems: "center",
							}}>
							<ArrowBackIcon
								color="primary"
								style={{ marginRight: "8px", cursor: "pointer" }}
								onClick={() => props.history.push("/checkout")}
							/>
							<p>Opsi Pengiriman</p>
						</div>

						{isEmpty(couriers) && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
									height: "400px",
								}}>
								<CircularProgress />
								<p style={{ paddingTop: "14px" }}>Mohon tunggu sebentar...</p>
							</div>
						)}
						<WrapShippingService>
							{!isRefetch && _mappingShipping()}
						</WrapShippingService>
					</div>
					<div>
						<Button
							size="large"
							style={{ width: "100%", color: "white" }}
							color="primary"
							variant="contained"
							onClick={() => handleClickSubmit(couriers, props.history)}>
							Pilih Pengiriman
						</Button>
					</div>
				</WrapShippingPage>
			</Layout>
		</>
	);
};

export default ShippingPageDesktop;
