import styled from 'styled-components'
import constanta from '../../configs/constanta'

export const SearchCustomWrap = styled.div`
  position: relative;
  .MuiOutlinedInput-root {
    border-radius: 4px;
    background-color: #FFF;
  }
  .MuiInputAdornment-root {
    padding: 10px 5px;
    border-radius: 5px;
    height: unset;
    position: relative;
    height: 24px;
    width: 24px;
    margin-right: 0;
  }
  .MuiOutlinedInput-adornedStart {
    padding-left: 4px;
  }
  .search{
    width: 24px;
    cursor: pointer;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  input {
    padding: 14px 5px;
    font-size: 14px;
    font-family: 'Inter-Regular';
    ::placeholder {
      color: #7F8E9D;
    }
  }

  @media (max-width: 500px) {
    .MuiOutlinedInput-root {
      background: #FFF;
    } 
  }
`