import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
}));

export default function ControlledAccordions(props) {
	const { item } = props;
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	function isValidJsonString(jsonString) {
		try {
			JSON.parse(jsonString);
			return true;
		} catch (error) {
			if (!(jsonString && typeof jsonString === "string")) {
				return false;
			}
		}
	}

	let answerFAQ = item.answer;
	return (
		<div className={classes.root}>
			<Accordion
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
				style={{
					backgroundColor: "#fff",
					borderRadius: "0",
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					<Typography
						style={{}}
						className={classes.heading}
					>
						{item.question}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>{answerFAQ}</Typography>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
