export const getTotalWeight = (state) => {
	let total = 0;
	state.map((item) => {
		if (item.type !== "bundling") {
			total = total + item.total * item.object.weight_gram;
			return "";
		}
	});
	return total;
};

export const getTotalBundlingWeight = (state) => {
	let totalBundling = 0;
	state.map((item) => {
		if (item.type === "bundling") {
			totalBundling = item.total * item.weight_gram;
			return "";
		}
	});
	return totalBundling;
};

export const getLowestPrice = (state) => {
	let temp = [];
	state.map((item) => {
		temp.push(item.cost[0].value);
		return "";
	});
	return Math.min(...temp);
};

export const showServices = async (index, setState, state, setIsRefetch) => {
	let clone = state;
	clone[index] = {
		...clone[index],
		isHidden: !clone[index].isHidden,
	};
	setState(clone);
	setIsRefetch(true);
};

export const handleSelectService = async (
	e,
	index,

	setCouriers,
	couriers,
	setIsRefetch
) => {
	couriers.map((item) => {
		item.isChecked = false;
		return "";
	});

	let clone = couriers;
	clone[index] = {
		...clone[index],
		isChecked: e.target.checked,
	};
	setCouriers(clone);
	setIsRefetch(true);
};

export const handleClickSubmit = (state, history) => {
	localStorage.setItem("SHIPPING_SERVICES", JSON.stringify(state));
	// localStorage.setItem("SHIPPING_SERVICES_DISTRIBUTOR", JSON.stringify(state));
	history.goBack();
};
