import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AlamatPageDesktop from "./AlamatPage.desktop";
import AlamatPageMobile from "./AlamatPage.mobile";

const AlamatPage = (props) => {
	const [dataAlamat, setDataAlamat] = useState([]);
	const [isRefetch, setIsRefetch] = useState([]);
	const isMobile = useMediaQuery("(max-width:500px)");

	useEffect(() => {
		const fetch = async () => {
			let local = JSON.parse(localStorage.getItem("ALAMAT_CUSTOMER"));
			if (local !== null) {
				setDataAlamat(local);
			} else {
				localStorage.setItem("ALAMAT_CUSTOMER", JSON.stringify([]));
				setDataAlamat([]);
			}
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setDataAlamat([]);
			let local = JSON.parse(localStorage.getItem("ALAMAT_CUSTOMER"));
			setDataAlamat(local);
			setIsRefetch(false);
		};
		fetch();
	}, [isRefetch]);
	return isMobile ? (
		<AlamatPageMobile
			dataAlamat={dataAlamat}
			setDataAlamat={setDataAlamat}
			setIsRefetch={setIsRefetch}
			{...props}
		/>
	) : (
		<AlamatPageDesktop
			dataAlamat={dataAlamat}
			setDataAlamat={setDataAlamat}
			setIsRefetch={setIsRefetch}
			{...props}
		/>
	);
};

export default AlamatPage;
