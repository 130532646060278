import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomerServiceMobilePage from "./customerService.mobile";
import CustomerServiceDesktopPage from "./customerService.desktop";
import { getCustomerServices } from "./handler";

const CustomerServicePage = (props) => {
	const [isLoading, setIsLoading] = useState(true);
	const [customerService, setCustomerService] = useState([]);
	const [selected, setSelected] = useState("");
	const [isDisabled, setIsDisabled] = useState(true);
	const { type } = props.match.params;

	useEffect(() => {
		setIsLoading(true);
		const fetchCustomerService = async () => {
			const { data } = await getCustomerServices(type);
			setCustomerService(data);
			setIsLoading(false);
		};
		fetchCustomerService();
	}, [type]);

	useEffect(() => {
		if (selected !== "") {
			setIsDisabled(false);
		}
	}, [selected]);

	const handleSelectCustomerService = (index) => {
		setSelected(index);
	};

	const isMobile = useMediaQuery("(max-width:500px)");
	return isMobile ? (
		<CustomerServiceMobilePage
			{...{
				...props,
				isLoading,
				customerService,
				selected,
				isDisabled,
				handleSelectCustomerService,
			}}
		/>
	) : (
		<CustomerServiceDesktopPage
			{...{
				...props,
				isLoading,
				customerService,
				selected,
				isDisabled,
				handleSelectCustomerService,
			}}
		/>
	);
};
export default CustomerServicePage;
