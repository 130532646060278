import { useMediaQuery } from "@material-ui/core";
import React from "react";
import pdfFile from '../../assets/files/CompanyProfileNaisha.pdf';

const PdfViewer = () => {
	return (
	  <div>
		<object data={pdfFile} type="application/pdf" width="100%" height="1000px">
		  <p>PDF cannot be displayed in this browser. Please download the PDF to view it: <a href={pdfFile}>Download PDF</a></p>
		</object>
	  </div>
	);
  };
  
  export default PdfViewer;