import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AmbilKuponDesktop from "./AmbilKupon.desktop";
import AmbilKuponMobile from "./AmbilKupon.mobile";

const AmbilKupon = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <AmbilKuponMobile {...props} /> : <AmbilKuponDesktop {...props} />;
};

export default AmbilKupon;
