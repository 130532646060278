import { useMediaQuery } from "@material-ui/core";
import React from "react";
import ShippingPageDesktop from "./ShippingPage.desktop";
import ShippingPageMobile from "./ShippingPage.mobile";

const ShippingPage = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return (
      isMobile ? <ShippingPageMobile {...props} /> : <ShippingPageDesktop {...props} />
  );
};

export default ShippingPage;
