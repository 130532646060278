import { useMediaQuery } from "@material-ui/core";
import React from "react";
import AlamatTambahPageDesktop from "./AlamatTambahPage.desktop";
import AlamatTambahPageMobile from "./AlamatTambahPage.mobile";

const AlamatTambahPage = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	return isMobile ? (
		<AlamatTambahPageMobile {...props} />
	) : (
		<AlamatTambahPageDesktop {...props} />
	);
};

export default AlamatTambahPage;
