import { axiosInstance } from './axiosInstance';

export const CONFIRM_PAYMENT = async (body) => {
  const data = new FormData();
  for (const key in body) {
    data.append(key, body[key]);
  }
  const url = `/api/orders/confirmation`;
  const res = axiosInstance.post(url, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return res;
};
