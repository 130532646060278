import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { WrapTags, WrapMenu } from "./style.js";
import { Grid, Card, Button, Typography } from "@material-ui/core";


export default function MenuHadiah(props) {
    const active = props.active;
    return (
        <WrapMenu>
            <div style={{
                padding: "70px 0 10px 0",
                margin: "0px 14px",
                borderRadius: "8px",
            }}>
                <Card className="card-custom">
                    <div
                        style={{
                            display: "flex",
                            padding: "4px",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button 
                            variant={active === "progress" ? "contained" : ""}
                            className={active === "progress" ? "btn-menu active" : "btn-menu"} onClick={() => props.history.push("/hadiah/progress")}>
                            <span>Progress</span>
                        </Button>
                        <Button 
                            variant={active === "complete" ? "contained" : ""}
                            className={active === "complete" ? "btn-menu active" : "btn-menu"}  onClick={() => props.history.push("/hadiah/complete")}>
                            <span>Berhasil</span>
                        </Button>
                    </div>
                </Card>
            </div>
        </WrapMenu>
    )
}