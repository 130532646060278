import React, { useEffect, useState } from "react";
import {
	ProductCardFull,
	ProductCardBundling,
	ShippingAddress,
	MenuBottom,
} from "../../components";
import { LayoutWithAuth, Navbar } from "../../containers";
import { useAuthContext } from "../../contexts/Auth.context";
import { WrapCheckoutPageMobile, InputOptional } from "./style";
import iconShipping from "../../assets/icons/iconShipping.svg";
import iconNote from "../../assets/icons/iconNote.svg";
import iconArrowRight from "../../assets/icons/iconArrowRight.svg";
import iconRincian from "../../assets/icons/iconRincian.svg";
import iconWallet from "../../assets/icons/iconWallet.svg";
import Wallet from "../../assets/icons_1/Wallet.svg";
import Shipping from "../../assets/icons_1/shipping.svg";
import Voucher from "../../assets/icons_1/voucher.svg";
import {
	EST_SHIPPING_COST,
	GET_COURIER_RECOMENDATION,
} from "../../services/supportData.service";
import { GET_DISTRIBUTOR_SHIPING_COST } from "../../services/orders.service";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import {
	Grid,
	InputBase,
	Button,
	Typography,
	Checkbox,
	FormControlLabel,
	Divider,
} from "@material-ui/core";
import {
	getShippingCost,
	getShippingService,
	handleBuyNow,
	onChangeForm,
	subTotalPrice,
	subTotalPriceBundling,
} from "./handler";
import { isEmpty } from "lodash";
import { formatRupiah } from "../../utils/currencyFormat";
import { useOrderContext } from "../../contexts/Order.context";
import KuponModal from "./KuponModal";
import constanta from "../../configs/constanta";
import swal from "sweetalert";

const CheckoutPageMobile = (props) => {
	const [listAddress, setListAddress] = useState([]);
	const [listProducts, setListProducts] = useState([]);

	const [isLoading, setIsLoading] = useState(true);
	const [isOpenKupon, setIsOpenKupon] = useState(false);
	const [selectedKupon, setSelectedKupon] = useState(null);
	const [isDropshipper, setIsDropshipper] = useState(false);
	const [isFileResi, setIsFileResi] = useState(false);
	const [fileResi, setFileResi] = useState(null);
	const [marketplace, setMarketplace] = useState([]);
	const [dropshipper, setDropshipper] = useState([]);
	const { user } = useAuthContext();
	const { newPayment, referralCode } = useOrderContext();
	const [shippingServices, setShippingServices] = useState([]);
	const [paymentMethod, setPaymentMethod] = useState([]);
	const [weight, setWeight] = useState(1);
	const [shipping, setShipping] = useState("");
	const [state, setState] = useState({
		form: {
			customer_note: "",
		},
	});

	const _mappingProduct = () => {
		let render = [];
		return listProducts.map((item, index) => {
			if (item.type === "bundling") {
				render = (
					<ProductCardBundling
						productBundling={item}
						type="checkout"
						isChecked={item.isChecked}
						withCheckbox={false}
						withQuantityButton={false}
						withDeleteButton={false}
						withBorderBottom={true}
						quantity={item.total}
					/>
				);
			} else {
				render = (
					<ProductCardFull
						style={{ boxShadow: "none", marginBotton: 0, height: 105 }}
						key={index}
						type="checkout"
						name={item.name}
						image_url={item.object.image_url}
						price={
							!isEmpty(user) && user.customer_type?.name === "Customer"
								? item.object.price.consumer
								: item.object.price.user
						}
						// discount = '',
						weight={item.object.weight_gram}
						slug={item.slug}
						color={item.color}
						size={item.size}
						quantity={item.total}
						is_preorder={item.is_preorder}
						day_preorder={item.day_preorder}
						isChecked={item.isChecked}
						withQuantityButton={false}
						withDeleteButton={false}
						withCheckbox={false}
						withBorderBottom={true}
						referral={referralCode}
					/>
				);
			}
			return <div>{render}</div>;
		});
	};

	const isSelectedPayment = () => {
		const getChecked = paymentMethod.filter((x) => x.isChecked);
		if (isEmpty(getChecked)) {
			return false;
		} else {
			return true;
		}
	};
	const isSelectedMarketplace = () => {
		const getChecked = marketplace.filter((x) => x.isChecked);
		if (isEmpty(getChecked)) {
			return false;
		} else {
			return true;
		}
	};

	const isSelectedDropshipper = () => {
		const getChecked = dropshipper.filter((x) => x.isChecked);
		if (isEmpty(getChecked)) {
			return false;
		} else {
			return true;
		}
	};

	const countTotalPrice = (cart, user) => {
		let pcs = subTotalPrice(cart, user);
		let bundling = subTotalPriceBundling(cart);
		let total = pcs + bundling;
		return total;
	};

	const getTotalWeight = (cart) => {
		let total = 0;
		cart.map((item) => {
			total += item.object.weight_gram * item.total;
		});
		return total;
	};

	useEffect(() => {
		const fetch = async () => {
			let getAddress = JSON.parse(localStorage.getItem("ALAMAT_CUSTOMER"));
			let getFromCart = JSON.parse(localStorage.getItem("CART"));
			let getShipping = JSON.parse(localStorage.getItem("SHIPPING_SERVICES"));
			let getShippingDistributor = JSON.parse(
				localStorage.getItem("SHIPPING_SERVICES_DISTRIBUTOR")
			);
			let getPayment = JSON.parse(localStorage.getItem("PAYMENTS_METHOD"));
			let getMarketplace = JSON.parse(localStorage.getItem("MARKETPLACE"));
			let getDropshipper = JSON.parse(localStorage.getItem("DROPSHIPPER"));
			let getCourierRecomendation = JSON.parse(
				localStorage.getItem("RECOMMENDATION_COURIER")
			);
			getAddress !== null && setListAddress(getAddress);
			getPayment !== null && setPaymentMethod(getPayment);
			getMarketplace !== null && setMarketplace(getMarketplace);
			getDropshipper !== null && setDropshipper(getDropshipper);
			setListProducts(getFromCart.filter((x) => x.isChecked));
			getShipping !== null && setShippingServices(getShipping);
			setIsLoading(false);
			const totalWeight = getTotalWeight(
				getFromCart.filter((x) => x.isChecked)
			);

			if (getAddress.length > 0) {
				if (getShipping == null) {
					const alamat = getAddress.filter((x) => x.isPrimary)[0];
					let couriers = await EST_SHIPPING_COST({
						province: alamat.selectProvinceName,
						city: alamat.selectCityName,
						district: alamat.selectDistrictName,
						subdistrict: alamat.selectSubdistrictName,
						postal_code: alamat.kodePos,
						weight: totalWeight,
					});

					if (couriers.data.data) {
						const temp = couriers.data.data.map((item) => {
							return {
								...item,
								isChecked: false,
							};
						});

						let recommendationCourier = await GET_COURIER_RECOMENDATION();

						let isGetRecommendation = false;
						temp.map((item) => {
							if (
								item.logistic_name.toLowerCase() ==
									recommendationCourier.data.code &&
								item.rate_name == recommendationCourier.data.rate_name
							) {
								item.isChecked = true;
								isGetRecommendation = true;
							}
						});

						if (!isGetRecommendation) {
							temp[0].isChecked = true;
						}
						setShippingServices(temp);
						setShipping(temp.filter((x) => x.isChecked)[0]);
						localStorage.setItem("SHIPPING_SERVICES", JSON.stringify(temp));
					}
				} else {
					setShippingServices(getShipping);
					setShipping(getShipping.filter((x) => x.isChecked)[0]);
				}
			}
		};
		fetch();
	}, []);

	let payment = {};
	let market = {};
	let dropship = {};
	if (isSelectedPayment()) {
		payment.bank = paymentMethod.filter((x) => x.isChecked)[0].bank.name;
		payment.number = paymentMethod.filter((x) => x.isChecked)[0].account_number;
		payment.name = paymentMethod.filter((x) => x.isChecked)[0].account_name;
	}

	if (isSelectedMarketplace()) {
		market.name = marketplace.filter((x) => x.isChecked)[0].name;
	}

	if (isSelectedDropshipper()) {
		dropship.name = dropshipper.filter((x) => x.isChecked)[0].name;
	}

	useEffect(() => {
		let getDropshipper = JSON.parse(localStorage.getItem("IS_DROPSHIPPER"));
		setIsDropshipper(getDropshipper);
		if (getDropshipper) {
			localStorage.removeItem("SHIPPING_SERVICES");
			localStorage.removeItem("SHIPPING_SERVICES_DISTRIBUTOR");
		} else {
			localStorage.removeItem("DROPSHIPPER");
			localStorage.removeItem("MARKETPLACE");
		}
	}, []);

	useEffect(() => {
		let kupon = JSON.parse(localStorage.getItem("USE_VOUCHER"));
		setSelectedKupon(kupon);
	}, [isOpenKupon]);

	const totalPayment = (totalProduct, shippingCost, coupon) => {
		let result;
		shippingCost = Number(shippingCost);

		if (coupon) {
			if (coupon.type === "ongkir" && shippingCost === 0) {
				let calc = shippingCost - coupon.amount;
				if (calc <= -1) {
					result = totalProduct + 0;
				} else {
					result = totalProduct + calc;
				}
			} else {
				let calc = shippingCost - coupon.amount;
				if (calc <= -1) {
					result = totalProduct + 0;
				} else {
					result = totalProduct + calc;
				}
			}
		} else {
			result = totalProduct + shippingCost;
		}
		return result;
	};

	useEffect(() => {
		let isFileResi = JSON.parse(localStorage.getItem("IS_FILE_RESI"));
		setIsFileResi(isFileResi);
	}, []);

	return (
		<LayoutWithAuth withNavbar={false} {...props}>
			<Navbar type="navback" title="Checkout" {...props} />
			<WrapCheckoutPageMobile>
				<div style={{ margin: "14px" }}>
					<ShippingAddress listAddress={listAddress} />
				</div>

				<div style={{ margin: "14px" }}>
					<div className="listProducts" style={{ marginTop: 8 }}>
						{_mappingProduct()}
					</div>
				</div>

				<div style={{ margin: "14px" }}>
					<div style={{ marginBottom: "12px", marginLeft: "14px" }}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									checked={isDropshipper}
									onChange={(event) => {
										localStorage.setItem(
											"IS_DROPSHIPPER",
											event.target.checked
										);
										setIsDropshipper(event.target.checked);
									}}
								/>
							}
							label="Kirim sebagai dropshipper marketplace"
						/>
					</div>
					<div className="shipping">
						{isDropshipper ? (
							<>
								<div className="title">
									<div className="icon">
										<img src={iconWallet} alt="pembayaran" />
									</div>
									<div className="text">Marketplace</div>
								</div>
								<div
									className="choose"
									onClick={() => props.history.push("/marketplace")}>
									<div className="text">
										{isSelectedMarketplace()
											? `${market.name}`
											: `Pilih Marketplace`}
									</div>
									<div className="icon">
										<img src={iconArrowRight} alt="pilih-pembayaran" />
									</div>
								</div>
								<div className="title">
									<div className="icon">
										<img src={iconShipping} alt="pembayaran" />
									</div>
									<div className="text">Kurir Dropship</div>
								</div>
								<div
									className="choose"
									onClick={() => props.history.push("/dropshipper")}>
									<div className="text">
										{isSelectedDropshipper()
											? `${dropship.name}`
											: `Pilih Dropshipper`}
									</div>
									<div className="icon">
										<img src={iconArrowRight} alt="pilih-pembayaran" />
									</div>
								</div>

								{/* Nomor Resi Marketplace */}
								<div style={{ marginBottom: "12px" }}>
									<div
										className="title-service"
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}>
										<div style={{ display: "flex" }}>
											<div className="icon">
												<img src={iconNote} alt="note" />
											</div>
											<div className="text">Nomor Resi Marketplace</div>
										</div>
										<div>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														checked={isFileResi}
														onChange={(event) => {
															localStorage.setItem(
																"IS_FILE_RESI",
																event.target.checked
															);
															setIsFileResi(event.target.checked);
														}}
													/>
												}
												label="Unggah Resi"
											/>
										</div>
									</div>
									{isFileResi ? (
										<>
											<div>
												<input
													accept="image/*,application/pdf"
													id="contained-button-file"
													type="file"
													style={{ display: "none" }}
													onChange={(event) => {
														setFileResi(event.target.files[0]);
														onChangeForm(
															event.target.files[0],
															state,
															setState,
															"resi_file"
														);
													}}
												/>
												<label htmlFor="contained-button-file">
													<Button variant="contained" component="span">
														<PhotoCamera
															style={{
																marginRight: "8px",
															}}
														/>
														Unggah Resi
													</Button>
												</label>
												<span
													style={{
														marginLeft: "12px",
													}}>
													{fileResi ? fileResi.name : ""}
												</span>
											</div>
										</>
									) : (
										<InputOptional>
											<InputBase
												className="multilineInput"
												defaultValue=""
												placeholder="Nomor resi marketplace"
												multiline
												rows={1}
												name="dropship_resi"
												inputProps={{
													"aria-label": "naked",
												}}
												onChange={(e) =>
													onChangeForm(e.target.value, state, setState, "resi")
												}
											/>
										</InputOptional>
									)}
								</div>
							</>
						) : (
							<>
								{/* <div className="title">
									<div className="icon">
										<img src={iconShipping} alt="shipping" />
									</div>
									<div className="text">Opsi Pengiriman</div>
								</div> */}
								<div
									className="choose"
									onClick={() => {
										if (listAddress.length > 0) {
											props.history.push("/shipping-service");
										} else {
											swal("Pesan", "Pilih Atau Tambah Alamat dahulu", "error");
										}
									}}>
									<div className="text">
										<div className="icon-before">
											<img src={Shipping} alt="pilih-pembayaran" />
										</div>
										{isEmpty(shipping)
											? `Pilih Kurir`
											: shipping.logistic_name +
											  " - " +
											  shipping.rate_name +
											  " - Rp " +
											  formatRupiah(shipping.rate ?? 0)}
									</div>
									<div className="icon">
										<img src={iconArrowRight} alt="pilih-pengiriman" />
									</div>
								</div>
							</>
						)}
						{/* <div className="title">
							<div className="icon">
								<img src={iconWallet} alt="pembayaran" />
							</div>
							<div className="text">Metode Pembayaran</div>
						</div> */}
						<div
							className="choose"
							onClick={() => props.history.push("/payment-method")}>
							<div className="text">
								<div className="icon-before">
									<img src={Wallet} alt="pilih-pembayaran" />
								</div>

								{isSelectedPayment()
									? `BANK ${
											paymentMethod.filter((x) => x.isChecked)[0].bank.name
									  } (${
											paymentMethod.filter((x) => x.isChecked)[0].account_number
									  })`
									: `Metode Pembayaran`}
							</div>
							<div className="icon">
								<img src={iconArrowRight} alt="pilih-pembayaran" />
							</div>
						</div>

						<div className="choose" onClick={() => setIsOpenKupon(true)}>
							<div className="text">
								<div className="icon-before">
									<img src={Voucher} alt="pilih-pembayaran" />
								</div>
								{selectedKupon ? (
									<div style={{ display: "flex", flexDirection: "column" }}>
										<Typography color="primary" style={{ fontSize: "16px" }}>
											1 Voucher/Kupon terpakai
										</Typography>
										<Typography style={{ fontSize: "11px" }}>
											{`	Selamat, kamu hemat Rp ${formatRupiah(
												selectedKupon.amount
											)} untuk pesananmu!`}
										</Typography>
									</div>
								) : (
									<Typography color="primary" style={{ fontSize: "16px" }}>
										Pilih Voucher
									</Typography>
								)}
								<div className="icon">
									<img src={iconArrowRight} alt="pilih-pembayaran" />
								</div>
							</div>
						</div>
						<div className="title">
							<div className="icon">
								<img src={iconNote} alt="note" />
							</div>
							<div className="text">Catatan Pengiriman</div>
						</div>
						<div className="input">
							<InputBase
								className="multilineInput"
								defaultValue=""
								placeholder="Tambahan Catatan (Opsional) . . ."
								multiline
								rows={4}
								inputProps={{ "aria-label": "naked" }}
								onChange={(e) => onChangeForm(e.target.value, state, setState)}
							/>
						</div>
					</div>
				</div>
				<div style={{ margin: "14px" }}>
					<div className="summary">
						<Grid container style={{ padding: "4px 16px" }}>
							<Grid item xs={6}>
								Sub Total ({listProducts.length} Produk)
							</Grid>
							<Grid item xs={6} style={{ textAlign: "right" }}>
								Rp {formatRupiah(countTotalPrice(listProducts, user))}
							</Grid>
						</Grid>

						<Grid container style={{ padding: "4px 16px" }}>
							<Grid item xs={6}>
								Ongkos Kirim
							</Grid>
							<Grid item xs={6} style={{ textAlign: "right" }}>
								Rp {formatRupiah(shipping.rate ?? 0)}
							</Grid>
						</Grid>
						<Grid container style={{ padding: "4px 16px" }}>
							<Grid item xs={6}>
								Promo Diskon
							</Grid>
							<Grid item xs={6} style={{ textAlign: "right" }}>
								{selectedKupon
									? `Rp ${formatRupiah(selectedKupon.amount)}`
									: "0"}
							</Grid>
						</Grid>
						<Divider style={{ margin: "8px 0px" }} />
						<Grid container style={{ padding: "4px 16px", fontSize: 20 }}>
							<Grid item xs={6}>
								Total:
							</Grid>
							<Grid item xs={6} style={{ textAlign: "right" }}>
								Rp{" "}
								{formatRupiah(
									totalPayment(
										countTotalPrice(listProducts, user),
										shipping.rate ?? 0,
										selectedKupon
									)
								)}
							</Grid>
						</Grid>
					</div>
				</div>
			</WrapCheckoutPageMobile>
			<MenuBottom
				type="summary"
				submitText="Bayar Sekarang"
				isDisabled={false}
				price={totalPayment(
					countTotalPrice(listProducts, user),
					shipping.rate ?? 0,
					selectedKupon
				)}
				count={0}
				onSubmit={() =>
					handleBuyNow(
						state,
						shippingServices,
						listProducts,
						newPayment,
						props.history,
						isDropshipper
					)
				}
			/>
			<KuponModal
				open={isOpenKupon}
				handleClose={() => setIsOpenKupon(false)}
			/>
		</LayoutWithAuth>
	);
};

export default CheckoutPageMobile;
