import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapPaymentPageDesktop = styled.div`
	position: relative;
	padding-top: 60px;
	background: #f7f7f7;
	h1 {
		font-family: "DMSans-Bold";
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 24px;
	}
`;

export const AmountWrapperDesktop = styled.div`
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	padding: 20px;
	.title {
		font-family: "DMSans-Bold";
		font-weight: 500;
		font-size: 16px;
	}
	.description {
		font-size: 16px;
		color: #666666;
	}
	.description1 {
		font-size: 12px;
		// color: ${constanta.COLOR.primary};
	}

	.description-primary {
		font-size: 12px;
		font-weight: bold;
		color: ${constanta.COLOR.primary};
	}

	.total {
		margin-top: 16px;
		margin-bottom: 6px;
		color: ${constanta.COLOR.primary};
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 42px;
		text-align: center;
	}
`;

export const InfoWrapperDesktop = styled.div`
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	padding: 20px;
	.total {
		display: flex;
		margin-top: 8px;
		padding: 8px 0px;
		border-top: 2px solid #f1f1f1;
		p {
			font-family: "DMSans-Bold";
			font-size: 14px;
			width: 50%;
			:last-child {
				color: ${constanta.COLOR.darkPink};
				position: relative;
				text-align: right;
				width: 50%;
			}
		}
	}
	.details {
		padding: 8px 0px;
		.detail {
			display: flex;
			font-size: 12px;
			.text,
			.price {
				width: 50%;
				padding: 2px 0;
			}
			.price {
				position: relative;
				text-align: right;
			}
		}
	}

	.primary button {
		font-size: 16px;
	}
`;

export const MethodPaymentWrapperDesktop = styled.div`
	margin-top: 16px;
	padding: 20px;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	.title {
		font-family: "DMSans-Bold";
		font-weight: 500;
		font-size: 16px;
	}
	.description {
		font-size: 16px;
		color: #666666;
	}
	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 16px;
		.logo {
			width: 30%;
			top: 18px;
			img {
				width: 100%;
			}
		}
		.info {
			width: 60%;
			span {
				width: 100%;
				display: inline-block;
				color: #333;
				margin: 0;
				padding: 0;
				line-height: 12px;
				:nth-child(1) {
					font-size: 10px;
				}
				:nth-child(2) {
					font-size: 14px;
					font-family: "DMSans-Bold";
				}
				:nth-child(3) {
					font-size: 10px;
				}
			}
		}
	}
	.copyBtn {
		width: 36px;
		height: 36px;
		border-radius: 4px;
		background: #ffe4f2;
		text-align: center;
		cursor: pointer;
		img {
			width: 18px;
			position: relative;
			
		}
	}
`;

export const WrapPaymentPage = styled.div`
	position: relative;
	padding-top: 60px;
	font-family: "DMSans-Regular";
	background: #EFF8FC;
	min-height: 100vh;
	.info {
		margin: 0 20px;
		.total {
			display: flex;
			justify-content: center;
			margin-top: 20px;
			p {
				font-family: "Inter-Bold";
				font-size: 24px;
				color: ${constanta.COLOR.primary};
			}
		}
		.details {
			padding: 8px 16px;
			border-radius: 8px;
			color: ${constanta.COLOR.dark};
			border: 1px solid ${constanta.COLOR.strk};
			.detail {
				display: flex;
				font-size: 14px;
				border-bottom: 1px solid ${constanta.COLOR.strk};
				padding: 10px 0px;
				.text,
				.price {
					width: 50%;
				}
				.price {
					position: relative;
					text-align: right;
				}
				:last-child {
					border-bottom: none;
				}
			}
		}
	}
	.warning {
		margin: 20px 20px;
		padding: 8px 16px;
		background: rgba(253, 181, 4, 0.15);
		border-radius: 8px;
		border: 1px solid #FDB504;
		p{
			font-size: 14px;
		}
	}
	.metode {
		margin-top: 16px;
		padding: 8px 16px;
		.name {
			text-align: center;
			p {
				font-size: 16px;
			}
			span {
				font-weight: 700;
			}
		}
		.list {
			display: flex;
			background: #fff;
			padding: 8px 16px;
			border-radius: 8px;
			border: 1px solid ${constanta.COLOR.strk};
			.logo {
				width: 20%;
				position: relative;
				img {
					width: 100%;
				}
			}
			.info {
				position: relative;
				margin: 0 20px;
				width: 60%;
				margin-top: 8px;
				span {
					width: 100%;
					display: inline-block;
					color: ${constanta.COLOR.text};
					margin: 0;
					padding: 0;
					line-height: 12px;
					font-size: 16px;
					font-weight: 700;
				}
			}
		}
		.copyBtn {
			width: 36px;
			margin-top: 8px;
			border-radius: 4px;
			text-align: center;

			cursor: pointer;
			img {
				width: 18px;
				position: relative;
				top: 6px;
			}
			span {
				font-size: 14px;
				color: ${constanta.COLOR.primary};
				font-weight: 700;
			}
		}
	}

	.bottomButton {
		margin: 0;
		padding: 16px;
		height: 56px;
		background: #fff;
		position: fixed;
		bottom: 0;
		width: 100%;
		button {
			width: 95%;
		}
	}
`;
