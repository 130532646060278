import { axiosInstance } from "./axiosInstance";

export const GET_PRODUCTS = (filters) => {
	const url = `/api/products${filters ?? ""}`;
	const response = axiosInstance.get(url);
	return response;
};

export const GET_ALL_PRODUCTS = (filters) => {
	const url = `/api/all-products${filters ?? ""}`;
	const response = axiosInstance.get(url);
	return response;
};

export const GET_PRODUCT_FLASHSALE = (filters) => {
	const url = `/api/flashsale/index${filters ?? ""}`;
	const response = axiosInstance.get(url);
	return response;
};

export const GET_PROMO = (filter = {}) => {
	let promo = filter.promo ? `?filter[promo]=${filter.promo}` : "";
	let page = filter.page ? `&page=${filter.page}` : "";
	let paginate = filter.paginate ? `&paginate=${filter.paginate}` : "";

	const url = `/api/products${promo}${page}${paginate}`;
	const response = axiosInstance.get(url);
	return response;
};

export const GET_BUNDLING = (filter = {}) => {
	let include = filter.include ? `?include=${filter.include}` : "";
	let page = filter.page ? `&page=${filter.page}` : "";
	let paginate = filter.paginate ? `&paginate=${filter.paginate}` : "";

	const url = `/api/bundlings${include}${page}${paginate}`;
	const response = axiosInstance.get(url);
	return response;
};

export const GET_DETAIL_BUNDLING = (filter = {}) => {
	let id = filter.include ? `?filter[id]=${filter.id}` : "";
	let include = filter.include ? `&include=${filter.include}` : "";

	const url = `/api/bundlings${id}${include}`;
	const response = axiosInstance.get(url);
	return response;
};

export const GET_HOME_PRODUCTS = (filter = {}) => {
	let url = "/api/home";
	const response = axiosInstance.get(url);
	return response;
};
