import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ReactComponent as BadgeGoogleplay } from "../../assets/icons/badgeGoogleplay.svg";
import { ReactComponent as BadgeAppstore } from "../../assets/icons/badgeAppstore.svg";

export default function infoDownloadAppModal({ open, handleClose }) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={"xs"}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
					Download aplikasi kami
				</DialogTitle>
				<DialogContent>
					<div className="badge">
						<a
							href="https://apps.apple.com/id/app/naisha/id1557506084?l=id"
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "none", color: "black" }}
						>
							<BadgeAppstore />
						</a>

						<a
							href="https://play.google.com/store/apps/details?id=id.naisha.app"
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "none", color: "black" }}
						>
							<BadgeGoogleplay />
						</a>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						variant="contained"
						style={{ color: "white", width: "100%" }}
						color="primary"
						autoFocus
					>
						Tutup
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
