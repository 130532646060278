import React from "react";
import { ButtonCustom } from "..";
import { WrapMenuBottom } from "./style";
import { ReactComponent as Chat } from "../../assets/icons_1/Chat_bk_rec.svg";
import { ReactComponent as Keranjang } from "../../assets/icons_1/Buy_bk_rec.svg";
import { formatRupiah } from "../../utils/currencyFormat";
import { useMediaQuery } from "@material-ui/core";
import { CUSTOMER_SERVICE_CONSTANT } from "../../configs/constanta";
import { Height } from "@material-ui/icons";

const MenuBottomComponent = ({
	children,
	isDisabled = true,
	isFullWrapper = false,
	onClickWrapper = null,
	onClickAddToCart = null,
	type = "normal",
	submitText = "Beli Sekarang",
	count = 0,
	price = 0,
	onSubmit = null,
	history,
	style,
}) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	const _render = () => {
		let render = null;
		if (type === "normal") {
			render = (
				<>
					<div className="backgroundOutside" onClick={onClickWrapper}></div>
					{isFullWrapper && <div className="children">{children}</div>}
					{!isFullWrapper && (
						<div className="items">
							<div
								className="item"
								onClick={() =>
									history.push(
										`/customer-services/${CUSTOMER_SERVICE_CONSTANT.customerService}`
									)
								}>
								<Chat />
							</div>
							<div className="item" onClick={onClickAddToCart}>
								<Keranjang />
							</div>
							<div className="item right">
								<ButtonCustom
									fullWidth
									mdSize
									isDisabled={isDisabled}
									onClick={onSubmit}>
									{submitText}
								</ButtonCustom>
							</div>
						</div>
					)}
				</>
			);
		} else if (type === "checkout") {
			render = (
				<>
					<div className="items">
						<div className="item" style={{ width: "100%" }}>
							<div
								className="price"
								style={{
									display: "flex",
									justifyContent: "space-between",
									width: "100%",
									alignContent: "center",
									minHeight: "50px",
								}}>
								<div>
									<h5>Total ({count} Produk):</h5>
									<p>Belum termasuk ongkir</p>
								</div>
								<h5>Rp {formatRupiah(price)}</h5>
							</div>
							<div className="">
								<ButtonCustom
									fullWidth
									mdSize
									isDisabled={isDisabled}
									onClick={onSubmit}>
									{submitText}
								</ButtonCustom>
							</div>
						</div>
					</div>
				</>
			);
		} else if (type === "summary") {
			render = (
				<>
					<div className="items">
						<div className="" style={{ width: "100%" }}>
							<div
								className="price"
								style={{
									display: "flex",
									justifyContent: "space-between",
									width: "100%",
									alignContent: "center",
									minHeight: "50px",
								}}>
								<div>Total Pembayaran</div>
								<h5>Rp {formatRupiah(price)}</h5>
							</div>
							<div className="">
								<ButtonCustom
									fullWidth
									mdSize
									isDisabled={isDisabled}
									onClick={onSubmit}>
									{submitText}
								</ButtonCustom>
							</div>
						</div>
					</div>
				</>
			);
		}
		return render;
	};
	return (
		<WrapMenuBottom
			isFullWrapper={isFullWrapper}
			style={!isMobile ? { position: "relative", margin: "32px 0px" } : style}>
			{_render()}
		</WrapMenuBottom>
	);
};

export default MenuBottomComponent;
