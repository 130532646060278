import { Radio, withStyles, Button } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import constanta from "../../configs/constanta";
import { Layout } from "../../containers";
import { GET_PAYMENTS_METHOD } from "../../services/supportData.service";
import { handleSelectPayment, handleSubmitSelect } from "./handler";
import { WrapPaymentMethodPage } from "./style";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const PaymentMethodDesktopPage = (props) => {
	const [listPaymentMethod, setListPaymentMethod] = useState([]);
	const [isRefetch, setIsRefetch] = useState(false);

	const PinkRadio = withStyles({
		root: {
			color: `${constanta.COLOR.primary}`,
			"&$checked": {
				color: `${constanta.COLOR.primary}`,
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const isDisabledButton = listPaymentMethod.filter((x) => x.isChecked).length > 0 ? false : true;
	useEffect(() => {
		const fetch = async () => {
			let tempListPayment = [];
			let getListPayment = await GET_PAYMENTS_METHOD();
			getListPayment.data.map((item) => {
				tempListPayment.push({
					...item,
					isChecked: false,
				});
				return "";
			});
			setListPaymentMethod(tempListPayment);
			localStorage.setItem("PAYMENTS_METHOD", JSON.stringify(tempListPayment));
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			const getLocalStorage = JSON.parse(localStorage.getItem("PAYMENTS_METHOD"));
			setListPaymentMethod(getLocalStorage);
			setIsRefetch(false);
		};
		isRefetch && fetch();
	}, [isRefetch]);

	return (
		<Layout {...props}>
			<WrapPaymentMethodPage>
				<div style={{ paddingBottom: "24px", display: "flex", alignItems: "center" }}>
					<ArrowBackIcon
						color="primary"
						style={{ marginRight: "8px", cursor: "pointer" }}
						onClick={() => props.history.push("/checkout")}
					/>
					<p>Metode Pembayaran</p>
				</div>
				{!isEmpty(listPaymentMethod) &&
					listPaymentMethod.map((item, index) => (
						<div className="list" key={index}>
							<div className="logo" style={{ width: "110px" }}>
								<img src={item.bank.icon} alt={item.bank.name} />
							</div>
							<div className="info">
								<span>{item.bank.name}</span>
								<span>{item.account_number}</span>
								<span>{item.account_name}</span>
							</div>
							<div className="radioBtn">
								<PinkRadio
									checked={item.isChecked}
									onChange={() =>
										handleSelectPayment(
											item.id,
											listPaymentMethod,
											setListPaymentMethod,
											setIsRefetch
										)
									}
									value={item.service}
								/>
							</div>
						</div>
					))}
				<div style={{ margin: "32px 0px" }}>
					<Button
						size="large"
						style={{ width: "100%", color: "white" }}
						color="primary"
						variant="contained"
						disabled={isDisabledButton}
						onClick={() =>
							handleSubmitSelect(listPaymentMethod, setIsRefetch, props.history)
						}
					>
						Pilih Metode Pembayaran
					</Button>
				</div>
			</WrapPaymentMethodPage>
		</Layout>
	);
};
export default PaymentMethodDesktopPage;
