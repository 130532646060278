import _ from "lodash";

export const handleSelect = (id, state, setState, setIsRefetch) => {
	let cloneArray = state;
	let temp = [];
	cloneArray.map((item, index) => {
		temp.push({
			...item,
			isChecked: false,
		});
		return "";
	});
	const foundIndex = _.findIndex(cloneArray, { code: id });
	temp[foundIndex] = {
		...temp[foundIndex],
		isChecked: true,
	};
	localStorage.setItem("DROPSHIPPER", JSON.stringify(temp));
	setIsRefetch(true);
};

export const handleSubmitSelect = (state, setIsRefetch, history) => {
	localStorage.setItem("DROPSHIPPER", JSON.stringify(state));
	setIsRefetch(true);
	history.goBack();
};
