import { Grid, useMediaQuery } from "@material-ui/core";
import React from "react";
import { WrapFooter } from "./style";
import { ReactComponent as IconFb } from "../../assets/icons/iconFb.svg";
import { ReactComponent as IconInstagram } from "../../assets/icons/iconInstagram.svg";
import { ReactComponent as BadgeAppstore } from "../../assets/icons/badgeAppstore.svg";
import { ReactComponent as BadgeGoogleplay } from "../../assets/icons/badgeGoogleplay.svg";
import BrandBCA from "../../assets/brand/bca.svg";
import BrandMandiri from "../../assets/brand/mandiri.svg";
import BrandBRI from "../../assets/brand/bri.svg";
import BrandBNI from "../../assets/brand/bni.svg";
// import BrandOVO from "../../assets/brand/ovo.svg";
// import BrandGopay from "../../assets/brand/gopay.svg";
import jne from "../../assets/brand/jne.svg";
import jnt from "../../assets/brand/jnt.svg";
import sicepat from "../../assets/brand/sicepat.svg";
import ninja from "../../assets/brand/ninja.svg";

const FooterComponent = () => {
	const isMobile = useMediaQuery("(max-width:500px)");
	return (
		<WrapFooter>
			<div
				style={
					!isMobile
						? {
								maxWidth: 1220,
								position: "relative",
								margin: "0 auto",
								left: 0,
								right: 0,
						  }
						: {}
				}
			>
				<Grid container>
					<Grid item xs={4}>
						<div className="title">BANTUAN</div>
						<ul>
							<li>Cara Belanja</li>
							<a
								href="/faq"
								// target="_blank"
								// rel="noreferrer"
								style={{ textDecoration: "none", color: "black" }}
							>
								<li>FAQ (Tanya Jawab)</li>
							</a>

							<li>Tentang Naisha</li>
							<a
								href="/customer-services/2"
								// target="_blank"
								// rel="noreferrer"
								style={{ textDecoration: "none", color: "black" }}
							>
								<li>Hubungi Kami</li>
							</a>
						</ul>
					</Grid>
					<Grid item xs={4}>
						<div className="title">IKUTI KAMI</div>
						<ul>
							<li>
								<a
									href="https://www.facebook.com/naishaofficial/"
									target="_blank"
									rel="noreferrer"
									style={{ textDecoration: "none", color: "black" }}
								>
									<IconFb />
									Naisha Official Shop
								</a>
							</li>
							<li>
								<a
									href="https://www.instagram.com/naisha.katalog/?hl=en"
									target="_blank"
									rel="noreferrer"
									style={{ textDecoration: "none", color: "black" }}
								>
									<IconInstagram />
									naisha.katalog
								</a>
							</li>
						</ul>
						<div className="title" style={{ marginTop: 24 }}>
							Download Aplikasi Kami
						</div>
						<div className="badge">
							<a
								href="https://apps.apple.com/id/app/naisha/id1557506084?l=id"
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: "none", color: "black" }}
							>
								<BadgeAppstore />
							</a>

							<a
								href="https://play.google.com/store/apps/details?id=id.naisha.app"
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: "none", color: "black" }}
							>
								<BadgeGoogleplay />
							</a>
						</div>
					</Grid>

					<Grid item xs={4}>
						<div className="title">METODE PEMBAYARAN</div>
						<div className="badge">
							<img src={BrandBCA} alt="payment" />
							<img src={BrandMandiri} alt="payment" />
							<img src={BrandBRI} alt="payment" />
							<img src={BrandBNI} alt="payment" />
							{/* <img src={BrandOVO} alt="payment" />
							<img src={BrandGopay} alt="payment" /> */}
						</div>

						<div className="title" style={{ marginTop: 24 }}>
							Jasa Pengiriman
						</div>
						<div className="badge">
							<img src={jne} alt="pengiriman" />
							<img src={jnt} alt="pengiriman" />
							<img src={sicepat} alt="pengiriman" />
							<img src={ninja} alt="pengiriman" />
						</div>
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={12}>
						<div className="copyright">
							Copyright 2020 <span className="black">NAISHA</span> PT. NAISHA
							INSPIRASI MUSLIMAH all rights reserved.
						</div>
					</Grid>
				</Grid>
			</div>
		</WrapFooter>
	);
};

export default FooterComponent;
