import { axiosInstance } from "./axiosInstance";

export const GET_REWARDS = (filters) => {
	const url = `/api/reward/reward-omzet`;
	const response = axiosInstance.get(url);
	return response;
};

export const GET_OMZET = (id) => {
	const url = `/api/reward/user-omzet`;
	const response = axiosInstance.post(url, { user_id: id });
	return response;
};

export const REWARD_CLAIM = (body) => {
	const url = `/api/reward/reward-claim`;
	const response = axiosInstance.put(url, body);
	return response;
};

export const REWARD_CLAIMED = (body) => {
	const url = `/api/reward/reward-claimed`;
	const response = axiosInstance.post(url, body);
	return response;
};
