import { Radio, withStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ButtonCustom, Skeleton } from '../../components';
import constanta from '../../configs/constanta';
import { Layout, Navbar } from '../../containers';
import { handleSubmitSelect } from './handler';
import { WrapCustomerService } from './style';

const CustomerServiceMobilePage = (props) => {
  const {
    isLoading,
    isDisabled,
    selected,
    customerService,
    handleSelectCustomerService,
  } = props;

  const PinkRadio = withStyles({
    root: {
      color: `${constanta.COLOR.primary}`,
      '&$checked': {
        color: `${constanta.COLOR.primary}`,
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  return (
    <Layout withNavbar={false} {...props}>
      <Navbar type="navback" title="Pilih CS Support" {...props} />
      <WrapCustomerService>
        <div className="container">
          {isLoading ? (
            <Skeleton type="bulletList" />
          ) : (
            customerService.map((item, index) => (
              <div
                className="item"
                key={index}
                onClick={() => handleSelectCustomerService(index)}
              >
                <div className="logo">
                  <img
                    loading="lazy"
                    src={constanta.APP_HOST_NAME + '/' + item.photo}
                    alt={item.nama}
                  />
                </div>
                <div className="info">
                  <p>
                    {item.nama}{' '}
                    <span className="role">{`(${item.category.name})`}</span>
                  </p>
                  <p>{item.nomor}</p>
                </div>
                <div className="radioBtn">
                  <PinkRadio checked={selected === index} value={selected} />
                </div>
              </div>
            ))
          )}
        </div>
        <div className="floating">
          <ButtonCustom
            style={{ margin: '0px 8px' }}
            rounded
            isDisabled={isDisabled}
            onClick={() => handleSubmitSelect(customerService[selected].nomor)}
          >
            Pilih CS
          </ButtonCustom>
        </div>
      </WrapCustomerService>
    </Layout>
  );
};
export default CustomerServiceMobilePage;
