import React from "react";
import { CardAlamat } from "./style";
import { isEmpty } from "lodash";
import { ButtonCustom, EmptyState, Spacer } from "../../components";
import { LayoutWithAuth } from "../../containers";
import { Grid, Switch, Card, Button } from "@material-ui/core";
import { handleDeleteAlamat, setToPrimary } from "./handler";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const AlamatPageDesktop = (props) => {
	const { dataAlamat, setDataAlamat, setIsRefetch } = props;

	const _extractNewLineAlamatLengkap = (address) =>
		address.split("\n").map((text, key) => <p key={key}>{text}</p>);

	const _mappingAlamat = () =>
		dataAlamat.map((alamat, index) => (
			<CardAlamat key={index}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						paddingLeft: "12px",
						paddingRight: "12px",
					}}
				>
					<div className="label">
						<span className="bold">{alamat.label}</span> {alamat.isPrimary && `(Utama)`}
					</div>
					<div className="switchPrimary">
						<Switch
							checked={alamat.isPrimary}
							color="primary"
							onChange={(e) =>
								setToPrimary(
									index,
									e.target.checked,
									dataAlamat,
									setDataAlamat,
									setIsRefetch
								)
							}
						/>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						paddingLeft: "12px",
						paddingRight: "12px",
					}}
				>
					<div className="content">
						<p>
							{alamat.namaPenerima} | {alamat.noTelpPenerima}
						</p>
						{_extractNewLineAlamatLengkap(alamat.alamatLengkapPenerima)}
						<p>
							{alamat.selectSubdistrictName} - {alamat.selectCityType}{" "}
							{alamat.selectCityName}, {alamat.selectProvinceName} {alamat.kodePos}
						</p>
					</div>
				</div>
				<div className="container-actions">
					<div
						className="actions"
						style={{
							justifyContent: !alamat.isPrimary ? "space-around" : "center",
						}}
					>
						<div
							className="action"
							onClick={() => props.history.push(`/alamat/edit/${index}`)}
						>
							Ubah
						</div>
						{!alamat.isPrimary && (
							<>
								<div style={{ borderLeft: "1px solid #EEEEEE" }} />
								<div
									className="action"
									onClick={() =>
										handleDeleteAlamat(index, dataAlamat, setIsRefetch)
									}
								>
									Hapus
								</div>
							</>
						)}
					</div>
				</div>
			</CardAlamat>
		));
	return (
		<LayoutWithAuth {...props}>
			<div style={{ marginTop: "32px", minHeight: "calc(100vh - 227px)" }}>
				<div
					style={{
						padding: "14px",
						marginLeft: "8px",
						marginRight: "8px",
					}}
				>
					<div
						style={{
							borderBottom: "1px solid #EEEEEE",
							paddingBottom: "12px",
						}}
					>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ArrowBackIcon
								color="primary"
								style={{ marginRight: "8px", cursor: "pointer" }}
								onClick={() => props.history.push("/checkout")}
							/>
							<p>Alamat Pengiriman</p>
						</div>
					</div>
					<Spacer />
					<div style={{ width: "100%" }}>
						<Button
							size="large"
							style={{ width: "100%", color: "white" }}
							onClick={() => props.history.push("/alamat/add")}
							color="primary"
							variant="contained"
						>
							Tambah Alamat
						</Button>
					</div>
					<Spacer size="46" variant="vertical" />
					<div>
						{isEmpty(dataAlamat) ? (
							<EmptyState desc="Belum ada data penerima" />
						) : (
							<div>{_mappingAlamat()}</div>
						)}
					</div>
				</div>
			</div>
		</LayoutWithAuth>
	);
};

export default AlamatPageDesktop;
