import { Radio, withStyles, Button } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import constanta from "../../configs/constanta";
import { Layout } from "../../containers";
import { GET_MARKET_PLACE } from "../../services/supportData.service";
import { handleSelectMarketplace, handleSubmitSelect } from "./handler";
import { WrapPaymentMethodPage } from "./style";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const MarketPlaceDesktopPage = (props) => {
	const [listMarketplace, setMarketplace] = useState([]);
	const [isRefetch, setIsRefetch] = useState(false);

	const PinkRadio = withStyles({
		root: {
			color: `${constanta.COLOR.primary}`,
			"&$checked": {
				color: `${constanta.COLOR.primary}`,
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const isDisabledButton = listMarketplace.filter((x) => x.isChecked).length > 0 ? false : true;
	useEffect(() => {
		const fetch = async () => {
			let tempListPayment = [];
			let getListPayment = await GET_MARKET_PLACE();
			getListPayment.map((item) => {
				tempListPayment.push({
					...item,
					isChecked: false,
				});
				return "";
			});
			setMarketplace(tempListPayment);
			localStorage.setItem("MARKETPLACE", JSON.stringify(tempListPayment));
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			const getLocalStorage = JSON.parse(localStorage.getItem("MARKETPLACE"));
			setMarketplace(getLocalStorage);
			setIsRefetch(false);
		};
		isRefetch && fetch();
	}, [isRefetch]);

	return (
		<Layout {...props}>
			<WrapPaymentMethodPage>
				<div style={{ paddingBottom: "24px", display: "flex", alignItems: "center" }}>
					<ArrowBackIcon
						color="primary"
						style={{ marginRight: "8px", cursor: "pointer" }}
						onClick={() => props.history.push("/checkout")}
					/>
					<p>Marketplace</p>
				</div>
				{!isEmpty(listMarketplace) &&
					listMarketplace.map((item, index) => (
						<div className="list" key={index}>
							<div className="logo" style={{ width: "80px", height: "80px" }}>
								<img
									src={item.icon}
									alt={item.code}
									style={{ width: "100%", height: "100%" }}
								/>
							</div>
							<div className="info" style={{ display: "flex", alignItems: "center" }}>
								<span style={{ fontSize: "16px" }}>{item.name}</span>
							</div>
							<div className="radioBtn">
								<PinkRadio
									checked={item.isChecked}
									onChange={() =>
										handleSelectMarketplace(
											item.code,
											listMarketplace,
											setMarketplace,
											setIsRefetch,
										)
									}
									value={item.service}
								/>
							</div>
						</div>
					))}
				<div style={{ margin: "32px 0px" }}>
					<Button
						size="large"
						style={{ width: "100%", color: "white" }}
						color="primary"
						variant="contained"
						disabled={isDisabledButton}
						onClick={() =>
							handleSubmitSelect(listMarketplace, setIsRefetch, props.history)
						}
					>
						Pilih Marketplace
					</Button>
				</div>
			</WrapPaymentMethodPage>
		</Layout>
	);
};
export default MarketPlaceDesktopPage;
