import React, { useState, useEffect } from "react";

import { Spacer } from "../../components";
// import LayoutAccount from "../../containers/LayoutAccount";
import { Layout, Navbar } from "../../containers";
import { Typography, Card, Grid, Button, TextField, CircularProgress } from "@material-ui/core";
import NoImageProduct from "../../assets/img/noImage.svg";
import swal from "sweetalert";
import { GET_VOUCHERS, 	UNCLAIM_VOUCHER, } from "../../services/voucher.service";
import { ChipContainedPrimary } from "../../components/chips";
import { formatRupiah } from "../../utils/currencyFormat";
import moment from "moment";
import CashbackIMG from "../../assets/icons/cashbackIMG.png";
import DiskonIMG from "../../assets/icons/diskonIMG.png";
import OngkirIMG from "../../assets/icons/gratisOngkirIMG.png";

const KuponSayaMobile = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [filter, setFilter] = useState({
		type: "ongkir",
		is_purchasable: "0",
		name: "",
		claimed: true,
	});

	const retrieveVoucher = (filter) => {
		setIsLoading(true);
		GET_VOUCHERS(filter)
			.then((res) => {
				setData(res.data);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		retrieveVoucher(filter);
	}, [filter]);

	const setFilterByVoucher = (data) => {
		setFilter((prevState) => {
			return {
				...prevState,
				type: data.value,
			};
		});
	};

	const onChangePurchaseAble = (event) => {
		setFilter((prevState) => {
			return {
				...prevState,
				is_purchasable: event.target.value,
			};
		});
	};

	const handleSearch = (event) => {
		setFilter((prevState) => {
			return {
				...prevState,
				name: event.target.value,
			};
		});
	};

	const removeVoucher = async (detail) => {
		swal({
			title: "Lepas Voucher ?",
			text: "apakah kamu yakin ingin lepas voucher ini sekarang ?",
			buttons: {
				cancel: "Batal",
				ok: "Ok",
			},
		}).then((value) => {
			if (value === "ok") {
				handleRemoveVoucher(detail.id);
			}
		});
	};

	const handleRemoveVoucher = (id) => {
		setIsLoading(true);
		UNCLAIM_VOUCHER(id)
			.then((res) => {
				setData(res.data);
				swal("Selamat !", "Sukses melepaskan voucher", "success");
				retrieveVoucher();
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const listPromo = [
		{
			value: "ongkir",
			label: "Gratis Ongkir",
		},
		{
			value: "discount",
			label: "Diskon",
		},
		{
			value: "cashback",
			label: "Cashback",
		},
	];

	const renderImage = (type) => {
		let img;
		if (type === "cashback") {
			img = CashbackIMG;
		} else if (type === "discount") {
			img = DiskonIMG;
		} else if (type === "ongkir") {
			img = OngkirIMG;
		} else {
			img = NoImageProduct;
		}
		return (
			<img
				src={img}
				alt="product"
				style={{
					width: "130px",
					height: "130px",
					borderRadius: "10px",
				}}
			/>
		);
	};

	const renderContent = (data) => {
		let content;
		if (isLoading) {
			content = (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "300px",
					}}
				>
					<CircularProgress />
				</div>
			);
		} else {
			if (data && data.length > 0) {
				content = (
					<Grid container spacing={2}>
						{data.map((item, key) => {
							return (
								<React.Fragment key={`claim-${key}`}>
									<Grid item xs={12}>
										<Card
											style={{ minHeight: "165px", width: "100%" }}
											className="card-outlined"
										>
											<div style={{ display: "flex", padding: "14px" }}>
												{renderImage(filter.type)}
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														paddingLeft: "14px",
														width: "100%",
													}}
												>
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<Typography
															style={{
																fontSize: "14px",
																fontWeight: "bold",
															}}
														>
															{item.name}
														</Typography>
														<Typography
															style={{
																fontSize: "14px",
																fontWeight: "bold",
															}}
														>
															Rp {formatRupiah(item.amount)}
														</Typography>
													</div>
													<Typography
														style={{
															fontSize: "12px",
															color: "#EC4F42",
														}}
													>
														Min. Belanja Rp{" "}
														{formatRupiah(item.minimum_payments)}
													</Typography>
													<Typography
														style={{
															fontSize: "12px",
															color: "#5E5FEF",
														}}
													>
														Berlaku Hingga
														{moment(item.end_date).format(
															"DD MMMM YYYY",
														)}
													</Typography>
													<Button
															style={{
																marginTop: "12px",
																color: item.is_claimed
																	? "black"
																	: "white",
															}}
															size="small"
															variant={
																item.is_claimed
																	? "outlined"
																	: "contained"
															}
															color="primary"
															onClick={() => {
																if (item.is_claimed) {
																	removeVoucher(item);
																} 
															}}
														>
															{item.is_claimed ? "lepas" : "pakai"}{" "}
															voucher
														</Button>
												</div>
											</div>
										</Card>
									</Grid>
								</React.Fragment>
							);
						})}
					</Grid>
				);
			}
		}
		return content;
	};

	return (
		<Layout withNavbar={false} {...props}>
			<Navbar type="navback" title="Kupon Saya" {...props} />
			<Card className="card-custom" style={{ paddingTop: "64px" }}>
				<div
					style={{
						padding: "14px",
						minHeight: "calc(100vh - 227px)",
						marginLeft: "8px",
						marginRight: "8px",
					}}
				>
					<div
						style={{
							borderBottom: "1px solid #EEEEEE",
							paddingBottom: "12px",
						}}
					>
						<div
							style={{
								display: "flex",
								// flexDirection: "column",
								justifyContent: "space-between",
								alignItems: "center",
								maxHeight: "42px",
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									// flexDirection: "column",
									justifyContent: "center",
								}}
							>
								<div>
									<TextField
										style={{ width: "100%" }}
										placeholder="Cari Kupon"
										variant="outlined"
										size="small"
										onKeyPress={(event) => {
											if (event.key === "Enter") {
												event.preventDefault();
												handleSearch(event);
											}
										}}
										onBlur={handleSearch}
									/>
								</div>
								<Spacer />
								<div>
									<TextField
										style={{ paddingBottom: "7px" }}
										select
										placeholder="Select Type"
										SelectProps={{
											native: true,
										}}
										margin="normal"
										fullWidth
										variant="outlined"
										size="small"
										value={filter.is_purchasable}
										// disabled={isFetching}
										onChange={onChangePurchaseAble}
									>
										<option value={"0"}>Gratis</option>
										<option value={"1"}>Berbayar</option>
									</TextField>
								</div>
							</div>
						</div>
					</div>
					<Spacer />
					<div style={{ display: "flex" }}>
						{listPromo.map((item, index) => {
							return (
								<React.Fragment key={index}>
									<ChipContainedPrimary
										text={item.label}
										isActive={filter.type === item.value}
										onClickChip={() => {
											setFilterByVoucher(item);
										}}
									/>
									<Spacer />
								</React.Fragment>
							);
						})}
					</div>
					<Spacer />
					{renderContent(data)}
				</div>
			</Card>
		</Layout>
	);
};

export default KuponSayaMobile;
