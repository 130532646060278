import { axiosInstance } from "./axiosInstance";

export const LOGIN = (body) => {
	const url = "/api/login";
	const data = axiosInstance.post(url, body);
	return data;
};

export const REGISTER = (body) => {
	const url = "/api/register";
	const data = axiosInstance.post(url, body);
	return data;
};

export const REGISTER_AFFILIATE = (body) => {
	const url = "/api/register-affiliate";
	const data = axiosInstance.post(url, body);
	return data;
};

export const LOGIN_GOOGLE = (body) => {
	const url = "/api/login/google";
	const data = axiosInstance.post(url, body);
	return data;
};

export const RESET_PASSWORD_EMAIL = (body) => {
	const url = `/api/password/email`;
	const data = axiosInstance.post(url, body);
	return data;
};

export const VALIDATE_TOKEN = (body) => {
	const url = `/api/password/token`;
	const data = axiosInstance.post(url, body);
	return data;
};

export const RESET_PASSWORD_USER = (body) => {
	const url = `/api/password/reset`;
	const data = axiosInstance.post(url, body);
	return data;
};
