import { useMediaQuery } from "@material-ui/core";
import React from "react";
import ResellerCardPagesDesktop from "./ResellerCardPages.desktop";
import ResellerCardPagesMobile from "./ResellerCardPages.mobile";

const ResellerCardPages = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return (
      isMobile ? <ResellerCardPagesMobile {...props} /> : <ResellerCardPagesDesktop {...props} />
  );
};

export default ResellerCardPages;
