import { Grid, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Footer } from "../../components";
import { Navbar } from "../index";
import { WrapLayout } from "./style";
import { useAuthContext } from "../../contexts/Auth.context";
import FloatingActionButton from "../../components/FAB";
import { withRouter } from "react-router";

const LayoutWithAuth = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	const { isAuthenticated } = useAuthContext();
	const { withNavbar = true, withContainer = true } = props;

	const isContainer = withContainer
		? {
				maxWidth: 1220,
				position: "relative",
				margin: "0 auto",
				left: 0,
				right: 0,
		  }
		: {};

	React.useEffect(() => {
		if (isAuthenticated !== null) {
			if (!isAuthenticated) {
				window.location = "/login";
			}
		}
	}, [isAuthenticated]);

	let user = JSON.parse(localStorage.getItem("USER_NAISHA"));

	const renderFloatingButton = () => {
		const { pathname } = props.location;
		if (user) {
			switch (pathname) {
				case "/checkout":
				case "/payment":
				case "/confirm-payment":
					return null;
				default:
					return (
						<FloatingActionButton redirect={() => props.history.push("/kupon-saya")} />
					);
			}
		} else {
			return null;
		}
	};

	return (
		<WrapLayout>
			{withNavbar && (
				<Grid container>
					<Grid item xs={12}>
						<Navbar {...props} />
					</Grid>
				</Grid>
			)}
			<Grid container>
				<Grid item xs={12}>
					<div style={!isMobile ? isContainer : {}}>{props.children}</div>
				</Grid>
			</Grid>

			{!isMobile && (
				<Grid container>
					<Grid item xs={12}>
						<Footer />
					</Grid>
				</Grid>
			)}
			{/* {renderFloatingButton()} */}
		</WrapLayout>
	);
};

export default withRouter(LayoutWithAuth);
