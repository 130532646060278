import { isEmpty } from "lodash";
import React from "react";
import { Layout, Navbar } from "../../containers";
import { useOrderContext } from "../../contexts/Order.context";
import { formatRupiah } from "../../utils/currencyFormat";
import { WrapPaymentPage } from "./style";
import iconCopy from "../../assets/icons/iconCopy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ButtonCustom } from "../../components";
import moment from "moment";
import constanta from "../../configs/constanta";

const getTotalPayment = (payment) => {
	return payment.total + payment.shipping.cost - payment.payment.code_unique;
};

const PaymentPageMobile = (props) => {
	const { payment } = useOrderContext();
	let expired =
		payment && payment.payment !== undefined
			? moment(payment.payment.expired_at).format("DD MMM YYYY HH:mm")
			: "";
	return (
		<Layout withNavbar={false} {...props}>
			<Navbar type="navback" title="Pembayaran" {...props} />
			<WrapPaymentPage>
				<div className="info">
					{expired && (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								textAlign: "center",
							}}
						>
							<p
								style={{ fontSize: "18px", marginTop: 20 }}
							>{`Instruksi Pembayaran`}</p>
							<p style={{ fontSize: "16px", marginTop: 10 }}>
								Transfer sesuai nominal di bawah ini:
							</p>
							<div className="total">
								<p>Rp {!isEmpty(payment) && formatRupiah(getTotalPayment(payment))}</p>
							</div>
						</div>
					)}
				</div>

				<div className="warning">
					<p>
						PENTING! Mohon transfer tepat sampai 3 angka terakhir agar donasi anda lebih mudah  diverifikasi
					</p>
				</div>

				<div className="info">
					<div className="details">
						<div className="detail">
							<div className="text">Total Belanja</div>
							<div className="price">
								Rp {!isEmpty(payment) && formatRupiah(payment.payment.amount + payment.payment.code_unique)}
							</div>
						</div>
						{/* <div className="detail">
							<div className="text">Promo Diskon</div>
							<div className="price">Rp 0</div>
						</div> */}
						<div className="detail">
							<div className="text">Biaya Pengiriman</div>
							<div className="price">
								Rp {!isEmpty(payment) && formatRupiah(payment.shipping.cost)}
							</div>
						</div>
						<div className="detail">
							<div className="text">Kode Unik</div>
							<div className="price">
								{!isEmpty(payment) && formatRupiah(payment.payment.code_unique)}
							</div>
						</div>
					</div>
				</div>
				<div className="metode">
					{!isEmpty(payment) && (
						<div>
							<div className="name">
								<p>
									Pembayaran dilakukan ke rekening a/n{" "}
								</p>
								<span>{payment.payment.payment_method.account_name}</span>
							</div>
							<div className="list">
								<div className="logo">
									<img
										src={payment.payment.payment_method.bank.icon}
										alt={payment.payment.payment_method.bank.name}
									/>
								</div>
								<div className="info">
									<span>{payment.payment.payment_method.account_number}</span>
								</div>
								<CopyToClipboard text={payment.payment.payment_method.account_number}>
									<div className="copyBtn">
										{/* <img src={iconCopy} alt="copy" /> */}
										<span>Salin</span>
									</div>
								</CopyToClipboard>
							</div>
						</div>
					)}
				</div>
				<div className="info">
					{expired && (
						<div
						>
							<p style={{ fontSize: "14px" }}>
								{`Transfer sebelum ${expired} WIB atau pesanan kamu otomatis dibatalkan oleh sistem.`}
							</p>
						</div>
					)}
				</div>

				<div className="bottomButton">
					<ButtonCustom
						mdSize
						onClick={() => props.history.push("/confirm-payment")}>
						Saya Sudah Transfer
					</ButtonCustom>
				</div>
			</WrapPaymentPage>
		</Layout>
	);
};
export default PaymentPageMobile;
