import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IconNext from "../../assets/icons/icNext.svg";
import IconPrev from "../../assets/icons/icPrev.svg";
import { WrapProductSlider } from "./style";
import { useMediaQuery } from "@material-ui/core";

const ProductSliderComponent = (props) => {
  const { children, customSettings, refSlider } = props;
  const isMobile = useMediaQuery("(max-width:500px)");
  function NextArrow(props) {
    return (
      <div onClick={props.onClick} className="arrow-right">
        <img src={IconNext} alt="next" />
      </div>
    );
  }

  function PrevArrow(props) {
    return (
      <div onClick={props.onClick} className="arrow-left">
        <img src={IconPrev} alt="next" />
      </div>
    );
  }
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: !isMobile ? 5 : 2,
    variableWidth: true,
    nextArrow: !isMobile ? <NextArrow /> : null,
    prevArrow: !isMobile ? <PrevArrow /> : null,
  };
  return (
    <WrapProductSlider>
      {customSettings !== undefined ? (
        <Slider ref={slider => refSlider(slider)} {...customSettings}>{children}</Slider>
      ) : (
        <Slider {...settings}>{children}</Slider>
      )}
    </WrapProductSlider>
  );
};

export default ProductSliderComponent;
