import { Radio, withStyles } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { ButtonCustom } from "../../components";
import constanta from "../../configs/constanta";
import { Layout, Navbar } from "../../containers";
import { COURIR_DROPSHIPPER } from "../../services/supportData.service";
import { handleSelect, handleSubmitSelect } from "./handler";
import { WrapPaymentMethodPage } from "./style";

const DropshipperMobilePage = (props) => {
	const [listDropshipper, setDropshipper] = useState([]);
	const [isRefetch, setIsRefetch] = useState(false);

	const PinkRadio = withStyles({
		root: {
			color: `${constanta.COLOR.primary}`,
			"&$checked": {
				color: `${constanta.COLOR.primary}`,
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const isDisabledButton = listDropshipper.filter((x) => x.isChecked).length > 0 ? false : true;
	useEffect(() => {
		const fetch = async () => {
			let tempListPayment = [];
			let getListPayment = await COURIR_DROPSHIPPER();
			getListPayment.data.map((item) => {
				tempListPayment.push({
					...item,
					isChecked: false,
				});
				return "";
			});
			setDropshipper(tempListPayment);
			localStorage.setItem("DROPSHIPPER", JSON.stringify(tempListPayment));
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			const getLocalStorage = JSON.parse(localStorage.getItem("DROPSHIPPER"));
			setDropshipper(getLocalStorage);
			setIsRefetch(false);
		};
		isRefetch && fetch();
	}, [isRefetch]);

	return (
		<Layout withNavbar={false} {...props}>
			<Navbar type="navback" title="Pilih Metode Pembayaran" {...props} />
			<WrapPaymentMethodPage>
				{!isEmpty(listDropshipper) &&
					listDropshipper.map((item, index) => (
						<div className="list" key={index}>
							<div className="logo">
								<img src={item.icon} alt={item.name} />
							</div>
							<div className="info">
								<span>{item.name}</span>
							</div>
							<div className="radioBtn">
								<PinkRadio
									checked={item.isChecked}
									onChange={() =>
										handleSelect(
											item.code,
											listDropshipper,
											setDropshipper,
											setIsRefetch,
										)
									}
									value={item.service}
								/>
							</div>
						</div>
					))}
				<div className="floating">
					<ButtonCustom
						style={{ margin: "0px 8px" }}
						rounded
						isDisabled={isDisabledButton}
						onClick={() =>
							handleSubmitSelect(listDropshipper, setIsRefetch, props.history)
						}
					>
						Pilih Marketplace
					</ButtonCustom>
				</div>
			</WrapPaymentMethodPage>
		</Layout>
	);
};
export default DropshipperMobilePage;
