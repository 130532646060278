import styled from "styled-components";

export const WrapPhotoCard = styled.div`
	position: relative;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0px 2px 15px rgba(0, 18, 30, 0.06);
	margin-right: 11px;
	cursor: pointer;

	.image {
		img {
			width: 100%;
			height: 100%;
			border-radius: 4px;
		}
		height: 100%;
	}
`;

