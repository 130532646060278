import React, { useState, useEffect } from "react";
import { WrapProductCard } from "./style";
import NoImageProduct from "../../assets/img/noImage.svg";
import IconDiscount from "../../assets/icons/icDiscount.svg";
import { useMediaQuery } from "@material-ui/core";
import { formatRupiah } from "../../utils/currencyFormat";
import { useHistory } from "react-router";
import StarIMG from "../../assets/icons_1/star_1.svg";

const ProductCardComponent = ({
	name = "Name Product",
	image_url = NoImageProduct,
	price = 999999,
	discount,
	slug = "slug",
	role,
	isFlashSale = false,
	flashsalePrice,
}) => {
	const [resultDiscount, setResulDiscount] = useState(0);
	const [isHaveDiscount, setIsHaveDiscount] = useState(false);

	let history = useHistory();
	const isMobile = useMediaQuery("(max-width:500px)");

	const getPercentDiscount = (beforePrice, currentPrice) => {
		let resultPrice = (((beforePrice - currentPrice) / beforePrice) * 100).toFixed(0);

		if (parseInt(resultPrice) !== 0) {
			setIsHaveDiscount(true);
			setResulDiscount(parseInt(resultPrice));
		}
	};

	useEffect(() => {
		if (price && discount) getPercentDiscount(discount, price);
	}, [price, discount]);

	return (
		<div style={{ width: isMobile ? "144px" : "227px", margin: isMobile ? "4px 0" : "0 0 18px 0" }}>
			<WrapProductCard
				style={{ height: isMobile ? "232px" : "340px" }}
				onClick={() => history.push(`/product/${slug}`)}
			>
				<div className="image">
					{role === "Customer" ? null : (
						<>
							{discount === 0 ? null : (
								<>
									{isHaveDiscount && (
										<div className="discount">
											<div className="text">Komisi</div>
											<div className="total">{resultDiscount}%</div>
											<img src={IconDiscount} alt="discount" />
										</div>
									)}
								</>
							)}
						</>
					)}

					<img src={image_url ?? NoImageProduct} alt="product" />
				</div>
				<div className="desc">
					<div className="title">
						<h4>{name}</h4>
					</div>
					<div className="price">
						<p>Rp {formatRupiah(price)}</p>
					</div>
					{discount === 0 ? null : (
						<>
							{isHaveDiscount && (
								<>
									<div className="discount">
									<div className="percentage">
										{resultDiscount}%
									</div>
									<div className="price-strike">Rp {formatRupiah(discount)}</div>
								</div>
								</>
							)}
						</>
					)}
				</div>
			</WrapProductCard>
		</div>
	);
};

export default ProductCardComponent;
