import { axiosInstance } from "./axiosInstance";

const isHaveAmpersand = (params) => {
	let lastCharacter = params.slice(-1);
	let newCharacter = lastCharacter === "?" ? "" : "&";

	return newCharacter;
};

export const GET_VOUCHERS = (filter = {}) => {
	let name = filter.name !== undefined ? `?filter[name]=${filter.name}` : "";
	let type = filter.type ? `&filter[type]=${filter.type}` : "";
	let claimed = filter.claimed ? `&filter[claimed]=${filter.claimed}` : "";
	let is_purchasable = filter.is_purchasable
		? `&filter[is_purchasable]=${filter.is_purchasable}`
		: "";

	const url = `/api/vouchers${name}${is_purchasable}${type}${claimed}`;
	const response = axiosInstance.get(url);
	return response;
};

export const GET_DETAIL_VOUCHER = (filter = {}) => {
	let id = filter.include ? `?filter[id]=${filter.id}` : "";
	let include = filter.include ? `&include=${filter.include}` : "";

	const url = `/api/vouchers${id}${include}`;
	const response = axiosInstance.get(url);
	return response;
};

export const CLAIM_VOUCHER = (id) => {
	const url = `/api/vouchers/${id}/claim`;
	const response = axiosInstance.post(url, {});
	return response;
};

export const UNCLAIM_VOUCHER = (id) => {
	const url = `/api/vouchers/${id}/unclaim`;
	const response = axiosInstance.delete(url, {});
	return response;
};

export const CHECKOUT_VOUCHER = (id) => {
	const url = `/api/vouchers/${id}/checkout`;
	const response = axiosInstance.post(url, {});
	return response;
};
