import styled from "styled-components";

export const WrapPaymentMethodPage = styled.div`
	position: relative;
	padding-top: 32px;
	min-height: calc(100vh - 227px);
	.list {
		display: flex;
		.logo {
			width: 30%;
			padding: 4px 16px;
			img {
				width: 100%;
			}
		}
		.info {
			position: relative;
			margin-left: 8px;
			width: 60%;
			span {
				width: 100%;
				display: inline-block;
				color: #333;
				margin: 0;
				padding: 0;
				line-height: 12px;
				:nth-child(1) {
					font-size: 10px;
				}
				:nth-child(2) {
					font-size: 14px;
					font-family: "DMSans-Bold";
				}
				:nth-child(3) {
					font-size: 10px;
				}
			}
		}
		.radioBtn {
			width: 10%;
			position: relative;
			top: 10px;
		}
	}
	.floating {
		position: fixed;
		bottom: 16px;
		width: 100%;
	}
`;
