import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapButton = styled.div`
	button {
		cursor: pointer;
		position: relative;
		border: none;
		padding: 12px 16px;
		border-radius: 5px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: ${(props) => (props.isMobile ? `14px` : `20px`)};
		font-family: "DMSans-Regular";
		outline: none;
		:focus {
			outline: none;
		}
		svg {
			padding-right: 10px;
		}
	}
	.primary {
		button {
			background: ${constanta.COLOR.primary};
			:disabled {
				background: grey;
				color: black;
			}
		}
	}
	.secondary {
		button {
			background: ${constanta.COLOR.lightPink};
			color: ${constanta.COLOR.textPrimary};
			font-size: 14px;
			font-weight: 400;
			:disabled {
				background: #e8e8e8;
			}
		}
	}
	.lightBlue {
		button {
			background: ${constanta.COLOR.lightBlue};
			color: ${constanta.COLOR.text};
			font-size: 14px;
			font-weight: 400;
			:disabled {
				background: #e8e8e8;
			}
		}
	}
	.select {
		button {
			font-size: 12px;
			color: #666;
			border: 1px solid #dddddd;
			border-radius: 5px;
			background: none;
			width: auto;
			:focus {
				background: ${constanta.COLOR.primary};
				color: #fff;
				border: 1px solid ${constanta.COLOR.primary};
			}
		}
		.active {
			button {
				background: ${constanta.COLOR.darkPink};
				color: #fff;
				border: 1px solid ${constanta.COLOR.darkPink};
			}
		}
	}
`;
