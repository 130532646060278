import React from "react";
import NoImageProduct from "../../assets/img/noImage.svg";
import { formatRupiah } from "../../utils/currencyFormat";
import { useHistory } from "react-router";
import { WrapProductCardFull } from "./style";
import { ButtonCustom, QuantityCounter } from "..";
import { Checkbox, Card } from "@material-ui/core";
import { ReactComponent as IconTrashGray } from "../../assets/icons/iconTrashGray.svg";
import Spacer from "../Spacer";
import { useMediaQuery } from "@material-ui/core";
import { Divider, OutlinedInput } from "@material-ui/core";
import { PreOrder } from "../../components";

const ProductCardFull = ({
	type = "default",
	name = "Name Product",
	image_url = NoImageProduct,
	price = 999999,
	discount = "",
	slug = "slug",
	color = "color",
	size = "size",
	onDelete = null,
	handleMinus = null,
	handlePlus = null,
	quantity = 1,
	stock = "",
	weight = 0,
	withQuantityButton = true,
	withDeleteButton = true,
	withCheckbox = true,
	isChecked = false,
	handleChecked = null,
	withBorderBottom = false,
	referral = "",
	is_preorder = 0,
	day_preorder = 0,
	style,
}) => {
	let history = useHistory();

	const getTotalPrice = (quantity, price) => {
		let total = 0;
		total = quantity * price;
		return total;
	};

	const isMobile = useMediaQuery("(max-width:500px)");
	const _render = () => {
		if (type === "default") {
			return (
				<>
					<div className="">
						<div className="container">
							{withCheckbox && (
								<div className="checkbox">
									<Checkbox
										color="primary"
										checked={isChecked}
										onChange={handleChecked}
									/>
								</div>
							)}
							<div className="image">
								<img
									src={image_url ?? NoImageProduct}
									alt={`product-${slug}`}
								/>
							</div>
							<div className="wrapper-content">
								<h4 className="title">{name}</h4>
								{/* <div className="tags">
									<div className="tag">{color}</div>
									<div className="tag">{size}</div>
								</div> */}
								<div className="price">
									<p>Rp {formatRupiah(price)}</p>
								</div>
								{discount && (
									<div className="discount">
										<span>{price}</span>
									</div>
								)}
							</div>

							<div className="stock">
								<p> {stock} Tersisa</p>
							</div>

							{!isMobile && (
								<>
									{(withQuantityButton || withDeleteButton) && (
										<div className="wrapper-action">
											{withQuantityButton && (
												<div className="quantity">
													<QuantityCounter
														onClickMinus={handleMinus}
														onClickPlus={handlePlus}
														total={quantity}
													/>
												</div>
											)}
											{withDeleteButton && (
												<div className="delete" onClick={onDelete}>
													<IconTrashGray />
												</div>
											)}
										</div>
									)}
								</>
							)}
						</div>
						{is_preorder !== 0 && <PreOrder days={day_preorder} />}

						{isMobile && (
							<>
								{(withQuantityButton || withDeleteButton) && (
									<div className="wrapper-action">
										{withQuantityButton && (
											<div className="quantity">
												<QuantityCounter
													onClickMinus={handleMinus}
													onClickPlus={handlePlus}
													total={quantity}
												/>
											</div>
										)}
										{withDeleteButton && (
											<div className="delete" onClick={onDelete}>
												<IconTrashGray />
											</div>
										)}
									</div>
								)}
							</>
						)}
					</div>
				</>
			);
		} else if (type === "checkout") {
			return (
				<>
					<div
						style={{
							padding: "12px",
							borderBottom: withBorderBottom ? "1px solid #DDD" : "none",
							backgroundColor: "#fff",
							borderRadius: "4px",
						}}>
						<div style={{ display: "flex" }}>
							<div
								className="image"
								style={{ paddingLeft: 0, borderRadius: 4, overflow: "hidden" }}>
								<img
									src={image_url ?? NoImageProduct}
									alt={`product-${slug}`}
								/>
							</div>
							<div className="wrapper-content" style={{ paddingTop: "0" }}>
								<h4 className="title" style={{ margin: 0 }}>
									{name}
								</h4>
								<div
									className="tags"
									style={{ fontSize: 14, color: "#7F8E9D" }}>
									{color}, {size}
								</div>
								<div style={{ display: "flex", alignItems: "center" }}>
									<div className="price">
										<p>Rp {formatRupiah(price)}</p>
									</div>
									{/* <div className="gram">
									{`${quantity} Barang (${weight * quantity} gram)`}
								</div> */}
								</div>
								{is_preorder !== 0 && <PreOrder days={day_preorder} />}
								<div className="stock">
									<p>Sisa 3</p>
								</div>

								{stock && (
									<div className="stock">
										<p>Sisa {stock}</p>
									</div>
								)}
								{discount && (
									<div className="discount">
										<span>{price}</span>
									</div>
								)}
								{referral && (
									<div className="referral">
										<span>Referral: {referral}</span>
									</div>
								)}
							</div>
						</div>
						<Divider />
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div className="price">
								<p>Total Order ({quantity})</p>
							</div>
							<div className="price">
								<p>Rp {formatRupiah(price * quantity)}</p>
							</div>
							{/* <div className="gram" style={{ marginLeft: "24px" }}>
								<span>x</span>
								{` ${quantity} `}
							</div> */}
							{/* <div className="gram">
								{`${quantity} Barang (${weight * quantity} gram)`}
							</div> */}
						</div>
					</div>
				</>
			);
		} else if ((type = "cart")) {
			return (
				<>
					<div
						className="card-custom cart"
						style={{
							boxShadow: "none",
							padding: "6px 12px",
							color: "#1E3354",
						}}>
						<div
							className="container"
							style={{ marginTop: 0, paddingBottom: 12 }}>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "top",
								}}>
								{withCheckbox && (
									<div className="checkbox">
										<Checkbox
											color="primary"
											checked={isChecked}
											onChange={handleChecked}
										/>
									</div>
								)}
								<div className="image">
									<img
										src={image_url ?? NoImageProduct}
										alt={`product-${slug}`}
									/>
								</div>
							</div>
							<div className="wrapper-content">
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}>
									<h4 className="title">{name}</h4>
								</div>
								<div className="price">
									<p>Rp {formatRupiah(price)}</p>
									{discount && (
										<div className="discount">
											<span>{price}</span>
										</div>
									)}
								</div>
								{is_preorder !== 0 && <PreOrder days={day_preorder} />}
								{!isMobile ? (
									<div className="tags">
										<div className="tag">{color}</div>
										<div className="tag">{size}</div>
									</div>
								) : (
									<ButtonCustom>
										Varian: {color} {size}
									</ButtonCustom>
								)}
								{isMobile && (
									<div className="wrapper-action">
										{withQuantityButton && (
											<div className="quantity">
												<QuantityCounter
													onClickMinus={handleMinus}
													onClickPlus={handlePlus}
													total={quantity}
												/>
											</div>
										)}
										{withDeleteButton && (
											<div className="delete" onClick={onDelete}>
												<IconTrashGray />
											</div>
										)}
									</div>
								)}
							</div>
							{!isMobile && (
								<div className="wrapper-action">
									{withQuantityButton && (
										<div className="quantity">
											<QuantityCounter
												onClickMinus={handleMinus}
												onClickPlus={handlePlus}
												total={quantity}
											/>
										</div>
									)}
									{withDeleteButton && (
										<div className="delete" onClick={onDelete}>
											<IconTrashGray />
										</div>
									)}
								</div>
							)}
						</div>
						{/* {isMobile && (
							<>
								<div className="wrapper-action">
									{withQuantityButton && (
										<div className="quantity">
											<QuantityCounter
												onClickMinus={handleMinus}
												onClickPlus={handlePlus}
												total={quantity}
											/>
										</div>
									)}
									{withDeleteButton && (
										<div className="delete" onClick={onDelete}>
											<IconTrashGray />
										</div>
									)}
								</div>
							</>
						)} */}
					</div>
				</>
			);
		}
	};
	return (
		<>
			<WrapProductCardFull>{_render()}</WrapProductCardFull>
			<Spacer />
		</>
	);
};

export default ProductCardFull;
