import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapOrderCard = styled.div`
	.container {
		padding: 12px;
	}
	.card {
		box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		margin-bottom: 12px;
		.text-primary {
			font-size: 14px;
			color: ${constanta.COLOR.textPrimary};
		}
		.price {
			display: flex;
			justify-content: space-between;
			padding-bottom: 8px;
			p {
				font-size: 14px;
				color: #666666;
			}
			span {
				font-size: 14px;
				color: ${constanta.COLOR.textPrimary};
				font-family: "DMSans-Bold";
			}
		}

		.total {
			border-bottom: 1px solid #dddddd;
			display: flex;
			justify-content: space-between;
			padding-bottom: 10px;
			margin-top: -15px;
			p {
				font-size: 14px;
				color: #666666;
			}
			span {
				font-size: 14px;
				color: ${constanta.COLOR.text};
				font-family: "Inter-Bold";
			}
		}

		.details {
			padding-bottom: 8px;
			border-bottom: 1px solid rgb(221, 221, 221);
			p {
				color: #666666;
				font-size: 10px;
			}
			.resi {
				display: flex;
				justify-content: space-between;
				margin-top: 10px;
				span {
					font-size: 10px;
					font-weight: bold;
				}
			}
		}
		.tracking {
			display: flex;
			justify-content: space-between;
			padding: 10px 0;
			border-bottom: 1px solid rgb(221, 221, 221);
			p {
				color: #4db6ac;
				font-size: 10px;
				text-overflow: ellipsis;
				max-width: 90%;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}
`;
