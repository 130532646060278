import React from "react";

const EditProfileDesktop = (props) => {

	return (
		<>
		Edit Profile
		</>
	);
};

export default EditProfileDesktop;
