import {
	Radio,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import constanta from "../../configs/constanta";
import { Layout, Navbar } from "../../containers";
import { EST_SHIPPING_COST } from "../../services/supportData.service";
import { formatRupiah } from "../../utils/currencyFormat";
import {
	getLowestPrice,
	getTotalWeight,
	handleClickSubmit,
	handleSelectService,
	showServices,
} from "./handler";
import { WrapShippingPage, WrapShippingService } from "./style";
import { ButtonCustom } from "../../components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ShippingPageMobile = (props) => {
	const [couriers, setCouriers] = useState([]);
	const [isRefetch, setIsRefetch] = useState(false);

	const PinkRadio = withStyles({
		root: {
			color: `${constanta.COLOR.primary}`,
			"&$checked": {
				color: `${constanta.COLOR.primary}`,
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const _mappingShipping = () => {
		let render = [];
		couriers.map((item, index) => {
			render.push(
				<div key={index} className="services">
					<div
						onClick={() =>
							showServices(index, setCouriers, couriers, setIsRefetch)
						}>
						<div className="title" style={{ textTransform: "uppercase" }}>
							{item.logistic_name}
						</div>
						{item.min_day && item.max_day && (
							<span className="desc">
								Estimasi {item.min_day}
								{" - "}
								{item.max_day} hari
							</span>
						)}
					</div>
					<div className="right">
						{item.rate === 0 ? null : ( // <div className="desc">Cash On Delivery</div>
							<div className="price">Rp {formatRupiah(item.rate ?? 0)}</div>
						)}
						<div className="checkbox">
							<PinkRadio
								checked={item.isChecked}
								onChange={(e) =>
									handleSelectService(
										e,
										index,

										setCouriers,
										couriers,
										setIsRefetch
									)
								}
								value={item.service}
							/>
						</div>
					</div>
				</div>
			);
			return "";
		});
		return render;
	};

	const _mappingShippingDistributor = () => {
		let render = [];
		for (const key in couriers) {
			if (couriers.hasOwnProperty(key)) {
				render.push(
					<Accordion key={key} defaultExpanded={true}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							{key}
						</AccordionSummary>

						{couriers[key].map((item, index) => {
							return (
								<AccordionDetails key={`setCourier-${index}`}>
									<hr />
									<div className="services">
										<div
											onClick={() =>
												showServices(index, setCouriers, couriers, setIsRefetch)
											}>
											<div
												className="title"
												style={{ textTransform: "uppercase" }}>
												{item.logistic_name}
											</div>
											{item.rate === 0 ? null : ( // <div className="desc">Cash On Delivery</div>
												<div className="desc">
													Rp {formatRupiah(item.price ?? 0)}
												</div>
											)}
											{item.etd_from && item.etd_thru && (
												<span>
													Estimasi {item.etd_from}
													{" - "}
													{item.etd_thru} hari
												</span>
											)}
										</div>
										<div className="checkbox">
											<PinkRadio
												checked={item.isChecked}
												onChange={(e) =>
													handleSelectService(
														e,
														index,

														setCouriers,
														couriers,
														setIsRefetch
													)
												}
												value={item.service}
											/>
										</div>
									</div>
								</AccordionDetails>
							);
						})}
					</Accordion>
				);
			}
		}
		return <>{render}</>;
	};

	const _mappingService = (array, index) => {
		let render = [];
		array.map((item, indexService) => {
			render.push(
				<div className={`services`} key={indexService}>
					<div className="checkbox">
						<PinkRadio
							checked={item.isChecked}
							onChange={(e) =>
								handleSelectService(
									e,
									index,
									indexService,
									setCouriers,
									couriers,
									setIsRefetch
								)
							}
							value={item.service}
						/>
					</div>
					{item.cost[0].note === "COD" ? (
						<>
							<div className="title">{item.service} </div>
							<div className="desc">Ambil Pesanan di toko</div>
						</>
					) : (
						<>
							<div className="title">
								{item.service}{" "}
								<span className="primary">
									Rp {formatRupiah(item.cost[0].value)}
								</span>
							</div>
							<div className="desc">Estimasi tiba {item.cost[0].etd} hari</div>
						</>
					)}
				</div>
			);
			return "";
		});
		return render;
	};

	const isDisabledButton = () => {
		let isDisabled = true;

		couriers.map((courier) => {
			if (courier.services.filter((x) => x.isChecked).length !== 0) {
				isDisabled = false;
			}
			return "";
		});
		return isDisabled;
	};

	useEffect(() => {
		const fetch = async () => {
			let getSelectedAlamat = JSON.parse(
				localStorage.getItem("ALAMAT_CUSTOMER")
			).filter((x) => x.isPrimary)[0];
			let getCart = JSON.parse(localStorage.getItem("CART")).filter(
				(x) => x.isChecked
			);

			let getCouriers = JSON.parse(localStorage.getItem("SHIPPING_SERVICES"));
			if (getCouriers) {
				setCouriers(getCouriers);
			}
			// // let getCouriers = await GET_COURIERS()
			// let getEstCost = await EST_SHIPPING_COST({
			// 	destination_subdistrict: getSelectedAlamat.selectSubdistrict,
			// 	destination_city: getSelectedAlamat.selectCity,
			// 	weight_gram: getTotalWeight(getCart),
			// });

			// let featureCOD = {
			// 	code: "COD",
			// 	costs: [
			// 		{
			// 			service: "Ambil di toko",
			// 			description: "Cash on delivery",
			// 			cost: [{ value: 0, etd: "", note: "COD" }],
			// 		},
			// 	],
			// 	name: "COD",
			// };

			// let resultShipping = [featureCOD, ...getEstCost.data];

			// let tempCouriers = [];
			// const assignObject = (array) => {
			// 	let temp = [];
			// 	array.map((cost) => {
			// 		temp.push({
			// 			...cost,
			// 			isChecked: false,
			// 		});
			// 		return "";
			// 	});
			// 	return temp;
			// };
			// await resultShipping.map((item, index) => {
			// 	tempCouriers.push({
			// 		...item,
			// 		services: assignObject(item.costs),
			// 		isHidden: true,
			// 	});
			// 	return "";
			// });
			// setCouriers(tempCouriers);
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setIsRefetch(false);
		};
		fetch();
	}, [isRefetch]);

	return (
		<>
			<Layout withNavbar={false} {...props}>
				<Navbar type="navback" title="Pilih Pengiriman" {...props} />
				<WrapShippingPage>
					<div style={{ paddingTop: 60, marginBottom: 60 }}>
						<WrapShippingService>
							{!isRefetch && _mappingShipping()}
						</WrapShippingService>
					</div>
					<div className="floating">
						<ButtonCustom
							mdSize
							onClick={() => handleClickSubmit(couriers, props.history)}>
							Pilih Pengiriman
						</ButtonCustom>
					</div>
				</WrapShippingPage>
			</Layout>
		</>
	);
};

export default ShippingPageMobile;
