import React, { useEffect, useState } from "react";
import LayoutAccount from "../../containers/LayoutAccount";
import { isEmpty } from "lodash";
import ImgEmpty from "../../assets/img/empty.svg";
import SkeletonProductList from "../../assets/img/skeletonProductList.svg";
import FileIcon from "../../assets/icons/iconFile.svg";
import { EmptyState, Skeleton } from "../../components";
import OrderCard from "../../components/OrderCard/OrderCard.component";

import { getStages, getUserProductByStage } from "./handler";

const OrderPageDesktop = (props) => {
	const [isLoading, setLoading] = useState(false);
	const [orders, setOrders] = useState([]);
	const { handleClick } = props;

	useEffect(() => {
		setOrders([]);
		const fetchOrder = async () => {
			setLoading(true);
			const response = await getUserProductByStage({
				stage: getStages(props.match.params.type),
			});
			setOrders(response.data);
			setLoading(false);
		};
		fetchOrder();
	}, [props.match.params.type]);

	const emptyState = () => {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "500px",
				}}
			>
				<img src={FileIcon} alt="kosong" style={{ width: "200px", height: "200px" }} />
				<p style={{ paddingTop: "12px" }}>Belum ada pesanan</p>
			</div>
		);
	};

	return (
		<LayoutAccount {...props}>
			<div
				style={{
					minHeight: "calc(100vh - 227px)",
					marginLeft: "8px",
					marginRight: "8px",
				}}
			>
				{isLoading ? (
					<img
						src={SkeletonProductList}
						alt="loading-product"
						style={{ height: "600px", width: "100%" }}
					/>
				) : isEmpty(orders) ? (
					<>{emptyState()}</>
				) : (
					<>
						{orders.map((value, index) => {
							return (
								<OrderCard
									key={index}
									orders={value}
									handleClick={(type, payload) => {
										handleClick(type, payload);
									}}
								/>
							);
						})}
					</>
				)}
			</div>
		</LayoutAccount>
	);
};

export default OrderPageDesktop;
