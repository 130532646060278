import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Typography, CircularProgress, Grid, Card, Button } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import NoImageProduct from "../../assets/img/noImage.svg";
import CloseIcon from "@material-ui/icons/Close";
import { GET_VOUCHERS } from "../../services/voucher.service";
import { formatRupiah } from "../../utils/currencyFormat";
import Constanta from "../../configs/constanta";
import CashbackIMG from "../../assets/icons/cashbackIMG.png";
import DiskonIMG from "../../assets/icons/diskonIMG.png";
import OngkirIMG from "../../assets/icons/gratisOngkirIMG.png";
import PaymentIMG from "../../assets/icons_1/payment.svg";
import DateIMG from "../../assets/icons_1/date.svg";
import moment from "moment";

export default function KuponModal({ open, handleClose, detail }) {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [usedKupon, setUsedKupon] = useState({});

	const retrieveVoucher = () => {
		setIsLoading(true);
		GET_VOUCHERS({
			name: "",
			claimed: true,
		})
			.then((res) => {
				setData(res.data);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		let kupon = JSON.parse(localStorage.getItem("USE_VOUCHER"));
		if (kupon) {
			let filter = data.map((item) => item.id ===  kupon.id);
			setUsedKupon(kupon);
			setData(filter);
		}
		retrieveVoucher();

	}, []);

	const usedVoucher = (voucher) => {
		localStorage.setItem("USE_VOUCHER", JSON.stringify(voucher));
		setUsedKupon(voucher);
		retrieveVoucher();
	};

	const unusedVoucher = (voucher) => {
		let kupon = JSON.parse(localStorage.getItem("USE_VOUCHER"));

		if (kupon.id === voucher.id) {
			localStorage.removeItem("USE_VOUCHER");
			setUsedKupon({});
			retrieveVoucher();
		}
	};

	const renderImage = (type) => {
		let img;
		if (type === "cashback") {
			img = CashbackIMG;
		} else if (type === "discount") {
			img = DiskonIMG;
		} else if (type === "ongkir") {
			img = OngkirIMG;
		} else {
			img = NoImageProduct;
		}
		return (
			<img
				src={img}
				alt="product"
				style={{
					width: "70px",
					height: "70px",
					borderRadius: "10px",
				}}
			/>
		);
	};

	const getType = (type) => {
		let text;
		if (type === "cashback") {
			text = "Cashback";
		} else if (type === "discount") {
			text = "Diskon";
		} else if (type === "ongkir") {
			text = "Gratis Ongkir";
		} else {
			text = "";
		}
		return text;
	};

	const renderContent = (data) => {
		let content;
		if (isLoading) {
			content = (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "300px",
					}}
				>
					<CircularProgress />
				</div>
			);
		} else {
			if (data && data.length > 0) {
				content = (
					<Grid container spacing={2}>
						{data.map((item, key) => {
							return (
								<React.Fragment key={`claim-${key}`}>
									<Grid item xs={12}>
										<Card
											style={{  width: "100%", border: "none" }}
											className="card-outlined"
										>
											<div style={{ display: "flex", padding: "14px" }}>
												{/* {renderImage(item.type)} */}
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														paddingLeft: "10px",
														flexGrow: 1,
														// width: "100%",
													}}
												>
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
													
														<Typography
															style={{
																fontSize: "14px",
																fontWeight: "bold",
															}}
														>
															{getType(item.type)}{" Rp "}{formatRupiah(item.amount)}
														</Typography>
													</div>
													<Typography
														style={{
															fontSize: "12px",
															color: "#7F8E9D",
														}}
													>
														{formatRupiah(item.name)}
													</Typography>
													<div style={{ display: "flex", alignItems: "center" }}>
													<img
															src={PaymentIMG}
															alt="product"
															style={{
																width: "12px",
																height: "12px",
																marginRight: "5px",
															}}
														/>
													<Typography
														style={{
															fontSize: "12px",
															color: "#7F8E9D",
														}}
													>
														Min. Pembelian Rp{" "}
														{formatRupiah(item.minimum_payments)}
													</Typography>
													</div>
													{item.end_date && (
														<div style={{ display: "flex", alignItems: "center" }}>
														<img
																src={DateIMG}
																alt="product"
																style={{
																	width: "12px",
																	height: "12px",
																	marginRight: "5px",
																}}
															/>
														<Typography
															style={{
																fontSize: "12px",
																color: "#7F8E9D",
															}}
														>
															Valid {" "}
															{moment(item.end_date).format("DD MMMM YYYY")}
														</Typography>
														</div>
													)}
													{/* <Typography
														style={{
															fontSize: "14px",
															fontWeight: "bold",
														}}
													>
														Rp {formatRupiah(item.amount)}
													</Typography> */}
												</div>
												<div  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
													<Button
														style={{
															marginLeft: "10px",
															color:
																item.id === usedKupon.id
																	? Constanta.COLOR.primary
																	: "white",
														}}
														size="small"
														variant={
															item.id === usedKupon.id
																? "outlined"
																: "contained"
														}
														color="primary"
														onClick={() => {
															if (item.id === usedKupon.id) {
																unusedVoucher(item);
															} else {
																usedVoucher(item);
															}
														}}
													>
														{item.id === usedKupon.id
															? "Lepas"
															: "Pakai"}
													</Button>
												</div>
											</div>
										</Card>
									</Grid>
								</React.Fragment>
							);
						})}
					</Grid>
				);
			}
		}
		return content;
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={"md"}
				fullWidth
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle
					id="alert-dialog-title"
					disableTypography
					style={{
						fontWeight: "bold",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						margin: "0",
						backgroundColor: "#EFF8FC",
					}}
				>
					<Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
						Kupon
					</Typography>
					<CloseIcon onClick={handleClose} />
				</DialogTitle>
				<DialogContent style={{ height: "400px", overflow: "auto", backgroundColor: "#EFF8FC" }}>
					{renderContent(data)}
				</DialogContent>
			</Dialog>
		</div>
	);
}
