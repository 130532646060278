import { axiosInstance } from "./axiosInstance";
import axios from "axios";

export const GET_USER = (id, token) => {
  const url = `/api/users/${id}`;
  const response = axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });
  return response;
};
export const REGENERATE_TOKEN = (token) => {
  const url = `/api/customer/regenerate-token`;
  const response = axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ` + token,
      //   Accept: "application/json",
    },
  });
  return response;
};

export const GET_PROFILE = (id) => {
  const url = `/api/users/${id}`;
  const response = axiosInstance.get(url);
  return response;
};

export const PUT_PROFILE = (id, body) => {
  const url = `/api/users/${id}`;

  const res = axiosInstance.put(url, body);
  return res;
};

export const PUT_PHOTO_PROFILE = (id, form, token) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/users/${id}/profile-photo`;

  const response = axios({
    method: "post",
    url: url,
    data: form,
    headers: {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ` + token,
    },
  });

  return response;
  // const response = axiosInstance.post(url, {
  // 	headers: {
  // 		Authorization: `Bearer ` + token,
  // 	},
  // });
  // return response;
};
