import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapProductCardFull = styled.div`
	.container {
		display: flex;
		align-items: center;
		color: ${constanta.COLOR.text};
	}
	.image {
		border-radius: 4px;
		overflow: hidden;
		display: flex;
		align-items: center;
		padding-left: 12px;
		width: 66px!important;
		img {
			width: 66px !important;
			height: 66px !important;
		}
	}
	.wrapper-content {
		padding-left: 24px;
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.wrapper-action {
		padding-left: 24px;
		padding-top: 12px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: space-between;
	}
	.title {
		font-size: 18px;
		text-overflow: ellipsis;
		font-weight: normal;
		overflow: hidden;
		white-space: nowrap;
		margin: 4px 0px;
		max-width: 500px;
		color: ${constanta.COLOR.text};
		font-family: "Inter-Bold";
	}
	.stock {
		p {
			font-size: 12px;
			color: ${constanta.COLOR.strk};
		}
	}
	.price p {
		font-size: 14px;
		color: ${constanta.COLOR.text};
		font-family: "Inter-Regular";
		margin: 8px 0px;
	}
	.discount {
		color: #959595;
		font-size: 13px;
		font-family: "DMSans-Regular";
		text-decoration: line-through;
	}
	.tags {
		display: flex;
		margin-top: 4px;
	}
	.tag {
		font-size: 12px;
		color: #666;
		border: 1px solid #ededed;
		border-radius: 5px;
		padding: 4px 8px;
		background: #ededed;
		width: auto;
		margin-right: 8px;
		text-transform: uppercase;
		:focus {
			background: ${constanta.COLOR.darkPink};
			color: #fff;
			border: 1px solid ${constanta.COLOR.darkPink};
		}
	}
	.delete {
		// margin-top: 12px;
		padding-right: 6px;
	}
	.quantity {
		padding: 12px;
		border: 1px solid #f1f1f1;
		border-radius: 8px;
	}
	.checkbox {
		display: flex;
		align-items: center;
		.MuiIconButton-root {
			padding: 0;
		}
	}
	.gram {
		font-size: 20px;
		// color: ${constanta.COLOR.textPrimary};
		font-family: "DMSans-Bold";
		margin: 8px 0px;
	}
	

	@media (max-width: 768px) {
		.wrapper-action {
			padding-left: 0px;
			padding-right: 20px;
			padding-bottom: 12px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		.title {
			font-size: 16px;
			text-overflow: ellipsis;
			font-weight: normal;
			overflow: hidden;
			white-space: nowrap;
			margin: 0px 0px;
			max-width: 170px;
		}
		.quantity {
			padding: 0;
			border-left: none;
			border-right: none;
			border-radius: 0;
	}
`;
