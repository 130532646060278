import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery, Card, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import { ReactComponent as LihatSemua } from "../../assets/icon-img/promo.svg";
import { ReactComponent as Favorit } from "../../assets/icon-img/favorit.svg";
import { ReactComponent as Chat } from "../../assets/icon-img/chat.svg";
import { ReactComponent as Order } from "../../assets/icon-img/order.svg";

import PromoIMG from "../../assets/icons_1/home_promo.svg";
import FavoritIMG from "../../assets/icons_1/home_favorit.svg";
import KategoriIMG from "../../assets/icons_1/home_kategori.svg";
import OrderIMG from "../../assets/icons_1/home_pesanan.svg";
import ChatIMG from "../../assets/icons_1/Chat.svg";
import TicketIMG from "../../assets/icons_1/TicketStar.svg";
import CartIMG from "../../assets/icons_1/Buy.svg";
import TanggaIMG from "../../assets/icons_1/trend_up_01.svg";
import KoinIMG from "../../assets/icons_1/coins_01.svg";
import InfoIMG from "../../assets/icon-img/clarity_help-info-solid.svg";
import CoinIMG from "../../assets/icon-img/coin.svg";
import HadiahIMG from "../../assets/icon-img/eva_gift-fill.svg";
import InfoModal from "./info-modal";
import ClaimModal from "./claim-modal";
import { CUSTOMER_SERVICE_CONSTANT } from "../../configs/constanta";
import constanta from "../../configs/constanta";
import Progressbar from "../../components/Progress";
import { formatRupiah } from "../../utils/currencyFormat";
import {
	ContainerIconMobile,
	ContainerIconCircleMobile,
	ContainerIconBasicMobile,
} from "./container-icon";
import { SearchCustom } from "../../components";
import { GET_OMZET } from "../../services/rewards.service";
import queryString, { parse } from "query-string";
import { useOrderContext } from "../../contexts/Order.context";
import { isEmpty } from "lodash";

const MenuSection = (props) => {
	const { isAuthenticated } = props;
	const [isOpenInfo, setIsOpenInfo] = useState(false);
	const [isOpenClaim, setIsOpenClaim] = useState(false);
	// const [isLoading, setIsLoading] = useState(false);
	const [level, setLevel] = useState({});
	const [saldo, setSaldo] = useState(0);
	const [search, setSearch] = useState("");
	const isMobile = useMediaQuery("(max-width:500px)");
	const [coin, setCoin] = useState(0);
	const { cart } = useOrderContext();
	let getFromCart = JSON.parse(localStorage.getItem("CART"));
	let parsed;
	if (props.location) {
		parsed = queryString.parse(props.location.search);
	}

	const toggleInfoModal = () => {
		setIsOpenInfo(!isOpenInfo);
	};

	const toggleClaimModal = () => {
		setIsOpenClaim(!isOpenClaim);
	};

	const containerStyle = {
		maxWidth: 1220,
		position: "relative",
		margin: "0 auto",
		left: 0,
		right: 0,
	};

	useEffect(() => {
		// setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		setCoin(user ? user.coin : 0);
		setSaldo(user ? user.saldo : 0);
		if (user) {
			GET_OMZET(user.id)
				.then((res) => {
					setLevel(res.data);
				})
				.catch((err) => {})
				.finally(() => {
					// setIsLoading(false);
				});
		}
	}, []);

	let percentage = 0;

	if (level) {
		if (level && level.omzet_level) {
			percentage = Math.ceil(
				(level.omzet_total / level.omzet_level.maximum_omzet) * 100
			);
		}
	}
	let result = percentage > 100 ? 100 : percentage;

	const handleSearchProduct = (event) => {
		setSearch(event);
		if (event !== "") {
			window.location.href = `/products?nama=${event}`;
		} else {
			window.location.href = `/products`;
		}
	};

	useEffect(() => {
		if (!isEmpty(parsed)) {
			setSearch(parsed.name);
		}
	}, []);

	return (
		<>
			<div
				style={{
					...containerStyle,
					marginBottom: 12,
					background: `${constanta.COLOR.primary}`,
					minHeight: 264,
					borderRadius: 8,
				}}>
				<div
					style={{
						padding: "40px 21px 0",
						display: "flex",
						justifyContent: "space-between",
						background: constanta.COLOR.primary,
					}}>
					<SearchCustom
						placeholder="Cari apa saja"
						handleSearch={(event) => handleSearchProduct(event.target.value)}
					/>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<ContainerIconBasicMobile
							onClickIcon={() => {
								props.history.push(
									`/customer-services/${CUSTOMER_SERVICE_CONSTANT.customerService}`
								);
							}}
							img={ChatIMG}
							text="Chat Naisha"
						/>
						<ContainerIconBasicMobile
							onClickIcon={() => {
								props.history.push(
									`/customer-services/${CUSTOMER_SERVICE_CONSTANT.customerService}`
								);
							}}
							img={TicketIMG}
							text="Ticket Star"
						/>
						<ContainerIconBasicMobile
							onClickIcon={() => {
								props.history.push(`/cart`);
							}}
							img={CartIMG}
							text="Cart"
						/>
						{!isEmpty(getFromCart) && (
							<span
								style={{
									fontSize: "10px",
									color: "#FFF",
									backgroundColor: "#FF0000",
									padding: "3px",
									borderRadius: "30px",
									height: "12px",
									width: "12px",
									textAlign: "center",
									position: "relative",
									right: "16px",
									top: "4px",
								}}>
								{getFromCart.length}
							</span>
						)}
					</div>
				</div>
				<Grid
					container
					spacing={3}
					style={{
						marginTop: 32,
						position: "relative",
						marginBottom: 32,
						paddingBottom: 20,
					}}>
					<Grid
						item
						xs={12}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							marginLeft: "21px",
							marginRight: "21px",
						}}>
						<ContainerIconCircleMobile
							onClickIcon={() => {
								props.history.push(`/promo`);
							}}
							img={PromoIMG}
							text="Promo"
						/>
						<ContainerIconCircleMobile
							onClickIcon={() => {
								props.history.push("/orders/0");
							}}
							img={OrderIMG}
							text="Pesanan"
						/>
						{/* AI: add href for favorit */}
						<ContainerIconCircleMobile img={FavoritIMG} text="Favorit" />
						<ContainerIconCircleMobile
							img={KategoriIMG}
							onClickIcon={() => {
								props.history.push(`/kategori`);
							}}
							text="Katagori"
						/>
					</Grid>
				</Grid>
			</div>
			<Grid
				item
				xs={12}
				style={{
					marginLeft: "20px",
					marginRight: "20px",
					marginTop: "-40px",
					position: "relative",
				}}>
				<Card
					style={{
						height: "100%",
						display: "flex",
						borderRadius: 12,
						background: "#fff",
						height: 83,
						boxShadow: "0px 8px 40px rgba(0, 0, 0, 0.1)",
					}}>
					<Grid container>
						<Grid item xs={12} style={{ padding: 0 }}>
							<div
								style={{
									padding: "13px",
									display: "flex",
								}}>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										flexGrow: 1,
									}}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}>
										<img
											src={TanggaIMG}
											alt="gambar-tangga"
											style={{
												paddingRight: "6px",
											}}
										/>
										<Typography
											variant="body2"
											component="span"
											style={{
												fontWeight: 600,
												color: "#333333",
												paddingRight: "6px",
											}}>
											{`Level ${
												level && level.omzet_level
													? level.omzet_level.level
													: "0"
											}`}
										</Typography>

										<Typography
											variant="body2"
											component="span"
											style={{
												fontWeight: "bold",
											}}
											color="primary">
											{`${
												level && level.omzet_level
													? `Ke ${level.omzet_level.level + 1}`
													: ""
											}`}
										</Typography>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
										}}>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												paddingTop: "6px",
											}}>
											<Typography
												variant="body2"
												style={{
													fontSize: "10px",
													color: "#B9B9B9",
													paddingRight: "6px",
													width: "35px",
												}}>
												Omset
											</Typography>
											<div>
												<Typography
													variant="body2"
													color="#222741"
													style={{
														fontSize: "12px",
														fontWeight: "bold",
														paddingRight: "4px",
													}}>
													{level && level.omzet_total
														? `Rp ${formatRupiah(level.omzet_total)}`
														: "Rp 0 "}
												</Typography>
											</div>
										</div>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												padding: "2px 0 6px 0",
											}}>
											<Typography
												variant="body2"
												style={{
													fontSize: "10px",
													color: "#B9B9B9",
													paddingRight: "6px",
													width: "35px",
												}}>
												Saldo
											</Typography>
											<div>
												<Typography
													variant="body2"
													color="#222741"
													style={{
														fontSize: "12px",
														fontWeight: "bold",
														paddingRight: "4px",
													}}>
													{saldo ? `Rp ${formatRupiah(saldo)}` : "Rp 0 "}
												</Typography>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} style={{ padding: 0 }}>
							<div
								style={{
									padding: "13px 13px 13px 0px",
									display: "flex",
								}}>
								<div
									style={{
										paddingLeft: "13px",
										height: "41px",
										borderLeft: `1px solid #E2E0E0`,
									}}></div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										flexGrow: 1,
									}}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}>
										<img
											src={KoinIMG}
											alt="gambar-tangga"
											style={{
												paddingRight: "6px",
											}}
										/>
										<Typography
											variant="body2"
											component="span"
											style={{
												fontWeight: 600,
												color: "#333333",
												paddingRight: "6px",
											}}>
											Koin {coin ? `${formatRupiah(coin)}` : "0"}
										</Typography>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
										}}>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "space-between",
												marginTop: "6px",
											}}>
											<div
												onClick={() => {
													props.history.push("/hadiah/progress");
												}}>
												<img
													src={HadiahIMG}
													alt="gambar-tangga"
													height={14}
													style={{
														padding: "6px",
														background: `${constanta.COLOR.primary}`,
														borderRadius: "4px",
													}}
												/>
												<Typography
													variant="body2"
													style={{
														fontSize: "8px",
														color: "#333",
														paddingRight: "6px",
														marginTop: "-4px",
														textAlign: "center",
													}}>
													Hadiah
												</Typography>
											</div>
											<div
												onClick={() => {
													setIsOpenInfo(true);
												}}>
												<img
													src={InfoIMG}
													alt="gambar-tangga"
													height={14}
													style={{
														padding: "6px",
														background: `${constanta.COLOR.primary}`,
														borderRadius: "4px",
													}}
												/>
												<Typography
													variant="body2"
													style={{
														fontSize: "8px",
														color: "#333",
														paddingRight: "6px",
														marginTop: "-4px",
														textAlign: "center",
													}}>
													Info
												</Typography>
											</div>
											<div
												onClick={() => {
													setIsOpenClaim(true);
												}}>
												<img
													src={CoinIMG}
													alt="gambar-tangga"
													height={14}
													style={{
														padding: "6px",
														background: `${constanta.COLOR.primary}`,
														borderRadius: "4px",
													}}
												/>
												<Typography
													variant="body2"
													style={{
														fontSize: "8px",
														color: "#333",
														paddingRight: "6px",
														marginTop: "-4px",
														textAlign: "center",
													}}>
													Klaim
												</Typography>
											</div>
											<div></div>
										</div>
										{/* <Progressbar value={result} /> */}
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<InfoModal open={isOpenInfo} handleClose={toggleInfoModal} />
			<ClaimModal open={isOpenClaim} handleClose={toggleClaimModal} />
		</>
	);
};

export default withRouter(MenuSection);
