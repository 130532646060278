import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery, Card, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import { ReactComponent as LihatSemua } from "../../assets/icon-img/promo.svg";
import { ReactComponent as Favorit } from "../../assets/icon-img/favorit.svg";
import { ReactComponent as Chat } from "../../assets/icon-img/chat.svg";
import { ReactComponent as Order } from "../../assets/icon-img/order.svg";

import PromoIMG from "../../assets/icon-img/promo.svg";
import FavoritIMG from "../../assets/icon-img/favorit.svg";
import ChatIMG from "../../assets/icon-img/chat.svg";
import OrderIMG from "../../assets/icon-img/order.svg";
import TanggaIMG from "../../assets/icon-img/mdi_stairs-up.svg";
import InfoIMG from "../../assets/icon-img/clarity_help-info-solid.svg";
import HadiahIMG from "../../assets/icon-img/eva_gift-fill.svg";
import CoinIMG from "../../assets/icon-img/coin.svg";
import InfoModal from "./info-modal";
import ClaimModal from "./claim-modal";
import { CUSTOMER_SERVICE_CONSTANT } from "../../configs/constanta";
import constanta from "../../configs/constanta";
import Progressbar from "../../components/Progress";
import { formatRupiah } from "../../utils/currencyFormat";
import { ContainerIcon, ContainerIconCircle } from "./container-icon";
import { GET_OMZET } from "../../services/rewards.service";
import MenuSectionMobile from "./menu-section-mobile";

const MenuSection = (props) => {
	const { isAuthenticated } = props;
	const [isOpenInfo, setIsOpenInfo] = useState(false);
	const [isOpenClaim, setIsOpenClaim] = useState(false);
	// const [isLoading, setIsLoading] = useState(false);
	const [level, setLevel] = useState({});
	const [saldo, setSaldo] = useState(0);
	const isMobile = useMediaQuery("(max-width:500px)");

	const toggleInfoModal = () => {
		setIsOpenInfo(!isOpenInfo);
	};

	const toggleClaimModal = () => {
		setIsOpenClaim(!isOpenClaim);
	};

	const containerStyle = {
		maxWidth: 1220,
		position: "relative",
		margin: "0 auto",
		left: 0,
		right: 0,
	};

	useEffect(() => {
		// setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		setSaldo(user && user.saldo ? user.saldo : 0);

		if (user) {
			GET_OMZET(user.id)
				.then((res) => {
					setLevel(res.data);
				})
				.catch((err) => {})
				.finally(() => {
					// setIsLoading(false);
				});
		}
	}, []);

	let percentage = 0;

	if (level) {
		if (level && level.omzet_level) {
			percentage = Math.ceil(
				(level.omzet_total / level.omzet_level.maximum_omzet) * 100
			);
		}
	}
	let result = percentage > 100 ? 100 : percentage;

	return (
		<>
			{isMobile ? (
				<MenuSectionMobile />
			) : (
				<div
					style={
						!isMobile
							? {
									...containerStyle,
									marginTop: 32,
									marginBottom: 12,
							  }
							: { marginTop: 32, marginBottom: 12 }
					}>
					<Grid container spacing={3} style={{ marginTop: 16 }}>
						<Grid
							item
							xs={6}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<ContainerIconCircle
								onClickIcon={() => {
									props.history.push(`/promo`);
								}}
								img={PromoIMG}
								text="Promo"
							/>
							<ContainerIconCircle
								img={ChatIMG}
								onClickIcon={() => {
									props.history.push(
										`/customer-services/${CUSTOMER_SERVICE_CONSTANT.customerService}`
									);
								}}
								text="Naisha Chat"
							/>
							<ContainerIconCircle
								onClickIcon={() => {
									props.history.push("/orders/0");
								}}
								img={OrderIMG}
								text="Pesanan Saya"
							/>
							<ContainerIconCircle img={FavoritIMG} text="Favorit Saya" />
						</Grid>

						<Grid item xs={6}>
							<Card
								style={{ height: "100%", display: "flex" }}
								className="card-custom-outlined">
								<Grid
									container
									style={{
										paddingRight: "14px",
										paddingLeft: "14px",
									}}>
									<Grid item xs={8} style={{ padding: 0 }}>
										<div
											style={{
												padding: "12px",
												display: "flex",
											}}>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													flexGrow: 1,
												}}>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														paddingBottom: "8px",
													}}>
													<img
														src={TanggaIMG}
														alt="gambar-tangga"
														style={{
															paddingRight: "6px",
														}}
													/>
													<Typography
														variant="body2"
														component="span"
														style={{
															fontWeight: "bold",
															color: "#333333",
															paddingRight: "6px",
														}}>
														{`Level ${
															level && level.omzet_level
																? level.omzet_level.level
																: "0"
														}`}
													</Typography>

													<Typography
														variant="body2"
														component="span"
														style={{
															fontWeight: "bold",
														}}
														color="primary">
														{`${
															level && level.omzet_level
																? `Ke ${level.omzet_level.level + 1}`
																: ""
														}`}
													</Typography>
												</div>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
													}}>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															paddingBottom: "6px",
														}}>
														<Typography
															variant="body2"
															component="span"
															style={{
																fontSize: "14px",
																fontWeight: "bold",
																color: "black",
																paddingRight: "6px",
															}}>
															Saldo
														</Typography>
														<div>
															<Typography
																variant="body2"
																component="span"
																color="primary"
																style={{
																	fontSize: "14px",
																	fontWeight: "bold",
																	paddingRight: "6px",
																}}>
																{saldo ? `Rp ${formatRupiah(saldo)}` : "Rp 0 "}
															</Typography>
														</div>
													</div>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															paddingBottom: "6px",
														}}>
														<Typography
															variant="body2"
															component="span"
															style={{
																fontSize: "14px",
																fontWeight: "bold",
																color: "black",
																paddingRight: "6px",
															}}>
															Omset
														</Typography>
														<div>
															<Typography
																variant="body2"
																component="span"
																color="primary"
																style={{
																	fontSize: "14px",
																	fontWeight: "bold",
																	paddingRight: "6px",
																}}>
																{level && level.omzet_total
																	? `Rp ${formatRupiah(level.omzet_total)}`
																	: "Rp 0 "}
																/
															</Typography>
															<Typography
																variant="body2"
																component="span"
																color="primary"
																style={{
																	fontSize: "14px",
																	fontWeight: "bold",
																	color: "#333",
																	paddingRight: "6px",
																}}>
																{level && level.omzet_level
																	? `Rp ${formatRupiah(
																			level.omzet_level.maximum_omzet
																	  )}`
																	: "0"}
															</Typography>
														</div>
													</div>
													<Progressbar value={result} />
												</div>
											</div>
											<div
												style={{
													paddingLeft: "26px",
													height: "78px",
													borderRight: `3px solid  ${constanta.COLOR.primary}`,
												}}></div>
										</div>
									</Grid>

									<Grid item xs={4} style={{ padding: 0 }}>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "space-between",
												padding: "12px",
											}}>
											<ContainerIcon
												img={HadiahIMG}
												text="Hadiah"
												onClickIcon={() => {
													props.history.push(`/hadiah/complete`);
												}}
											/>
											<ContainerIcon
												img={InfoIMG}
												onClickIcon={toggleInfoModal}
												text="Info"
											/>
											<ContainerIcon
												img={CoinIMG}
												onClickIcon={toggleClaimModal}
												text="Klaim"
											/>
										</div>
									</Grid>
								</Grid>
							</Card>
						</Grid>
					</Grid>
				</div>
			)}
			<InfoModal open={isOpenInfo} handleClose={toggleInfoModal} />
			<ClaimModal open={isOpenClaim} handleClose={toggleClaimModal} />
		</>
	);
};

export default withRouter(MenuSection);
