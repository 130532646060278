import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ORDERS_HANDLER_CONSTANT } from "../../configs/constanta";
import { useOrderContext } from "../../contexts/Order.context";
import {
	getStages,
	getUserProductByStage,
	handleCancelOrder,
	handleDoneOrder,
	handlePaymentOrder,
} from "./handler";
import OrderPageMobile from "./OrderPageMobile";
import OrderPageDesktop from "./OrderPage.desktop";

const OrderPage = (props) => {
	const [isLoading, setLoading] = useState(true);
	const [orders, setOrders] = useState([]);
	const [tab, setTabs] = useState(0);
	const { newPayment } = useOrderContext();

	const handleSetTabs = (tab) => {
		props.history.push(`/orders/${tab}`);
		setTabs(tab);
	};

	const handleClick = (type, payload) => {
		switch (type) {
			case ORDERS_HANDLER_CONSTANT.cancel:
				return handleCancelOrder(payload.id);
			case ORDERS_HANDLER_CONSTANT.payment:
				return handlePaymentOrder(payload.id, newPayment, props.history);
			case ORDERS_HANDLER_CONSTANT.done:
				return handleDoneOrder(payload.id);
			default:
				break;
		}
	};

	useEffect(() => {
		setOrders([]);
		const fetchOrder = async () => {
			setLoading(true);
			const response = await getUserProductByStage({
				stage: getStages(tab),
			});
			setOrders(response.data);
			setLoading(false);
		};
		fetchOrder();
	}, [tab]);

	const isMobile = useMediaQuery("(max-width:500px)");
	return isMobile ? (
		<OrderPageMobile {...{ ...props, isLoading, orders, tab, handleClick, handleSetTabs }} />
	) : (
		<OrderPageDesktop {...{ ...props, isLoading, orders, tab, handleClick, handleSetTabs }} />
	);
};

export default OrderPage;
