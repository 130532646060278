import styled from "styled-components";

export const WrapMenuBottom = styled.div`
	position: fixed;
	width: 100%;
	bottom: 0;
	height: ${(props) => (props.isFullWrapper ? "100%" : "auto")};
	.backgroundOutside {
		position: fixed;
		content: "";
		width: 100%;
		height: ${(props) => (props.isFullWrapper ? "100%" : "auto")};
		background: rgba(0, 0, 0, 0.5);
	}
	.items {
		display: flex;
		background: #fff;
		padding: 16px;
		position: absolute;
		bottom: 0;
		width: 100%;
		width: -webkit-fill-available;
	}
	.item {
		margin-right: 16px;
		position: relative;
		top: 4px;
	}
	.right {
		position: relative;
		right: 0;
		text-align: right;
		width: 100%;
	}
	.children {
		background: #fff;
		bottom: 0;
		position: absolute;
		width: 100%;
		width: -webkit-fill-available;
		padding: 16px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		z-index: 99;
	}
	.price {
		display: 'flex', 
		justify-content: 'space-between', 
		width: '100%', 
		align-content: 'center',
		min-height: '50px'
		span {
			font-size: 20px;
			color: #666;
			display: inline-block;
			width: 100%;
			:last-child {
				font-family: "DMSans-Bold";
				font-size: 18px;
				color: #333;
			}
		}
		h5 {
			font-size: 16px;
			color: #1E3354;
		}
		p {
			font-size: 12px;
			color: #7F8E9D;
		}
	}
`;
