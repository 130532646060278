import styled from "styled-components";

export const WrapFooter = styled.div`
  position: relative;
  height: 277px;
  width: 100%;
  background: #fee8f3;
  margin-top: 24px;
  padding-top: 35px;

  ul {
    margin: 0;
    padding: 0;
  }
  ul li {
    display: block;
    margin-top: 12px;
    cursor: pointer;
    color: #333;
    svg {
      top: 7px;
      position: relative;
      margin-right: 8px;
    }
  }

  .title {
    font-size: 16px;
    color: #000;
    font-family: "DMSans-Regular";
    text-transform: uppercase;
  }
  .badge {
    display: flex;
    margin-top: 12px;
    svg {
      margin: 0px 4px;
    }
    img {
      margin: 0px 8px;
    }
  }

  .copyright {
    margin-top: 30px;
    text-align: center;
    color: #666;
    font-size: 13px;
    font-family: 'DMSans-Regular';
    .black {
      font-family: 'DMSans-Bold';
    }
  }
`;
