import React, { useEffect, useState } from "react";
import {
	ProductCardFull,
	ProductCardBundling,
	ShippingAddress,
	Spacer,
} from "../../components";
import { LayoutWithAuth } from "../../containers";
import { useAuthContext } from "../../contexts/Auth.context";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { HStack, VStack, Summary, InputOptional } from "./style";
import iconShipping from "../../assets/icons/iconShipping.svg";
import iconNote from "../../assets/icons/iconNote.svg";
import iconArrowRight from "../../assets/icons/iconArrowRight.svg";
import iconRincian from "../../assets/icons/iconRincian.svg";
import iconWallet from "../../assets/icons/iconWallet.svg";
import {
	EST_SHIPPING_COST,
	GET_COURIER_RECOMENDATION,
} from "../../services/supportData.service";
import { GET_DISTRIBUTOR_SHIPING_COST } from "../../services/orders.service";
import {
	Grid,
	InputBase,
	Card,
	Button,
	Typography,
	Checkbox,
	FormControlLabel,
} from "@material-ui/core";
import {
	handleBuyNow,
	onChangeForm,
	subTotalPrice,
	subTotalPriceBundling,
} from "./handler";
import { isEmpty } from "lodash";
import { formatRupiah } from "../../utils/currencyFormat";
import { useOrderContext } from "../../contexts/Order.context";
import KuponModal from "./KuponModal";
import constanta from "../../configs/constanta";
import swal from "sweetalert";

const CheckoutPageDesktop = (props) => {
	const [listAddress, setListAddress] = useState([]);
	const [listProducts, setListProducts] = useState([]);

	const [isLoading, setIsLoading] = useState(true);
	const [isOpenKupon, setIsOpenKupon] = useState(false);
	const [selectedKupon, setSelectedKupon] = useState(null);
	const [isDropshipper, setIsDropshipper] = useState(false);
	const [isFileResi, setIsFileResi] = useState(false);
	const [fileResi, setFileResi] = useState(null);
	const { user } = useAuthContext();
	const { newPayment, referralCode } = useOrderContext();
	const [shippingServices, setShippingServices] = useState([]);
	const [paymentMethod, setPaymentMethod] = useState([]);
	const [marketplace, setMarketplace] = useState([]);
	const [dropshipper, setDropshipper] = useState([]);
	const [weight, setWeight] = useState(1);
	const [shipping, setShipping] = useState("");
	const [state, setState] = useState({
		form: {
			customer_note: "",
		},
	});

	const _mappingProduct = () => {
		let render = [];
		return listProducts.map((item, index) => {
			if (item.type === "bundling") {
				render = (
					<ProductCardBundling
						productBundling={item}
						type="checkout"
						isChecked={item.isChecked}
						withCheckbox={false}
						withQuantityButton={false}
						withDeleteButton={false}
						withBorderBottom={true}
						quantity={item.total}
					/>
				);
			} else {
				render = (
					<ProductCardFull
						style={{ boxShadow: "none", marginBotton: 0, height: 105 }}
						key={index}
						type="checkout"
						name={item.name}
						image_url={item.object.image_url}
						price={
							!isEmpty(user) && user.customer_type?.name === "Customer"
								? item.object.price.consumer
								: item.object.price.user
						}
						// discount = '',
						weight={item.object.weight_gram}
						slug={item.slug}
						color={item.color}
						size={item.size}
						quantity={item.total}
						is_preorder={item.is_preorder}
						day_preorder={item.day_preorder}
						isChecked={item.isChecked}
						withQuantityButton={false}
						withDeleteButton={false}
						withCheckbox={false}
						withBorderBottom={true}
						referral={referralCode}
					/>
				);
			}
			return <div>{render}</div>;
		});
	};

	const isSelectedPayment = () => {
		const getChecked = paymentMethod.filter((x) => x.isChecked);
		if (isEmpty(getChecked)) {
			return false;
		} else {
			return true;
		}
	};

	const isSelectedMarketplace = () => {
		const getChecked = marketplace.filter((x) => x.isChecked);
		if (isEmpty(getChecked)) {
			return false;
		} else {
			return true;
		}
	};

	const isSelectedDropshipper = () => {
		const getChecked = dropshipper.filter((x) => x.isChecked);
		if (isEmpty(getChecked)) {
			return false;
		} else {
			return true;
		}
	};

	const totalPriceProduct = (cart, user) => {
		let pcs = subTotalPrice(cart, user);
		let bundling = subTotalPriceBundling(cart);

		let total = pcs + bundling;

		return total;
	};

	const totalPayment = (totalProduct, shippingCost, coupon) => {
		let result;
		shippingCost = Number(shippingCost);

		if (coupon) {
			if (coupon.type === "ongkir" && shippingCost === 0) {
				let calc = shippingCost - coupon.amount;
				if (calc <= -1) {
					result = totalProduct + 0;
				} else {
					result = totalProduct + calc;
				}
			} else {
				let calc = shippingCost - coupon.amount;
				if (calc <= -1) {
					result = totalProduct + 0;
				} else {
					result = totalProduct + calc;
				}
			}
		} else {
			result = totalProduct + shippingCost;
		}
		return (
			<span className="summary-md">
				Rp
				{formatRupiah(result)}
			</span>
		);
	};

	const getTotalWeight = (cart) => {
		let total = 0;
		cart.map((item) => {
			total += item.object.weight_gram * item.total;
		});
		return total;
	};

	useEffect(() => {
		const fetch = async () => {
			let getAddress = JSON.parse(localStorage.getItem("ALAMAT_CUSTOMER"));
			let getFromCart = JSON.parse(localStorage.getItem("CART"));
			let getShipping = JSON.parse(localStorage.getItem("SHIPPING_SERVICES"));
			let getShippingDistributor = JSON.parse(
				localStorage.getItem("SHIPPING_SERVICES_DISTRIBUTOR")
			);
			let getPayment = JSON.parse(localStorage.getItem("PAYMENTS_METHOD"));
			let getMarketplace = JSON.parse(localStorage.getItem("MARKETPLACE"));
			let getDropshipper = JSON.parse(localStorage.getItem("DROPSHIPPER"));
			let getCourierRecomendation = JSON.parse(
				localStorage.getItem("RECOMMENDATION_COURIER")
			);
			getAddress !== null && setListAddress(getAddress);
			getPayment !== null && setPaymentMethod(getPayment);
			getMarketplace !== null && setMarketplace(getMarketplace);
			getDropshipper !== null && setDropshipper(getDropshipper);
			setListProducts(getFromCart.filter((x) => x.isChecked));
			const totalWeight = getTotalWeight(
				getFromCart.filter((x) => x.isChecked)
			);

			setIsLoading(false);
			if (getAddress.length > 0) {
				if (getShipping == null) {
					const alamat = getAddress.filter((x) => x.isPrimary)[0];
					let couriers = await EST_SHIPPING_COST({
						province: alamat.selectProvinceName,
						city: alamat.selectCityName,
						district: alamat.selectDistrictName,
						subdistrict: alamat.selectSubdistrictName,
						postal_code: alamat.kodePos,
						weight: totalWeight,
					});

					if (couriers.data.data) {
						const temp = couriers.data.data.map((item) => {
							return {
								...item,
								isChecked: false,
							};
						});

						let recommendationCourier = await GET_COURIER_RECOMENDATION();

						let isGetRecommendation = false;
						temp.map((item) => {
							if (
								item.logistic_name.toLowerCase() ==
									recommendationCourier.data.code &&
								item.rate_name == recommendationCourier.data.rate_name
							) {
								item.isChecked = true;
								isGetRecommendation = true;
							}
						});

						if (!isGetRecommendation) {
							temp[0].isChecked = true;
						}
						setShippingServices(temp);
						setShipping(temp.filter((x) => x.isChecked)[0]);
						localStorage.setItem("SHIPPING_SERVICES", JSON.stringify(temp));
					}
				} else {
					setShippingServices(getShipping);
					setShipping(getShipping.filter((x) => x.isChecked)[0]);
				}
			}
		};
		fetch();
	}, []);

	useEffect(() => {
		let kupon = JSON.parse(localStorage.getItem("USE_VOUCHER"));
		setSelectedKupon(kupon);
	}, [isOpenKupon]);

	let payment = {};
	let market = {};
	let dropship = {};
	if (isSelectedPayment()) {
		payment.bank = paymentMethod.filter((x) => x.isChecked)[0].bank.name;
		payment.number = paymentMethod.filter((x) => x.isChecked)[0].account_number;
		payment.name = paymentMethod.filter((x) => x.isChecked)[0].account_name;
	}

	if (isSelectedMarketplace()) {
		market.name = marketplace.filter((x) => x.isChecked)[0].name;
	}

	if (isSelectedDropshipper()) {
		dropship.name = dropshipper.filter((x) => x.isChecked)[0].name;
	}

	useEffect(() => {
		let getDropshipper = JSON.parse(localStorage.getItem("IS_DROPSHIPPER"));
		setIsDropshipper(getDropshipper);
		if (getDropshipper) {
			localStorage.removeItem("SHIPPING_SERVICES");
			localStorage.removeItem("SHIPPING_SERVICES_DISTRIBUTOR");
		} else {
			localStorage.removeItem("DROPSHIPPER");
			localStorage.removeItem("MARKETPLACE");
		}
	}, []);

	useEffect(() => {
		let isFileResi = JSON.parse(localStorage.getItem("IS_FILE_RESI"));
		setIsFileResi(isFileResi);
	}, []);

	return (
		<LayoutWithAuth {...props}>
			<div style={{ marginTop: 32, minHeight: "calc(100vh - 227px)" }}>
				<p>Keranjang Belanja</p>
				<Spacer />
				<Grid container spacing={4}>
					<Grid item xs={9}>
						<ShippingAddress listAddress={listAddress} />
						<Spacer variant="vertical" />
						<Card className="card-custom">
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}>
								<p style={{ padding: "20px" }}>Produk Pesanan</p>
								<Spacer />
								<div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
									{_mappingProduct()}
								</div>
								<Summary>
									<div
										className="pengiriman-section"
										style={{ padding: "20px", width: "100%" }}>
										<Spacer />
										<div style={{ marginBottom: "24px" }}>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														checked={isDropshipper}
														onChange={(event) => {
															localStorage.setItem(
																"IS_DROPSHIPPER",
																event.target.checked
															);
															setIsDropshipper(event.target.checked);
														}}
													/>
												}
												label="Kirim sebagai dropshipper marketplace"
											/>
										</div>
										<VStack>
											{isDropshipper ? (
												<>
													<div>
														<div className="title-service">
															<div className="icon">
																<img src={iconShipping} alt="shipping" />
															</div>
															<div className="text">Marketplace</div>
														</div>
														<div
															className="services"
															style={{ minHeight: "69px" }}
															onClick={() =>
																props.history.push("/marketplace")
															}>
															{isSelectedMarketplace() ? (
																<>
																	<div
																		className="service"
																		style={{
																			alignItems: "center",
																			justifyContent: "center",
																		}}>
																		<span
																			className="name-service"
																			style={{
																				paddingBottom: "4px",
																			}}>{`${market.name}`}</span>
																	</div>
																</>
															) : (
																<div
																	style={{
																		display: "flex",
																		alignItems: "center",
																		paddingBottom: "8px",
																	}}>
																	<span style={{ fontSize: "14px" }}>
																		Pilih Marketplace
																	</span>
																</div>
															)}
															<div
																className="icon"
																style={{
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center",
																}}>
																<span className="txt-ubah">Ubah</span>
															</div>
														</div>
													</div>

													{/* Kurir Dropship */}
													<div>
														<div className="title-service">
															<div className="icon">
																<img src={iconShipping} alt="shipping" />
															</div>
															<div className="text">Kurir Dropship</div>
														</div>
														<div
															className="services"
															style={{ minHeight: "69px" }}
															onClick={() =>
																props.history.push("/dropshipper")
															}>
															{isSelectedDropshipper() ? (
																<>
																	<div
																		className="service"
																		style={{
																			alignItems: "center",
																			justifyContent: "center",
																		}}>
																		<span
																			className="name-service"
																			style={{
																				paddingBottom: "4px",
																			}}>{`${dropship.name}`}</span>
																	</div>
																</>
															) : (
																<div
																	style={{
																		display: "flex",
																		alignItems: "center",
																		paddingBottom: "8px",
																	}}>
																	<span style={{ fontSize: "14px" }}>
																		Pilih Kurir Dropship
																	</span>
																</div>
															)}
															<div
																className="icon"
																style={{
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center",
																}}>
																<span className="txt-ubah">Ubah</span>
															</div>
														</div>
													</div>
													{/* Nomor Resi Marketplace */}
													<div style={{ marginBottom: "12px" }}>
														<div
															className="title-service"
															style={{
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
															}}>
															<div style={{ display: "flex" }}>
																<div className="icon">
																	<img src={iconNote} alt="note" />
																</div>
																<div className="text">
																	Nomor Resi Marketplace
																</div>
															</div>
															<div>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={isFileResi}
																			onChange={(event) => {
																				localStorage.setItem(
																					"IS_FILE_RESI",
																					event.target.checked
																				);
																				setIsFileResi(event.target.checked);
																			}}
																		/>
																	}
																	label="Unggah Resi"
																/>
															</div>
														</div>
														{isFileResi ? (
															<>
																<div>
																	<input
																		accept="image/*,application/pdf"
																		id="contained-button-file"
																		type="file"
																		style={{ display: "none" }}
																		onChange={(event) => {
																			setFileResi(event.target.files[0]);
																			onChangeForm(
																				event.target.files[0],
																				state,
																				setState,
																				"resi_file"
																			);
																		}}
																	/>
																	<label htmlFor="contained-button-file">
																		<Button
																			variant="contained"
																			component="span">
																			<PhotoCamera
																				style={{
																					marginRight: "8px",
																				}}
																			/>
																			Unggah Resi
																		</Button>
																	</label>
																	<span
																		style={{
																			marginLeft: "12px",
																		}}>
																		{fileResi ? fileResi.name : ""}
																	</span>
																</div>
															</>
														) : (
															<InputOptional>
																<InputBase
																	className="multilineInput"
																	defaultValue=""
																	placeholder="Nomor resi marketplace"
																	multiline
																	rows={1}
																	name="dropship_resi"
																	inputProps={{
																		"aria-label": "naked",
																	}}
																	onChange={(e) =>
																		onChangeForm(
																			e.target.value,
																			state,
																			setState,
																			"resi"
																		)
																	}
																/>
															</InputOptional>
														)}
													</div>
												</>
											) : (
												<>
													{" "}
													{listAddress.length > 0 && (
														<div>
															<div className="title-service">
																<div className="icon">
																	<img src={iconShipping} alt="shipping" />
																</div>
																<div className="text">Opsi Pengiriman</div>
															</div>
															<div
																className="services"
																style={{ minHeight: "68px" }}
																onClick={() => {
																	if (listAddress.length > 0) {
																		props.history.push("/shipping-service");
																	} else {
																		swal(
																			"Pesan",
																			"Pilih Atau Tambah Alamat dahulu",
																			"error"
																		);
																	}
																}}>
																{!isEmpty(shippingServices) ? (
																	<>
																		<div className="service">
																			<div
																				style={{
																					paddingBottom: "4px",
																				}}>
																				<span className="name-service">{`${shipping.logistic_name}`}</span>
																				{shipping.rate_name && (
																					<span className="type-service">{`(${shipping.rate_name})`}</span>
																				)}
																			</div>
																			{shipping.courier === "COD" ? (
																				<span
																					className="cost-service"
																					style={{
																						paddingBottom: "4px",
																					}}>
																					{shipping.courier_type}
																				</span>
																			) : (
																				<span
																					className="cost-service"
																					style={{
																						paddingBottom: "4px",
																					}}>
																					Rp{" "}
																					{shipping.rate &&
																						formatRupiah(shipping.rate)}
																				</span>
																			)}
																			{shipping.min_day && shipping.max_day && (
																				<span>
																					Estimasi {shipping.min_day}
																					{" - "}
																					{shipping.max_day} hari
																				</span>
																			)}
																		</div>
																	</>
																) : (
																	<div
																		style={{
																			display: "flex",
																			alignItems: "center",
																			paddingBottom: "8px",
																		}}>
																		<span style={{ fontSize: "14px" }}>
																			Pilih Kurir
																		</span>
																	</div>
																)}

																<div
																	className="icon"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																	}}>
																	<span className="txt-ubah">Ubah</span>
																</div>
															</div>
														</div>
													)}
												</>
											)}
											<Spacer />
											<div>
												<div className="title-service">
													<div className="icon">
														<img src={iconWallet} alt="pembayaran" />
													</div>
													<div className="text">Metode Pembayaran</div>
												</div>
												<div
													className="services"
													style={{ minHeight: "69px" }}
													onClick={() => props.history.push("/payment-method")}>
													{isSelectedPayment() ? (
														<>
															<div className="service">
																<span
																	className="name-service"
																	style={{
																		paddingBottom: "4px",
																	}}>{`${payment.bank}`}</span>
																<span
																	className="cost-service"
																	style={{
																		paddingBottom: "4px",
																	}}>{`${payment.number}`}</span>
																<span>{`${payment.name}`}</span>
															</div>
														</>
													) : (
														<div
															style={{
																display: "flex",
																alignItems: "center",
																paddingBottom: "8px",
															}}>
															<span style={{ fontSize: "14px" }}>
																Pilih Metode Pembayaran
															</span>
														</div>
													)}
													<div
														className="icon"
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
														}}>
														<span className="txt-ubah">Ubah</span>
														{/* <img src={iconArrowRight} alt="pilih-pengiriman" /> */}
													</div>
												</div>
											</div>
											<Spacer />
											<div>
												<div className="title-service">
													<div className="icon">
														<img src={iconNote} alt="note" />
													</div>
													<div className="text">Catatan Pengiriman</div>
												</div>
												<InputOptional>
													<InputBase
														className="multilineInput"
														defaultValue=""
														placeholder="Tambahan Catatan (Opsional)"
														multiline
														rows={4}
														name="note"
														inputProps={{ "aria-label": "naked" }}
														onChange={(e) =>
															onChangeForm(
																e.target.value,
																state,
																setState,
																"customer_note"
															)
														}
													/>
												</InputOptional>
											</div>
										</VStack>
									</div>
								</Summary>
							</div>
						</Card>
					</Grid>

					<Grid item xs={3}>
						<Card
							className="card-custom"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								minHeight: "40px",
								padding: "8px",
								backgroundColor: "#FEF1F8",
								cursor: "pointer",
							}}
							onClick={() => setIsOpenKupon(true)}>
							{selectedKupon ? (
								<div style={{ display: "flex", flexDirection: "column" }}>
									<Typography color="primary" style={{ fontSize: "16px" }}>
										1 Voucher/Kupon terpakai
									</Typography>
									<Typography style={{ fontSize: "11px" }}>
										{`	Selamat, kamu hemat Rp ${formatRupiah(
											selectedKupon.amount
										)} untuk pesananmu!`}
									</Typography>
								</div>
							) : (
								<Typography color="primary" style={{ fontSize: "16px" }}>
									Makin hemat dengan promo
								</Typography>
							)}
						</Card>
						<Spacer />
						<Card className="card-custom">
							<VStack style={{ minHeight: "121px", padding: "14px" }}>
								<HStack style={{ justifyContent: "space-between" }}>
									<span className="summary">Total Belanja</span>
									<span className="summary">
										{" "}
										Rp {formatRupiah(totalPriceProduct(listProducts, user))}
									</span>
								</HStack>
								<Spacer variant="vertical" size="8" />
								<HStack style={{ justifyContent: "space-between" }}>
									<span className="summary">Biaya Pengiriman</span>
									<span className="summary">
										Rp {formatRupiah(shipping.rate ?? 0)}
									</span>
								</HStack>
								<Spacer variant="vertical" size="8" />
								<HStack style={{ justifyContent: "space-between" }}>
									<span className="summary">Promo Diskon</span>
									<span className="summary">
										{selectedKupon
											? `Rp ${formatRupiah(selectedKupon.amount)}`
											: "0"}
									</span>
								</HStack>

								<Spacer variant="vertical" />
								<div style={{ borderTop: "1px solid #DDD" }} />
								<Spacer variant="vertical" />
								<HStack
									style={{
										justifyContent: "space-between",
									}}>
									<span className="summary-md">Total Pembayaran</span>
									{totalPayment(
										totalPriceProduct(listProducts, user),
										shipping.rate,
										selectedKupon
									)}
								</HStack>
								<Spacer variant="vertical" size="20" />
								<Button
									variant="contained"
									color="primary"
									style={{
										color: "#fff",
									}}
									onClick={() =>
										handleBuyNow(
											state,
											shippingServices,
											listProducts,
											newPayment,
											props.history,
											isDropshipper,
											isFileResi
										)
									}>
									Buat Pesanan
								</Button>
							</VStack>
						</Card>
					</Grid>
				</Grid>
			</div>
			<KuponModal
				open={isOpenKupon}
				handleClose={() => setIsOpenKupon(false)}
			/>
		</LayoutWithAuth>
	);
};

export default CheckoutPageDesktop;
