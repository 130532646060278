import { useMediaQuery } from "@material-ui/core";
import React from "react";
import KategoriDesktop from "./kategori.desktop";
import KategoriMobile from "./kategori.mobile";

const KategoriPage = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	return isMobile ? <KategoriMobile {...props} /> : <KategoriDesktop {...props} />;
};
export default KategoriPage;
