import React, { useState, useEffect } from "react";

import { Spacer } from "../../components";
import LayoutAccount from "../../containers/LayoutAccount";
import { Typography, Card, CircularProgress, Button } from "@material-ui/core";
import NoImageProduct from "../../assets/img/noImage.svg";
import { WrapTags } from "./style.js";
import swal from "sweetalert";
import { REWARD_CLAIM, REWARD_CLAIMED } from "../../services/rewards.service";
import DetailHadiahModal from "./detail-hadiah-modal";

const HadiahComplete = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [isOpenHadiah, setIsOpenHadiah] = useState(false);
	const [detailHadiah, setDetailhadiah] = useState({});

	const toggleModalHadiah = () => {
		setIsOpenHadiah(!isOpenHadiah);
	};

	const retrieveCompleteHadiah = () => {
		setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		let payload = {
			user_id: user.id,
			// reward_omzet_id: detail.id,
		};

		if (user) {
			REWARD_CLAIMED(payload)
				.then((res) => {
					setData(res.data);
				})
				.catch((err) => {})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	useEffect(() => {
		retrieveCompleteHadiah();
	}, []);

	const handleClaimReward = (detail) => {
		setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		let payload = {
			user_id: user.id,
			reward_omzet_id: detail.reward_omzet_id,
		};
		REWARD_CLAIM(payload)
			.then((res) => {
				setData(res.data);
				swal("Selamat !", "Sukses klaim hadiah", "success");
				retrieveCompleteHadiah();
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const takeReward = async (detail) => {
		swal({
			title: "Ambil sekarang ?",
			text: "apakah kamu yakin ingin klaim ini sekarang ?",
			buttons: {
				cancel: "Batal",
				ok: "Ok",
			},
		}).then((value) => {
			if (value === "ok") {
				handleClaimReward(detail);
			}
		});
	};

	return (
		<LayoutAccount {...props}>
			<Card className="card-custom">
				<div
					style={{
						padding: "14px",
						minHeight: "calc(100vh - 227px)",
						marginLeft: "8px",
						marginRight: "8px",
					}}
				>
					<div
						style={{
							borderBottom: "1px solid #EEEEEE",
							paddingBottom: "12px",
						}}
					>
						<span style={{ fontSize: "20px", color: "#333333" }}>
							Hadiah Berhasil Terselesaikan
						</span>
					</div>
					<Spacer />
					{data && data.length > 0
						? data.map((item, key) => {
								const BASE_URL = process.env.REACT_APP_BASE_URL;

								return (
									<React.Fragment key={`claim-${key}`}>
										<Card
											style={{ minHeight: "165px", maxWidth: "459px" }}
											className="card-outlined"
										>
											<div style={{ display: "flex", padding: "14px" }}>
												<img
													src={
														BASE_URL + "/" + item.images ??
														NoImageProduct
													}
													alt="product"
													onClick={() => {
														setDetailhadiah(item);
														toggleModalHadiah();
													}}
													style={{
														width: "130px",
														height: "130px",
														borderRadius: "10px",
													}}
												/>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														paddingLeft: "14px",
													}}
												>
													<Typography>{item.reward_desc}</Typography>
													<WrapTags>
														<div className="tag">{`Level ${item.level}`}</div>
													</WrapTags>
													{/* periodic disable claim */}
													{/* <Button
														onClick={() => {
															takeReward(item);
														}}
														style={{
															marginTop: "12px",
															color: "white",
														}}
														disabled={item.is_claimed === 1}
														size="small"
														variant="contained"
														color="primary"
													>
														Klaim Hadiah
													</Button> */}
												</div>
											</div>
										</Card>
										<Spacer />
									</React.Fragment>
								);
						  })
						: null}
				</div>
			</Card>
			<DetailHadiahModal
				open={isOpenHadiah}
				handleClose={toggleModalHadiah}
				detail={detailHadiah}
			/>
		</LayoutAccount>
	);
};

export default HadiahComplete;
