import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapEmptyState = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  .image {
    width: 300px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .desc {
    margin-top: 16px;
    .title {
      font-size: 18px;
    }
    p {
      margin-top: 8px;
      color: #333;
    }
  }

  @media (max-width: 500px) {
    .image {
      width: 70%
    }
    .desc {
      .title {
        font-size: 24px;
        color: ${constanta.COLOR.text}
      }
      p {
        font-size: 12px;
      }
    }
  }
`;
