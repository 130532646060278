import { Grid, useMediaQuery, Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
	BannerSlider,
	ProductCard,
	ProductSlider,
	Skeleton,
	PhotoCard,
} from "../../components";
import { Layout } from "../../containers";
import { WrapHome, WrapCountDown } from "./style";
import Countdown from "react-countdown";
import NoImageProduct from "../../assets/img/noImage.svg";
import ImgGamis from "../../assets/img/gamis.png";
import ImgKhimar from "../../assets/img/khimar.png";
import ImgAksesoris from "../../assets/img/aksesoris.png";
import ImgCoupon from "../../assets/img/coupon.png";
import { GET_BANNERS } from "../../services/banner.service";
import { useAuthContext } from "../../contexts/Auth.context";
import {
	GET_PRODUCTS,
	GET_PRODUCT_FLASHSALE,
	GET_HOME_PRODUCTS,
} from "../../services/products.service";
import { GET_POPUP } from "../../services/popup.service";
import CloseIcon from "@material-ui/icons/Close";
import constanta from "../../configs/constanta";
import { isEmpty } from "lodash";
import MenuSection from "./menu-section";
import ToolbarMobile from "../../components/Mobile/ToolbarMobile";

const HomePage = (props) => {
	const { user } = useAuthContext();
	const isMobile = useMediaQuery("(max-width:500px)");
	const [banners, setBanners] = useState([]);
	const { isAuthenticated } = useAuthContext();
	const [products, setProducts] = useState({
		flashsale: [],
		all: [],
		recommended: [],
		gamis: [],
		khimar: [],
		accessories: [],
	});
	const [filters, setFilters] = useState({
		paginate: 10,
		categoryId: 2,
		name: "",
		tags: [],
		slug: "",
		sort: "",
		page: 1,
	});
	const [isLoading, setIsLoading] = useState(true);
	const [showBanner, setShowBanner] = useState(false);
	const [banner, setBanner] = useState();

	const getFilter = (categoryId) => {
		let filter = `?paginate=${filters.paginate}&page=${filters.page}&filter=from_newest`;
		filters.name !== "" && (filter = filter + `&filter[name]=${filters.name}`);
		filters.slug !== "" && (filter = filter + `&filter[slug]=${filters.slug}`);
		filters.sort !== "" && (filter = filter + `&sort=${filters.sort}`);
		!isEmpty(filters.tags) &&
			(filter = filter + `&filter[tags]=${filters.tags.toString()}`);
		// check if the categoryId is undefined
		if (categoryId !== undefined) {
			// if it is not undefined, add the category filter
			filter = filter + `&filter[category.id]=${categoryId}`;
		} else {
			// if it is undefined, means recommendation products
			filter = filter + `&filter[recomendations]=1`;
		}

		return filter;
	};

	const getFilterFlashSale = () => {
		let filter = `?paginate=${filters.paginate}&page=${filters.page}`;
		filters.running !== "" && (filter = filter + `&filter[running]=${true}`);
		return filter;
	};

	useEffect(() => {
		if (isAuthenticated) {
			GET_POPUP()
				.then((res) => {
					if (res.data) {
						setShowBanner(true);
						setBanner(res.data[0]);
					}
				})
				.catch((err) => {});
		}
	}, [isAuthenticated]);

	useEffect(() => {
		const getBanners = async () => {
			await GET_BANNERS(1).then((res) => {
				setBanners(res.data);
			});
		};
		getBanners();
	}, []);

	const updateProducts = (key, data) => {
		setProducts((products) => ({
			...products,
			[key]: data,
		}));
	};

	useEffect(() => {
		const getProducts = async () => {
			const productResult = await GET_HOME_PRODUCTS();
			updateProducts("flashsale", productResult.flashsale);
			updateProducts("recommended", productResult.rekomendasi);
			updateProducts("gamis", productResult.gamis);
			updateProducts("khimar", productResult.khimar);
			updateProducts("accessories", productResult.aksesoris);

			setIsLoading(false);
		};

		getProducts();
		localStorage.removeItem("USE_VOUCHER");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const containerStyle = {
		maxWidth: 1220,
		position: "relative",
		margin: "0 auto",
		left: 0,
		right: 0,
	};
	const BASE_URL = process.env.REACT_APP_BASE_URL;
	let data = products?.flashsale[0];
	return (
		<WrapHome>
			<Layout {...props} withContainer={false} type="none">
				{/* <div style={!isMobile ? containerStyle : {}}>
					<BannerSlider banners={banners} />
				</div> */}
				<MenuSection isAuthenticated={isAuthenticated} />
				<div style={!isMobile ? containerStyle : {}}>
					<BannerSlider banners={banners} />
				</div>
				{/* OPEN FLASHSALE SECTION */}
				<>
					<div
						style={
							!isMobile
								? {
										...containerStyle,
										marginTop: 32,
										marginBottom: 12,
								  }
								: { marginTop: 32, marginBottom: 12 }
						}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										padding: "0 16px",
										marginTop: 16,
									}}>
									<h3 style={{ color: "#EF5DA8", marginRight: 20 }}>
										{data?.name}
									</h3>
									<div>
										{data?.end_date && (
											<Countdown
												date={data?.end_date}
												renderer={(props) => {
													return (
														<WrapCountDown>
															<div className="container-count">
																{props.formatted.days}
															</div>
															<span>:</span>
															<div className="container-count">
																{props.formatted.hours}
															</div>
															<span>:</span>
															<div className="container-count">
																{props.formatted.minutes}
															</div>
															<span>:</span>
															<div className="container-count">
																{props.formatted.seconds}
															</div>
														</WrapCountDown>
													);
												}}
											/>
										)}
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
					<div style={{ backgroundColor: "#EF5DA8" }}>
						<div style={!isMobile ? containerStyle : {}}>
							<Grid
								container
								spacing={3}
								style={{ marginTop: 15, padding: "0 20px" }}>
								<Grid item xs={12}>
									{!isLoading ? (
										<ProductSlider>
											{data?.items.length > 0 &&
												data?.items.map((item) => {
													return (
														<ProductCard
															role={
																!isEmpty(user) ? user?.customer_type?.name : ""
															}
															name={item.product.name}
															discount={item.product.skus[0].price.consumer}
															price={
																!isEmpty(user) &&
																user?.customer_type?.name === "Customer"
																	? item.product.skus[0].price.consumer
																	: item.product.skus[0].price.user
															}
															image_url={item.product.image_url}
															key={item.product.slug}
															slug={item.product.slug}
														/>
													);
												})}
										</ProductSlider>
									) : (
										<Skeleton type="product" />
									)}
								</Grid>
							</Grid>
						</div>
					</div>
				</>
				{/* {products.flashsale.map((data) => {
					return (
						
					);
				})} */}
				{/* CLOSE FLASHSALE SECTION */}
				<div
					style={
						!isMobile
							? {
									...containerStyle,
									marginTop: 32,
									marginBottom: 12,
							  }
							: { marginTop: 32, marginBottom: 12 }
					}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									margin: "15px 20px",
								}}>
								<h3
									style={{
										fontSize: 14,
										fontWeight: "semi-bold",
										color: "#1E3354",
										padding: 0,
									}}>
									Recommended untuk kamu ✨
								</h3>
								<a
									onClick={() => {
										window.location.href = `/products`;
									}}
									style={{
										fontSize: 11,
										fontWeight: "semi-bold",
										color: "#3AD4E0",
										padding: 0,
									}}>
									Lihat Semua
								</a>
							</div>
						</Grid>
					</Grid>
				</div>
				<div
					style={{
						background: "linear-gradient(-45deg, #F3788C, #EEC5D8)",
						padding: 20,
					}}>
					<div style={!isMobile ? containerStyle : {}}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								{!isLoading ? (
									<ProductSlider>
										{!isEmpty(products.recommended) &&
											products.recommended
												.sort(
													(a, b) => Number(b.show_stock) - Number(a.show_stock)
												)
												.map((item, index) => {
													if (item && item.show_product) {
														if (!isEmpty(user)) {
															return (
																<React.Fragment key={index}>
																	<ProductCard
																		role={
																			!isEmpty(user)
																				? user.customer_type?.name
																				: ""
																		}
																		name={item.name}
																		price={
																			!isEmpty(user) &&
																			user.customer_type?.name === "Customer"
																				? item.skus[0].price.consumer
																				: item.skus[0].price.user
																		}
																		discount={item.skus[0].price.consumer}
																		image_url={item.image_url}
																		key={item.slug}
																		slug={item.slug}
																	/>
																</React.Fragment>
															);
														} else {
															return (
																<React.Fragment key={index}>
																	<ProductCard
																		role={
																			!isEmpty(user)
																				? user.customer_type?.name
																				: ""
																		}
																		name={item.name}
																		price={item.skus[0].price.consumer}
																		discount={item.skus[0].price.consumer}
																		image_url={item.image_url}
																		key={item.slug}
																		slug={item.slug}
																	/>
																</React.Fragment>
															);
														}
													}
												})}
									</ProductSlider>
								) : (
									<Skeleton type="product" />
								)}
							</Grid>
						</Grid>
					</div>
				</div>
				<div style={!isMobile ? containerStyle : {}}>
					<Grid
						container
						spacing={3}
						style={{ marginTop: 15, padding: "0 20px" }}>
						<Grid item xs={12} style={{ paddingBottom: 0 }}>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<h3
									style={{
										fontSize: 14,
										fontWeight: "semi-bold",
										color: "#1E3354",
										padding: 0,
									}}>
									Gamis
								</h3>
								<div
									onClick={() => {
										window.location.href = `/category?type=${2}`;
									}}>
									<a
										style={{
											fontSize: 11,
											fontWeight: "semi-bold",
											color: "#3AD4E0",
											padding: 0,
											cursor: "pointer",
										}}>
										Lihat Semua
									</a>
								</div>
							</div>
						</Grid>
						<Grid item xs={12}>
							{!isLoading ? (
								<ProductSlider>
									{!isEmpty(products.gamis) &&
										products.gamis
											.sort(
												(a, b) => Number(b.show_stock) - Number(a.show_stock)
											)
											.map((item, index) => {
												if (item && item.show_product) {
													if (!isEmpty(user)) {
														return (
															<React.Fragment key={index}>
																<ProductCard
																	name={item.name}
																	role={
																		!isEmpty(user)
																			? user.customer_type?.name
																			: ""
																	}
																	price={
																		!isEmpty(user) &&
																		user.customer_type?.name === "Customer"
																			? item.skus[0].price.consumer
																			: item.skus[0].price.user
																	}
																	discount={item.skus[0].price.consumer}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</React.Fragment>
														);
													} else {
														return (
															<React.Fragment key={index}>
																<ProductCard
																	role={
																		!isEmpty(user)
																			? user.customer_type?.name
																			: ""
																	}
																	name={item.name}
																	price={item.skus[0].price.consumer}
																	discount={item.skus[0].price.consumer}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</React.Fragment>
														);
													}
												}
											})}
								</ProductSlider>
							) : (
								<Skeleton type="product" />
							)}
						</Grid>
					</Grid>
				</div>
				<div style={!isMobile ? containerStyle : {}}>
					<Grid
						container
						spacing={3}
						style={{ marginTop: 15, padding: "0 20px" }}>
						<Grid item xs={12} style={{ paddingBottom: 0 }}>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<h3
									style={{
										fontSize: 14,
										fontWeight: "semi-bold",
										color: "#1E3354",
										padding: 0,
									}}>
									Khimar
								</h3>
								<div
									onClick={() => {
										window.location.href = `/category?type=${4}`;
									}}>
									<a
										style={{
											fontSize: 11,
											fontWeight: "semi-bold",
											color: "#3AD4E0",
											padding: 0,
											cursor: "pointer",
										}}>
										Lihat Semua
									</a>
								</div>
							</div>
						</Grid>
						<Grid item xs={12}>
							{!isLoading ? (
								<ProductSlider>
									{!isEmpty(products.khimar) &&
										products.khimar
											.sort(
												(a, b) => Number(b.show_stock) - Number(a.show_stock)
											)
											.map((item, index) => {
												if (item && item.show_product) {
													if (!isEmpty(user)) {
														return (
															<React.Fragment key={index}>
																<ProductCard
																	name={item.name}
																	role={
																		!isEmpty(user)
																			? user.customer_type?.name
																			: ""
																	}
																	price={
																		!isEmpty(user) &&
																		user.customer_type?.name === "Customer"
																			? item.skus[0].price.consumer
																			: item.skus[0].price.user
																	}
																	discount={item.skus[0].price.consumer}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</React.Fragment>
														);
													} else {
														return (
															<React.Fragment key={index}>
																<ProductCard
																	role={
																		!isEmpty(user)
																			? user.customer_type?.name
																			: ""
																	}
																	name={item.name}
																	price={item.skus[0].price.consumer}
																	discount={item.skus[0].price.consumer}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</React.Fragment>
														);
													}
												}
											})}
								</ProductSlider>
							) : (
								<Skeleton type="product" />
							)}
						</Grid>
					</Grid>
				</div>

				{/* {!isEmpty(products.flashsale) && (
				<div style={!isMobile ? containerStyle : {
					
				}}>
					<Grid container spacing={3} style={{ marginTop: 20 }}>
						<Grid item xs={12}>
							<div style={{ display: "flex", justifyContent: "space-between", margin: "0 20px" }}>
								<h3
								style={{
									fontSize: 14,
									fontWeight: "semi-bold",
									color: "#1E3354",
									padding: 0,
								}}
								>Spesial promo 🏷</h3>
								<div
									onClick={() => {
										window.location.href = `/category?type=${2}`;
									}}
								>
									<a
									style={{
										fontSize: 11,
										fontWeight: "semi-bold",
										color: "#3AD4E0",
										padding: 0,
										cursor: "pointer",
									}}
								>
									Lihat Semua
								</a>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} style={{ 
								backgroundImage: `url("/promo_bg.png") no-repeat`,
								backgroundSize: "cover",
								backgroundPosition: "left center",

								paddingLeft: 135,
							}}>
							{!isLoading ? (
								<ProductSlider >
									{!isEmpty(products.flashsale) &&
										products.flashsale
											.sort(
												(a, b) =>
													Number(b.show_stock) - Number(a.show_stock),
											)
											.map((item, index) => {
												if (item && item.show_product) {
													if (!isEmpty(user)) {
														return (
															<React.Fragment key={index}>
																<ProductCard
																	role={
																		!isEmpty(user)
																			? user.customer_type
																					.name
																			: ""
																	}
																	name={item.name}
																	price={
																		!isEmpty(user) &&
																		user.customer_type?.name ===
																			"Customer"
																			? item.skus[0].price
																					.consumer
																			: item.skus[0].price
																					.user
																	}
																	discount={
																		item.skus[0].price.consumer
																	}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</React.Fragment>
														);
													} else {
														return (
															<React.Fragment key={index}>
																<ProductCard
																	role={
																		!isEmpty(user)
																			? user.customer_type
																					.name
																			: ""
																	}
																	name={item.name}
																	price={
																		item.skus[0].price.consumer
																	}
																	discount={
																		item.skus[0].price.consumer
																	}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</React.Fragment>
														);
													}
												}
											})}
								</ProductSlider>
							) : (
								<Skeleton type="product" />
							)}
						</Grid>
					</Grid>
				</div>
				)} */}

				<div style={!isMobile ? containerStyle : {}}>
					<Grid
						container
						spacing={3}
						style={{ marginTop: 15, padding: "0 20px" }}>
						<Grid item xs={12} style={{ paddingBottom: 0 }}>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<h3
									style={{
										fontSize: 14,
										fontWeight: "semi-bold",
										color: "#1E3354",
										padding: 0,
									}}>
									Aksesoris
								</h3>
								<div
									onClick={() => {
										window.location.href = `/category?type=${12}`;
									}}>
									<a
										style={{
											fontSize: 11,
											fontWeight: "semi-bold",
											color: "#3AD4E0",
											padding: 0,
											cursor: "pointer",
										}}>
										Lihat Semua
									</a>
								</div>
							</div>
						</Grid>
						<Grid item xs={12}>
							{!isLoading ? (
								<ProductSlider>
									{!isEmpty(products.accessories) &&
										products.accessories
											.sort(
												(a, b) => Number(b.show_stock) - Number(a.show_stock)
											)
											.map((item, index) => {
												if (item && item.show_product) {
													if (!isEmpty(user)) {
														return (
															<React.Fragment key={index}>
																<ProductCard
																	role={
																		!isEmpty(user)
																			? user.customer_type?.name
																			: ""
																	}
																	name={item.name}
																	price={
																		!isEmpty(user) &&
																		user.customer_type?.name === "Customer"
																			? item.skus[0].price.consumer
																			: item.skus[0].price.user
																	}
																	discount={
																		// item.skus[0].price.consumer
																		item.skus[0].price.consumer
																	}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</React.Fragment>
														);
													} else {
														return (
															<React.Fragment key={index}>
																<ProductCard
																	role={
																		!isEmpty(user)
																			? user.customer_type?.name
																			: ""
																	}
																	name={item.name}
																	price={item.skus[0].price.consumer}
																	discount={item.skus[0].price.consumer}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</React.Fragment>
														);
													}
												}
											})}
								</ProductSlider>
							) : (
								<Skeleton type="product" />
							)}
						</Grid>
					</Grid>
				</div>

				{/* Naisha Mate */}
				{/* <div style={!isMobile ? containerStyle : {}}>
					<Grid container spacing={3} style={{ marginTop: 20, marginBottom: 100 }}>
						<Grid item xs={12}>
						<div style={{ display: "flex", justifyContent: "space-between", margin: "0 20px" }}>
								<h3
								style={{
									fontSize: 14,
									fontWeight: "semi-bold",
									color: "#1E3354",
									padding: 0,
								}}
								>#Naishamate 📸</h3>
								<div
									onClick={() => {
										window.location.href = `/category?type=${2}`;
									}}
								>
									<a
									style={{
										fontSize: 11,
										fontWeight: "semi-bold",
										color: "#3AD4E0",
										padding: 0,
										cursor: "pointer",
									}}
								>
									Lihat Semua
								</a>
								</div>
							</div>
						</Grid>
						<Grid item xs={12}>
							{!isLoading ? (
								<ProductSlider>
									{!isEmpty(products.accessories) &&
										products.accessories
											.sort(
												(a, b) =>
													Number(b.show_stock) - Number(a.show_stock),
											)
											.map((item, index) => {
												if (item && item.show_product) {
													
													if (!isEmpty(user)) {
														return (
															<React.Fragment key={index}>
																<PhotoCard
																	role={
																		!isEmpty(user)
																			? user.customer_type
																					.name
																			: ""
																	}
																	name={item.name}
																	price={
																		!isEmpty(user) &&
																		user.customer_type?.name ===
																			"Customer"
																			? item.skus[0].price
																					.consumer
																			: item.skus[0].price
																					.user
																	}
																	discount={
																		// item.skus[0].price.consumer
																		item.skus[0].price.consumer
																	}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</React.Fragment>
														);
													} else {
														return (
															<React.Fragment key={index}>
																<PhotoCard
																	role={
																		!isEmpty(user)
																			? user.customer_type
																					.name
																			: ""
																	}
																	name={item.name}
																	price={
																		item.skus[0].price.consumer
																	}
																	discount={
																		item.skus[0].price.consumer
																	}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</React.Fragment>
														);
													}
												}
											})}
								</ProductSlider>
							) : (
								<Skeleton type="product" />
							)}
						</Grid>
					</Grid>
				</div> */}

				{isMobile && (
					<div style={{ marginTop: 20 }}>
						<img
							src={ImgCoupon}
							style={{
								position: "fixed",
								bottom: 100,
								right: 20,
								zIndex: 100,
							}}
							onClick={() => {
								window.location.href = `/ambil-kupon`;
							}}
						/>
					</div>
				)}

				{isMobile && (
					<div style={{ marginTop: 20 }}>
						<ToolbarMobile {...props} />
					</div>
				)}

				<Dialog
					fullWidth={false}
					maxWidth={"xl"}
					onClose={() => setShowBanner(false)}
					aria-labelledby="simple-dialog-title"
					open={showBanner}>
					<div
						style={{
							fontWeight: "bold",
							position: "absolute",
							left: "0",
							right: "0",
							top: "4px",
							padding: "12px",
							display: "flex",
							flexDirection: "row-reverse",
							cursor: "pointer",
						}}
						onClick={() => {
							setShowBanner(false);
						}}>
						<CloseIcon />
					</div>
					<a
						href={banner ? banner.link : ""}
						target="_blank"
						rel="noreferrer"
						style={{
							textDecoration: "none",
							color: "black",
							maxWidth: isMobile ? "80vw" : "auto",
							maxHeight: isMobile ? "auto" : "90vh",
						}}>
						<img
							src={banner ? banner?.image : NoImageProduct}
							alt="banner"
							style={{
								maxWidth: isMobile ? "80vw" : "auto",
								maxHeight: isMobile ? "auto" : "90vh",
							}}
						/>
					</a>
				</Dialog>
			</Layout>
		</WrapHome>
	);
};

export default HomePage;
