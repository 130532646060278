import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapBanner = styled.div`
  position: relative;
  margin-top: 32px;
  .image {
    margin: 0px 8px;
    img {
      width: 100%;
    }
  }
  .slick-slide {
    margin: 0px 8px;
    outline: none !important;
    border: none;
    :focus {
      outline: none;
    }
  }
  .arrow-left {
    background: ${constanta.COLOR.primary};
    position: absolute;
    z-index: 1;
    top: 40%;
    border-radius: 10px;
    width: 41px;
    height: 41px;
    text-align: center;
    left: -10px;
    cursor: pointer;
  }
  .arrow-right {
    background: ${constanta.COLOR.primary};
    position: absolute;
    z-index: 1;
    top: 40%;
    border-radius: 10px;
    width: 41px;
    height: 41px;
    text-align: center;
    right: -10px;
    cursor: pointer;
  }

  .slick-dots {
    li {
      margin: 0;
    }
    li button:before {
      color: #dfe0df;
      font-size: 8px;
    }
    .slick-active button:before {
      color: ${constanta.COLOR.primary};
    }
  }
`;

export const WrapBannerMobile = styled.div`
  position: relative;
  padding: 21px;
  .image {
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  .slick-dots {
    li {
      margin: 0;
    }
    li button:before {
      color: #dfe0df;
      font-size: 8px;
    }
    .slick-active button:before {
      color: ${constanta.COLOR.primary};
    }
  }
`;
