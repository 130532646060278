import React from "react";
import constanta from "../../configs/constanta";

export const ChipContained = ({ text, onClickChip, isActive }) => {
	return (
		<div
			style={{
				borderRadius: "20px",
				padding: "6px",
				paddingRight: "8px",
				paddingLeft: "8px",
				marginTop: "20px",
				marginRight: "10px",
				textAlign: "center",
				border: isActive ? `1px solid ${constanta.COLOR.primary}` : `1px solid ${constanta.COLOR.strk}`,
				backgroundColor: isActive ? "white" : "",
				color: isActive ? constanta.COLOR.primary : constanta.COLOR.text,
				fontSize: "12px",
				fontWeight: isActive ? "bold" : "normal",
				cursor: "pointer",
			}}
			onClick={() => {
				if (onClickChip) {
					onClickChip();
				}
			}}
		>
			{text}
		</div>
	);
};

export const ChipContainedPrimary = ({ text, onClickChip, isActive }) => {
	return (
		<div
			style={{
				borderRadius: "7px",
				padding: "6px",
				paddingRight: "8px",
				paddingLeft: "8px",
				textAlign: "center",
				border: `1px solid ${isActive ? constanta.COLOR.primary : "#DDDDDD"}`,
				backgroundColor: isActive ? "#FEF1F8" : "white",
				color: isActive ? constanta.COLOR.primary : "black",
				cursor: "pointer",
			}}
			onClick={() => {
				if (onClickChip) {
					onClickChip();
				}
			}}
		>
			{text}
		</div>
	);
};
