import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import HadiahCompleteDesktop from "./HadiahComplete.desktop";
import HadiahCompleteMobile from "./HadiahComplete.mobile";

const HadiahComplete = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <HadiahCompleteMobile {...props} /> : <HadiahCompleteDesktop {...props} />;
};

export default HadiahComplete;
