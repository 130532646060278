import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { SearchCustomWrap } from "./style";
import iconSearch from "../../assets/icons/iconSearch.svg";


const SearchCustomComponent = (props) => {
	const { handleSearch = null, onSearch = null, withIcon = true } = props;
	return (
		<SearchCustomWrap >
			<TextField
				{...props}
				id="search"
				variant="outlined"
			
				onBlur={handleSearch}
				
				onKeyPress={(event) => {
					if (event.key === "Enter") {
						handleSearch(event);
					}
				}}
				InputProps={
					withIcon && {
						startAdornment: (
							<InputAdornment position="start"
							style={{cursor: "pointer"}}
							>
								<img
									src={iconSearch}
									alt="search"
									className="search"
									onClick={onSearch}
								/>
							</InputAdornment>
						),
					}
				}
			/>
		</SearchCustomWrap>
	);
};

export default SearchCustomComponent;
