import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function InfoModal({ open, handleClose }) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={"xs"}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
					Tentang Level Akun
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Level Omset adalah bonus yang bisa didapatkan dari experience (XP) pada
						setiap penambahan omset penjualanmu. Bonus berupa hadiah-hadiah menarik yang
						disesuaikan dengan level.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						variant="contained"
						style={{ color: "white", width: "100%" }}
						color="primary"
						autoFocus
					>
						Oke
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
