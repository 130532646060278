import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
	ProductCard,
	ProductBundlingCard,
	Skeleton,
	EmptyState,
} from "../../components";
import { Layout } from "../../containers";
import { WrapHome } from "./style";
import { useAuthContext } from "../../contexts/Auth.context";
import { useOrderContext } from "../../contexts/Order.context";
import { GET_PROMO, GET_BUNDLING } from "../../services/products.service";
import { ChipContained } from "../../components/chips";
import { Spacer } from "../../components";
import _, { isEmpty } from "lodash";
import Pagination from "@material-ui/lab/Pagination";
// import {
// 	onClickTotalProduct,
// 	onSelectColorDesktop,
// 	onSelectedImage,
// 	onSelectSize,
// 	handleAddToCart,
// 	handleBuyNow,
// } from "../product-page/handler";
import swal from "sweetalert";

const PromoPageDesktop = (props) => {
	const { refreshOrderContext } = useOrderContext();
	const { user } = useAuthContext();
	const isMobile = useMediaQuery("(max-width:500px)");
	const { isAuthenticated } = useAuthContext();
	const [products, setProducts] = useState([]);
	const [bundlings, setBundlings] = useState([]);
	const [filters, setFilters] = useState({
		paginate: 10,
		page: 1,
		promo: "all",
	});
	const [filterBundling, setFilterBundling] = useState({
		paginate: 10,
		page: 1,
		include: "items.product.skus",
	});
	const [isLoading, setIsLoading] = useState(false);

	const getProducts = async () => {
		setIsLoading(true);

		GET_PROMO(filters)
			.then((res) => {
				setProducts(res);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const getBundling = async () => {
		setIsLoading(true);

		GET_BUNDLING(filterBundling)
			.then((res) => {
				setBundlings(res);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (filters.promo === "bundling") {
			getBundling();
		} else {
			getProducts();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const setFilterByPromo = (data) => {
		setFilters((prevState) => {
			return {
				...prevState,
				promo: data.value,
			};
		});
	};

	const getDetail = async (item) => {
		props.history.push(`/bundling/${item.id}`);
	};

	const containerStyle = {
		maxWidth: 1220,
		position: "relative",
		margin: "0 auto",
		left: 0,
		right: 0,
	};

	const listPromo = [
		{
			value: "all",
			label: "Semua Promo",
		},
		{
			value: "ongkir",
			label: "Gratis Ongkir",
		},
		{
			value: "cashback",
			label: "Cashback",
		},
		{
			value: "discount",
			label: "Potongan Harga",
		},
		{
			value: "bundling",
			label: "Bundling",
		},
	];

	return (
		<WrapHome>
			<Layout {...props} withContainer={false}>
				<div style={!isMobile ? containerStyle : {}}>
					<Grid container spacing={3} style={{ marginTop: 20 }}>
						<Grid item xs={12}>
							<div
								style={{
									minHeight: "130px",

									backgroundColor: "#F969AE",
									borderRadius: "10px",
								}}>
								<div style={{ padding: "20px" }}>
									<div style={{ display: "flex", marginBottom: "12px" }}>
										<span
											style={{
												fontSize: "32px",
												color: "#fff",
												fontWeight: "bold",
											}}>
											Promo
										</span>
									</div>
									<div style={{ display: "flex" }}>
										{listPromo.map((item, index) => {
											return (
												<React.Fragment key={index}>
													<ChipContained
														text={item.label}
														isActive={filters.promo === item.value}
														onClickChip={() => {
															setFilterByPromo(item);
														}}
													/>
													<Spacer />
												</React.Fragment>
											);
										})}
									</div>
								</div>
							</div>
						</Grid>
						<Grid item xs={12}>
							{!isLoading ? (
								<div
									style={{
										width: "100%",
										display: "flex",
										flexWrap: "wrap",
									}}>
									{filters.promo === "bundling" ? (
										<>
											{!isEmpty(bundlings.data) ? (
												bundlings.data.map((item, index) => (
													<div
														key={index}
														style={{
															flexGrow: 1,
														}}>
														<ProductBundlingCard
															getDetail={() => getDetail(item)}
															product={item}
															userType={user.customer_type?.name}
															buyBundle={() => {
																props.history.push(
																	`/bundling-checkout/${item.id}`
																);
															}}
														/>
													</div>
												))
											) : (
												<div
													style={{
														width: "100%",
														display: "flex",
														flexWrap: "wrap",
														justifyContent: "space-between",
													}}>
													<EmptyState
														desc="Produk Yang Anda Cari Tidak Ditemukan"
														// title=""
													/>
												</div>
											)}
										</>
									) : (
										<>
											{!isEmpty(products.data) ? (
												products.data.map((item) => {
													if (isEmpty(user)) {
														return (
															<div
																style={{
																	flexGrow: 1,
																}}>
																<ProductCard
																	role={
																		!isEmpty(user)
																			? user.customer_type.name
																			: ""
																	}
																	name={item.name}
																	price={
																		!isEmpty(user) &&
																		user.customer_type?.name === "Customer"
																			? item.skus[0].price.consumer
																			: item.skus[0].price.user
																	}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</div>
														);
													} else {
														return (
															<div
																style={{
																	flexGrow: 1,
																}}>
																<ProductCard
																	name={item.name}
																	price={item.skus[0].price.consumer}
																	image_url={item.image_url}
																	key={item.slug}
																	slug={item.slug}
																/>
															</div>
														);
													}
												})
											) : (
												<div
													style={{
														width: "100%",
														display: "flex",
														flexWrap: "wrap",
														justifyContent: "space-between",
													}}>
													<EmptyState
														desc="Produk Yang Anda Cari Tidak Ditemukan"
														// title=""
													/>
												</div>
											)}
										</>
									)}
								</div>
							) : (
								<div style={{ display: "flex", flexDirection: "column" }}>
									<div style={{ paddingBottom: "24px" }}>
										<Skeleton type="product" />
									</div>
									<div style={{ paddingBottom: "24px" }}>
										<Skeleton type="product" />
									</div>
									<div style={{ paddingBottom: "24px" }}>
										<Skeleton type="product" />
									</div>
								</div>
							)}
						</Grid>
					</Grid>

					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							paddingTop: "24px",
						}}>
						{!isEmpty(products.data) ||
							(!isEmpty(bundlings.data) && (
								<>
									{filters.promo === "bundling" ? (
										<Pagination
											page={filterBundling.page}
											onChange={(event, page) => {
												setFilterBundling((prevState) => {
													return {
														...prevState,
														page: page,
													};
												});
											}}
											count={bundlings.meta?.total}
											variant="outlined"
											shape="rounded"
										/>
									) : (
										<Pagination
											page={filters.page}
											onChange={(event, page) => {
												setFilters((prevState) => {
													return {
														...prevState,
														page: page,
													};
												});
											}}
											count={products.meta?.total}
											variant="outlined"
											shape="rounded"
										/>
									)}
								</>
							))}
					</div>
				</div>
			</Layout>
		</WrapHome>
	);
};

export default PromoPageDesktop;
