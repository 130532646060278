import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapperOrder = styled.div`
  background: #EFF8FC;
  min-height: 100vh;
  .orders {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    padding-top: 60px;
	background: #EFF8FC;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  }
  .orders-content {
    padding-top 120px;
    min-height: 100vh;
    .empty {
      min-height: 90vh;
      display: flex;
      align-items: center;
    }
  }

  .actionButtonWrapper {
    justify-content: flex-end;
	margin-bottom: 8px;
    button {
      border: 1px solid ${constanta.COLOR.primary} !important;
      padding: 8px 24px;
      font-size: 14px;
    }
  }
  .inline {
    display: flex;
    justify-items: center;
    items-align: center;
	margin-bottom: 8px;
    span {
      margin-left: 5px;
      color: #999999;
    }
  }

  @media (max-width: 500px) {
    .empty {
      .image {
        width: 50%;
      }
      .desc {
        margin-top: 8px;
        .title {
			font-size: 24px;
			color: ${constanta.COLOR.text}
			font-weight: 600;
        }
      }
    }
  }
`;

export const WrapProductCard = styled.div`
	.container {
		padding-top: 8px;
		padding-right: 24px;
		padding-left: 24px;
		min-height: 156px;
		display: flex;
		flex-direction: column;
	}
	.image {
		display: flex;
		align-items: center;
		img {
			width: 124px !important;
			height: 124px !important;
		}
	}
	.wrapper-content {
		padding: 8px;
		padding-left: 24px;
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.wrapper-action {
		padding-left: 24px;
		padding-top: 12px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: space-between;
	}
	.title {
		font-size: 16px;
		text-overflow: ellipsis;
		font-weight: normal;
		overflow: hidden;
		white-space: nowrap;
		margin: 4px 0px;
		max-width: 500px;
	}
	.stock {
		p {
			font-size: 12px;
		}
	}
	.price p {
		font-size: 20px;
		color: ${constanta.COLOR.textPrimary};
		font-family: "DMSans-Bold";
		margin: 8px 0px;
	}
	.total-price {
		font-size: 16px;
		color: ${constanta.COLOR.textPrimary};
		font-family: "DMSans-Bold";
		margin-left: 12px;
	}
	.discount {
		color: #959595;
		font-size: 13px;
		font-family: "DMSans-Regular";
		text-decoration: line-through;
	}
	.tags {
		display: flex;
		margin-top: 4px;
	}
	.tag {
		font-size: 12px;
		color: #666;
		border: 1px solid #ededed;
		border-radius: 5px;
		padding: 4px 8px;
		background: #ededed;
		width: auto;
		margin-right: 8px;
		text-transform: uppercase;
		:focus {
			background: ${constanta.COLOR.darkPink};
			color: #fff;
			border: 1px solid ${constanta.COLOR.darkPink};
		}
	}
	.quantity {
		padding: 12px;
		border: 1px solid #f1f1f1;
		border-radius: 8px;
	}
	.checkbox {
		display: flex;
		align-items: center;
		.MuiIconButton-root {
			padding: 0;
		}
	}
	.gram {
		font-size: 20px;
		// color: ${constanta.COLOR.textPrimary};
		font-family: "DMSans-Bold";
		margin: 8px 0px;
	}

	// @media (max-width: 768px) {
	// 	.wrapper-action {
	// 		padding-left: 48px;
	// 		padding-right: 20px;
	// 		padding-bottom: 12px;
	// 		display: flex;
	// 		flex-direction: row;
	// 		align-items: center;
	// 		justify-content: space-between;
	// 	}
	// 	.title {
	// 		font-size: 16px;
	// 		text-overflow: ellipsis;
	// 		font-weight: normal;
	// 		overflow: hidden;
	// 		white-space: nowrap;
	// 		margin: 4px 0px;
	// 		max-width: 170px;
	// 	}
	// }
`;
