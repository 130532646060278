import { useMediaQuery } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import FaqPageDesktop from "./faq.desktop";
import FaqPageMobile from "./faq.mobile";

const FaqPage = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <FaqPageMobile /> : <FaqPageDesktop />;
};

export default FaqPage;
