import { REGISTER_AFFILIATE } from "../../services/auth.service";
import swal from "sweetalert";

export const submitRegisterAffiliate = async (form, history) => {
	const formData = new FormData();
	formData.append("name", form.name);
	formData.append("phone", form.phone);
	formData.append("email", form.email);
	// formData.append("password", form.password);
	// formData.append("password_confirmation", form.password_confirmation);
	// formData.append("address", form.address);
	// formData.append("instagram_username", form.instagram_username);
	// formData.append("instagram_follower", form.instagram_follower);
	// formData.append("tiktok_username", form.tiktok_username);
	// formData.append("tiktok_follower", form.tiktok_follower);
	// formData.append("youtube_username", form.youtube_username);
	// formData.append("youtube_subscribe", form.youtube_subscribe);
	// formData.append("ratecard", form.ratecard);
	// formData.append("is_recomend", form.is_recomend);
	// formData.append("improvement", form.improvement);
	// formData.append("interest", form.interest);
	// formData.append("recomend_speaker", form.recomend_speaker);

	await REGISTER_AFFILIATE(formData)
		.then((res) => {
			swal("Berhasil", "Selamat anda berhasil register", "success");
			history.push("/login");
		})
		.catch((e) => {
			console.log(e);
			const errorFields = [
				"name",
				"phone",
				"email",
				"referral",
				"address",
				"instagram_username",
				"instagram_follower",
				"tiktok_username",
				"tiktok_follower",
				"youtube_username",
				"youtube_subscribe",
				"ratecard",
				"is_recomend",
				"improvement",
				"interest",
				"recomend_speaker",
			];

			let errorMessage = "";

			errorFields.forEach((field) => {
				const errorValue = e.response.data?.errors?.[field]?.toString();
				if (errorValue) {
					errorMessage += `${errorValue}, \n`;
				}
			});

			errorMessage = errorMessage.slice(0, -2); // Remove the last comma and space

			swal("Gagal", errorMessage, "error");
		});

	return true;
};
