import styled from "styled-components";
import constanta from "../../configs/constanta";
export const WrapLayout = styled.div`
  position: relative;
  width: 100%;
  
  h1,h2,h3,h4,h5,h6,p {margin:0;padding:0;font-family: 'Inter-Regular';}

  h3 { font-size: 20px;}

  .text {
    font-family: 'Inter-Regular';
  }

  .primary {
    color: ${constanta.COLOR.primary};
  }

  .link {
    cursor: pointer;
    color: ${constanta.COLOR.primary};
    font-family: 'Inter-Bold'
  }

  .bold {
    color: #000;
    font-family: 'Inter-Bold'
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${constanta.COLOR.darkPink}
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${constanta.COLOR.darkPink}
  }

  @media (max-width: 500px) {
    overflow: hidden;
    p {
      font-size: 12px;
    }
  }
`;
