import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ProductCard, ProductBundlingCard, Skeleton, EmptyState } from "../../components";
import { Layout } from "../../containers";
import { WrapHome } from "./style";
import { useAuthContext } from "../../contexts/Auth.context";
import { useOrderContext } from "../../contexts/Order.context";
import { FAQ_API } from "../../services/faq.service";
import { ChipContained } from "../../components/chips";
import { Spacer } from "../../components";
import ListAccordion from "./listAccordion";

const FaqPageDesktop = (props) => {
	const { refreshOrderContext } = useOrderContext();
	const { user } = useAuthContext();

	const isMobile = useMediaQuery("(max-width:500px)");
	const { isAuthenticated } = useAuthContext();

	const [faq, setDataFaq] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getFAQ = async () => {
		setIsLoading(true);

		FAQ_API()
			.then((res) => {
				setDataFaq(res);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getFAQ();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const containerStyle = {
		maxWidth: 1220,
		position: "relative",
		margin: "0 auto",
		left: 0,
		right: 0,
	};

	return (
		<WrapHome>
			<Layout {...props} withContainer={false}>
				<div style={!isMobile ? containerStyle : {}}>
					<Grid container spacing={3} style={{ marginTop: 20 }}>
						<Grid
							item
							xs={12}
							style={{
								display: "flex",
								marginBottom: "12px",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div
								style={{
									fontSize: "32px",
									color: "#000",
									fontWeight: "bold",
									display: "flex",
									marginBottom: "12px",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								Frequently Asked Questions Naisha
							</div>
						</Grid>
						<Grid item xs={12}>
							{faq && faq.data
								? faq.data.map((item, index) => {
										return (
											<div key={index}>
												<ListAccordion item={item} />
											</div>
										);
								  })
								: null}
						</Grid>
					</Grid>
				</div>
			</Layout>
		</WrapHome>
	);
};

export default FaqPageDesktop;
