import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapAccount = styled.div`
	display: flex;
	padding: 14px;
	flex-direction: column;

	.account {
		display: flex;
		margin-bottom: 12px;

		.account-text {
			margin-left: 16px;
			display: flex;
			flex-direction: column;
		}

		.account-name {
			font-weight: 600;
		}
		.account-omzet {
			font-size: 14px;
			padding-top: 6px;
		}
	}

	.account-icon {
		padding-right: 6px;
	}

	.account-current-lvl {
		font-weight: 600;
	}

	.account-next-lvl {
		padding-left: 6px;
		color: ${constanta.COLOR.textPrimary};
	}
`;
