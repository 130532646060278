import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapShippingAddress = styled.div`
	position: relative;
	padding: ${(props) => (props.naked ? "0" : "8px 16px")};
	box-shadow: ${(props) => (props.naked ? "none" : "0px 4px 10px rgba(0, 0, 0, 0.1)")};
	border-radius: 4px;
	background: #fff;
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.label {
		padding: 8px 0px;
		display: flex;
		.icon {
			width: 16px;
			height: 16px;
			img {
				width: 100%;
			}
			margin-right: 8px;
		}
		.text {
			color: #333;
			font-size: ${(props) => (props.naked ? "14px" : "12px")};
			width: 50%;
		}
		.action {
			text-align: right;
			width: 50%;
		}
	}
	.list {
		margin-top: 8px;
		padding-bottom: 8px;
	}
	button {
		border: none;
		outline: none;
		color: ${constanta.COLOR.darkPink};
		padding: none;
		background: none;
		cursor: pointer;
		font-size: 12px;
	}
`;
