import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapCheckoutPageMobile = styled.div`
	position: relative;
	width: 100%;
	padding: 60px 0px;
	background-color: #EFF8FC;
	.title {
		display: flex;
		margin: 0px 16px;
		.icon {
			width: 16px;
			height: 16px;
			margin-right: 8px;
			img {
				width: 100%;
			}
		}
		.text {
			font-size: 12px;
			color: #333;
		}
	}
	.shipping {
		position: relative;
		position: relative;
		width: 100%;
		display: block;
		margin: 0px 0px;
		.choose {
			background-color: #fff;
			position: relative;
			cursor: pointer;
			display: block;
			padding: 16px 16px;
			margin: 8px 0;
			border-radius: 4px;
			.icon {
				position: absolute;
				right: 16px;
				top: 16px;
			}
			.text {
				display: flex;

			}
			.icon-before{
				margin-right:8px;
			}
		}
	}
	.input {
		margin: 8px 16px;
		background-color: #fff;
		border-radius: 4px;
		font-size: 12px;
		.MuiInputBase-multiline {
			width: 100%;
			overflow: hidden;
		}
		.MuiInputBase-inputMultiline {
			padding: 8px;
		}
	}
	.summary {
		font-size: 14px;
		margin-bottom: 90px;
		margin-top: 40px;
	}
`;
export const HStack = styled.div`
	display: flex;
	flex-direction: row;
	.summary {
		font-size: 12px;
	}
	.summary-md {
		font-size: 14px;
		font-weight: 700;
	}
`;

export const VStack = styled.div`
	display: flex;
	flex-direction: column;
	.summary {
		font-size: 12px;
	}
	.summary-md {
		font-size: 14px;
		font-weight: 700;
	}
`;

export const Summary = styled.div`
	background-color :#FAFDFF;
    min-height : 173px;
    display : flex;
    
    .pengiriman-section {
        display:flex;
        flex-direction:column;
        
        .title-service {
            margin-bottom :12px;
            display:flex;
            .text {
                padding-left:12px;
            }
            
            .icon {
                width:20px;
                height:20px;
                 img {
                     height:100%;
                     width:100%;
                 }
            }
        }
    }

    .services {
			position: relative;
            background-color :#fff;
			cursor: pointer;
			display: flex;
            padding:12px;
            justify-content : space-between;
            min-height:60px;
            // width:250px;
			border: 1px solid #dddddd;
			border-radius: 8px;
            margin-bottom:12px;
            .service {
                display:flex;
                flex-direction:column;
                padding-left:12px;

                .name-service {
                    text-transform : uppercase;
                    margin-right:8px;
                    font-weight: 700;
                }

                .type-service {
                    font-weight: 700;
                }

                .cost-service {
                     color: ${constanta.COLOR.textPrimary};
                     font-weight:700
                }
            }
			.icon {
				display:flex;
                align-items:center;
                justify-content:center;
                padding-right:12px;
                .txt-ubah {
                    color: ${constanta.COLOR.textPrimary};
                     font-weight:700
                }
			}
`;

export const InputOptional = styled.div`
	// margin: 8px 16px;
	background-color: #fff;
	border: 1px solid #dddddd;
	border-radius: 8px;
	min-height: 60px;
	// width: 250px;
	font-size: 12px;
	.MuiInputBase-multiline {
		width: 100%;
		overflow: hidden;
		padding: 0;
	}
	.MuiInputBase-inputMultiline {
		padding: 10px;
	}
`;
