import { useMediaQuery } from "@material-ui/core";
import React from "react";
import constanta from "../../configs/constanta";
import { WrapButton } from "./style";

const ButtonCustomComponent = ({
	children,
	color = "primary",
	onClick = null,
	style,
	type = "normal",
	active = false,
	fullWidth = true,
	rounded = false,
	isDisabled = false,
	mdSize = false,
	icon,
}) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	const _button = () => {
		let button = <></>;
		if (type === "normal") {
			button = (
				<div className={color} style={{ width: fullWidth ? "100%" : "auto" }}>
					<button
						onClick={onClick}
						style={{ borderRadius: rounded ? 100 : 4, minHeight: mdSize ? 52 : 0 }}
						disabled={isDisabled}
					>
						{icon} {children}
					</button>
				</div>
			);
		} else if (type === "select") {
			button = (
				<div className="select">
					<div className={active ? "active" : ""}>
						<div className={color}>
							<button onClick={onClick} disabled={isDisabled}
								style={{ padding: mdSize ? "8px 20px" : "6px 8px"}}
							>
								{icon} {children}
							</button>
						</div>
					</div>
				</div>
			);
		} else if (type === "submit") {
			button = (
				<div className={color} style={{ width: fullWidth ? "100%" : "auto" }}>
					<button
						onClick={onClick}
						style={{ borderRadius: rounded ? 100 : 4, minHeight: mdSize ? 52 : 0 }}
						type="submit"
						disabled={isDisabled}
					>
						{icon} {children}
					</button>
				</div>
			);
		} else if (type === "naked") {
			button = (
				<div className={color} style={{ width: fullWidth ? "100%" : "auto" }}>
					<button
						onClick={onClick}
						style={{
							borderRadius: rounded ? 100 : 4,
							background: "none",
							border: "1px solid #DDD",
							color: `${constanta.COLOR.darkPink}`,
						}}
						disabled={isDisabled}
					>
						{icon} {children}
					</button>
				</div>
			);
		}
		return button;
	};
	return (
		<WrapButton style={style} isMobile={isMobile}>
			{_button()}
		</WrapButton>
	);
};

export default ButtonCustomComponent;
