import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapProductPage = styled.div`
  position: relative;
  h3 {
    font-size: 16px;
  }
  .information {
    padding-left: 35px;
    .type {
      margin-top: 4px;
      width: 100%;
      display: inline-block;
      button {
        text-transform: uppercase;
        margin-right: 8px;
        float: left;
        margin-bottom: 8px;
      }
    }
    .btnGroup {
      display: flex;
    }
  }
  .desc {
    margin-top: 16px;
    h3 {
      font-size: 16px;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
    }
  }
  .affiliate {
    margin-top: 16px;
    h3 {
      font-size: 16px;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
    }
  }
  .rincian {
    position: relative;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.1);
    border-radius: 8px;
    padding: 16px;  
    h3 {
      margin-bottom: 16px;
    }
    .info {
      display: flex;
      p {
        width: 50%;
        font-size: 14px;
      }
    }
  }
  .shipping {
    position: relative;
    margin-top: 16px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.1);
    border-radius: 8px;
    padding: 16px;  
    h3 {
      margin-bottom: 16px;
    }
    .info {
      display: flex;
      p {
        width: 50%;
        font-size: 14px;
      }
    }
  }
`;

export const ImageViewer = styled.div`
  .imageViewer {
    .big {
      width: 302px;
      height: 302px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      ::before {
        position: relative;
        display: block;
        content: '';
        width: 100%;
        background-image: url(${props => props.bg});
        height: 100%;
        z-index: 1;
        filter: blur(2px);
        background-position-y: 23%;
        background-repeat: no-repeat;
        background-size: cover;
      }
      img {
        position: absolute;
        display: block;
        height: 302px;
        border-radius: 5px;
        text-align: center;
        margin: 0 auto;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
      }
    }
    .wrapSmall {
      display: flex;
      margin-top: 14px;
      .small {
        width: 42px;
        height: 42px;
        overflow: hidden;
        margin-right: 8px;
        cursor: pointer;
        border-radius: 4px;
        img {
          width: 100%;
          border-radius: 5px;
        }
        .active {
          border: 2px solid ${constanta.COLOR.primary};
        }
      }
    }
  }
`

export const WrapProductPageMobile = styled.div`
  position: relative;
  .image {
    width: 100%;
    position: relative;
    .imgProduct {
      width: 300px;
      height: 394px;
      img {
        height: 100%;
        text-align: center;
        position: relative;
        margin: 0 auto;
      }
    }
  }
  .imgCounter {
    position: absolute;
    right: 36px;
    color: #000;
    bottom: 10%;
    background: #fff;
    padding: 6px 8px;
    border-radius: 4px;
  }
  .imgTypeProduct {
    position: absolute;
    left: 36px;
    color: #000;
    bottom: 10%;
    background: #fff;
    padding: 6px 8px;
    border-radius: 4px;
    text-transform: uppercase;
  }
  .information {
    padding: 0px 16px;
    background: #EFF8FC;
    .price {
      margin-bottom: 15px;
      color: ${constanta.COLOR.text};
      p {
        font-family: 'Inter-Bold';
        font-size: 24px;
        line-height: 130%;
      }
    }
    .like {
      margin-top: 28px;
      background: #FA634D;
      border-radius: 5px;
      height: 28px;
      width: 28px;
      border-radius: 50%;
      box-shadow: 0px 7px 15px rgba(255, 33, 0, 0.2);
    }
    .name {
      font-size: 18px;
      color: ${constanta.COLOR.text};
      line-height: 150%;
      font-family: 'Inter-Regular';
    }
    .rating {
      color: #94A3B8;
      font-size: 12px;
      padding: 3px;
    }
    .coupon {
      color: ${constanta.COLOR.white};
      .back{
        border-radius: 4px;
        display: flex;
        overflow: hidden;
      }
      .left{
        background: ${constanta.COLOR.primary};
        width: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        ::after{
          content: '';
          width: 18px;
          height: 18px;
          background: ${constanta.COLOR.primary};
          border-radius: 50%;
          position: absolute;
          right: -9px;
        }
      }
      .right{
        background: #863ED5;
        width: 100%;
        padding: 8px 16px;
        h6{
          font-size: 14px;
          font-family: 'Inter-Bold';
        }
        p{
          font-size: 10px;
          font-family: 'Inter-Regular';
          margin-top: 3x;
        }
      }
    }
    hr {
      margin: 18px 0;
      border-top: solid 6px #E8E8E8; 
    }
    h3 {
      color: ${constanta.COLOR.text};
      font-size: 14px;
      margin-bottom: 16px;
    }
    .type {
      margin-top: 4px;
      width: 100%;
      display: inline-block;
      button {
        text-transform: uppercase;
        margin-right: 8px;
        float: left;
        margin-bottom: 8px;
      }
    }
  }

  .rincian {
    margin-top: 27px;
    padding: 16px 20px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.02);
    color: ${constanta.COLOR.text};
    .status{
      border-radius: 4px;
      background: ${constanta.COLOR.primary};
      color: #fff;
      padding: 6px 8px;
      position: absolute;
      top: 13px;
      right: 20px;
      font-size: 10px;
    }
    table {
      margin-top: 30px;
      width: 100%;
      font-size: 12px;
      margin-bottom: 16px;
      td {
        line-height: 24px;
        span{
          color: ${constanta.COLOR.strk};
        }
      }
    }

    p{
      margin-top: 16px;
      line-height: 24px;
      color: #7F8E9D;
    }
    .desc {
      height: 84px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .affiliate {
      margin-top: 16px;
    }
    button {
      border-radius: 5px;
      font-size: 12px;
      color: ${constanta.COLOR.primary};
      background-color: transparent !important;
      padding: 6px 0px;
      width: 100%;
      border: none;
      margin: 16px 0px;
    }
  }
  .seeAllAlamat {
    position: absolute;
    right: 16px;
    font-size: 12px;
    color: ${constanta.COLOR.primary};
    cursor: pointer;
  }
  .addToCartPopup {
    h3 {
      color: ${constanta.COLOR.text};
      font-size: 14px;
      margin-bottom: 16px;
    }
    .type {
      margin-top: 4px;
      width: 100%;
      display: inline-block;
      button {
        text-transform: uppercase;
        margin-right: 8px;
        float: left;
        margin-bottom: 8px;
      }
    }
  }
`
