import {
	MenuItem,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
	InputAdornment,
	IconButton,
	OutlinedInput,
} from "@material-ui/core";
import { getName, getTypeKabupaten } from "../pages/alamat-page/handler";
import ButtonFileUpload from "../components/ButtonFileUpload/ButtonFileUpload.component";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useState } from "react";

export const mappingForms = (forms, setFormValues, formValues) => {
	let render = [];
	forms.map((form, index) => {
		if (form.type === "text") {
			render.push(
				<div className="formInput" key={index}>
					<TextField
						id={form.id}
						label={form.label}
						variant="outlined"
						fullWidth
						rounded={form.rounded}
						required={form.required}
						defaultValue={form.value}
						onChange={(e) =>
							onChangeText(form.id, e.target.value, setFormValues, formValues)
						}
					/>
					{form.desc && <span>{form.desc}</span>}
				</div>
			);
		} else if (form.type === "number") {
			render.push(
				<div className="formInput" key={index}>
					<TextField
						// id={form.id}
						label={form.label}
						variant="outlined"
						fullWidth
						rounded={form.rounded}
						type="number"
						required={form.required}
						defaultValue={form.value}
						value={form.value}
						onChange={(e) =>
							onChangeText(form.id, e.target.value, setFormValues, formValues)
						}
					/>
					{form.desc && <span>{form.desc}</span>}
				</div>
			);
		} else if (form.type === "multiline") {
			render.push(
				<div className="formInput" key={index}>
					<TextField
						id={form.id}
						label={form.label}
						variant="outlined"
						fullWidth
						rounded={form.rounded}
						multiline
						rows={4}
						required={form.required}
						defaultValue={form.value}
						onChange={(e) =>
							onChangeText(form.id, e.target.value, setFormValues, formValues)
						}
					/>
					{form.desc && <span>{form.desc}</span>}
				</div>
			);
		} else if (form.type === "date") {
			render.push(
				<div className="formInput" key={index}>
					<TextField
						id={form.id}
						label={form.label}
						variant="outlined"
						fullWidth
						rounded={form.rounded}
						type="date"
						required={form.required}
						defaultValue={form.value}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(e) =>
							onChangeText(form.id, e.target.value, setFormValues, formValues)
						}
					/>
					{form.desc && <span>{form.desc}</span>}
				</div>
			);
		} else if (form.type === "file") {
			render.push(
				<div className="formInput" key={index}>
					<ButtonFileUpload
						id={form.id}
						rounded={form.rounded}
						color="secondary"
						hasIcon
						onChange={(e) =>
							onChangeText(
								form.id,
								e.target.files[0],
								setFormValues,
								formValues
							)
						}>
						{form.label}
					</ButtonFileUpload>
					{form.desc && <span>{form.desc}</span>}
				</div>
			);
		} else if (form.type === "select") {
			render.push(
				_selects(
					form.id,
					form.value,
					form.label,
					form.required,
					form.options,
					setFormValues,
					formValues
				)
			);
		} else if (form.type === "checkbox") {
			render.push(
				<div className="formInput" key={index}>
					<FormControlLabel
						control={
							<Checkbox
								checked={form.value}
								onChange={(e) =>
									onChangeText(
										form.id,
										e.target.checked,
										setFormValues,
										formValues
									)
								}
								name={form.label}
								color="primary"
							/>
						}
						label={form.label}
					/>
				</div>
			);
		} else if (form.type === "password") {
			let [showPassword, setShowPassword] = useState(false);
			render.push(
				<div className="formInput" key={index}>
					<OutlinedInput
						id={form.id}
						placeholder={form.label}
						fullWidth
						type={showPassword ? "text" : "password"}
						value={form.value}
						size="small"
						onChange={(e) =>
							onChangeText(form.id, e.target.value, setFormValues, formValues)
						}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowPassword(!showPassword)}>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
					/>
					{form.desc && <span>{form.desc}</span>}
				</div>
			);
		}
		return "";
	});

	return render;
};

export const _selects = (
	id,
	value,
	label,
	isRequired,
	items,
	setFormValues,
	formValues
) => {
	return (
		<div className="formInput" key={id}>
			<TextField
				id={id}
				select
				label={label}
				variant="outlined"
				fullWidth
				required={isRequired}
				defaultValue={value}
				onChange={(e) =>
					onChangeText(id, e.target.value, setFormValues, formValues, items)
				}>
				{items.map((option) => (
					<MenuItem key={option.id} value={option.id}>
						{option.name}
					</MenuItem>
				))}
			</TextField>
		</div>
	);
};

const onChangeText = (id, value, setFormValues, formValues, options) => {
	if (id === "selectProvince") {
		setFormValues({
			...formValues,
			[id]: value,
			selectProvinceName: getName(value, options),
		});
	} else if (id === "selectCity") {
		setFormValues({
			...formValues,
			[id]: value,
			selectCityName: getName(value, options),
			selectCityType: getTypeKabupaten(value, options),
		});
	} else if (id === "selectDistrict") {
		setFormValues({
			...formValues,
			[id]: value,
			selectDistrictName: getName(value, options),
		});
	} else if (id === "selectSubdistrict") {
		setFormValues({
			...formValues,
			[id]: value,
			selectSubdistrictName: getName(value, options),
		});
	} else {
		setFormValues({
			...formValues,
			[id]: value,
		});
	}
};
