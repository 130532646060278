import { isEmpty } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import { ButtonCustom } from '../../components';
import { CUSTOMER_SERVICE_CONSTANT } from '../../configs/constanta';
import { Layout, Navbar } from '../../containers';
import { formatRupiah } from '../../utils/currencyFormat';
import { mappingForms } from '../../utils/formsUtils';
import { handleConfirmationPayment } from './handler';
import { WrapConfirmPayment } from './style';

const getTotalPayment = (payment) => {
  return payment.total + payment.shipping.cost - payment.payment.code_unique;
};

const ConfirmPaymentPageMobile = (props) => {
  const { forms, payment, formValues, setFormValues, isDisabled } = props;
  const history = useHistory();

  return (
    <Layout withNavbar={false} {...props}>
      <Navbar type="navback" title="Konfirmasi Pembayaran" {...props} />
      <WrapConfirmPayment>
        <div className="info">
          <div className="total">
            <p>Total Tagihan</p>
            <p>
              Rp {!isEmpty(payment) && formatRupiah(getTotalPayment(payment))}
            </p>
          </div>
        </div>
        <div className="confirmation">
          <ButtonCustom
            color="secondary"
            mdSize
            onClick={() => {
              props.history.push(
                `/customer-services/${CUSTOMER_SERVICE_CONSTANT.paymentService}`
              );
            }}
          >
            Konfirmasi Via WhatsApp
          </ButtonCustom>
          <p>atau Konfirmasi Langsung</p>
          <div className="form">
            {mappingForms(forms, setFormValues, formValues)}
          </div>
        </div>
        <div className="floating">
          <ButtonCustom
            style={{ margin: '0px 8px' }}
            
            isDisabled={isDisabled}
            onClick={() => {
              handleConfirmationPayment({
                history: history,
                payload: {
                  ...formValues,
                  order_id: payment.id,
                  payment_method: payment.payment.payment_method.id,
                  amount: getTotalPayment(payment),
                },
              });
            }}
          >
            Konfirmasi
          </ButtonCustom>
        </div>
      </WrapConfirmPayment>
    </Layout>
  );
};

export default ConfirmPaymentPageMobile;
