const ORDER_STAGES = {
  waiting_payment: 'waiting_payment',
  forwarded: 'forwarded',
  processed: 'processed',
  delivery: 'delivery',
  done: 'done',
  canceled: 'canceled',
  canceledAdmin: 'canceled_admin',
};

export default ORDER_STAGES;
