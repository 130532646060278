import React, { useState, useEffect } from "react";
import { WrapDetailBundling } from "./style";
import NoImageProduct from "../../assets/img/noImage.svg";
import IconDiscount from "../../assets/icons/icDiscount.svg";
import { useMediaQuery, Button } from "@material-ui/core";
import { formatRupiah } from "../../utils/currencyFormat";
import { useHistory } from "react-router";

const DetailProductBundlingCard = ({ product, userType, buyBundle }) => {
	let history = useHistory();
	const isMobile = useMediaQuery("(max-width:500px)");

	let priceBundling;

	if (product.items !== undefined) {
		priceBundling =
			product.items
				.map((e) => (e.product != null ? e.product.skus[0].price.bundling_price : 0))
				.reduce((a, b) => a + b) ?? 0;
	} else {
		priceBundling = "";
	}

	return (
		<div style={{ width: isMobile ? 160 : 260 }}>
			<WrapDetailBundling>
				<div style={{ padding: "12px" }}>
					<h4 style={{ marginBottom: "24px" }}>{product?.name}</h4>
					<div style={{ display: "flex", flexDirection: "column" }}>
						{product.items !== undefined
							? product.items.map((data, index) => {
									let price;
									let discount;
									let nameProduct =
										data.product.name.length > 80
											? data.product.name.substring(0, 80) + "..."
											: data.product.name;

									price = data.product.skus[0].price.bundling_price;

									discount = data.product.skus[0].price.user_without_disc;

									return (
										<div
											style={{ display: "flex", paddingBottom: "20px" }}
											key={index}
										>
											<div
												style={{
													height: "80px",
													width: "80px",
													flexShrink: "0",
												}}
											>
												<img
													src={data.product?.image_url ?? NoImageProduct}
													alt="product"
													style={{
														height: "100%",
														width: "100%",
														borderTopLeftRadius: "8px",
														borderBottomLeftRadius: "8px",
													}}
												/>
											</div>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													marginLeft: "14px",
												}}
											>
												<div className="title">
													<span
														style={{
															fontSize: "12px",
															fontWeight: "bold",
														}}
													>
														{nameProduct}
													</span>
												</div>
												{discount === 0 ? null : (
													<>
														<div className="discount">
															<div
																className="price-strike"
																style={{ fontSize: "12px" }}
															>
																Rp {formatRupiah(discount)}
															</div>
														</div>
													</>
												)}
												<div className="price">
													<p style={{ fontSize: "12px" }}>
														Rp {formatRupiah(price)}
													</p>
												</div>
											</div>
										</div>
									);
							  })
							: null}
					</div>
				</div>
			</WrapDetailBundling>
			<div
				style={{
					paddingTop: "12px",
					display: "flex",
					marginBottom: "12px",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<span style={{ fontSize: "14px" }}>Harga Bundling</span>
					<div className="price">
						<p style={{ fontSize: "12px" }}>Rp {formatRupiah(priceBundling)}</p>
					</div>
				</div>
				<div>
					<Button
						style={{ color: "white", borderRadius: "100px" }}
						variant="contained"
						color="primary"
						size="sm"
						onClick={() => {
							buyBundle(product.items);
						}}
					>
						Beli Bundling
					</Button>
				</div>
			</div>
		</div>
	);
};

export default DetailProductBundlingCard;
