import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _, { isEmpty } from "lodash";
import {
	ButtonCustom,
	ProductCard,
	ProductSlider,
	QuantityCounter,
	ShippingAddress,
	Skeleton,
	Stars,
	PreOrder,
} from "../../components";
import { Layout } from "../../containers";
import { ImageViewer, WrapProductPage } from "./style";
import {
	onClickTotalProduct,
	onSelectColorDesktop,
	onSelectColor,
	onSelectedImage,
	onSelectSize,
	handleAddToCart,
	handleBuyNow,
	copyProductLink,
} from "./handler";
import { formatRupiah } from "../../utils/currencyFormat";
import { useAuthContext } from "../../contexts/Auth.context";
import { useOrderContext } from "../../contexts/Order.context";
import swal from "sweetalert";
import constanta from "../../configs/constanta";

const ProductPageDesktop = (props) => {
	const {
		isLoading,
		product,
		imageProducts,
		setImageProducts,
		uniqueImagesProduct,
		otherProducts,
		dataAlamat,
	} = props;
	const { user, isAuthenticated } = useAuthContext();
	const { refreshOrderContext } = useOrderContext();
	const [selectedProduct, setSelectedProduct] = useState({
		// index: 0,
		name: "",
		slug: "",
		color: "",
		size: "",
		code: "",
		is_preorder: 0,
		day_preorder: 0,
		total: 1,
		stock: 10,
		object: {},
		// price : ""
	});

	const [state, setState] = useState({
		isDisabledButton: true,
		isRefetch: false,
		isShowAddToCart: false,
	});

	const _mappingThumbnail = () => {
		let items = [];
		let render = [];
		imageProducts.map((item) => {
			items.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
			});
			return "";
		});
		_.uniqBy(items, "color").map((item, index) => {
			render.push(
				<div
					className="small"
					key={item.code}
					onClick={() =>
						onSelectedImage(
							setImageProducts,
							imageProducts,
							item.code,
							setSelectedProduct,
							selectedProduct
						)
					}>
					<img
						src={item.image_url}
						alt={`product-${product.name}-${item.color}`}
						className={`${item.isSelected && "active"}`}
					/>
				</div>
			);
			return "";
		});
		return render;
	};

	const _mappingColor = () => {
		let button = [];
		let render = [];
		imageProducts.map((item) => {
			button.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
				stock: item.stock,
			});
			return "";
		});
		_.uniqBy(button, "color").map((item, index) => {
			render.push(
				<ButtonCustom
					type="select"
					key={index}
					active={item.color === selectedProduct.color}
					isDisabled={item.stock === 0}
					onClick={() =>
						onSelectColorDesktop(
							item.color,
							index,
							selectedProduct,
							setSelectedProduct
						)
					}>
					{`${item.color} ${item.stock === 0 ? `( Habis )` : ""}`}
				</ButtonCustom>
			);
			return "";
		});
		return render;
	};

	const _mappingSize = () => {
		let button = [];
		let render = [];
		imageProducts.map((item) => {
			button.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
			});
			return "";
		});
		button
			.filter((item) => item.color === selectedProduct.color)
			.map((item, index) => {
				render.push(
					<ButtonCustom
						type="select"
						key={index}
						active={item.size === selectedProduct.size}
						onClick={() =>
							onSelectSize(item.size, selectedProduct, setSelectedProduct)
						}>
						{item.size}
					</ButtonCustom>
				);
				return "";
			});
		return render;
	};

	// const getPriceCustomer = () => {
	// 	let render;
	// 	if (isAuthenticated) {
	// 		if (!isEmpty(selectedProduct.object)) {
	// 			render = formatRupiah(selectedProduct.object.price.consumer);
	// 		} else {
	// 			render = formatRupiah(
	// 				uniqueImagesProduct[selectedProduct.index]?.price?.consumer ?? 0,
	// 			);
	// 		}
	// 	} else {
	// 		if (!isEmpty(selectedProduct.object)) {
	// 			render = formatRupiah(selectedProduct.object.price.consumer);
	// 		} else {
	// 			render = formatRupiah(
	// 				uniqueImagesProduct[selectedProduct.index]?.price?.consumer ?? 0,
	// 			);
	// 		}
	// 	}
	// 	return (
	// 		<div style={{ paddingLeft: "14px" }}>
	// 			<p style={{ margin: 0 }}>Harga Customer</p>
	// 			<p style={{ margin: 0 }}>Rp {render}</p>
	// 		</div>
	// 	);
	// };

	const getPriceReseller = () => {
		let priceUser;
		let priceCustomer;
		let priceComission;
		let renderPercen;
		let rawPercen;
		const min_price = formatRupiah(product?.min_price?.consumer_price_idr ?? 0);
		const max_price = formatRupiah(
			product?.max_price?.consumer_price_idr ?? min_price
		);
		const getMinPrice = () => {
			return product.skus.reduce((min, sku) => {
				return sku.price.reseller < min ? sku.price.reseller : min;
			}, Infinity);
		};

		const getMaxPrice = () => {
			return product.skus.reduce((max, sku) => {
				return sku.price.reseller > max ? sku.price.reseller : max;
			}, 0);
		};

		function getPercentDiscount(beforePrice, currentPrice) {
			let resultPrice = (
				((beforePrice - currentPrice) / beforePrice) *
				100
			).toFixed(0);

			if (parseInt(resultPrice) !== 0) {
				return parseInt(resultPrice);
			} else {
				return "";
			}
		}
		function getPercentDiscountWithoutFixed(beforePrice, currentPrice) {
			let resultPrice = ((beforePrice - currentPrice) / beforePrice) * 100;

			if (parseInt(resultPrice) !== 0) {
				return parseInt(resultPrice);
			} else {
				return "";
			}
		}

		if (isAuthenticated) {
			if (!isEmpty(selectedProduct.object)) {
				priceUser = formatRupiah(selectedProduct.object.price.user ?? 0);
				priceCustomer = selectedProduct.object.price.consumer;
				priceComission = selectedProduct.object.price.commission;

				renderPercen = getPercentDiscount(
					priceCustomer,
					selectedProduct.object.price.user
				);
				rawPercen = getPercentDiscountWithoutFixed(
					priceCustomer,
					selectedProduct.object.price.user
				);
			} else {
				priceUser =
					formatRupiah(getMinPrice()) + " - " + formatRupiah(getMaxPrice());
				priceCustomer = min_price + " - " + max_price;
				priceComission =
					uniqueImagesProduct[selectedProduct.index]?.price?.commission ?? 0;
				renderPercen = getPercentDiscount(priceCustomer, min_price);
				rawPercen = getPercentDiscountWithoutFixed(priceCustomer, priceUser);
			}
		} else {
			if (!isEmpty(selectedProduct.object)) {
				priceUser = formatRupiah(selectedProduct.object.price.user ?? 0);
				priceCustomer = selectedProduct.object.price.consumer;
				priceComission = selectedProduct.object.price.commission;
				renderPercen = getPercentDiscount(
					priceCustomer,
					selectedProduct.object.price.user
				);
				rawPercen = getPercentDiscountWithoutFixed(
					priceCustomer,
					selectedProduct.object.price.user
				);
			} else {
				priceUser =
					formatRupiah(getMinPrice()) + " - " + formatRupiah(getMaxPrice());
				priceCustomer = min_price + " - " + max_price;
				priceComission =
					uniqueImagesProduct[selectedProduct.index]?.price?.commission ?? 0;
				renderPercen = getPercentDiscount(priceCustomer, min_price);
				rawPercen = getPercentDiscountWithoutFixed(priceCustomer, priceUser);
			}
		}

		let resultComission = selectedProduct?.object?.price?.commission ?? 0;

		return (
			<div>
				{!isEmpty(user) && user.customer_type?.name === "Customer" ? null : (
					<>
						{renderPercen ? (
							<div style={{ display: "flex" }}>
								<div
									style={{
										backgroundColor: "rgba(116, 214, 214, 0.2)",
										borderRadius: "4px",
										padding: "0px 8px",
										color: "rgb(77, 182, 172)",
										marginRight: "12px",
									}}>
									- {renderPercen}%
								</div>
								<div
									style={{
										color: "rgb(149, 149, 149)",
										textDecoration: "line-through",
									}}>
									{/* Rp. {formatRupiah(priceCustomer)} */}
									Rp. {priceUser}
								</div>
							</div>
						) : null}
					</>
				)}

				<div style={{ display: "flex", marginTop: "12px" }}>
					{isEmpty(user) ||
					(!isEmpty(user) && user.customer_type?.name) === "Customer" ? null : (
						<div style={{ paddingRight: "14px" }}>
							<p style={{ margin: 0 }}>Harga Reseller</p>
							<p style={{ margin: 0 }}> Rp. {formatRupiah(priceUser)}</p>
						</div>
					)}
					<div>
						<p style={{ margin: 0 }}>Harga Customer</p>
						<p style={{ margin: 0 }}>Rp. {formatRupiah(priceCustomer)}</p>
					</div>
				</div>
				{!isEmpty(user) && user.customer_type?.name === "Customer" ? null : (
					<>
						{isNaN(rawPercen) ? null : (
							<div
								className="stock"
								style={{ display: "flex", marginTop: "12px" }}>
								<span style={{ paddingRight: "8px" }}>Komisi Anda:</span>
								<span
									style={{
										color: constanta.COLOR.primary,
										fontWeight: "bold",
									}}>
									{`(${rawPercen}%)`}
								</span>

								<span
									style={{
										marginLeft: "12px",
										color: constanta.COLOR.primary,
										fontWeight: "bold",
									}}>
									Rp. {formatRupiah(resultComission)}
								</span>
							</div>
						)}
					</>
				)}
			</div>
		);
	};

	const addToCart = async () => {
		swal({
			title: "Tambah ke Keranjang",
			text: "apakah kamu yakin ingin menambahkan produk ini ke keranjang ?",
			buttons: {
				cancel: "Batal",
				ok: "Ok",
			},
		}).then((value) => {
			if (value === "ok") {
				handleAddToCart(selectedProduct);
				refreshOrderContext();
				swal(
					"Success",
					"Berhasil menambahkan produk kedalam keranjang",
					"success"
				);
			}
		});
	};

	const buyNow = async () => {
		swal({
			title: "Beli sekarang ?",
			text: "apakah kamu yakin ingin membeli produk ini sekarang ?",
			buttons: {
				cancel: "Batal",
				ok: "Ok",
			},
		}).then((value) => {
			if (value === "ok") {
				handleBuyNow(selectedProduct, props.history);
				refreshOrderContext();
				if (isAuthenticated) {
					window.location = "/login";
				}
			}
		});
	};

	useEffect(() => {
		const updateSelectedProduct = async () => {
			setSelectedProduct({
				...selectedProduct,
				name: product.name,
				slug: product.slug,
				color: imageProducts[0]?.properties?.color ?? "",
				// size: imageProducts[0]?.properties?.size ?? "",
				is_preorder: imageProducts[0]?.properties?.is_preorder ?? 0,
				day_preorder: imageProducts[0]?.properties?.day_preorder ?? 0,
			});
		};
		updateSelectedProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageProducts]);

	useEffect(() => {
		const identifySelected = async () => {
			!isEmpty(product.skus) &&
				product.skus.map((sku) => {
					if (sku.properties.color === selectedProduct.color) {
						if (selectedProduct.size !== "") {
							sku.properties.size === selectedProduct.size &&
								setSelectedProduct({
									...selectedProduct,
									code: sku.code,
									stock: sku.stock,
									object: sku,
								});
						}
					}
					return "";
				});
		};
		if (selectedProduct.size !== "" && selectedProduct.color !== "") {
			setState({
				...state,
				isDisabledButton: false,
			});
		}

		identifySelected();
	}, [selectedProduct.color, selectedProduct.size]);

	return (
		<WrapProductPage>
			<Layout {...props}>
				<Grid container style={{ marginTop: 32 }}>
					<Grid item xs={3}>
						{isLoading ? (
							<Skeleton type="imageView" />
						) : (
							<ImageViewer
								bg={
									!isEmpty(selectedProduct.object)
										? selectedProduct.object.image_url
										: imageProducts.filter((image) => image.isSelected)[0]
												.image_url
								}>
								<div className="imageViewer">
									<div className="big">
										<img
											src={
												!isEmpty(selectedProduct.object)
													? selectedProduct.object.image_url
													: imageProducts.filter((image) => image.isSelected)[0]
															.image_url
											}
											alt={`product-${product.name}`}
										/>
									</div>
									<div className="wrapSmall">{_mappingThumbnail()}</div>
								</div>
							</ImageViewer>
						)}
					</Grid>
					<Grid item xs={6}>
						<div className="information">
							{isLoading ? (
								<Skeleton type="productDetail.desc" />
							) : (
								<>
									{!isLoading && (
										<>
											<div>
												<h4>{product.name}</h4>
											</div>
											<div style={{ paddingTop: "4px" }}>
												<Stars count={4} />
											</div>
										</>
									)}
									{product.show_stock ? (
										<div className="stok" style={{ paddingTop: "4px" }}>{`${
											selectedProduct.stock === 0
												? "Stock Habis"
												: `Tersedia stok ${selectedProduct.stock} barang`
										}`}</div>
									) : null}

									<div className="price" style={{ paddingTop: "4px" }}>
										{getPriceReseller()}
									</div>
									{selectedProduct.is_preorder !== 0 ? (
										<div style={{ marginTop: 16 }}>
											<PreOrder days={selectedProduct.day_preorder} />
										</div>
									) : null}
									<div style={{ marginTop: 16 }}>
										<h3>Warna:</h3>
										<div className="type">{_mappingColor()}</div>
									</div>
									<div style={{ marginTop: 16 }}>
										<h3>Ukuran:</h3>
										<div className="type">{_mappingSize()}</div>
									</div>
									<div style={{ marginTop: 16, position: "relative" }}>
										<h3>Jumlah:</h3>
										<QuantityCounter
											total={selectedProduct.total}
											style={{
												position: "relative",
												margin: "16px 0px",
											}}
											onClickMinus={() =>
												onClickTotalProduct(
													"minus",
													selectedProduct,
													setSelectedProduct
												)
											}
											onClickPlus={() =>
												onClickTotalProduct(
													"plus",
													selectedProduct,
													setSelectedProduct
												)
											}
										/>
									</div>
									<div className="btnGroup">
										<ButtonCustom
											isDisabled={state.isDisabledButton}
											onClick={() => buyNow()}>
											Beli Sekarang
										</ButtonCustom>
										<ButtonCustom
											type="naked"
											style={{ marginLeft: 8 }}
											isDisabled={state.isDisabledButton}
											onClick={() => addToCart()}>
											Masukan ke Keranjang
										</ButtonCustom>
									</div>
									<div className="desc">
										<h3>Deskripsi</h3>
										{!isEmpty(product) &&
											product.description
												.split("\n")
												.map((desc, index) => <p key={index}>{desc}</p>)}
									</div>
									{isEmpty(user) ||
									(!isEmpty(user) &&
										user.customer_type?.name === "Customer") ? null : (
										<div className="affiliate">
											<h3>
												Jadi Affiliator Naisha, Dapatkan Komisi{" "}
												{user.commision_affliliate
													? `${user.commision_affliliate}%`
													: "Menarik"}
											</h3>
											<p>Copy link di bawah ini dan bagikan ke teman kamu</p>
											<div className="btnGroup" style={{ marginTop: 16 }}>
												<ButtonCustom
													type="naked"
													fullWidth={false}
													isDisabled={user == null}
													onClick={() => {
														copyProductLink(product.slug);
													}}>
													Copy Link
												</ButtonCustom>
											</div>
										</div>
									)}
								</>
							)}
						</div>
					</Grid>
					<Grid item xs={3}>
						{isLoading ? (
							<Skeleton type="productDetail.rincian" />
						) : (
							<>
								<div className="rincian">
									<h3>Rincian Produk</h3>
									<div className="info">
										<p>SKU</p>
										<p>
											{!isEmpty(selectedProduct.object)
												? selectedProduct.object.code
												: "-"}
										</p>
									</div>
									<div className="info">
										<p>Berat</p>
										<p>
											{!isEmpty(selectedProduct.object)
												? `${selectedProduct.object.weight_gram} gram`
												: "-"}
										</p>
									</div>
									{product.show_stock ? (
										<div className="info">
											<p>Stok</p>
											<p>
												{!isEmpty(selectedProduct.object)
													? selectedProduct.object.stock
													: "-"}
											</p>
										</div>
									) : null}
									<div className="info">
										<p>Bahan</p>
										<p>
											{!isEmpty(selectedProduct.object)
												? selectedProduct.object.properties.material
												: "-"}
										</p>
									</div>
								</div>
								<div className="shipping">
									<h3>Pengiriman</h3>
									<div className="info">
										<p>Dari</p>
										<p>D.I Yogyakarta</p>
									</div>
									{!isEmpty(dataAlamat) && (
										<ShippingAddress listAddress={dataAlamat} naked />
									)}
								</div>
							</>
						)}
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: 16, marginBottom: 68 }}>
					<Grid item xs={12} style={{ padding: "0px 16px" }}>
						<h4>Produk Lainnya</h4>
					</Grid>
					<Grid item xs={12}>
						{!isLoading ? (
							<ProductSlider>
								{!isEmpty(otherProducts) &&
									otherProducts.map((item) => {
										if (isEmpty(user)) {
											return (
												<ProductCard
													role={!isEmpty(user) ? user.customer_type?.name : ""}
													name={item.name}
													price={
														!isEmpty(user) &&
														user.customer_type?.name === "Customer"
															? item.skus[0].price.consumer
															: item.skus[0].price.user
													}
													image_url={item.image_url}
													key={item.slug}
													slug={item.slug}
												/>
											);
										} else {
											return (
												<ProductCard
													name={item.name}
													price={item.skus[0].price.consumer}
													image_url={item.image_url}
													key={item.slug}
													slug={item.slug}
												/>
											);
										}
									})}
							</ProductSlider>
						) : (
							<Skeleton type="product" />
						)}
					</Grid>
				</Grid>
			</Layout>
		</WrapProductPage>
	);
};

export default ProductPageDesktop;
