import React, { useState, useEffect } from "react";
import {
	GET_PROFILE,
	PUT_PROFILE,
	PUT_PHOTO_PROFILE,
} from "../../services/user.service";
import { Spacer } from "../../components";
import LayoutAccount from "../../containers/LayoutAccount";
import {
	Grid,
	Card,
	Button,
	Avatar,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	CircularProgress,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";

const ProfilePage = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState({});
	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			...user,
		},
	});

	const onSubmit = async (value) => {
		setIsLoading(true);
		let payload = {
			phone_code_id: 62,
			...value,
		};
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		await PUT_PROFILE(user.id, payload)
			.then((res) => {
				swal("Success", "Berhasil merubah profile saya", "success");
				retrieveDataUser();
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleSubmitUpload = (e) => {
		e.preventDefault();
		let dataImage = e.target.files[0];
		var formData = new FormData();
		formData.append("avatar", dataImage);

		let token = JSON.parse(localStorage.getItem("TOKEN"));

		if (dataImage) {
			setIsLoading(true);
			PUT_PHOTO_PROFILE(user.id, formData, token.accessToken)
				.then((response) => {
					swal("Success", "Berhasil merubah photo profile saya", "success");
					retrieveDataUser();
				})
				.catch((err) => {
					swal("Error", "Gagal merubah photo profile saya", "success");
				});
		} else {
			setIsLoading(false);
		}
	};

	const retrieveDataUser = () => {
		setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		GET_PROFILE(user.id)
			.then((res) => {
				let initialUser = {
					name: res.data.name,
					phone: res.data.phone,
					email: res.data.email,
					gender: res.data.gender,
					bio: res.data.bio,
					id: res.data.id,
					profile_photos: res.data.profile_photos,
					birthday: res.data.birthday,
					referral_code: res.data.referral_code ?? "",
					customer_type_id: res.data.customer_type_id,
				};
				setUser(initialUser);
				reset(initialUser);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		retrieveDataUser();
	}, []);

	const profilePhoto = user.profile_photos
		? user.profile_photos.medium.url
		: "https://via.placeholder.com/150";
	return (
		<LayoutAccount {...props}>
			<Card className="card-custom">
				<div
					style={{
						padding: "14px",
						minHeight: "calc(100vh - 227px)",
						marginLeft: "8px",
						marginRight: "8px",
					}}>
					<div
						style={{
							borderBottom: "1px solid #EEEEEE",
							paddingBottom: "12px",
						}}>
						<span style={{ fontSize: "20px", color: "#333333" }}>
							Profile Saya
						</span>
					</div>
					<Spacer />

					<div style={{ display: "flex", flexDirection: "column" }}>
						{isLoading ? (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									height: "400px",
									justifyContent: "center",
								}}>
								<CircularProgress disableShrink />
								<p style={{ marginTop: "14px" }}>Loading</p>
							</div>
						) : (
							<>
								{" "}
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "20px",
									}}>
									<Grid container>
										<Grid item xs={2}>
											<div
												style={{
													height: "100%",
													display: "flex",
													paddingTop: "32px",
												}}>
												<span>Profile Foto</span>
											</div>
										</Grid>
										<Grid item xs={10}>
											<div>
												<Avatar
													alt="photo saya"
													src={profilePhoto}
													style={{ height: "100px", width: "100px" }}
												/>
												<div style={{ marginTop: "14px" }}>
													<input
														accept="image/*"
														style={{ display: "none" }}
														id="contained-button-file"
														multiple
														type="file"
														onChange={handleSubmitUpload}
													/>
													<label htmlFor="contained-button-file">
														<Button
															variant="outlined"
															color="primary"
															component="span">
															Pilih Photo
														</Button>
													</label>
												</div>
											</div>
										</Grid>
									</Grid>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "20px",
									}}>
									<Grid container spacing={1}>
										<Grid item xs={2}>
											<div
												style={{
													height: "100%",
													display: "flex",
													alignItems: "center",
												}}>
												<span>Nama</span>
											</div>
										</Grid>
										<Grid item xs={5}>
											<Controller
												name="name"
												control={control}
												render={({ field }) => {
													return (
														<TextField
															{...field}
															placeholder={"Nama"}
															variant="outlined"
															fullWidth
															rounded={true}
															disabled={isLoading}
														/>
													);
												}}
											/>
										</Grid>
									</Grid>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "20px",
									}}>
									<Grid container spacing={1}>
										<Grid item xs={2}>
											<div
												style={{
													height: "100%",
													display: "flex",
													alignItems: "center",
												}}>
												<span>Jenis Kelamin</span>
											</div>
										</Grid>
										<Grid item xs={10}>
											<Controller
												name="gender"
												control={control}
												render={({ field }) => {
													return (
														<RadioGroup
															{...field}
															name="gender"
															style={{ flexDirection: "row" }}
															aria-label="gender"
															value={`${field.value}`}>
															<FormControlLabel
																value="0"
																control={<Radio />}
																label="Laki-Laki"
																color="primary"
															/>
															<FormControlLabel
																value="1"
																control={<Radio />}
																label="Perempuan"
																color="primary"
															/>
														</RadioGroup>
													);
												}}
											/>
										</Grid>
									</Grid>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "20px",
									}}>
									<Grid container spacing={1}>
										<Grid item xs={2}>
											<div
												style={{
													height: "100%",
													display: "flex",
													alignItems: "center",
												}}>
												<span>Tanggal Lahir</span>
											</div>
										</Grid>
										<Grid item xs={5}>
											<Controller
												name="birthday"
												control={control}
												render={({ field }) => {
													return (
														<TextField
															{...field}
															type="date"
															placeholder={"Tanggal Lahir"}
															variant="outlined"
															fullWidth
															rounded={true}
															disabled={isLoading}
														/>
													);
												}}
											/>
										</Grid>
									</Grid>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "20px",
									}}>
									<Grid container spacing={1}>
										<Grid item xs={2}>
											<div
												style={{
													height: "100%",
													display: "flex",
													alignItems: "center",
												}}>
												<span>Email</span>
											</div>
										</Grid>
										<Grid item xs={5}>
											<Controller
												name="email"
												control={control}
												render={({ field }) => {
													return (
														<TextField
															{...field}
															placeholder={"Email"}
															variant="outlined"
															fullWidth
															rounded={true}
															disabled={isLoading}
														/>
													);
												}}
											/>
										</Grid>
									</Grid>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "20px",
									}}>
									<Grid container spacing={1}>
										<Grid item xs={2}>
											<div
												style={{
													height: "100%",
													display: "flex",
													alignItems: "center",
												}}>
												<span>No Telepon</span>
											</div>
										</Grid>
										<Grid item xs={5}>
											<Controller
												name="phone"
												control={control}
												render={({ field }) => {
													return (
														<TextField
															type="number"
															{...field}
															placeholder={"No Telepon"}
															variant="outlined"
															fullWidth
															rounded={true}
															disabled={isLoading}
														/>
													);
												}}
											/>
										</Grid>
									</Grid>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "20px",
									}}>
									<Grid container spacing={1}>
										<Grid item xs={2}>
											<div
												style={{
													height: "100%",
													display: "flex",
													alignItems: "center",
												}}>
												<span>Bio</span>
											</div>
										</Grid>
										<Grid item xs={5}>
											<Controller
												name="bio"
												control={control}
												render={({ field }) => {
													return (
														<TextField
															{...field}
															placeholder={"Bio"}
															variant="outlined"
															fullWidth
															rounded={true}
															disabled={isLoading}
														/>
													);
												}}
											/>
										</Grid>
									</Grid>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "20px",
									}}>
									<Grid container spacing={1}>
										<Grid item xs={2}>
											<div
												style={{
													height: "100%",
													display: "flex",
													alignItems: "center",
												}}>
												<span>Kode Referral</span>
											</div>
										</Grid>
										<Grid item xs={5}>
											{user && user?.customer_type_id == 11 ? ( // 11 = Affiliate
												<Controller
													name="referral_code"
													control={control}
													render={({ field }) => {
														return (
															<TextField
																{...field}
																placeholder={"Kode Referral"}
																variant="outlined"
																fullWidth
																rounded={true}
																disabled={isLoading}
																inputProps={{
																	readOnly:
																		user?.customer_type_id == 11 ? false : true,
																}}
															/>
														);
													}}
												/>
											) : (
												<div
													style={{
														height: "100%",
														display: "flex",
														alignItems: "center",
													}}>
													<span>{user?.referral_code}</span>
												</div>
											)}
										</Grid>
									</Grid>
								</div>
							</>
						)}
					</div>
					<div style={{ width: "100%" }}>
						<Button
							size="large"
							style={{ width: "100%", color: "white" }}
							onClick={handleSubmit((value) => {
								onSubmit(value);
							})}
							color="primary"
							variant="contained">
							Simpan Perubahan
						</Button>
					</div>
				</div>
			</Card>
		</LayoutAccount>
	);
};

export default ProfilePage;
