import { useMediaQuery } from "@material-ui/core";
import React from "react";
import DropshipperDesktop from "./dropshipper.desktop";
import DropshipperMobile from "./dropshipper.mobile";

const DropshipperPage = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	return isMobile ? <DropshipperMobile {...props} /> : <DropshipperDesktop {...props} />;
};
export default DropshipperPage;
