import { isEmpty } from "lodash";

export const handleAddAdress = (formsValue, history) => {
	let getLocal = JSON.parse(localStorage.getItem("ALAMAT_CUSTOMER"));
	if (isEmpty(getLocal)) {
		getLocal.push({
			...formsValue,
			isPrimary: true,
		});
	} else {
		getLocal.push({
			...formsValue,
			isPrimary: false,
		});
	}
	localStorage.setItem("ALAMAT_CUSTOMER", JSON.stringify(getLocal));
	history.push("/akun/alamat");
};

export const handleEditAdress = (index, formsValue, history) => {
	let getLocal = JSON.parse(localStorage.getItem("ALAMAT_CUSTOMER"));
	getLocal[index] = formsValue;
	localStorage.setItem("ALAMAT_CUSTOMER", JSON.stringify(getLocal));
	history.push("/akun/alamat");
};

export const getName = (id, arrays) => {
	return arrays.filter((object) => object.id === id)[0]?.name ?? "";
};

export const getTypeKabupaten = (id, arrays) => {
	return arrays.filter((object) => object.id === id)[0]?.type ?? "";
};

export const setToPrimary = (index, isChecked, dataAlamat, setDataAlamat, setIsRefetch) => {
	let cloneArrays = [];
	dataAlamat.map((alamat) => {
		cloneArrays.push({
			...alamat,
			isPrimary: false,
		});
		return "";
	});
	cloneArrays[index] = {
		...cloneArrays[index],
		isPrimary: isChecked,
	};
	localStorage.setItem("ALAMAT_CUSTOMER", JSON.stringify(cloneArrays));
	setDataAlamat(cloneArrays);
	setIsRefetch(true);
};

export const handleDeleteAlamat = (index, dataAlamat, setIsRefetch) => {
	let cloneArrays = dataAlamat;
	cloneArrays.splice(index, 1);
	localStorage.setItem("ALAMAT_CUSTOMER", JSON.stringify(cloneArrays));
	setIsRefetch(true);
};
