import { Box, Tab, Tabs, withStyles } from "@material-ui/core";
import React from "react";
import { Layout, Navbar } from "../../containers";
import constanta from "../../configs/constanta";
import { WrapperOrder } from "./style";
import OrderCard from "../../components/OrderCard/OrderCard.component";
import { EmptyState, Skeleton } from "../../components";
import FileIcon from "../../assets/img/Paper.png";
import { isEmpty } from "lodash";

const StyledTabs = withStyles({
	indicator: {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
		"& > span": {
			width: "100%",
			backgroundColor: constanta.COLOR.textPrimary,
		},
	},
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
	root: {
		textTransform: "none",
		color: "#666666",
		fontFamily: "DMSans-Regular",
		fontWeight: "normal",
		fontSize: "15px",
		marginRight: theme.spacing(1),
		"&$selected": {
			color: constanta.COLOR.textPrimary,
			fontWeight: "normal",
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<span>{children}</span>
				</Box>
			)}
		</div>
	);
};

const a11yProps = (index) => {
	return {
		id: `tab-${index}`,
		"aria-controls": `tabpanel-${index}`,
	};
};

const OrderPageMobile = (props) => {
	const { isLoading, orders, tab, handleClick, handleSetTabs } = props;

	return (
		<Layout withNavbar={false} {...props}>
			<Navbar type="navback" title="Pesanan Saya" {...props} />
			<WrapperOrder>
				<div className="orders">
					<StyledTabs
						value={tab}
						indicatorColor="primary"
						onChange={(e, value) => handleSetTabs(value)}
						variant="scrollable"
						aria-label="tabs"
					>
						<StyledTab label="Belum Bayar" {...a11yProps(0)} disabled={isLoading} />
						<StyledTab label="Konfirmasi Bayar" {...a11yProps(1)} disabled={isLoading} />
						<StyledTab label="Diproses" {...a11yProps(2)} disabled={isLoading} />
						<StyledTab label="Dikirim" {...a11yProps(3)} disabled={isLoading} />
						<StyledTab label="Diterima" {...a11yProps(4)} disabled={isLoading} />
						<StyledTab label="Dibatalkan" {...a11yProps(5)} disabled={isLoading} />
					</StyledTabs>
				</div>
				<div className="orders-content">
					{isLoading ? (
						<Skeleton type="productList" />
					) : isEmpty(orders) ? (
						<div className="empty">
							<EmptyState title="Tidak ada transaksi" image={FileIcon} />
						</div>
					) : (
						<TabPanel value={tab} index={tab}>
							{orders.map((value, index) => {
								return (
									<OrderCard
										key={index}
										orders={value}
										handleClick={(type, payload) => {
											handleClick(type, payload);
										}}
									/>
								);
							})}
						</TabPanel>
					)}
				</div>
			</WrapperOrder>
		</Layout>
	);
};

export default OrderPageMobile;
