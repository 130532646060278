import { Checkbox, Grid, Card, Button } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import {
	EmptyState,
	ProductCardFull,
	Spacer,
	ProductCardBundling,
} from "../../components";
import { ReactComponent as IconTrashGray } from "../../assets/icons/iconTrashGray.svg";
import { LayoutWithAuth } from "../../containers";
import { useAuthContext } from "../../contexts/Auth.context";
import { useOrderContext } from "../../contexts/Order.context";
import {
	EST_SHIPPING_COST,
	GET_COURIER_RECOMENDATION,
} from "../../services/supportData.service";
import {
	handleChecked,
	handleCheckedAll,
	handleDelete,
	handleDeleteAll,
	handlePlusMinus,
	handlePlusMinusBundling,
	handleDeleteBundling,
	subTotalPrice,
	subTotalPriceBundling,
} from "./handler";
import { formatRupiah } from "../../utils/currencyFormat";
import { WrapCartPage, HStack, VStack } from "./style";

const CartPageDesktop = (props) => {
	const { user } = useAuthContext();
	const { refreshOrderContext } = useOrderContext();
	const [cart, setCart] = useState([]);
	const [isRefetch, setIsRefetch] = useState(false);
	let localstorage = JSON.parse(localStorage.getItem("CART"));

	const _mappingCart = () => {
		let render = [];
		return cart.map((item, index) => {
			if (item.type === "bundling") {
				render = (
					<ProductCardBundling
						productBundling={item}
						withCheckbox={true}
						type="default"
						isChecked={item.isChecked}
						handleChecked={(e) =>
							handleChecked(index, e.target.checked, setCart)
						}
						onDelete={() => handleDeleteBundling(item.id, cart, setIsRefetch)}
						handleMinus={() =>
							handlePlusMinusBundling("minus", item.id, cart, setIsRefetch)
						}
						handlePlus={() =>
							handlePlusMinusBundling("plus", item.id, cart, setIsRefetch)
						}
						quantity={item.total}
					/>
				);
			} else {
				render = (
					<ProductCardFull
						key={index}
						name={item.name}
						image_url={item.object.image_url}
						price={
							!isEmpty(user) && user.customer_type?.name === "Customer"
								? item.object.price.consumer
								: item.object.price.user
						}
						type="cart"
						// discount = '',
						slug={item.slug}
						color={item.color}
						size={item.size}
						is_preorder={item.is_preorder}
						day_preorder={item.day_preorder}
						onDelete={() => handleDelete(item.code, cart, setIsRefetch)}
						handleMinus={() =>
							handlePlusMinus("minus", item.code, cart, setIsRefetch)
						}
						handlePlus={() =>
							handlePlusMinus("plus", item.code, cart, setIsRefetch)
						}
						quantity={item.total}
						isChecked={item.isChecked}
						handleChecked={(e) =>
							handleChecked(index, e.target.checked, setCart)
						}
					/>
				);
			}
			return <div>{render}</div>;
		});
	};

	const countTotalPrice = (cart, user) => {
		let pcs = subTotalPrice(cart, user);
		let bundling = subTotalPriceBundling(cart);
		let total = pcs + bundling;
		return total;
	};

	useEffect(() => {
		const fetch = () => {
			let temp = [];
			if (localstorage !== null) {
				localstorage.map((item) => {
					temp.push({
						...item,
						isChecked: false,
					});
					return "";
				});
				setCart(temp);
				localStorage.setItem("CART", JSON.stringify(temp));
			}
			refreshOrderContext();
		};

		const getCourierRecomendatiion = async () => {
			const recommendationCourier = await GET_COURIER_RECOMENDATION();
			if (recommendationCourier) {
				localStorage.setItem(
					"RECOMMENDATION_COURIER",
					JSON.stringify(recommendationCourier.data)
				);
			}
		};

		fetch();
		getCourierRecomendatiion();
		localStorage.removeItem("SHIPPING_SERVICES");
	}, []);

	useEffect(() => {
		const fetch = () => {
			let temp = [];
			localstorage.map((item) => {
				temp.push(item);
				return "";
			});
			setCart(temp);
			localStorage.setItem("CART", JSON.stringify(temp));
			refreshOrderContext();
			setIsRefetch(false);
		};
		isRefetch && fetch();
	}, [isRefetch]);

	return (
		<LayoutWithAuth {...props}>
			<div style={{ marginTop: 32, minHeight: "calc(100vh - 227px)" }}>
				<WrapCartPage>
					{isEmpty(cart) ? (
						<EmptyState title="Tidak ada produk di keranjang" />
					) : (
						<>
							<p>Keranjang Belanja</p>
							<Spacer />
							<Grid container spacing={4}>
								<Grid item xs={9}>
									<Card
										className="card-custom"
										style={{
											marginBottom: "12px",
										}}>
										<HStack
											style={{
												alignItems: "center",
												justifyContent: "space-between",
												minHeight: "58px",
												paddingLeft: "8px",
												paddingRight: "14px",
											}}>
											<HStack style={{ alignItems: "center" }}>
												<Checkbox
													color="primary"
													onChange={(e) =>
														handleCheckedAll(e.target.checked, setCart)
													}
												/>
												Pilih Semua
											</HStack>
											{cart.filter((x) => x.isChecked).length >= 1 && (
												<div
													style={{
														marginRight: "14px",
														cursor: "pointer",
													}}
													onClick={() =>
														handleDeleteAll(cart, setCart, setIsRefetch)
													}>
													<IconTrashGray />
												</div>
											)}
										</HStack>
									</Card>

									{_mappingCart()}
								</Grid>
								<Grid item xs={3}>
									<Card className="card-custom">
										<VStack style={{ maxHeight: "121px", padding: "14px" }}>
											<HStack style={{ justifyContent: "space-between" }}>
												<span>Total Harga</span>
												<span>
													Rp {formatRupiah(countTotalPrice(cart, user))}
												</span>
											</HStack>
											<Spacer variant="vertical" size="20" />
											<Button
												variant="contained"
												color="primary"
												style={{
													color: "#fff",
												}}
												onClick={() => props.history.push("/checkout")}>
												Checkout ({cart.filter((x) => x.isChecked).length})
											</Button>
										</VStack>
									</Card>
								</Grid>
							</Grid>
						</>
					)}
				</WrapCartPage>
				{/* <MenuBottom
					style={{ position: "relative", margin: "32px 0px" }}
					type="checkout"
					submitText="Checkout"
					isDisabled={!(cart.filter((x) => x.isChecked).length >= 1)}
					price={subTotalPrice(cart, user)}
					count={cart.filter((x) => x.isChecked).length}
					onSubmit={() => props.history.push("/checkout")}
				/> */}
			</div>
		</LayoutWithAuth>
	);
};

export default CartPageDesktop;
