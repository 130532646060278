import { GET_PRODUCTS, GET_DETAIL_BUNDLING } from "../../../services/products.service";
import _, { isEmpty } from "lodash";

export const getProductBundling = async (
	id,
	setProduct,
	setImageProducts,
	setUniqueImagesProduct,
	setProductBundlings
) => {
	let images = [];
	let items = [];
	let uniqueItems = [];
	let payloadFilter = {
		id: id,
		include: "items.product.skus",
	};
	await GET_DETAIL_BUNDLING(payloadFilter).then((res) => {
		setProduct(res.data[0].items[0].product);

		setProductBundlings(res.data[0]);
		res.data[0].items[0].product.skus.map((sku) => {
			images.push({
				...sku,
				isSelected: false,
			});
			return "";
		});
		images[0] = {
			...res.data[0].items[0].product.skus[0],
			isSelected: true,
		};
		setImageProducts(images);
	});

	images.map((item) => {
		items.push({
			...item.properties,
			image_url: item.image_url,
			code: item.code,
			price: item.price,
			isSelected: false,
		});
		return "";
	});
	_.uniqBy(items, "color").map((item, index) => {
		uniqueItems.push(item);
		return "";
	});
	uniqueItems[0] = {
		...uniqueItems[0],
		isSelected: true,
	};

	setUniqueImagesProduct(uniqueItems);
};

export const getProducts = async (filters, setOtherProducts) => {
	await GET_PRODUCTS(filters).then((res) => {
		setOtherProducts(res.data);
	});
};

export const onSelectedImage = async (
	setImageProducts,
	imageProducts,
	code,
	setSelectedProduct,
	selectedProduct
) => {
	setSelectedProduct({
		...selectedProduct,
		object: {},
	});
	let index = _.findIndex(imageProducts, { code: code });
	let clone = [];
	await imageProducts.map((image) => {
		clone.push({
			...image,
			isSelected: false,
		});
		return "";
	});
	clone[index] = {
		...clone[index],
		isSelected: true,
	};
	setImageProducts(clone);
};

export const onSelectColor = async (
	color,
	index,
	selectedProduct,
	setSelectedProduct,
	refSlider
) => {
	refSlider.slickGoTo(index);
	setSelectedProduct({
		...selectedProduct,
		color,
		index,
	});
};

export const onSelectColorDesktop = async (color, index, selectedProduct, setSelectedProduct) => {
	setSelectedProduct({
		...selectedProduct,
		color,
		index,
	});
};

export const onSelectSize = async (size, selectedProduct, setSelectedProduct) => {
	setSelectedProduct({
		...selectedProduct,
		size,
	});
};

export const onClickTotalProduct = (type, state, setState) => {
	if (type === "plus") {
		state.total !== state.stock &&
			setState({
				...state,
				total: state.total + 1,
			});
	} else {
		state.total !== 1 &&
			setState({
				...state,
				total: state.total - 1,
			});
	}
};

export const handleAddToCart = (selectedProduct) => {
	let localCart = JSON.parse(localStorage.getItem("CART") || "[]");
	if (isEmpty(localCart)) {
		localCart.push(selectedProduct);
		localStorage.setItem("CART", JSON.stringify(localCart));
	} else {
		let index = _.findIndex(localCart, { code: selectedProduct.code });
		if (index >= 0) {
			localCart[index] = {
				...localCart[index],
				total: localCart[index].total + selectedProduct.total,
			};
		} else {
			localCart.push(selectedProduct);
		}
		localStorage.setItem("CART", JSON.stringify(localCart));
	}
	return "";
};

export const handleBuyNow = async (selectedProduct, history) => {
	await handleAddToCart(selectedProduct);
	history.push("/cart");
};
