import { Radio, withStyles } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { ButtonCustom } from "../../components";
import constanta from "../../configs/constanta";
import { Layout, Navbar } from "../../containers";
import { GET_PAYMENTS_METHOD } from "../../services/supportData.service";
import { handleSelectPayment, handleSubmitSelect } from "./handler";
import { WrapPaymentMethodPage } from "./style";

const PaymentMethodMobilePage = (props) => {
  const [listPaymentMethod, setListPaymentMethod] = useState([])
  const [isRefetch, setIsRefetch] = useState(false)

  const PinkRadio = withStyles({
    root: {
      color: `${constanta.COLOR.primary}`,
      "&$checked": {
        color: `${constanta.COLOR.primary}`,
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const isDisabledButton = listPaymentMethod.filter(x => x.isChecked).length > 0 ? false : true
  useEffect(() => {
    const fetch = async () => {
      let tempListPayment = []
      let getListPayment = await GET_PAYMENTS_METHOD()
      getListPayment.data.map( item => {
        tempListPayment.push({
          ...item,
          isChecked: false
        })
        return''
      })
      setListPaymentMethod(tempListPayment)
      localStorage.setItem("PAYMENTS_METHOD", JSON.stringify(tempListPayment))
    }
    fetch()
  }, [])

  useEffect(() => {
    const fetch = async () => {
      const getLocalStorage = JSON.parse(localStorage.getItem("PAYMENTS_METHOD"))
      setListPaymentMethod(getLocalStorage)
      setIsRefetch(false)
    }
    isRefetch && fetch()
  }, [isRefetch])

  return (
    <Layout withNavbar={false} {...props}>
      <Navbar type="navback" title="Metode Pembayaran" {...props} />
      <WrapPaymentMethodPage>
        {
          !isEmpty(listPaymentMethod) && listPaymentMethod.map((item, index) => (
            <div className="list" key={index}>
              <div className="logo">
                <img src={item.bank.icon} alt={item.bank.name} />
              </div>
              <div className="info">
                {/* <span>{item.bank.name}</span> */}
                <span>{item.account_number}</span>
                <span>{item.account_name}</span>
              </div>
              <div className="radioBtn">
                <PinkRadio
                checked={item.isChecked}
                onChange={() => handleSelectPayment(item.id, listPaymentMethod, setListPaymentMethod, setIsRefetch)}
                value={item.service}
              />
              </div>
            </div>
          ))
        }
         <div className="floating">
            <ButtonCustom
              isDisabled={isDisabledButton}
              mdSize
              onClick={() => handleSubmitSelect(listPaymentMethod, setIsRefetch, props.history)}
            >
              Konfirmasi
            </ButtonCustom>
          </div>
      </WrapPaymentMethodPage>
    </Layout>
  );
};
export default PaymentMethodMobilePage;
