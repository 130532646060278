import React from "react";
import constanta from "../../configs/constanta";
import { Typography } from "@material-ui/core";

export const ContainerIcon = ({ img, text, onClickIcon }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				cursor: "pointer",
			}}
			onClick={() => {
				if (onClickIcon) {
					onClickIcon();
				}
			}}
		>
			<div
				style={{
					height: "50px",
					width: "50px",
					backgroundColor: constanta.COLOR.primary,
					borderRadius: "6px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img src={img} alt="gambar-hadiah" />
			</div>
			<Typography
				variant="body2"
				component="span"
				color="primary"
				style={{
					fontSize: "14px",
					color: "#333",
					paddingTop: "6px",
				}}
			>
				{text}
			</Typography>
		</div>
	);
};

export const ContainerIconMobile = ({ img, text, onClickIcon }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				cursor: "pointer",
			}}
			onClick={() => {
				if (onClickIcon) {
					onClickIcon();
				}
			}}
		>
			<div
				style={{
					height: "40px",
					width: "40px",
					backgroundColor: constanta.COLOR.primary,
					borderRadius: "6px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img
					style={{
						height: "40px",
						width: "40px",
					}}
					src={img}
					alt="gambar-hadiah"
				/>
			</div>
			<Typography
				variant="body2"
				component="span"
				color="primary"
				style={{
					fontSize: "12px",
					color: "#333",
					paddingTop: "6px",
				}}
			>
				{text}
			</Typography>
		</div>
	);
};

export const ContainerIconCircle = ({ img, text, onClickIcon }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				cursor: "pointer",
			}}
			onClick={() => {
				if (onClickIcon) {
					onClickIcon();
				}
			}}
		>
			<div
				style={{
					height: "88px",
					width: "88px",
					borderRadius: "50%",
					backgroundColor: "#FEF1F8",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img src={img} alt={text} />
			</div>
			<Typography
				variant="body2"
				component="span"
				color="primary"
				style={{
					fontSize: "14px",
					color: "#333",
					paddingTop: "6px",
				}}
			>
				{text}
			</Typography>
		</div>
	);
};

export const ContainerIconCircleMobile = ({ img, text, onClickIcon }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				cursor: "pointer",
			}}
			onClick={() => {
				if (onClickIcon) {
					onClickIcon();
				}
			}}
		>
			<div
				style={{
					height: "60px",
					width: "60px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img
					src={img}
					alt={text}
				/>
			</div>
			<Typography
				variant="h5"
				component="span"
				fontFamily="Inter-Regular"
				color="primary"
				style={{
					fontSize: "14px",
					color: "#fff",
					paddingTop: "6px",
				}}
			>
				{text}
			</Typography>
		</div>
	);
};

export const ContainerIconBasicMobile = ({ img, text, onClickIcon }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				cursor: "pointer",
			}}
			onClick={() => {
				if (onClickIcon) {
					onClickIcon();
				}
			}
			}
		>
			<div
				style={{
					height: "44px",
					width: "38px",
					backgroundColor: constanta.COLOR.primary,
					borderRadius: "6px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img
					style={{
						height: "24px",
						width: "24px",
					}}
					src={img}
					alt={text}
				/>
			</div>
		</div>
	);
};
