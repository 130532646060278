import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapProductPage = styled.div`
	position: relative;
	h3 {
		font-size: 16px;
	}
	.information {
		padding-left: 35px;
		.type {
			margin-top: 4px;
			width: 100%;
			display: inline-block;
			button {
				text-transform: uppercase;
				margin-right: 8px;
				float: left;
				margin-bottom: 8px;
			}
		}
		.btnGroup {
			display: flex;
		}
	}
	.desc {
		margin-top: 16px;
		h3 {
			font-size: 16px;
			margin-bottom: 8px;
		}
		p {
			font-size: 14px;
		}
	}
	.rincian {
		position: relative;
		background: #fff;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 16px;
		h3 {
			margin-bottom: 16px;
		}
		.info {
			display: flex;
			p {
				width: 50%;
				font-size: 14px;
			}
		}
	}
	.shipping {
		position: relative;
		margin-top: 16px;
		background: #fff;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 16px;
		h3 {
			margin-bottom: 16px;
		}
		.info {
			display: flex;
			p {
				width: 50%;
				font-size: 14px;
			}
		}
	}
`;

export const ImageViewer = styled.div`
	.imageViewer {
		.big {
			width: 302px;
			height: 302px;
			border-radius: 5px;
			overflow: hidden;
			position: relative;
			::before {
				position: relative;
				display: block;
				content: "";
				width: 100%;
				background-image: url(${(props) => props.bg});
				height: 100%;
				z-index: 1;
				filter: blur(2px);
				background-position-y: 23%;
				background-repeat: no-repeat;
				background-size: cover;
			}
			img {
				position: absolute;
				display: block;
				height: 302px;
				border-radius: 5px;
				text-align: center;
				margin: 0 auto;
				top: 0;
				left: 0;
				right: 0;
				z-index: 99;
			}
		}
		.wrapSmall {
			display: flex;
			margin-top: 14px;
			.small {
				width: 42px;
				height: 42px;
				overflow: hidden;
				margin-right: 8px;
				cursor: pointer;
				border-radius: 4px;
				img {
					width: 100%;
					border-radius: 5px;
				}
				.active {
					border: 2px solid ${constanta.COLOR.primary};
				}
			}
		}
	}
`;

export const WrapProductPageMobile = styled.div`
	position: relative;
	.image {
		width: 100%;
		position: relative;
		.imgProduct {
			width: 300px;
			height: 394px;
			img {
				height: 100%;
				text-align: center;
				position: relative;
				margin: 0 auto;
			}
		}
	}
	.imgCounter {
		position: absolute;
		right: 36px;
		color: #000;
		bottom: 10%;
		background: #fff;
		padding: 2px 8px;
		border-radius: 5px;
	}
	.imgTypeProduct {
		position: absolute;
		left: 36px;
		color: #000;
		bottom: 10%;
		background: #fff;
		padding: 2px 8px;
		border-radius: 5px;
		text-transform: uppercase;
	}
	.information {
		padding: 0px 16px;
		.price {
			color: ${constanta.COLOR.darkPink};
			font-size: 18px;
			font-family: "DMSans-Bold";
			margin-top: 8px;
		}
		h3 {
			color: #333;
			font-size: 12px;
		}
		.type {
			margin-top: 4px;
			width: 100%;
			display: inline-block;
			button {
				text-transform: uppercase;
				margin-right: 8px;
				float: left;
				margin-bottom: 8px;
			}
		}
	}

	.rincian {
		margin-top: 8px;
		table {
			width: 100%;
			font-size: 12px;
			margin-bottom: 16px;
			td {
				line-height: 24px;
			}
		}
		p {
			margin-top: 16px;
			line-height: 24px;
		}
		.desc {
			height: 84px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		button {
			border-radius: 5px;
			background: #ededed;
			font-size: 12px;
			color: #333;
			padding: 6px 0px;
			width: 100%;
			border: none;
			margin: 16px 0px;
		}
	}
	.seeAllAlamat {
		position: absolute;
		right: 16px;
		font-size: 12px;
		color: ${constanta.COLOR.primary};
		cursor: pointer;
	}
	.addToCartPopup {
		.type {
			margin-top: 4px;
			width: 100%;
			display: inline-block;
			button {
				text-transform: uppercase;
				margin-right: 8px;
				float: left;
				margin-bottom: 8px;
			}
		}
	}
`;

export const WrapBundlingPage = styled.div`
	position: relative;
	width: 100%;

	.checkAll {
		color: #999;
		font-size: 14px;
		background: #fff;
		padding: 16px;
	}
	.items {
		margin: 0px 12px;
		margin-bottom: 80px;
	}
	.deleteAll {
		position: absolute;
		right: 12px;
		margin-top: 12px;
	}

	@media (max-width: 768px) {
		position: relative;
		width: 100%;
		padding-top: 70px;

		.items {
			margin: 14px;
			margin-bottom: 80px;
		}
	}
`;

export const HStack = styled.div`
	display: flex;
	flex-direction: row;
`;

export const VStack = styled.div`
	display: flex;
	flex-direction: column;
`;
