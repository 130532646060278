import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _, { isEmpty } from "lodash";
import {
	ButtonCustom,
	ProductCard,
	ProductSlider,
	QuantityCounter,
	ShippingAddress,
	Skeleton,
	Stars,
	DetailProductBundlingCard,
} from "../../../components";
import { Layout } from "../../../containers";
import { ImageViewer, WrapProductPage } from "../style";
import {
	onClickTotalProduct,
	onSelectColorDesktop,
	onSelectedImage,
	onSelectSize,
	handleAddToCart,
	handleBuyNow,
	copyProductLink,
} from "./handler";
import { formatRupiah } from "../../../utils/currencyFormat";
import { useAuthContext } from "../../../contexts/Auth.context";
import { useOrderContext } from "../../../contexts/Order.context";
import swal from "sweetalert";
import BundlingPage from "../bundling-checkout/BundlingPage.desktop";

const ProductPageDesktop = (props) => {
	const {
		isLoading,
		product,
		imageProducts,
		setImageProducts,
		uniqueImagesProduct,
		otherProducts,
		dataAlamat,
		productBundlings,
	} = props;
	const { user } = useAuthContext();
	const { refreshOrderContext } = useOrderContext();
	const [selectedProduct, setSelectedProduct] = useState({
		index: 0,
		name: "",
		slug: "",
		color: "",
		size: "",
		code: "",
		total: 1,
		stock: 10,
		object: {},
	});

	const [state, setState] = useState({
		isDisabledButton: true,
		isRefetch: false,
		isShowAddToCart: false,
	});

	const _mappingThumbnail = () => {
		let items = [];
		let render = [];
		imageProducts.map((item) => {
			items.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
			});
			return "";
		});
		_.uniqBy(items, "color").map((item, index) => {
			render.push(
				<div
					className="small"
					key={item.code}
					onClick={() =>
						onSelectedImage(
							setImageProducts,
							imageProducts,
							item.code,
							setSelectedProduct,
							selectedProduct
						)
					}>
					<img
						src={item.image_url}
						alt={`product-${product.name}-${item.color}`}
						className={`${item.isSelected && "active"}`}
					/>
				</div>
			);
			return "";
		});
		return render;
	};

	const _mappingColor = () => {
		let button = [];
		let render = [];
		imageProducts.map((item) => {
			button.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
			});
			return "";
		});
		_.uniqBy(button, "color").map((item, index) => {
			render.push(
				<ButtonCustom
					type="select"
					key={index}
					active={item.color === selectedProduct.color}
					onClick={() =>
						onSelectColorDesktop(
							item.color,
							index,
							selectedProduct,
							setSelectedProduct
						)
					}>
					{item.color}
				</ButtonCustom>
			);
			return "";
		});
		return render;
	};

	const _mappingSize = () => {
		let button = [];
		let render = [];
		imageProducts.map((item) => {
			button.push({
				...item.properties,
				image_url: item.image_url,
				code: item.code,
			});
			return "";
		});
		button
			.filter((item) => item.color === selectedProduct.color)
			.map((item, index) => {
				render.push(
					<ButtonCustom
						type="select"
						key={index}
						active={item.size === selectedProduct.size}
						onClick={() =>
							onSelectSize(item.size, selectedProduct, setSelectedProduct)
						}>
						{item.size}
					</ButtonCustom>
				);
				return "";
			});
		return render;
	};

	const getPriceAfterSelectSize = () => {
		if (!isEmpty(selectedProduct.object)) {
			return user.customer_type?.name === "Customer"
				? formatRupiah(selectedProduct.object.price.consumer)
				: formatRupiah(selectedProduct.object.price.user);
		} else {
			return user.customer_type?.name === "Customer"
				? formatRupiah(
						uniqueImagesProduct[selectedProduct.index]?.price?.consumer ?? 0
				  )
				: formatRupiah(
						uniqueImagesProduct[selectedProduct.index]?.price?.user ?? 0
				  );
		}
	};

	const addToCart = async () => {
		swal({
			title: "Tambah ke Keranjang",
			text: "apakah kamu yakin ingin menambahkan produk ini ke keranjang ?",
			buttons: {
				cancel: "Batal",
				ok: "Ok",
			},
		}).then((value) => {
			if (value === "ok") {
				handleAddToCart(selectedProduct);
				refreshOrderContext();
				swal(
					"Success",
					"Berhasil menambahkan produk kedalam keranjang",
					"success"
				);
			}
		});
	};

	const buyNow = async () => {
		swal({
			title: "Beli sekarang ?",
			text: "apakah kamu yakin ingin membeli produk ini sekarang ?",
			buttons: {
				cancel: "Batal",
				ok: "Ok",
			},
		}).then((value) => {
			if (value === "ok") {
				handleBuyNow(selectedProduct, props.history);
				refreshOrderContext();
			}
		});
	};

	useEffect(() => {
		const updateSelectedProduct = async () => {
			setSelectedProduct({
				...selectedProduct,
				name: product.name,
				slug: product.slug,
				color: imageProducts[0]?.properties?.color ?? "",
			});
		};
		updateSelectedProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageProducts]);

	useEffect(() => {
		const identifySelected = async () => {
			!isEmpty(product.skus) &&
				product.skus.map((sku) => {
					if (sku.properties.color === selectedProduct.color) {
						if (selectedProduct.size !== "") {
							sku.properties.size === selectedProduct.size &&
								setSelectedProduct({
									...selectedProduct,
									code: sku.code,
									stock: sku.stock,
									object: sku,
								});
						}
					}
					return "";
				});
		};
		if (selectedProduct.size !== "" && selectedProduct.color !== "") {
			setState({
				...state,
				isDisabledButton: false,
			});
		}

		identifySelected();
	}, [selectedProduct.color, selectedProduct.size]);

	return (
		<WrapProductPage>
			<Layout {...props}>
				<Grid container style={{ marginTop: 32 }}>
					<Grid item xs={3}>
						{isLoading ? (
							<Skeleton type="imageView" />
						) : (
							<ImageViewer
								bg={
									!isEmpty(selectedProduct.object)
										? selectedProduct.object.image_url
										: imageProducts.filter((image) => image.isSelected)[0]
												.image_url
								}>
								<div className="imageViewer">
									<div className="big">
										<img
											src={
												!isEmpty(selectedProduct.object)
													? selectedProduct.object.image_url
													: imageProducts.filter((image) => image.isSelected)[0]
															.image_url
											}
											alt={`product-${product.name}`}
										/>
									</div>
									<div className="wrapSmall">{_mappingThumbnail()}</div>
								</div>
							</ImageViewer>
						)}
						<div style={{ paddingTop: "20px", display: "flex" }}>
							<span style={{ fontSize: "60px", paddingRight: "12px" }}>+</span>
							{productBundlings ? (
								<DetailProductBundlingCard
									product={productBundlings}
									buyBundle={(products) =>
										props.history.push(
											`/bundling-checkout/${props.match.params.id}`
										)
									}
									// userType={user.customer_type?.name}
								/>
							) : null}
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className="information">
							{isLoading && <Skeleton type="productDetail.desc" />}
							{!isLoading && (
								<>
									<div>
										<h4>{product.name}</h4>
									</div>
									<div>
										<Stars count={4} />
									</div>
								</>
							)}
							<div className="price">
								Rp {!isEmpty(user) ? getPriceAfterSelectSize() : 0}
							</div>
							<div className="stok">
								Tersedia stok {selectedProduct.stock} barang
							</div>
							<div style={{ marginTop: 16 }}>
								<h3>Warna:</h3>
								<div className="type">{_mappingColor()}</div>
							</div>
							<div style={{ marginTop: 16 }}>
								<h3>Ukuran:</h3>
								<div className="type">{_mappingSize()}</div>
							</div>
							<div style={{ marginTop: 16, position: "relative" }}>
								<h3>Jumlah:</h3>
								<QuantityCounter
									total={selectedProduct.total}
									style={{ position: "relative", margin: "16px 0px" }}
									onClickMinus={() =>
										onClickTotalProduct(
											"minus",
											selectedProduct,
											setSelectedProduct
										)
									}
									onClickPlus={() =>
										onClickTotalProduct(
											"plus",
											selectedProduct,
											setSelectedProduct
										)
									}
								/>
							</div>
							<div className="btnGroup">
								<ButtonCustom
									isDisabled={state.isDisabledButton}
									onClick={() => buyNow()}>
									Beli Sekarang
								</ButtonCustom>
								<ButtonCustom
									type="naked"
									style={{ marginLeft: 8 }}
									isDisabled={state.isDisabledButton}
									onClick={() => addToCart()}>
									Masukan ke Keranjang
								</ButtonCustom>
							</div>
							<div className="desc">
								<h3>Deskripsi</h3>
								{!isEmpty(product) &&
									product.description
										.split("\n")
										.map((desc, index) => <p key={index}>{desc}</p>)}
							</div>
						</div>
					</Grid>
					<Grid item xs={3}>
						<div className="rincian">
							{isLoading && <Skeleton type="productDetail.rincian" />}
							<h3>Rincian Produk</h3>
							<div className="info">
								<p>SKU</p>
								<p>
									{!isEmpty(selectedProduct.object)
										? selectedProduct.object.code
										: "-"}
								</p>
							</div>
							<div className="info">
								<p>Berat</p>
								<p>
									{!isEmpty(selectedProduct.object)
										? `${selectedProduct.object.weight_gram} gram`
										: "-"}
								</p>
							</div>
							<div className="info">
								<p>Stok</p>
								<p>
									{!isEmpty(selectedProduct.object)
										? selectedProduct.object.stock
										: "-"}
								</p>
							</div>
							<div className="info">
								<p>Bahan</p>
								<p>
									{!isEmpty(selectedProduct.object)
										? selectedProduct.object.properties.material
										: "-"}
								</p>
							</div>
						</div>

						<div className="shipping">
							<h3>Pengiriman</h3>
							<div className="info">
								<p>Dari</p>
								<p>D.I Yogyakarta</p>
							</div>
							{!isEmpty(dataAlamat) && (
								<ShippingAddress listAddress={dataAlamat} naked />
							)}
						</div>
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: 16, marginBottom: 68 }}>
					<Grid item xs={12} style={{ padding: "0px 16px" }}>
						<h4>Produk Lainnya</h4>
					</Grid>
					<Grid item xs={12}>
						{!isLoading ? (
							<ProductSlider>
								{!isEmpty(otherProducts) &&
									otherProducts.map((item) => {
										if (!isEmpty(user)) {
											return (
												<ProductCard
													role={!isEmpty(user) ? user.customer_type?.name : ""}
													name={item.name}
													price={
														!isEmpty(user) &&
														user.customer_type?.name === "Customer"
															? item.skus[0].price.consumer
															: item.skus[0].price.user
													}
													image_url={item.image_url}
													key={item.slug}
													slug={item.slug}
												/>
											);
										} else {
											return (
												<ProductCard
													name={item.name}
													price={item.skus[0].price.consumer}
													image_url={item.image_url}
													key={item.slug}
													slug={item.slug}
												/>
											);
										}
									})}
							</ProductSlider>
						) : (
							<Skeleton type="product" />
						)}
					</Grid>
				</Grid>
			</Layout>
		</WrapProductPage>
	);
};

export default ProductPageDesktop;
