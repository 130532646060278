import {
	Card,
	CardActions,
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	Box,
	styled,
} from "@material-ui/core";
import React from "react";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
import { ButtonCustom, ProductCardFull } from "..";
import { useAuthContext } from "../../contexts/Auth.context";
import { formatRupiah } from "../../utils/currencyFormat";
import { WrapOrderCard } from "./style";
import STATUS_ORDERS from "../../configs/statusOrders";
import { ReactComponent as IconClock } from "../../assets/icons/iconClock.svg";
import { ReactComponent as IconArrowRight } from "../../assets/icons/iconArrowRight.svg";
import { ORDERS_HANDLER_CONSTANT } from "../../configs/constanta";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const _getActionButton = (orders, handleClick, history) => {
	const { id, status, shipping, items } = orders;
	switch (status.code) {
		case STATUS_ORDERS.waiting_payment.code:
			return (
				<CardActions className="actionButtonWrapper">
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<ButtonCustom
								type="naked"
								onClick={() =>
									handleClick(ORDERS_HANDLER_CONSTANT.cancel, { id })
								}>
								Batalkan Pesanan
							</ButtonCustom>
						</Grid>
						<Grid item xs={6}>
							<ButtonCustom
								onClick={() =>
									handleClick(ORDERS_HANDLER_CONSTANT.payment, { id })
								}>
								Bayar Sekarang
							</ButtonCustom>
						</Grid>
					</Grid>
				</CardActions>
			);
		case STATUS_ORDERS.forwarded.code:
			return (
				<CardActions className="actionButtonWrapper">
					<div className="inline">
						<IconClock /> <span>Proses Pengecekan</span>
					</div>
				</CardActions>
			);
		case STATUS_ORDERS.processed.code:
			return (
				<div className="container">
					<p className="text-primary">
						Barang akan tiba {shipping.estimated ?? "- hari"} yang akan datang
					</p>
					{/* <CardActions className="actionButtonWrapper">
						<ButtonCustom
							type="naked"
							onClick={() => handleClick(ORDERS_HANDLER_CONSTANT.cancel, { id })}
						>
							Batalkan Pemesanan
						</ButtonCustom>
					</CardActions> */}
				</div>
			);
		case STATUS_ORDERS.delivery.code:
			return (
				<CardActions className="actionButtonWrapper">
					<ButtonCustom
						fullWidth
						onClick={() => handleClick(ORDERS_HANDLER_CONSTANT.done, { id })}>
						Pesanan Diterima
					</ButtonCustom>
				</CardActions>
			);
		case STATUS_ORDERS.done.code:
			return (
				<CardActions className="actionButtonWrapper">
					<ButtonCustom
						onClick={() =>
							history.push(`/product/${items[0].sku.product.slug}`)
						}>
						Beli Lagi
					</ButtonCustom>
				</CardActions>
			);
		case STATUS_ORDERS.canceled.code:
			return (
				<CardActions className="actionButtonWrapper">
					<ButtonCustom
						onClick={() =>
							history.push(`/product/${items[0].sku.product.slug}`)
						}>
						Beli Lagi
					</ButtonCustom>
				</CardActions>
			);
		default:
			return (
				<CardActions className="actionButtonWrapper">
					<ButtonCustom
						onClick={() =>
							history.push(`/product/${items[0].sku.product.slug}`)
						}>
						Beli Lagi
					</ButtonCustom>
				</CardActions>
			);
	}
};

const TrackAirwayBill = (props) => {
	const { resi, data } = props;
	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const showDate = (date) => {
		const dateSplit = date.split(" ");
		return dateSplit[1];
	};
	const showStatus = (status) => {
		const statusSplit = status.split("_");
		return statusSplit
			.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
			.join(" ");
	};

	return (
		<>
			<React.Fragment>
				<Typography onClick={handleClickOpen} style={{ cursor: "pointer" }}>
					{resi}
				</Typography>
				{data && (
					<BootstrapDialog
						onClose={handleClose}
						aria-labelledby="customized-dialog-title"
						open={open}>
						<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
							Status Pengiriman : {showStatus(data.status)}
						</DialogTitle>
						<DialogContent dividers>
							<Typography gutterBottom>
								<b>Awb Number :</b> {data.awb_number}
							</Typography>
							<Typography gutterBottom sx={{ mt: 1 }}>
								<i>History :</i>
							</Typography>
							{Object.keys(data.history).map((date) => (
								<Box key={date}>
									<Typography gutterBottom>
										<b>{date}</b>
									</Typography>
									{data.history[date].map((item, index) => (
										<Box key={index} sx={{ pl: 2 }}>
											<Typography gutterBottom>
												{showDate(item.date)} - {item.desc}
											</Typography>
										</Box>
									))}
								</Box>
							))}
						</DialogContent>
					</BootstrapDialog>
				)}
			</React.Fragment>
		</>
	);
};

const OrderCard = ({ orders, handleClick }) => {
	const { user } = useAuthContext();
	const { items, status, shipping, tracking, total } = orders;
	const history = useHistory();

	const isPDF = (url) => {
		return url.includes(".pdf");
	};

	const displayResi = (resi) => {
		if (resi) {
			if (isPDF(resi)) {
				return (
					<a href={resi} target="_blank">
						<span>Lihat Resi</span>
					</a>
				);
			}
			return <span>{resi}</span>;
		}
		return "Belum Ada Resi";
	};

	return (
		<WrapOrderCard>
			<Card className="card">
				{items.map((item, index) => {
					if (item.sku) {
						return (
							<ProductCardFull
								key={index}
								style={{ boxShadow: "none", marginBottom: "0" }}
								type="checkout"
								name={item.sku ? item.sku?.product?.name : ""}
								price={
									!isEmpty(user) && user.customer_type?.name === "Customer"
										? item.sku.price.consumer
										: item.sku.price.user
								}
								weight={item.sku.weight_gram}
								color={item.sku.properties.color}
								size={item.sku.properties.size}
								quantity={item.qty}
								image_url={item.sku.image_url}
								withQuantityButton={false}
								withDeleteButton={false}
								withCheckbox={false}
								withBorderBottom={true}
							/>
						);
					}
				})}
				<div className="container">
					<div className="total">
						<p>Total Pemesanan</p>
						<span>Rp {formatRupiah(total)}</span>
					</div>
				</div>
				{(status.code === STATUS_ORDERS.delivery.code ||
					status.code === STATUS_ORDERS.done.code) && (
					<div className="container">
						<div className="details">
							<p>
								Dikirim dengan {shipping.courier.name} {shipping.courier_type}
							</p>
							<p>Estimasi diterima pada {shipping.estimated}</p>
							<div className="resi">
								<p>No. Resi</p>
								<p>{displayResi(shipping.resi)}</p>
							</div>
						</div>
						<div className="tracking">
							{tracking ? (
								<>
									<p>
										{tracking.data.history ? (
											<TrackAirwayBill
												resi={tracking.data.awb_number}
												data={tracking.data}
											/>
										) : (
											<span>Tracking paket tidak ditemukan</span>
										)}
									</p>
								</>
							) : (
								<p>Resi sedang di input</p>
							)}
						</div>
					</div>
				)}

				{_getActionButton(orders, handleClick, history)}
			</Card>
		</WrapOrderCard>
	);
};

export default OrderCard;
