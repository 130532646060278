const Helpers = {
	ErrorHandler: (error) => {
		let message = "Silakan hubungi Administrator untuk informasi lebih lanjut";
		let severity = "error";

		if (error) {
			if (error.response) {
				if (error.response.status === 403) {
					message = "403 | Anda tidak memiliki izin untuk permintaan ini";
					severity = "warning";
				}

				if (error.response.status === 401) {
					message = "401 | Tidak sah, silakan masuk untuk melanjutkan";
					severity = "error";
				}

				if (error.response) {
					if (error.response.data.message) {
						message = error.response.data.message;
					} else if (error.response.data.errors) {
						error.response.data.errors.map((item) => {
							message = item.msg;
							return message;
						});
					}
				}
			}
		}

		return {
			message,
			severity,
		};
	},
};

export default Helpers;
