import { useMediaQuery } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import PromoPageDesktop from "./promo.desktop";
import PromoPageMobile from "./promo.mobile";

const PromoPage = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <PromoPageMobile {...props} /> : <PromoPageDesktop {...props} />;
};

export default PromoPage;
