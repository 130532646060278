import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapProfileMobile = styled.div`
	position: relative;

	.content {
		position: relative;
		padding-top: 25%;
		margin-bottom: 90px;
	}
	.bottomButton {
		position: fixed;
		bottom: 16px;
		width: 95%;
		margin: 0 auto;
		left: 0;
		right: 0;
	}

	@media (max-width: 768px) {
		padding: 16px;
	}
`;

export const WrapTags = styled.div`
	span {
		font-size: 12px;
		color: #C7C7C7;
	}
	.tag {
		font-size: 14px;
		font-weight: bold;
		color: #363636;
		width: auto;
		margin-right: 8px;
		text-transform: uppercase;
		:focus {
			background: ${constanta.COLOR.darkPink};
			color: #fff;
			border: 1px solid ${constanta.COLOR.darkPink};
		}
	}
`;

export const WrapMenu = styled.div`
	background: #EFF8FC;
	button{
	width: 100%;
	font-size: 13px;
	font-weight: 500;
	color: rgba(20, 20, 43, 0.48);
		&.active {
			background: #FFE2F1;
			color: #222741;
		}
	}

`;