import styled from 'styled-components'
import constanta from '../../configs/constanta'

export const WrapProductSlider = styled.div`
  .arrow-left {
    background: ${constanta.COLOR.primary};
    position: absolute;
    z-index: 1;
    top: 40%;
    border-radius: 10px;
    width: 41px;
    height: 41px;
    text-align: center;
    left: -10px;
    cursor: pointer;
  }
  .arrow-right {
    background: ${constanta.COLOR.primary};
    position: absolute;
    z-index: 1;
    top: 40%;
    border-radius: 10px;
    width: 41px;
    height: 41px;
    text-align: center;
    right: -10px;
    cursor: pointer;
  }

  .slick-dots {
    li {
      margin: 0;
    }
    li button:before{
      color: #DFE0DF; 
      font-size: 8px;
    }
    .slick-active button:before{
      color: ${constanta.COLOR.primary}; 
    }
  }



  @media (max-width: 500px) {
    padding: 0px 0px;
  }
`