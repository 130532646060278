import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EditProfileDesktop from "./EditProfile.desktop";
import EditProfileMobile from "./EditProfile.mobile";

const Profile = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");

	return isMobile ? <EditProfileMobile {...props} /> : <EditProfileDesktop {...props} />;
};

export default Profile;
