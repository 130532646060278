import { useMediaQuery } from "@material-ui/core";
import React from "react";
import RegisterAffiliatePageDesktop from "./RegisterAffiliatePage.desktop";
import RegisterAffiliatePageMobile from "./RegisterAffiliatePage.mobile";

const RegisterAffiliatePage = (props) => {
	const isMobile = useMediaQuery("(max-width:500px)");
	return isMobile ? (
		<RegisterAffiliatePageMobile {...props} />
	) : (
		<RegisterAffiliatePageDesktop {...props} />
	);
};
export default RegisterAffiliatePage;
