import { axiosInstance } from "./axiosInstance";


export const GET_REWARD_DAILY = (id) => {
	const url = `/api/daily-reward`;
	const data = axiosInstance.get(url, {
		params: {
			user_id: id,
		},
	});
	return data;
};


export const REWARD_DAILY_CLAIM = (body) => {
    const url = `/api/daily-reward`;
    const response = axiosInstance.post(url, body);
    return response;
};