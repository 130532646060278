import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import NoImageProduct from "../../assets/img/noImage.svg";
import CloseIcon from "@material-ui/icons/Close";
import { useMediaQuery } from "@material-ui/core";

export default function DetailHadiahModal({ open, handleClose, detail }) {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const isMobile = useMediaQuery("(max-width:500px)");

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={"md"}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle
					id="alert-dialog-title"
					disableTypography
					style={{
						fontWeight: "bold",
						display: "flex",
						justifyContent: "space-between",
						margin: "0",
					}}
				>
					<Typography variant="h6"> {`Hadiah Level ${detail.level} `}</Typography>
					<CloseIcon onClick={handleClose} />
				</DialogTitle>
				<DialogContent>
					<div >
						<img
							src={BASE_URL + "/" + detail.images ?? NoImageProduct}
							alt="product"
							style={{
								width: isMobile ? "100%" : "350px",
								height: isMobile ? "100%" : "146px",
								borderRadius: "10px",
							}}
						/>
						<div style={{ paddingTop: "12px" }}>
							<Typography>Capai target omset level 3 untuk mendapatkan</Typography>
							<Typography color="primary">{detail.reward_desc}</Typography>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
