import React, { useState, useEffect } from "react";
import { Layout } from "../../containers";
import { RESET_PASSWORD_EMAIL } from "../../services/auth.service";

import { WrapAuthForm } from "./style";
import {
	// TextField,
	OutlinedInput,
	useMediaQuery,
} from "@material-ui/core";

import { useAuthContext } from "../../contexts/Auth.context";
import { ButtonCustom } from "../../components";

import swal from "sweetalert";

const SendResetPassword = (props) => {
	const [form, setForm] = useState({
		email: "",
	});
	const { isAuthenticated } = useAuthContext();

	const isMobile = useMediaQuery("(max-width:500px)");

	const onChange = async (name, value) => {
		setForm({
			...form,
			[name]: value,
		});
	};
	const onSubmit = async () => {
		await RESET_PASSWORD_EMAIL(form)
			.then(async (response) => {
				if (response.message === "Ok") {
					swal("Berhasil", "Password reset email sent !", "success");
					window.location = `/validate-token/${form.email}`;
				}
			})
			.catch((e) => {
				swal(
					"Error",
					`${
						e.response.data?.errors?.email?.toString()
							? `${e.response.data?.errors?.email?.toString()},`
							: ""
					}
		            `,
					"error",
				);
			});
	};

	useEffect(() => {
		isAuthenticated && props.history.push("/");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout withNavbar={false} {...props}>
			<WrapAuthForm>
					<div className="header">
						<div className="title">Reset Password</div>
						<div className="subtitle">
							<p>Masukkan email yang terkait dengan akun Anda dan kami akan mengirimkan email berisi petunjuk untuk mengatur ulang kata sandi Anda.</p>
						</div>
					</div>
					<div style={isMobile ? { padding: 16 } : {}}>
					<div className="formGroup">
						<div style={{ marginBottom: "16px" }}>
							<OutlinedInput
								id="standard-adornment-email"
								placeholder="Email"
								fullWidth
								type={"text"}
								value={form.email}
								size="small"
								onChange={(e) => onChange("email", e.target.value)}
							/>
						</div>
					</div>
					<a href={"/login"} >
						<ButtonCustom color="lightBlue">
							Kembali Login
						</ButtonCustom>
					</a>
					<ButtonCustom color="primary" onClick={onSubmit} style={{ marginTop: "11px"}}>
						Kirim Instruksi
					</ButtonCustom>
				</div>
			</WrapAuthForm>
		</Layout>
	);
};

export default SendResetPassword;
