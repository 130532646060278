import React from 'react'
import { useHistory } from "react-router-dom";
// import swal from '@sweetalert/with-react';
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
// import { isLoggedIn } from '../../../services/axios';

// icon
import HomeIcon from '../../../assets/icons_1/home.svg'
import Promo from '../../../assets/icons_1/ticket.svg'
import Pesanan from '../../../assets/icons_1/shopping-cart.svg'
import UserIcon from '../../../assets/icons_1/user.svg'
import ProductIcon from '../../../assets/icons_1/basket.svg'
import StarIMG from "../../../assets/icons_1/star_1.svg";
// import { ReactComponent as Home } from '../../../assets/icons/mobile/menu_home.svg';
// import { ReactComponent as Bisnis } from '../../../assets/icons/mobile/menu_education.svg';
// import { ReactComponent as Notif } from '../../../assets/icons/mobile/icon_notification.svg';
// import { ReactComponent as Profile } from '../../../assets/icons/mobile/menu_profile.svg';
// import tokoku from '../../../assets/images/logo.svg'
// import NeedLoginSwal from '../../needLoginSwal';

const ToolbarMobile = withStyles(style)((props) => {
  const history = useHistory()
  const { classes } = props;
  const [storeFrontName, setstoreFrontName] = React.useState()

  React.useEffect(() => {
    const getStoreFrontName = () => {
      const nameStoreFront = localStorage.getItem("STORE_FRONT")
      if (nameStoreFront !== null) {
        setstoreFrontName(nameStoreFront)
      } else {
        const nameStoreFrontFromUserData = JSON.parse(localStorage.getItem('USER_DATA'))
        setstoreFrontName(nameStoreFrontFromUserData?.storefront?.slug)
      }
    }

    // if (isLoggedIn()) {
    //   getStoreFrontName()
    // }
  }, [])
  

  const handleToProfile = () =>{
    if (localStorage.getItem("TOKEN") === null) {
      window.location = "/login";
    } else {
      props.history.push("/akun")
    }
  }

  const menuToolbar = [
    {
      name: 'Beranda',
      icon: HomeIcon ,
      path: '/',
      onClick: () => props.history.push("/"),
    },
    {
      name: 'Promo',
      icon: Promo ,
      path: '/promo',
      onClick: () => props.history.push("/promo"),
    },
    {
      name: 'Produk',
      icon: ProductIcon ,
      path: '/products',
      onClick: () => props.history.push("/products"),
    },
    {
      name: 'Pesanan',
      icon: Pesanan ,
      path: '/orders/0',
      onClick: () => props.history.push("/orders/0"),
    },
    {
      name: 'Akun',
      icon: UserIcon ,
      path: '/akun',
      onClick: handleToProfile,
    },
  ]
  
  return (
    <div style={{ position: 'fixed', bottom: 0, backgroundColor: '#F8819E', width: '100%', minHeight: 73, boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', borderRadius: '16px 16px 0 0', paddingTop: 10}}>
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: 'auto' }}>
        {
          menuToolbar.map((res, index) => 
            <div key={index + 'menutoolbar'} onClick={res.onClick} style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 5, minWidth: '42px'}} >
              <div style={res.name === 'Produk' ? { position: 'absolute', bottom: 50, backgroundColor: 'white', borderRadius: '50%', display: 'flex', alignItems: 'center', padding: 20, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' } : {}} className={res.name === 'Produk' ? window.location.pathname === res.path ? classes.tokokuClicked : "" : ''}>
                <img src={res.icon} alt={res.name} />
              </div>
              <div style={res.name === 'Produk' ? { marginTop: 18 } : { }} className={window.location.pathname === res.path ? classes.buttonClicked : classes.button}>{res.name}</div>
            </div>
          )
        }
      </div>
    </div>
  )
})

export default ToolbarMobile