import styled from "styled-components";
import constanta from "../../configs/constanta";

export const WrapAlamatMobile = styled.div`
	position: relative;
	background-color: #EFF8FC;
	.content {
		position: relative;
		padding-top: 25%;
		margin-bottom: 100px;
	}
	.bottomButton {
		position: fixed;
		z-index: 999;
		bottom: 0px;
		width: 100%;
		margin: 0 auto;
		left: 0;
		right: 0;
		height: 60px;
		background-color: #fff;
		padding: 16px;
		button {
			width: 92%;
		}
	}
	.formInput {
		margin-bottom: 16px;
		.MuiOutlinedInput-root {
			background-color: #fff;
			border-radius: 4px;
			border: none!important;
		}
		span {
			font-size: 12px;
			margin-top: 8px;
			position: relative;
			display: block;
			color: #666;
		}
		fieldset{
			border-color: transparent;
		}
	}
	.MuiFormLabel-asterisk,
	.MuiInputLabel-asterisk {
		display: none !important;
	}

	.cardAlamat {
		border:none;
		border-radius: 5px;
		color: #333;
		margin-bottom: 16px;
		.content {
			padding: 8px;
			margin: 0px !important;
		}
		.label {
			padding: 8px;
		}
		.action {
			display: flex;
			div {
				width: 100%;
				text-align: center;
				cursor: pointer;
				color: ${constanta.COLOR.primary};
				border-top: 1px solid #ddd;
				padding: 8px;
				font-size: 12px;
				:last-child {
					border-left: 1px solid #ddd;
				}
			}
		}
	}

	@media (max-width: 768px) {
		padding: 16px;
	}
`;

export const CardAlamat = styled.div`
	border: 1px solid #ddd;
	border-radius: 5px;
	color: #333;
	padding-top: 12px;

	margin-bottom: 16px;
	.content {
		padding: 8px;
		margin: 0px !important;
	}
	.label {
		padding: 8px;
	}
	.container-actions {
		border-top: 1px solid #ddd;
		padding-left: 18px;
		padding-right: 18px;
		.actions {
			display: flex;
		}
		.action {
			color: ${constanta.COLOR.primary};
			padding-top: 6px;
			padding-bottom: 6px;
			cursor: pointer;
		}
	}
`;
