import React from "react";
import { WrapShippingAddress } from "./style";
import iconPointingMap from "../../assets/icons/iconPointingMap.svg";
import { ReactComponent as IconArrowRight } from "../../assets/icons/iconArrowRight.svg";
import { ReactComponent as IconLocation } from "../../assets/icons_1/location.svg";
import { isEmpty } from "lodash";
import { useHistory } from "react-router";
import { Grid, Divider } from "@material-ui/core";

const _listAddress = (listAddress) => {
	let history = useHistory();
	if (isEmpty(listAddress)) {
		return(
			<div className="label"
				onClick={() => history.push("/alamat/add")}
				style={{ display: "flex", justifyContent: "space-between" }}>
				<div style={{ display: "flex"}}>
					<IconLocation />
					<div className="">Pilih Alamat</div>
				</div>
				<IconArrowRight />
			</div>
		)
	}
	const alamat = listAddress.filter((x) => x.isPrimary)[0];
	const _extractNewLineAlamatLengkap = (address) =>
		address.split("\n").map((text, key) => <p key={key}>{text}</p>);
	return (
		<Grid container>
			<Grid item xs={12}>
				<div className="content">
					<p>
						{alamat.namaPenerima} | {alamat.noTelpPenerima}
					</p>
					{_extractNewLineAlamatLengkap(alamat.alamatLengkapPenerima)}
					<p>
						{alamat.selectSubdistrictName} - {alamat.selectCityType}{" "}
						{alamat.selectCityName}, {alamat.selectProvinceName} {alamat.kodePos}
					</p>
				</div>
			</Grid>
		</Grid>
	);
};

const ShippingAddressComponent = ({ listAddress, naked = false }) => {
	let history = useHistory();
	return (
		<WrapShippingAddress naked={naked}>
				{!isEmpty(listAddress) && (
					<div className="header">
						<div className="label">
							<IconLocation />
							Alamat Pengiriman
						</div>
						<div className="action">
							<button onClick={() => history.push("/alamat")}>Pilih Alamat Lain</button>
						</div>
					</div>
				)}
			<div className="list">{_listAddress(listAddress)}</div>
		</WrapShippingAddress>
	);
};

export default ShippingAddressComponent;
