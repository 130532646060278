import { useMediaQuery } from "@material-ui/core";
import React from "react";
import RegisterPageDesktop from "./RegisterPage.desktop";
import RegisterPageMobile from "./RegisterPage.mobile";

const RegisterPage = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return isMobile ? <RegisterPageMobile {...props} /> : <RegisterPageDesktop {...props} />;
};
export default RegisterPage;
