import React, { useState, useEffect } from "react";
import { Layout, Navbar } from "../../containers";

const AccountPageDesktop = (props) => {
    return (
        <Layout>
            <div>AccountPageDesktop</div>
        </Layout>
    );
}

export default AccountPageDesktop;