const constanta = {
	APP_HOST_NAME: process.env.REACT_APP_BASE_URL,
	GOOGLE_CLIENT_ID: "539589027711-t66o0onnqne4pfh0aviusn4c2150k7i2.apps.googleusercontent.com",
	COLOR: {
		white: "#FFF",
		pink: "#F969AE",
		darkPink: "#D83684",
		lightPink: "#FFE4F2",
		primary: "#E174B8",
		darkRed: "#E62424",
		textPrimary: "#F969AE",
		text: '#222741',
		secondary: "#FFFFD8",
		lightBlue: "#EFF8FC",
		stroke: "#E9DDDD",
		strk: "#B9B9B9",
		blue: "#3AD4E0",
		red: "#FF5B5B",
	},
	LOCAL_STORAGE: {
		PAYMENT: "CURRENTLY_PAYMENT",
	},
};

export const CUSTOMER_SERVICE_CONSTANT = {
	customerService: 2,
	paymentService: 1,
};

export const ORDERS_HANDLER_CONSTANT = {
	cancel: 1,
	payment: 2,
	done: 3,
};

export default constanta;
