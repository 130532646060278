import styled from "styled-components";
import constanta from "../../../configs/constanta";

export const WrapProfileMobile = styled.div`
	position: relative;
	background: ${constanta.COLOR.lightBlue};
	.content {
		position: relative;
		padding-top: 25%;
		margin-bottom: 90px;
	}
	.bottomButton {
		position: fixed;
		bottom: 16px;
		width: 95%;
		margin: 0 auto;
		left: 0;
		right: 0;
	}
	.MuiOutlinedInput-root {
		background-color: #fff;
		border-radius: 4px;
		border: none!important;
	}
	fieldset{
		border-color: transparent;
	}

	@media (max-width: 768px) {
		padding: 16px;
	}
`;


export const WrapMenu = styled.div`
    position: relative;
    background: ${constanta.COLOR.lightBlue};
    padding: 0 12px;
    background: #fff;
    border-radius: 12px;
	margin-bottom: 22px;
    button {
        width: 100%;
        justify-content: flex-start;
        height: 56px;
        border-bottom: 1px solid #e5e5e5;
    }
	.wrapper{
		display: flex;
		justify-content: space-between;
		width: 100%;
		text-transform: none;
	}
    button.logout {
        justify-content: space-between;
        color: ${constanta.COLOR.red};

    }
`;
