import React from "react";
import { WrapperQuantityCounter } from "./style";
import Spacer from "../Spacer";
const QuantityCounterComponent = ({
	style,
	total = 1,
	onClickMinus = null,
	onClickPlus = null,
}) => {
	return (
		<WrapperQuantityCounter style={style}>
			<button onClick={onClickMinus} style={{ cursor: "pointer" }}>
				-
			</button>
			<Spacer variant="horizontal" size="6" />
			<span>{total}</span>
			<Spacer variant="horizontal" size="6" />
			<button onClick={onClickPlus} style={{ cursor: "pointer" }}>
				+
			</button>
		</WrapperQuantityCounter>
	);
};

export default QuantityCounterComponent;
