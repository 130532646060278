import swal from "sweetalert";
import ORDER_STAGES from "../../configs/orderStages";
import {
	CANCEL_ORDER,
	DONE_ORDER,
	GET_ORDER_BY_FILTER,
	GET_ORDER_BY_ID,
} from "../../services/orders.service";
import {
	GET_SHIPPING_TRACKING,
	GET_TRACKING_AIRWAYBILL,
} from "../../services/supportData.service";

export const getStages = (index = 0) => {
	const key = Object.keys(ORDER_STAGES)[index];
	return ORDER_STAGES[key];
};

const replaceStage = (stage) => {
	if (stage === ORDER_STAGES.waiting_payment) {
		return `&filter[${ORDER_STAGES.waiting_payment}]=1&filter[${ORDER_STAGES.forwarded}]=1`;
	}
	if (stage === ORDER_STAGES.canceled) {
		return `&filter[${ORDER_STAGES.canceled}]=1&filter[${ORDER_STAGES.canceledAdmin}]=1`;
	}
	return `&filter[${stage}]=1`;
};

// export const getUserProductByStage = async ({ stage }) => {
// 	// const user = JSON.parse(localStorage.getItem("USER_NAISHA"));
// 	const dataOrder = JSON.parse(localStorage.getItem("DATA_ORDER"));
// 	if (stage === ORDER_STAGES.delivery || stage === ORDER_STAGES.done) {
// 		const response = await GET_ORDER_BY_FILTER(
// 			`?filter[customer_id]=${dataOrder.customer.id}${replaceStage(stage)}`,
// 		);
// 		await Promise.all(
// 			response.data.map(async (value) => {
// 				if (value.shipping.resi) {
// 					const tracking = await GET_SHIPPING_TRACKING({
// 						waybill: value.shipping.resi,
// 						courier: value.shipping.courier.code,
// 					});
// 					Object.assign(value, { tracking });
// 				}
// 			}),
// 		);
// 		return response;
// 	}
// 	return await GET_ORDER_BY_FILTER(
// 		`?filter[customer_id]=${dataOrder.customer.id}${replaceStage(stage)}`,
// 	);
// };

export const getUserProductByStage = async ({ stage }) => {
	const user = JSON.parse(localStorage.getItem("USER_NAISHA"));
	if (stage === ORDER_STAGES.delivery || stage === ORDER_STAGES.done) {
		// const response = await GET_ORDER_BY_FILTER(
		// 	`?filter[customer_name]=${user.name}${replaceStage(stage)}`,
		// );
		const response = await GET_ORDER_BY_FILTER(`?${replaceStage(stage)}`);
		await Promise.all(
			response.data.map(async (value) => {
				const tracking = await GET_TRACKING_AIRWAYBILL(value.shipping.resi);
				Object.assign(value, { tracking });
			})
		);
		return response;
	}
	return await GET_ORDER_BY_FILTER(`?${replaceStage(stage)}`);
};

export const handleCancelOrder = (id) => {
	swal({
		title: "Batalkan Pesanan",
		text: "Anda yakin untuk membatalkan pesanan ?",
		buttons: {
			cancel: "Batal",
			ok: "Yakin",
		},
	}).then(async (btn) => {
		if (btn === "ok") {
			await CANCEL_ORDER(id).then(() => {
				window.location.reload();
			});
		}
	});
};

export const handlePaymentOrder = async (id, newPayment, history) => {
	const response = await GET_ORDER_BY_ID(id);
	newPayment(response.data[0]);
	history.push("/payment");
};

export const handleDoneOrder = (id) => {
	swal({
		title: "Pesanan Diterima",
		text: "Anda yakin bahwa pesanan sudah di terima ?",
		buttons: {
			cancel: "Batal",
			ok: "Yakin",
		},
	}).then(async (btn) => {
		if (btn === "ok") {
			await DONE_ORDER(id).then(() => {
				window.location.reload();
			});
		}
	});
};
