import { Radio, withStyles, Button } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import constanta from "../../configs/constanta";
import { Layout } from "../../containers";
import { COURIR_DROPSHIPPER } from "../../services/supportData.service";
import { handleSelect, handleSubmitSelect } from "./handler";
import { WrapPaymentMethodPage } from "./style";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const DropshipperDesktopPage = (props) => {
	const [listDropshipper, setDropshipper] = useState([]);
	const [isRefetch, setIsRefetch] = useState(false);

	const PinkRadio = withStyles({
		root: {
			color: `${constanta.COLOR.primary}`,
			"&$checked": {
				color: `${constanta.COLOR.primary}`,
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const isDisabledButton = listDropshipper.filter((x) => x.isChecked).length > 0 ? false : true;
	useEffect(() => {
		const fetch = async () => {
			let temp = [];
			let getData = await COURIR_DROPSHIPPER();
			getData.data.map((item) => {
				temp.push({
					...item,
					isChecked: false,
				});
				return "";
			});
			setDropshipper(temp);
			localStorage.setItem("DROPSHIPPER", JSON.stringify(temp));
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			const getLocalStorage = JSON.parse(localStorage.getItem("DROPSHIPPER"));
			setDropshipper(getLocalStorage);
			setIsRefetch(false);
		};
		isRefetch && fetch();
	}, [isRefetch]);

	return (
		<Layout {...props}>
			<WrapPaymentMethodPage>
				<div style={{ paddingBottom: "24px", display: "flex", alignItems: "center" }}>
					<ArrowBackIcon
						color="primary"
						style={{ marginRight: "8px", cursor: "pointer" }}
						onClick={() => props.history.push("/checkout")}
					/>
					<p>Pilih Kurir Dropshipper</p>
				</div>
				{!isEmpty(listDropshipper) &&
					listDropshipper.map((item, index) => (
						<div className="list" key={index}>
							{/* <div className="logo" style={{ width: "80px", height: "80px" }}>
								<img
									src={item.icon}
									alt={item.code}
									style={{ width: "100%", height: "100%" }}
								/>
							</div> */}
							<div className="info" style={{ display: "flex", alignItems: "center" }}>
								<span style={{ fontSize: "16px" }}>{item.name}</span>
							</div>
							<div className="radioBtn">
								<PinkRadio
									checked={item.isChecked}
									onChange={() =>
										handleSelect(
											item.code,
											listDropshipper,
											setDropshipper,
											setIsRefetch,
										)
									}
									value={item.service}
								/>
							</div>
						</div>
					))}
				<div style={{ margin: "32px 0px" }}>
					<Button
						size="large"
						style={{ width: "100%", color: "white" }}
						color="primary"
						variant="contained"
						disabled={isDisabledButton}
						onClick={() =>
							handleSubmitSelect(listDropshipper, setIsRefetch, props.history)
						}
					>
						Pilih Kurir Dropshipper
					</Button>
				</div>
			</WrapPaymentMethodPage>
		</Layout>
	);
};
export default DropshipperDesktopPage;
