import React, { useState, useEffect } from "react";
import { Layout } from "../../containers";
import { WrapAuthForm } from "./style";
import {
	// TextField,
	OutlinedInput,
	useMediaQuery,
	InputAdornment,
	IconButton,
} from "@material-ui/core";
import { useAuthContext } from "../../contexts/Auth.context";
import swal from "sweetalert";
import { ButtonCustom } from "../../components";
import { RESET_PASSWORD_USER } from "../../services/auth.service";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import constanta from "../../configs/constanta";

const ResetPassword = (props) => {
	const { isAuthenticated } = useAuthContext();

	const [showPassword, setShowPassword] = useState(false);
	const [form, setForm] = useState({
		password: "",
		password_confirmation: "",
	});
	const isMobile = useMediaQuery("(max-width:500px)");

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const onChange = async (name, value) => {
		setForm({
			...form,
			[name]: value,
		});
	};

	const onSubmit = async () => {
		let payload = {
			email: props.match.params.email,
			token: localStorage.getItem("TOKEN_EMAIL"),
			...form,
		};
		await RESET_PASSWORD_USER(payload)
			.then((response) => {
				swal(
					"Berhasil",
					"Password reset success! Please login with your new password. !",
					"success",
				);
				window.location = "/login";
			})
			.catch((e) => {
				swal(
					"Error",
					`
                ${
					e.response.data?.errors?.email?.toString()
						? `${e.response.data?.errors?.email?.toString()},`
						: ""
				} 
                ${
					e.response.data?.errors?.password?.toString()
						? `${e.response.data?.errors?.password?.toString()}`
						: ""
				}`,
					"error",
				);
			});
	};

	useEffect(() => {
		isAuthenticated && props.history.push("/");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout withNavbar={false} {...props}>
			<WrapAuthForm>
				<div className="header">
					<div className="title">Buat Password Baru</div>
					<div className="subtitle">
						<p>Kata sandi baru Anda harus berbeda dari kata sandi yang digunakan sebelumnya.</p>
					</div>
				</div>
				<div style={isMobile ? { padding: 16 } : {}}>
					<div className="formGroup">
						{/* <div style={{ marginBottom: "16px" }}>
							<label>Email</label>
							<OutlinedInput
								id="standard-adornment-email"
								placeholder="Email"
								fullWidth
								type={"text"}
								value={form.email}
								size="small"
								onChange={(e) => onChange("email", e.target.value)}
							/>
						</div> */}
						<div style={{ marginBottom: "16px" }}>
							<OutlinedInput
								id="standard-adornment-password"
								placeholder="Password"
								fullWidth
								size="small"
								type={showPassword ? "text" : "password"}
								value={form.password}
								onChange={(e) => onChange("password", e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</div>
						<div>
							<OutlinedInput
								id="standard-adornment-password"
								placeholder="Ulangi Password"
								fullWidth
								size="small"
								type={showPassword ? "text" : "password"}
								value={form.password_confirmation}
								onChange={(e) => onChange("password_confirmation", e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</div>
					</div>
					<a href={"/login"} >
						<ButtonCustom color="lightBlue">
							Kembali Login
						</ButtonCustom>
					</a>
					<ButtonCustom color="primary" onClick={onSubmit}>
						Simpan Perubahan
					</ButtonCustom>
				</div>
			</WrapAuthForm>
		</Layout>
	);
};

export default ResetPassword;
