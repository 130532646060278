import React, { useEffect, useState } from "react";
import { Layout, Navbar } from "../../containers";
import { EmptyState, Skeleton } from "../../components";
import FileIcon from "../../assets/img/Paper.png";
import OrderCard from "../../components/OrderCard/OrderCard.component";
import { WrapperOrder } from "./style";
import {
	getStages,
	getUserProductByStage,
	handleCancelOrder,
	handleDoneOrder,
	handlePaymentOrder,
} from "./handler";
import { Typography, CircularProgress, Grid, Card, Button } from "@material-ui/core";

const OrderHistoryMobile = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        setOrders([]);
        const fetchOrder = async () => {
            setLoading(true);
            const response = await getUserProductByStage({
                stage: 'done',
            });
            setOrders(response.data);
            setLoading(false);
        };
        fetchOrder();
    }, []);


    return (
        <Layout withNavbar={false} {...props}>
			<Navbar type="navback" title="Riwayat Pesanan" {...props} />
            <WrapperOrder>
            <div className="orders-content" style={{padding: "80px 20px"}}>
                {isLoading ? (
                    		<div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "300px",
                            }}
                        >
                            <CircularProgress />
                        </div>
                ) :
                    <>
                        {orders.length > 0 ? 
                        orders.map((order, index) => (
                            <OrderCard
                                key={index}
                                orders={order}
                            />
                        ))
                        : 
                            <div className="empty">
                                <EmptyState title="Tidak ada transaksi" image={FileIcon} />
                            </div>
                        } 
                    </>
                }
            </div>
            </WrapperOrder>
        </Layout>
    );
};

export default OrderHistoryMobile;