import React, { useState, useEffect } from "react";
import { Layout } from "../../containers";
import { LOGIN, LOGIN_GOOGLE } from "../../services/auth.service";
import { GET_USER } from "../../services/user.service";
import { WrapAuthForm } from "./style";
import {
  // TextField,
  OutlinedInput,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import GoogleLogin from "react-google-login";
import constanta from "../../configs/constanta";
import brandGoogle from "../../assets/brand/google.svg";
import { useAuthContext } from "../../contexts/Auth.context";
import { ButtonCustom } from "../../components";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import bannerImg from "../../assets/img/login_banner.png";
import swal from "sweetalert";

const LoginPageMobile = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const { isAuthenticated } = useAuthContext();

  const isMobile = useMediaQuery("(max-width:500px)");

  const onChange = async (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  const onSubmit = async () => {
    await LOGIN(form)
      .then(async (response) => {
        if (response.message === "Ok") {
          await GET_USER(response.user.id, response.token.accessToken).then(
            (user) => {
              localStorage.setItem("USER_NAISHA", JSON.stringify(user.data));
            }
          );
          localStorage.setItem("TOKEN", JSON.stringify(response.token));
          localStorage.removeItem("TOKEN_EMAIL");
          swal("Berhasil", "Login berhasil !", "success");
          window.location = "/";
        }
      })
      .catch((e) => {
        swal(
          "Error",
          `${
            e.response.data?.errors?.email?.toString()
              ? `${e.response.data?.errors?.email?.toString()},`
              : ""
          } 
            ${
              e.response.data?.errors?.password?.toString()
                ? `${e.response.data?.errors?.password?.toString()},`
                : ""
            }`,
          "error"
        );
      });
  };

  const responseGoogleSignIn = async (e) => {
    let value = e.profileObj;
    if (value) {
      const body = {
        name: value.name,
        email: value.email,
        google_id: value.googleId,
        avatar: value.imageUrl,
      };
      await LOGIN_GOOGLE(body)
        .then((response) => {
          if (response.message === "Ok") {
            localStorage.setItem("USER_NAISHA", JSON.stringify(response.user));
            localStorage.setItem("TOKEN", JSON.stringify(response.token));

            swal("Berhasil", "Login berhasil !", "success");
            window.location = "/";
          }
        })
        .catch(() => {
          swal("Error", "Email / password tidak cocok", "error");
        });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    isAuthenticated && props.history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout withNavbar={false} {...props}>
      <WrapAuthForm>
        <div>
          {/* add image banner here */}
          <img
            src={bannerImg}
            alt="banner"
            style={{
              width: "100%",
              position: "absolute",
              top: -32,
              left: 0,
              maxHeight: 200,
            }}
          />
        </div>
        <div style={{ padding: 16, paddingTop: 220 }}>
          <div className="formGroup">
            <div style={{}}>
              <OutlinedInput
                id="standard-adornment-email"
                placeholder="Email"
                fullWidth
                type={"text"}
                value={form.email}
                size="small"
                onChange={(e) => onChange("email", e.target.value)}
              />
            </div>
            <div>
              <OutlinedInput
                id="standard-adornment-password"
                placeholder="Password"
                fullWidth
                size="small"
                style={{ marginTop: 9 }}
                type={showPassword ? "text" : "password"}
                value={form.password}
                onChange={(e) => onChange("password", e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </div>
          <ButtonCustom color="primary" onClick={onSubmit}>
            Masuk
          </ButtonCustom>
          <div className="separator">Atau</div>
          <GoogleLogin
            clientId={constanta.GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <div className="withSocial" onClick={renderProps.onClick}>
                <div className="center">
                  <div className="logo">
                    <img src={brandGoogle} alt="google" />
                  </div>
                  <div className="text">Login dengan Google</div>
                </div>
              </div>
            )}
            buttonText="Login"
            onSuccess={responseGoogleSignIn}
            onFailure={responseGoogleSignIn}
            cookiePolicy={"single_host_origin"}
          />
          {/* <div className="withSocial">
					<div className="center">
						<div className="logo">
							<img src={brandApple} alt="apple" />
						</div>
						<div className="text">{text} dengan Apple</div>
					</div>
				    </div> */}
          <div className="others">
            <p>
              <a href={"/send-reset-password"}>Kamu lupa Password?</a>
            </p>
            <p>
              Belum punya akun ? <a href={"/register"}>Daftar dulu sini</a>
            </p>
          </div>
          <div style={{ textAlign: "center", marginTop: 16 }}></div>
        </div>
      </WrapAuthForm>
    </Layout>
  );
};

export default LoginPageMobile;
