import { axiosInstance } from "./axiosInstance";
import qs from "querystring";
import axios from "axios";

export const GET_COURIERS = () => {
	const url = `/api/support/shipping/couriers`;
	const res = axiosInstance.get(url);
	return res;
};

export const EST_SHIPPING_COST = (body) => {
	const url = `${process.env.REACT_APP_BASE_URL}/api/support/kirimpaket/cost`;
	let config = {
		method: "post",
		url: url,
		headers: {
			Authorization:
				`Bearer ` + JSON.parse(localStorage.getItem("TOKEN")).accessToken,
			"Content-Type": "application/x-www-form-urlencoded",
		},
		data: qs.stringify(body),
	};
	const response = axios(config);
	return response;
};

export const GET_SHIPPING_TRACKING = (body) => {
	const url = `/api/support/shipping/tracking`;
	const res = axiosInstance.post(url, body);
	return res;
};

export const GET_TRACKING_AIRWAYBILL = async (id) => {
	return await axiosInstance.get(
		`/api/shipping/track-airwaybill?awb_number=${id}`
	);
};

export const GET_PAYMENTS_METHOD = () => {
	const url = `/api/payment-methods`;
	const res = axiosInstance.get(url);
	return res;
};

export const GET_MARKET_PLACE = () => {
	const url = `/api/support/dropship/marketplaces`;
	const res = axiosInstance.get(url);
	return res;
};

export const COURIR_DROPSHIPPER = () => {
	const url = `/api/support/dropship/couriers`;
	const res = axiosInstance.get(url);
	return res;
};

export const GET_COURIER_RECOMENDATION = () => {
	const url = `/api/support/courier-recommendation`;
	const res = axiosInstance.get(url);
	return res;
};
