import { useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { useAuthContext } from "../../contexts/Auth.context";
import NavbarDesktopComponent from "./NavbarDesktop.component";
import NavbarMobileComponent from "./NavbarMobile.component";
import { useOrderContext } from "../../contexts/Order.context";

const NavbarContainer = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const { isAuthenticated } = useAuthContext();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const { cart } = useOrderContext()

  const toggleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer);
  };

  return (
    <div>
      {isMobile ? (
        <NavbarMobileComponent
          isAuthenticated={isAuthenticated}
          toggleDrawer={toggleDrawer}
          isOpenDrawer={isOpenDrawer}
          cart={cart}
          {...props}
        />
      ) : (
        <NavbarDesktopComponent isAuthenticated={isAuthenticated} cart={cart} {...props} />
      )}
    </div>
  );
};

export default NavbarContainer;
