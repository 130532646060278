import styled from "styled-components";

export const WrapHome = styled.div`
	position: relative;

	.logoGroup {
		width: 100%;
		padding: 0px 24px;
		display: flex;
		margin-top: 16px;
		.item {
			cursor: pointer;
			text-align: center;
			width: 100%;
			p {
				font-size: 12px;
				color: #333;
			}
		}
	}

	@media (max-width: 500px) {
		h3 {
			padding: 0px 16px;
			font-size: 16px;
		}
	}
`;
